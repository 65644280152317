import { createContext, useEffect, useState } from "react";
// import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import useNotificationSound from "../components/reusables/newSoundReusable";
// import { formatMetaData } from "../utils/helper/Helper";

const ActionContext = createContext({
  addressStep: '',
  setAddressStep: (param) => {},
  createBusiness: false,
  createBusinessFunc: (param) => {},
  createRecipientGlobal: false,
  createRecipientGlobalFunc: (param) => {},
  editRecipientGlobal: false,
  editRecipientGlobalFunc: (param) => {},
  createRecipientGlobalType: "",
  createRecipientGlobalTypeFunc: (param) => {},
  sideMenuDrop: {},
  setSideMenuDrop: (param) => {},
  showProfileDrop: false,
  setShowProfileDrop: (param) => {},
  verificationSideBar: false,
  setVerificationSideBar: (param) => {},
  showChart: false,
  setShowChart: (param) => {},
  showAmount: true,
  setShowAmount: (param) => {},
  employeeEmail: "",
  setEmployeeEmail: (param) => {},
  checkShow: {},
  setCheckShow: (param) => {},
  showPinSet: true,
  setShowPinSet: (param) => {},
  updatePin: false,
  setShowUpdate: (param) => {},
  showVerifySlide: false,
  setShowVerifySlide: (param) => {},
  isLogout: false,
  setIsLogout: (param) => {},
  mobileSlide: false,
  setMobileSlide: (param) => {},
  toggleTheme: false,
  setToggleTheme: (param) => {},
  sideBarNav: "minimal",
  setSideBarNav: (param) => {},
  perPageVal: 10,
  setPerPage: (param) => {},
  verificationBtn: {},
  setVericationBtn: (param, val) => {},
  currentAccount: {},
  setCurrentAccount: (param) => {},
  currentAccountDrop: false,
  setCurrentAccountDrop: (param) => {},
  reCaptchaToken: "",
  setRaCaptchaToken: (param) => {},
  showChangeAccountBtn: false,
  setShowChangeAccountBtn: (param) => {},
  showGeneralLoader: false,
  setShowGeneralLoader: (param) => {},
  confettiVal: false,
  setConfettiVal: (param) => {},
  unfreezeCard: false,
  setUnfreezeCard: (param) => {},
  playSound: false,
  soundVal: "",
  setPlaySound: (param) => {},
  allNotifications: [],
  setAllNotifications: (param) => {},
  businessLogo: "",
  setBusinessLogo: (param) => {},
  globalCurrency: {},
  setGlobalCurrency: (param) => {},
  globalCurrencyInputs: {},
  setGlobalCurrencyInputs: (param) => {},
  verificationPopUp: false,
  setVerificationPopUp: (param) => {},
  trxPin: false,
  setTrxPin: (param) => {},
  imgPreviewUrl: '',
  setImgPreviewVal: (param) => {},
});

export function ActionContextProvider(props) {
  // const { user } = useSelector((state) => state.user);
  const location = useLocation();
  const [sideBarValNav, setSideBarValNav] = useState(
    localStorage.getItem("sidebar-theme-style") || "solid"
  );
  const [editRecipientVal, setEditRecipientVal] = useState(false);
  const [perPageValue, setPerPageValue] = useState({ label: "50", value: 50 });
  const [toggleThemeVal, setToggleThemeVal] = useState(false);
  const [isLogoutVal, setIsLogoutVal] = useState(false);
  const [currentAccountVal, setCurrentAccountVal] = useState({});
  const [genralLoaderVal, setShowGeneralLoaderVal] = useState(false);
  const [currentAccountDropVal, setCurrentAccountDropVal] = useState(false);
  const [showVerificationVal, setShowVerificationVal] = useState({
    bvn: false,
    id: false,
    nin: false,
  });
  const [showMobileSlide, setShowMobileSlide] = useState(false);
  const [verificationSideBar, setVirificationSidebar] = useState(false);
  const [showVerifySlideVal, setshowVerifySlideVal] = useState(false);
  const [showChartVal, setShowChartVal] = useState(false);
  const [showAmountVal, setShowAmountVal] = useState(true);
  const [showPinSetVal, setShowPinSetval] = useState(true);
  const [profileDropVal, setProfileDropVal] = useState(false);
  const [showUpdateVal, setShowUpdateVal] = useState(false);
  const [unfreezeCardVal, setUnfreezeCardVal] = useState(false);
  const [recaptchaTokenVal, setRecaptChaTokenVal] = useState("");
  const [popUpVal, setPopUpVal] = useState(false)
  const [showAccountChangeBtnVal, setShowAccountChangeBtnVal] = useState(false);
  const [checkShowVal, setCheckShowVal] = useState({
    one: false,
    two: false,
    three: false,
    four: false,
    five: false,
    six: false,
    seven: false,
    eight: false,
    nine: false,
  });
  const [sideMenuDropVal, setSideMenuDropVal] = useState({
    one: location.pathname.includes("/dashboard-bill-payment") ? true : false,
    two:
      location.pathname.includes("/dashboard-payroll") ||
      location.pathname.includes("/dashboard-payment-links") ||
      location.pathname.includes("/dashboard-pos") ||
      location.pathname.includes("/dashboard-store") ||
      location.pathname.includes("/dashboard-invoice")
        ? true
        : false,
    three:
      location.pathname.includes("/dashboard-finance-invoice") ||
      location.pathname.includes("/dashboard-loan") ||
      location.pathname.includes("/dashboard-overdraft")
        ? true
        : false,
    four:
      location.pathname.includes("/dashboard-add-money") ||
      location.pathname.includes("/dashboard-send-money") ||
      location.pathname.includes("/dashboard-payment-request")
        ? true
        : false,
    five: location.pathname.includes("/dashboard-global-account")
      ? true
      : false,
  });
  const [employeeEmailVal, setEmployeeEmailVal] = useState("");

  const [isConfettiActive, setIsConfettiActive] = useState(false);

  const handleConfettiClick = () => {
    setIsConfettiActive(true);

    // Reset the confetti after a short delay (e.g., 3 seconds)
    setTimeout(() => {
      setIsConfettiActive(false);
    }, 3000);
  };

  useEffect(() => {
    const val = JSON.parse(localStorage.getItem("show-amount"));
    // console.log(val);
    if (val) {
      setShowAmountVal(val);
    } else {
      setShowAmountVal(false);
    }
  }, []);

  function setSideMenuDropValFunc(param) {
    if (param === "one") {
      setSideMenuDropVal((prev) => {
        return {
          ...prev,
          one: !sideMenuDropVal?.one,
          two: false,
          three: false,
          four: false,
          five: false,
        };
      });
    }

    if (param === "two") {
      setSideMenuDropVal((prev) => {
        return {
          ...prev,
          one: false,
          two: !sideMenuDropVal?.two,
          three: false,
          four: false,
          five: false,
        };
      });
    }
    if (param === "three") {
      setSideMenuDropVal((prev) => {
        return {
          ...prev,
          one: false,
          three: !sideMenuDropVal?.three,
          two: false,
          four: false,
          five: false,
        };
      });
    }
    if (param === "four") {
      setSideMenuDropVal((prev) => {
        return {
          ...prev,
          one: false,
          three: false,
          two: false,
          five: false,
          four: !sideMenuDropVal?.four,
        };
      });
    }
    if (param === "five") {
      setSideMenuDropVal((prev) => {
        return {
          ...prev,
          one: false,
          three: false,
          two: false,
          four: false,
          five: !sideMenuDropVal?.five,
        };
      });
    }
    if (param === "close") {
      setSideMenuDropVal((prev) => {
        return {
          ...prev,
          one: false,
          two: false,
          three: false,
          four: false,
          five: false,
        };
      });
    }
  }

  function setVerificationSideBarFunc(param) {
    setVirificationSidebar(param);
  }

  function showChartFunc(param) {
    setShowChartVal(param);
  }

  function setShowAmountFunc(param) {
    localStorage.setItem("show-amount", JSON.parse(param));
    // localStorage.setItem("show-amount", param ? 'true' : 'false')
    setShowAmountVal(param);
    // console.log(param);
  }

  function setEmployeeEmailFunc(param) {
    setEmployeeEmailVal(param);
  }

  function checkShowVallFunc(param) {
    if (param === "one") {
      setCheckShowVal((prev) => {
        return {
          ...prev,
          one: !checkShowVal?.one,
          two: false,
          three: false,
          four: false,
          five: false,
          six: false,
          seven: false,
          eight: false,
          nine: false,
        };
      });
    }
    if (param === "two") {
      setCheckShowVal((prev) => {
        return {
          ...prev,
          two: !checkShowVal?.two,
          one: false,
          three: false,
          four: false,
          five: false,
          six: false,
          seven: false,
          eight: false,
          nine: false,
        };
      });
    }
    if (param === "three") {
      setCheckShowVal((prev) => {
        return {
          ...prev,
          three: !checkShowVal?.three,
          two: false,
          one: false,
          four: false,
          five: false,
          six: false,
          seven: false,
          eight: false,
          nine: false,
        };
      });
    }
    if (param === "four") {
      setCheckShowVal((prev) => {
        return {
          ...prev,
          four: !checkShowVal?.four,
          two: false,
          three: false,
          one: false,
          five: false,
          six: false,
          seven: false,
          eight: false,
          nine: false,
        };
      });
    }
    if (param === "five") {
      setCheckShowVal((prev) => {
        return {
          ...prev,
          five: !checkShowVal?.five,
          two: false,
          three: false,
          four: false,
          one: false,
          six: false,
          seven: false,
          eight: false,
          nine: false,
        };
      });
    }
    if (param === "six") {
      // console.log("yy");
      setCheckShowVal((prev) => {
        return {
          ...prev,
          five: false,
          two: false,
          three: false,
          four: false,
          one: false,
          seven: false,
          eight: false,
          nine: false,
          six: !checkShowVal?.six,
        };
      });
    }
    if (param === "seven") {
      // console.log("yy");
      setCheckShowVal((prev) => {
        return {
          ...prev,
          five: false,
          two: false,
          three: false,
          four: false,
          one: false,
          seven: !checkShowVal?.seven,
          eight: false,
          six: false,
          nine: false,
        };
      });
    }
    if (param === "eight") {
      // console.log("yy");
      setCheckShowVal((prev) => {
        return {
          ...prev,
          five: false,
          two: false,
          three: false,
          four: false,
          one: false,
          seven: false,
          eight: !checkShowVal?.eight,
          six: false,
          nine: false,
        };
      });
    }
    if (param === "nine") {
      // console.log("yy");
      setCheckShowVal((prev) => {
        return {
          ...prev,
          five: false,
          two: false,
          three: false,
          four: false,
          one: false,
          seven: false,
          eight: false,
          six: false,
          nine: !checkShowVal?.nine,
        };
      });
    }
    if (param === "close") {
      setCheckShowVal((prev) => {
        return {
          ...prev,
          one: false,
          two: false,
          three: false,
          four: false,
          five: false,
          six: false,
          seven: false,
          eight: false,
          nine: false,
        };
      });
    }
  }
  const [globalCurrencyVal, setGlobalCurrencyVal] = useState({});
  const [globalCurrencyInputVal, setGlobalCurrencyInputVal] = useState({});
  const [showCreateVal, setShowCreateVal] = useState(false);
  const [showCreateRecipientVal, setShowCreateRecipientVal] = useState(false);
  const [showCreateRecipientTypeVal, setShowCreateRecipientTypeVal] =
    useState("");

  function setShowPinSetFunc(param) {
    setShowPinSetval(param);
  }

  function showProfileDropFunc(param) {
    if (param === "show") {
      setProfileDropVal(!profileDropVal);
    }
    if (param === "close") {
      setProfileDropVal(false);
    }
  }

  function showUpdatePinFunc(param) {
    setShowUpdateVal(param);
  }

  function showVerifySlideFunc(param) {
    if (param === "show") {
      setshowVerifySlideVal(!showVerifySlideVal);
    }
    if (param === "close") {
      setshowVerifySlideVal(false);
    }
  }

  function setIsLogoutValFunc(param) {
    // if(param === false){
    //   console.log("false-----------------------");
    // }
    // if(param === true){
    //   console.log("true-----------------------");
    // }
    setIsLogoutVal(param);
  }

  function setShowMobileSlideFunc(param) {
    setShowMobileSlide(param);
  }

  function toggleThemeFunc() {
    setToggleThemeVal(!toggleThemeVal);
  }

  function setSideBarNavValFunc(param) {
    setSideBarValNav(param);
  }

  function setPerPageFunction(param) {
    setPerPageValue(param);
  }

  function changeVerificationBtn(param, val) {
    if (param === "bvn") {
      setShowVerificationVal((prev) => {
        return { ...prev, bvn: val };
      });
    }
    if (param === "id") {
      setShowVerificationVal((prev) => {
        return { ...prev, id: val };
      });
    }
    if (param === "nin") {
      setShowVerificationVal((prev) => {
        return { ...prev, nin: val };
      });
    }
  }

  const functionSetCurrentAccount = (param) => {
    // console.log(param);
    setCurrentAccountVal(param);
  };

  const functionSetCurrentAccountDrop = (param) => {
    setCurrentAccountDropVal(param);
  };

  const functionReCaptchaToken = (param) => {
    setRecaptChaTokenVal(param);
  };

  const functionShowAccountChangeBtn = (param) => {
    setShowAccountChangeBtnVal(param);
  };

  const functionShowGeneralLoaderVal = (param) => {
    setShowGeneralLoaderVal(param);
  };

  const functionShowCreate = (param) => {
    setShowCreateVal(param);
  };

  const functionShowCreateRecipient = (param) => {
    setShowCreateRecipientVal(param);
  };

  const functionShowCreateRecipientType = (param) => {
    setShowCreateRecipientTypeVal(param);
  };

  const functionUnfreezeCard = (param) => {
    setUnfreezeCardVal(param);
  };
  const [playSOundVal, setPlaySoundVal] = useState(false);
  const [soundVal, setSoundVal] = useState("");
  const playSound = useNotificationSound(soundVal);
  const playSoundFunc = (param) => {
    // console.log(param);
    setPlaySoundVal(true);
    setSoundVal(param);
    playSound();
    setTimeout(() => {
      setPlaySoundVal(false);
      setSoundVal("");
    }, 1500);
  };

  const [allNotificationVal, setAllNotificationVal] = useState([]);
  const setAllNotificationFunc = (param) => {
    setAllNotificationVal(param);
  };

  const [businessLogoVal, setBusinessLogoVal] = useState("");
  function businessLogoFunc(param) {
    setBusinessLogoVal(param);
  }

  const globalCurrencyFunc = (param) => {
    setGlobalCurrencyVal(param);
  };

  const globalCurrencyInputFunc = (param) => {
    setGlobalCurrencyInputVal(param);
  };

  const editRecipientGlobalFunc = (param) => {
    setEditRecipientVal(param);
  };

  const setPopUpValFunc = (param) => {
    setPopUpVal(param)
  }

  const [trxVal, setTrxVal] = useState(false);
  const setTrxPinFunc = (param) => {
    setTrxVal(param)
  }

  const [step, setStep] = useState(1);

  function setStepFunc(param) {
    // console.log(param);
    setStep(param);
  }

  const [imgPreviewVal, setImgPreviewVal] = useState("");
  function setImgPreviewFunc (param){
    setImgPreviewVal(param)
  }

  const context = {
    imgPreviewUrl: imgPreviewVal,
    setImgPreviewVal: setImgPreviewFunc,
    addressStep: step,
    setAddressStep: setStepFunc,
    createRecipientGlobalType: showCreateRecipientTypeVal,
    createRecipientGlobalTypeFunc: functionShowCreateRecipientType,
    createBusiness: showCreateVal,
    createBusinessFunc: functionShowCreate,
    createRecipientGlobal: showCreateRecipientVal,
    createRecipientGlobalFunc: functionShowCreateRecipient,
    editRecipientGlobal: editRecipientVal,
    editRecipientGlobalFunc: editRecipientGlobalFunc,
    sideMenuDrop: sideMenuDropVal,
    setSideMenuDrop: setSideMenuDropValFunc,
    showProfileDrop: profileDropVal,
    setShowProfileDrop: showProfileDropFunc,
    verificationSideBar: verificationSideBar,
    setVerificationSideBar: setVerificationSideBarFunc,
    showChart: showChartVal,
    setShowChart: showChartFunc,
    showAmount: showAmountVal,
    setShowAmount: setShowAmountFunc,
    employeeEmail: employeeEmailVal,
    setEmployeeEmail: setEmployeeEmailFunc,
    checkShow: checkShowVal,
    setCheckShow: checkShowVallFunc,
    showPinSet: showPinSetVal,
    setShowPinSet: setShowPinSetFunc,
    updatePin: showUpdateVal,
    setShowUpdate: showUpdatePinFunc,
    showVerifySlide: showVerifySlideVal,
    setShowVerifySlide: showVerifySlideFunc,
    isLogout: isLogoutVal,
    setIsLogout: setIsLogoutValFunc,
    mobileSlide: showMobileSlide,
    setMobileSlide: setShowMobileSlideFunc,
    toggleTheme: toggleThemeVal,
    setToggleTheme: toggleThemeFunc,
    sideBarNav: sideBarValNav,
    setSideBarNav: setSideBarNavValFunc,
    perPageVal: perPageValue,
    setPerPage: setPerPageFunction,
    verificationBtn: showVerificationVal,
    setVericationBtn: changeVerificationBtn,
    currentAccount: currentAccountVal,
    setCurrentAccount: functionSetCurrentAccount,
    currentAccountDrop: currentAccountDropVal,
    setCurrentAccountDrop: functionSetCurrentAccountDrop,
    reCaptchaToken: recaptchaTokenVal,
    setRaCaptchaToken: functionReCaptchaToken,
    showChangeAccountBtn: showAccountChangeBtnVal,
    setShowChangeAccountBtn: functionShowAccountChangeBtn,
    showGeneralLoader: genralLoaderVal,
    setShowGeneralLoader: functionShowGeneralLoaderVal,
    confettiVal: isConfettiActive,
    setConfettiVal: handleConfettiClick,
    unfreezeCard: unfreezeCardVal,
    setUnfreezeCard: functionUnfreezeCard,
    playSound: playSOundVal,
    soundVal: soundVal,
    setPlaySound: playSoundFunc,
    allNotifications: allNotificationVal,
    setAllNotifications: setAllNotificationFunc,
    businessLogo: businessLogoVal,
    setBusinessLogo: businessLogoFunc,
    globalCurrency: globalCurrencyVal,
    setGlobalCurrency: globalCurrencyFunc,
    globalCurrencyInputs: globalCurrencyInputVal,
    setGlobalCurrencyInputs: globalCurrencyInputFunc,
    verificationPopUp: popUpVal,
    setVerificationPopUp: setPopUpValFunc,
    trxPin: trxVal,
    setTrxPin: setTrxPinFunc,
  };

  return (
    <ActionContext.Provider value={context}>
      {props.children}
    </ActionContext.Provider>
  );
}

export default ActionContext;
