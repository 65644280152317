import {
  RavenCheckBox,
  RavenInputField,
  RavenNumberFormat,
  toast,
} from "@ravenpay/raven-bank-ui";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ContainerWrapper from "../../../../components/reusables/ContainerWrapper";
import TitletextBox from "../../../../components/reusables/TitletextBox";
import DashboardLayout from "../../../../layout/dashboard/DashboardLayout";
// import bankIconDefault from "../../../../assets/bank-general-img.svg";
import "../../../../styles/dashboard/send-money/SendMoneyBank.css";
import addIcon from "../../../../assets/add-icon-form.svg";
import { FaTimes } from "react-icons/fa";
import bankIcon from "../../../../assets/bank-general-img.svg";
import {
  convertObjectToFormData,
  filterEmptyColumns,
  // formatListWithImage,
  formatNumWithCommaNairaSymbol,
  getAllAmount,
  reactSelectStyle,
  removeSpace,
  returnCbnTransferFee,
  stringifyArrayOfObjects,
  sumAllNum,
  trimLongString,
} from "../../../../utils/helper/Helper";
// import PrevieDetailsBox from "../raven/PrevieDetailsBox";
// import SelectScheduleBox from "../../../../components/reusables/SelectScheduleBox";
// import ConfirmTransactionPinModal from "../ConfirmPinModal";
import TransactionPinModal from "../../../../components/reusables/TransactionPinModal";
import { useSelector, useDispatch } from "react-redux";
import {
  // bulkTransfer,
  bulkTransferManual,
  fetchBanks,
  quickTransfer,
  validateBankAccount,
} from "../../../../redux/transaction";
import { getBeneficiaries } from "../../../../redux/settings";
import { ThreeDots } from "react-loader-spinner";
// import useDebounce from "../../../../utils/helper/UseDebounce";
import { formatNumWithoutCommaNaira } from "../../../../utils/Helpers";
import ravenLogo from "../../../../assets/raven-small-logo-green.svg";
// import moment from "moment";
import SuccessModal from "../../../../components/reusables/SuccessModal";
import ActionContext from "../../../../context/ActionContext";
import { getAllAccounts, getUser } from "../../../../redux/user";
import useWindowSize from "../../../../utils/helper/UseWindowSize";
// import PrevieDetailsBox from "./PreviewDetailBox";
import NewPreviewDetailBox from "./NewPreviewDetailBox";
import { useOnClickOutside } from "../../../../utils/helper/UseOnClickOutside";
import ImageFragment from "../../../../components/fragments/ImageFragment";
import DescriptionPreviewBox from "../../../../components/initiator-des-box/DescriptionPreviewBox";
// import BigPreviewModal from "../../../../components/reusables/BigPreviewModal";
// import CustomOption from "../../../../components/reusables/CustomOption";

const DashboardSendToBank = () => {
  const dispatch = useDispatch();
  const actionCtx = useContext(ActionContext);
  const { user } = useSelector((state) => state.user);
  const { bankList, loadingVerify, loading } = useSelector(
    (state) => state.transaction
  );
  const { beneficiaries } = useSelector((state) => state.settings);
  const [showModal, setShowModal] = useState({
    pin: false,
    success: false,
  });
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [previewOpen, setPreviewOpen] = useState("");
  const [beneficiarySelect, setBeneficiarySelect] = useState("");
  const [viewAll, setViewAll] = useState(false);
  const stepList = ["Transfer detail", "Confirm Transactions"];
  const [fromDate, setFromDate] = useState("");
  const [details, setDetails] = useState({
    username: "",
    amount: "",
    narration: "Sent from raven",
    bulk_list: [],
    beneficiary: false,
    account_num: "",
    bank: "",
    name: "",
    schedule_time: "",
    schedule: "",
    transfer_pin: "",
    template_title: "",
    save_template: false,
    source_account: "",
    description: "",
    des_imges: [],
  });

  // const [beneListVal, setBenelistVal] = useState(beneficiaries?.bankAccounts);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const obj = { ...details, [name]: value };
    setDetails(obj);
  };

  const addAllsumFunc = () => {
    const sumVal = details?.bulk_list?.map((chi) =>
      returnCbnTransferFee(
        user?.transfer_fee_details?.transfer_fee,
        Number(formatNumWithoutCommaNaira(chi?.amount))
      )
    );
    const sumValTotal = sumAllNum(sumVal);
    return formatNumWithCommaNairaSymbol(sumValTotal);
  };

  const formatSelectOption = (param) => {
    const list = param.map((chi) => {
      const { code, name } = chi;
      return {
        label: chi?.name,
        value: code,
        labelTwo: name,
      };
    });
    // const newList = formatListWithImage(list)
    // console.log(newList);
    return list;
  };

  const formatBeneficiary = (list) => {
    const newList = list?.map((chi) => {
      return {
        ...chi,
        label: `${trimLongString(chi?.account_name, 15)} - ${chi?.bank}`,
        value: chi?.bank_code,
      };
    });
    return newList;
  };

  const checkBene = beneficiaries?.bankAccounts?.some(
    (chi) => chi?.account_number === details?.account_num
  );

  const handleBulkTransfer = () => {
    // console.log(beneficiaries?.bankAccounts);
    if (actionCtx?.currentAccount?.account_number === details?.account_num) {
      // console.log(actionCtx?.currentAccount);
      // console.log(details?.bulk_list);
      toast.error("You are unable to transfer to your own account.", {});
      return;
    }
    const checkList = details?.bulk_list?.some(
      (chi) =>
        chi?.account_number === details?.account_num &&
        chi?.name === details?.name
    );

    if (!checkList) {
      setValidatedName("");
      const obj = {
        account_number: details?.account_num,
        amount: details?.amount,
        narration: details?.narration,
        beneficiary: checkBene ? true : false,
        bank: {
          label: details?.bank.label,
          value: details?.bank.value || details?.bank?.bank_code,
        },
        account_name: beneficiarySelect?.account_name || validateName,
      };
      const newList = [obj, ...details?.bulk_list];
      //   console.log(newList);
      setDetails((prev) => {
        return {
          ...prev,
          bulk_list: newList,
          bank: "",
          amount: "",
          narration: "Sent from raven",
          account_num: "",
          name: "",
        };
      });
      setBeneficiarySelect("");
    } else {
      toast.error("Recepient already exist in bulk list");
    }
  };

  const handleRemoveFromList = (obj) => {
    if (details?.bulk_list?.length === 1) {
      setViewAll(false);
      setStep(1);
    }
    const newList = details?.bulk_list?.filter(
      (chi) => chi?.account_number !== obj?.account_number
    );
    setDetails((prev) => {
      return {
        ...prev,
        bulk_list: newList,
      };
    });
  };

  const handleBeneficiarySelect = (obj) => {
    // console.log(obj);
    const newObj = {
      ...details,
      bank: { label: obj?.bank, value: obj?.bank_code },
      name: obj?.name,
      account_num: obj?.account_number,
    };
    setDetails(newObj);
    // console.log(obj);
    setBeneficiarySelect(obj);
  };

  // const removeBeneficiaryOption = () => {
  //   setBeneficiarySelect("");
  //   setDetails((prev) => {
  //     return { ...prev, account_num: "", bank: "", name: "" };
  //   });
  // };

  const handleNextStep = (param) => {
    if (param === "one") {
      if (actionCtx?.currentAccount?.account_number === details?.account_num) {
        // console.log(actionCtx?.currentAccount);
        toast.error("You are unable to transfer to your own account.", {});
        return;
      }
      if (
        details?.amount &&
        details?.bank &&
        validateName &&
        details?.bulk_list?.length > 0
      ) {
        handleBulkTransfer();
        // console.log("lkjs");
      }
      setBeneficiarySelect("");
      details?.bulk_list.length > 0 && setValidatedName("");
      setStep(2);
      checkbeneFunc();
    }
    if (param === "two") {
      if (user?.pin_status === 0) {
        actionCtx.setCheckShow("nine");
      } else {
        setShowModal((prev) => {
          return { ...prev, pin: true };
        });
      }

      // handleSubmit()
    }
  };

  const handleAddBeneficiaryAll = (val) => {
    if (details?.bulk_list?.length > 0) {
      const newList = details?.bulk_list?.map((chi) => {
        return { ...chi, beneficiary: val };
      });
      setDetails((prev) => {
        return {
          ...prev,
          bulk_list: newList,
          beneficiary: val,
          username: "",
          amount: "",
          narration: "Sent from raven",
        };
      });
    } else {
      const newObj = { ...details, beneficiary: val };
      setDetails(newObj);
    }
  };

  useEffect(() => {
    if (user) {
      dispatch(fetchBanks());
      dispatch(getBeneficiaries());
    }
    // getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  // useEffect(() => {
  //   if (beneficiaries) {
  //     checkbeneFunc(beneficiaries?.bankAccounts);
  //   }
  //   // getUser();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [beneficiaries]);

  const checkbeneFunc = () => {
    const val = beneficiaries?.bankAccounts?.some(
      (chi) => chi?.account_number === details?.account_num
    );
    setDetails((prev) => {
      return { ...prev, beneficiary: val };
    });
    return val;
  };

  const handleFinish = () => {
    setShowModal((prev) => {
      return { ...prev, success: true, pin: false };
    });
    dispatch(getUser());
    dispatch(getAllAccounts());
    setViewAll(false);
    setStep(1);
    setBeneficiarySelect("");
    setFromDate("");
    setValidatedName("");
    setDetails({
      username: "",
      amount: "",
      narration: "Sent from raven",
      bulk_list: [],
      beneficiary: false,
      account_num: "",
      bank: "",
      name: "",
      schedule_time: "",
      schedule: "",
      transfer_pin: "",
      template_title: "",
      save_template: false,
      source_account: "",
      description: "",
      des_imges: [],
    });
    setPreviewOpen(false);
    navigate("/dashboard-overview");
  };

  // const restructureName = (name) => {
  //   let val;
  //   const nameSplit = name?.split(" ");
  //   if (nameSplit?.length > 2) {
  //     const newVal = `${nameSplit[0]} ${nameSplit[1]}  ...`;
  //     val = newVal;
  //     // eslint-disable-next-line no-unused-expressions
  //   } else {
  //     val = name;
  //   }
  //   return val;
  // };

  const [validateName, setValidatedName] = useState("");
  // validate bank account details
  const validateBankDetails = async (e) => {
    const payload = {
      bank_code: e || details?.bank.value || details?.bank?.bank_code,
      account_number: removeSpace(details.account_num),
    };
    // console.log(payload);

    if (payload?.bank_code && payload?.account_number?.length > 9) {
      const data = await dispatch(validateBankAccount(payload));
      // console.log(data);
      if (data?.payload?.data?.status === "success") {
        // console.log(data?.payload?.data.data);
        setDetails((prev) => {
          return { ...prev, username: data?.payload?.data.data };
        });
        setValidatedName(data?.payload?.data.data);
        // setStage(3);
      }
    }
  };

  const selectValidateAccount = async (e) => {
    if (details?.account_num.replace?.length > 9) {
      validateBankDetails(e);
    }
  };

  useEffect(() => {
    if (details?.bank && details?.account_num?.length > 9) {
      validateBankDetails();
    }
    removeSpace(details?.account_num);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [details?.account_num || details?.bank]);

  const handleSubmit = async (pin, token) => {
    if (details?.bulk_list?.length > 0) {
      let val;
      const newList = details?.bulk_list?.map((chi) => {
        return {
          bank: chi?.bank?.label || chi?.bank?.bank,
          bank_code: chi?.bank?.value || chi?.bank?.bank_code,
          amount: formatNumWithoutCommaNaira(chi?.amount),
          currency: "NGN",
          account_number: chi?.account_number,
          account_name: chi?.account_name,
          save_beneficiary: chi?.beneficiary === true ? "1" : "0",
        };
      });
      if (details?.save_template) {
        const obj = {
          transfer_data: stringifyArrayOfObjects(newList),
          transfer_pin: pin,
          two_factor_token: token,
          schedule: fromDate ? "1" : "0",
          schedule_time: fromDate ? fromDate : "",
          template_title: details?.save_template ? details?.template_title : "",
          selected_account_number:
            details?.source_account?.account_number ||
            actionCtx?.currentAccount?.account_number,
          description: details?.description,
          document: details?.des_imges,
        };
        val = obj;
      } else {
        const obj = {
          transfer_data: stringifyArrayOfObjects(newList),
          transfer_pin: pin,
          two_factor_token: token,
          schedule: fromDate ? "1" : "0",
          schedule_time: fromDate ? fromDate : "",
          selected_account_number:
            details?.source_account?.account_number ||
            actionCtx?.currentAccount?.account_number,
          description: details?.description,
          document: details?.des_imges,
        };
        val = obj;
      }

      // console.log(val);
      // return;
      const data = await dispatch(
        bulkTransferManual(convertObjectToFormData(filterEmptyColumns(val)))
      );
      if (data?.payload?.data?.status === "success") {
        //  console.log(data); convertObjectToFormData
        handleFinish();
      }
    } else {
      let val;
      if (fromDate) {
        const obj = {
          bank: details?.bank?.label || details?.bank?.bank,
          bank_code: details?.bank?.value || details?.bank?.bank_code,
          amount: formatNumWithoutCommaNaira(details?.amount),
          currency: "NGN",
          account_number: details?.account_num,
          account_name: validateName || details?.name,
          narration: details?.narration,
          transfer_pin: pin,
          two_factor_token: token,
          schedule: fromDate ? "1" : "0",
          schedule_time: fromDate ? fromDate : "",
          save_beneficiary: details?.beneficiary ? "1" : "0",
          selected_account_number:
            details?.source_account?.account_number ||
            actionCtx?.currentAccount?.account_number,
          description: details?.description,
          document: details?.des_imges,
        };
        val = obj;
      } else {
        const obj = {
          bank: details?.bank?.label || details?.bank?.bank,
          bank_code: details?.bank?.value || details?.bank?.bank_code,
          amount: formatNumWithoutCommaNaira(details?.amount),
          currency: "NGN",
          account_number: details?.account_num,
          account_name: validateName || details?.name,
          narration: details?.narration,
          transfer_pin: pin,
          two_factor_token: token,
          schedule: fromDate ? "1" : "0",
          save_beneficiary: details?.beneficiary ? "1" : "0",
          selected_account_number:
            details?.source_account?.account_number ||
            actionCtx?.currentAccount?.account_number,
          description: details?.description,
          document: details?.des_imges,
        };
        val = obj;
      }
      // console.log(convertObjectToFormData(val));
      // for (const [key, value] of convertObjectToFormData(val).entries()) {
      //   console.log(`${key}: ${value}`);
      // }
      // // console.log(val);

      // return;
      const data = await dispatch(
        quickTransfer(convertObjectToFormData(filterEmptyColumns(val)))
      );
      if (data?.payload?.data?.status === "success") {
        handleFinish();
      }
    }

    // let val;
    // if (details?.bulk_list?.length > 0) {
    //   const newList = details?.bulk_list?.map((chi) => {
    //     return {
    //       ...chi,
    //       bank: chi?.bank?.label || chi?.bank?.bank,
    //       bank_code: chi?.bank?.value || chi?.bank?.bank_code,
    //       amount: formatNumWithoutCommaNaira(chi?.amount),
    //       currency: "NGN",
    //       account_number: chi?.account_number,
    //       account_name: chi?.account_name,
    //     };
    //   });
    //   const obj = {
    //     transfer_data: newList,
    //     transfer_pin: pin,
    //     schedule: fromDate ? 1 : 0,
    //     schedule_time: fromDate
    //       ? fromDate
    //       : "",
    //   };
    //   val = obj;
    // } else {
    //   const obj = {
    //     bank: details?.bank?.label || details?.bank?.bank,
    //     bank_code: details?.bank?.value || details?.bank?.bank_code,
    //     amount: details?.amount,
    //     currency: "NGN",
    //     account_number: details?.account_num,
    //     account_name: validateName,
    //     narration: "",
    //     transfer_pin: pin,
    //     schedule: fromDate ? 1 : 0,
    //     schedule_time: fromDate ? fromDate : "",
    //   };
    //   val = obj;
    // }
    // console.log(val);
    // const data = await dispatch(bulkTransfer(val));
    // if (data?.payload?.status === "success") {

    // }
  };

  const size = useWindowSize();

  const testingPreviewRef = useOnClickOutside(() => {
    setViewAll(false);
  });

  useEffect(() => {
    if (
      actionCtx?.currentAccount?.authorization_level !==
        ("initiator_approver" || "initiator") &&
      actionCtx?.currentAccount?.authorization_level !==
        ("initiator" || "initiator_approver")
    ) {
      navigate("/dashboard-send-money");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const formatObjValue = (param) => {
  //   return { ...param, label: param?.labelTwo };
  // };

  // const showLabelFormat = (imgUrl, name) => {
  //   return (
  //     <div
  //       style={{
  //         cursor: "pointer",
  //         paddingBottom: "1rem",
  //         alignItems: "center",
  //         display: "flex",
  //         gap: ".5rem",
  //       }}
  //       className={`name-account-box`}
  //     >
  //       <figure style={{ width: "3rem", height: "3rem", borderRadius: "50%" }}>
  //         <img
  //           style={{ width: "100%", height: "100%", objectFit: "contain" }}
  //           src={imgUrl}
  //           alt=""
  //         />
  //       </figure>
  //       <p>{name}</p>
  //     </div>
  //   );
  // };

  // const formatOptionOne = (list) => {
  //   if (list?.length > 0) {
  //     const newList = list?.map((chi, idx) => {
  //       return {
  //         ...chi,
  //         label: showLabelFormat(chi?.logo, chi?.label),
  //         value: chi?.value,
  //       };
  //     });
  //     return newList;
  //   }
  // };

  return (
    <>
      <DashboardLayout>
        {/* title box start */}
        <TitletextBox
          title={`Send to Bank account`}
          text={`Send funds to a bank account`}
          back
          onBack={() => {
            navigate(`/dashboard-send-money`);
          }}
        />
        <ContainerWrapper
          back={step > 1}
          onBack={() => {
            step > 1 && setStep(step - 1);
            // console.log(details);
          }}
          stepClickable={
            details?.bulk_list?.length > 0
              ? details?.bulk_list?.length > 0
              : details?.account_num && details?.amount && details?.bank
          }
          onStepClick={(e) => {
            details?.bulk_list?.length > 0
              ? setStep(e)
              : details?.account_num &&
                details?.amount &&
                details?.bank &&
                setStep(e);
          }}
          onBtnClick={() => {
            step === 1 && handleNextStep("one");
            // if()
            step === 2 && handleNextStep("two");
          }}
          width={size?.width < 900 && `100%`}
          // dontShowHeader={size.width < 900}
          activeStep={step}
          stepList={stepList}
          btnText={`Complete Transfer`}
          disabled={
            step === 2 &&
            actionCtx?.currentAccount?.authorization_level === "initiator" &&
            !details?.description
              ? true
              : step === 1
              ? details?.bulk_list?.length > 0
                ? details?.bulk_list?.length < 1
                : !details?.account_num || !details?.amount || !details?.bank
              : step === 2
              ? details?.save_template
                ? !details?.template_title
                : false
              : false
          }
        >
          <div className="send-money-wrap-box-bank animate-move-up-class">
            <form
              style={{ paddingBottom: viewAll && "10rem" }}
              onSubmit={(e) => e.preventDefault()}
              action=""
              className="form"
              autoComplete="off"
            >
              {/* step one start ------------------- */}
              {step === 1 && (
                <>
                  {beneficiaries?.bankAccounts?.length > 0 && (
                    <>
                      <form
                        autoComplete="off"
                        style={{
                          width: "100%",
                          position: "relative",
                          zIndex: 1001,
                        }}
                        action=""
                      >
                        {" "}
                        <RavenInputField
                          label="Select beneficiaries*"
                          selectStyles={reactSelectStyle}
                          type={`select`}
                          placeholder={`Select beneficiary`}
                          selectOption={formatBeneficiary(
                            beneficiaries?.bankAccounts
                          )}
                          color={`black-light`}
                          className={"select-react"}
                          value={beneficiarySelect}
                          onChange={(e) => {
                            handleBeneficiarySelect(e);
                            // handleMoveToPreview(e);
                          }}
                        />
                      </form>
                      <div className="text-line">
                        <span></span>
                        <p
                          className="text"
                          style={{ textTransform: "uppercase" }}
                        >
                          Or
                        </p>
                        <span></span>
                      </div>
                      {/* recepient box end */}
                    </>
                  )}
                  <div
                    autoComplete="off"
                    style={{
                      width: "100%",
                      position: "relative",
                      zIndex: 1000,
                    }}
                    action=""
                  >
                    {" "}
                    <RavenInputField
                      color={`black-light`}
                      type={`select`}
                      label="Select bank* "
                      placeholder="Choose a bank"
                      // selectComponents={{ Option: CustomOption }}
                      onChange={(e) => {
                        setBeneficiarySelect("");
                        setValidatedName("");
                        selectValidateAccount(e.bank_code);
                        if (details?.account_num?.length > 9) {
                          validateBankDetails(e.value);
                        }
                        beneficiarySelect
                          ? setDetails((prev) => {
                              return { ...prev, bank: e, account_num: "" };
                            })
                          : setDetails((prev) => {
                              return { ...prev, bank: e };
                            });
                      }}
                      selectValue={details?.bank}
                      name={`bank`}
                      value={details?.bank}
                      className={`select-react`}
                      selectOption={formatSelectOption(bankList)}
                      selectStyles={reactSelectStyle}
                    />
                  </div>
                  <div autoComplete="off" style={{ width: "100%" }} action="">
                    {" "}
                    <RavenInputField
                      color={`black-light`}
                      type={`account-number`}
                      label="Account number* "
                      placeholder="Recipient account no."
                      onChange={(e) => {
                        setValidatedName("");
                        handleChange(e);
                      }}
                      value={details?.account_num}
                      name={`account_num`}
                      maxLength={10}
                      labelSpanText={
                        loadingVerify ? (
                          <div
                            style={{
                              padding: "0rem",
                              display: "flex",
                              justifyContent: "flex-end",
                              transform: "translateX(1.5rem)",
                            }}
                            className="load-wrap"
                          >
                            <ThreeDots
                              height="10"
                              width="80"
                              radius="9"
                              // color="#020202"
                              ariaLabel="three-dots-loading"
                              wrapperStyle={{}}
                              wrapperClassName=""
                              visible={true}
                            />
                          </div>
                        ) : (
                          "Verify"
                        )
                      }
                      labelColor={`purple-light`}
                      labelClassName={`label-class-span ${
                        details?.account_num?.length === 10 &&
                        "label-class-span-active"
                      }`}
                      onActionClick={() => {
                        details?.account_num?.length === 10 &&
                          validateBankDetails();
                      }}
                    />
                  </div>

                  {/* verify name box start */}
                  <div
                    className={`verify-name-box ${
                      validateName && "verify-name-box-show"
                    }`}
                  >
                    <RavenCheckBox
                      checked={true}
                      color={`black-light`}
                      className={`check`}
                    />
                    <p className="text">{validateName}</p>
                  </div>
                  {/* verify name box end */}
                  <div autoComplete="off" style={{ width: "100%" }} action="">
                    {" "}
                    <RavenInputField
                      type={`number`}
                      color={`black-light`}
                      label={`Amount* `}
                      name={`amount`}
                      value={details?.amount}
                      onChange={handleChange}
                      placeholder={`How much are you sending`}
                      labelColor={`black-light`}
                      labelSpanText={
                        actionCtx?.showAmount
                          ? `Bal: ${
                              details?.source_account
                                ? RavenNumberFormat(
                                    details?.source_account?.balance
                                  )
                                : formatNumWithCommaNairaSymbol(
                                    actionCtx?.currentAccount?.balance
                                  )
                            }`
                          : "Bal: ₦ ••••••"
                      }
                      labelClassName={`label-span-amount label-span-theme`}
                      thousandFormat
                      numberPrefix={`₦`}
                      onActionClick={() => {
                        actionCtx?.setShowAmount(!actionCtx?.showAmount);
                      }}
                    />
                  </div>

                  {details?.bulk_list < 1 && (
                    <RavenInputField
                      type={`text`}
                      color={`black-light`}
                      label={`Narration* `}
                      value={details?.narration}
                      onChange={handleChange}
                      placeholder={`E.g From timi`}
                      name={`narration`}
                      capitalize
                    />
                  )}
                  {/* add another text start */}
                  <div
                    onClick={() => {
                      details?.amount && details?.bank && handleBulkTransfer();
                    }}
                    className={`add-another-box ${
                      details?.amount &&
                      details?.bank &&
                      "add-another-box-active"
                    }`}
                  >
                    <figure className="img-box">
                      <img src={addIcon} alt="" className="img" />
                    </figure>
                    <p className="text">Transfer to another account.</p>
                  </div>
                  {/* add another text end */}
                  {/* recepient box start */}
                  <div
                    style={{ position: "relative", zIndex: viewAll ? 1200 : 1 }}
                    className={`recepient-box ${
                      details?.bulk_list?.length > 0 && "recepient-box-show"
                    }`}
                  >
                    <div className="text-line">
                      <span></span>
                      <p className="text">Recipients</p>
                      <span></span>
                    </div>
                    <div ref={testingPreviewRef} className="view-total-box">
                      <p className="total">{`Total added (${details?.bulk_list?.length})`}</p>
                      <div className="view-wrap">
                        {/* view all box start */}
                        <div
                          className={`view-all-box card-bg ${
                            viewAll && "view-all-box-show"
                          }`}
                        >
                          <div
                            // onClick={() => {
                            //   console.log(details);
                            // }}
                            className="wrap"
                          >
                            {details?.bulk_list?.map((chi, idx) => {
                              return (
                                <div
                                  key={idx}
                                  className="child-wrap border-theme"
                                >
                                  <ImageFragment
                                    className="img-box"
                                    url={
                                      String(chi?.bank?.value) === "090615"
                                        ? ravenLogo
                                        : `https://businessapi.getraventest.com/static/media/banks/${chi?.bank?.value}.png `
                                    }
                                  >
                                    <figure className="img-box">
                                      <img
                                        src={bankIcon}
                                        alt=""
                                        className="img"
                                        // style={{ filter: `brightness(100%)` }}
                                      />
                                    </figure>
                                  </ImageFragment>
                                  <div className="text-box">
                                    <p className="name">
                                      {trimLongString(
                                        chi?.account_name?.replace("-", ""),
                                        20
                                      ) || "----"}
                                    </p>
                                    <p className="text grey-white-color">{`${
                                      chi?.bank?.label || "087654567"
                                    } • ${chi?.account_number}`}</p>
                                  </div>
                                  <div
                                    onClick={() => {
                                      handleRemoveFromList(chi);
                                    }}
                                    className="cancel-box"
                                  >
                                    <FaTimes className="icon" />
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                        {/* view all box end */}
                        {viewAll ? (
                          <p
                            onClick={() => {
                              setViewAll(false);
                              details?.bulk_list?.length === 1 &&
                                setViewAll(false);
                            }}
                            className="text"
                          >
                            Close
                          </p>
                        ) : (
                          <p
                            onClick={() => {
                              setViewAll(true);
                            }}
                            className="text"
                          >
                            View all
                          </p>
                        )}
                      </div>
                    </div>
                    {/* end of view total box */}
                    {/* preview list start */}
                    {!viewAll && (
                      <div className="preview-box">
                        {details?.bulk_list?.slice(0, 2).map((chi, idx) => {
                          const { bank, account_number, account_name } = chi;
                          return (
                            <div className="box box-card grey-bg" key={idx}>
                              <div
                                onClick={() => {
                                  handleRemoveFromList(chi);
                                  // console.log(chi?.bank?.value);
                                }}
                                className="cancel-box"
                              >
                                <FaTimes className="icon" />
                              </div>
                              <ImageFragment
                                className="img-box"
                                url={
                                  String(chi?.bank?.value) === "090615"
                                    ? ravenLogo
                                    : `https://businessapi.getraventest.com/static/media/banks/${chi?.bank?.value}.png `
                                }
                              >
                                <figure className="img-box">
                                  <img
                                    src={bankIcon}
                                    alt=""
                                    className="img"
                                    // style={{ filter: `brightness(100%)` }}
                                  />
                                </figure>
                              </ImageFragment>

                              <p className="name">
                                {trimLongString(
                                  account_name?.replace("-", ""),
                                  20
                                ) || "---"}
                              </p>
                              <p className="text grey-white-color">{`${
                                trimLongString(bank?.label, 17) || "0121559651"
                              } • ${account_number}`}</p>
                            </div>
                          );
                        })}
                      </div>
                    )}
                    {/* preview list end */}
                  </div>
                  {/* recepient box end */}
                </>
              )}
              {/* step one end ------------------- */}
              {/* step two start ------------------ */}
              {step === 2 && (
                <>
                  {/* select schedule start */}
                  {/* <SelectScheduleBox
                    id={`onud`}
                    value={fromDate}
                    onChange={(e) => {
                      setFromDate(e);
                      // console.log(e);
                    }}
                    onReset={() => setFromDate("")}
                  /> */}
                  {/* select schedule end */}
                  {/* <PrevieDetailsBox collapse onChange={(e) => {console.log(e.target.checked);}} /> */}
                  {details?.bulk_list?.length > 0 ? (
                    // <>
                    //   {details?.bulk_list?.length === 1 ? (
                    //     <>
                    //       {details?.bulk_list?.map((chi, idx) => {
                    //         const {
                    //           account_name,
                    //           amount,
                    //           beneficiary,
                    //           narration,
                    //           bank,
                    //           account_number,
                    //         } = chi;
                    //         return (
                    //           <PrevieDetailsBox
                    //             key={idx}
                    //             value={beneficiary}
                    //             onChange={(e) => {
                    //               handleAddBeneficiary(chi, e.target.checked);
                    //             }}
                    //             previewOpen={previewOpen}
                    //             setPreviewOpen={setPreviewOpen}
                    //             bank={bank?.label}
                    //             account_num={account_number}
                    //             narration={narration}
                    //             name={account_name}
                    //             amount={amount}
                    //             checked={beneficiary}
                    //           />
                    //         );
                    //       })}
                    //     </>
                    //   ) : (
                    //     <>
                    //       {details?.bulk_list?.map((chi, idx) => {
                    //         const {
                    //           account_name,
                    //           amount,
                    //           beneficiary,
                    //           narration,
                    //           bank,
                    //           account_number,
                    //         } = chi;
                    //         return (
                    //           <PrevieDetailsBox
                    //             key={idx}
                    //             id={idx}
                    //             value={beneficiary}
                    //             onChange={(e) => {
                    //               handleAddBeneficiary(chi, e.target.checked);
                    //             }}
                    //             previewOpen={previewOpen}
                    //             setPreviewOpen={setPreviewOpen}
                    //             bank={bank?.label}
                    //             account_num={account_number}
                    //             narration={narration}
                    //             name={account_name}
                    //             amount={amount}
                    //             collapse
                    //             checked={beneficiary}
                    //           />
                    //         );
                    //       })}
                    //     </>
                    //   )}
                    // </>
                    <>
                      <NewPreviewDetailBox
                        totalFee={
                          user?.transfer_fee_details?.transfer_fee_style ===
                          "cbn"
                            ? addAllsumFunc()
                            : formatNumWithCommaNairaSymbol(
                                user?.transfer_fee_details?.transfer_fee?.flat *
                                  details?.bulk_list?.length
                              )
                        }
                        onReset={() => setFromDate("")}
                        valueSchedule={fromDate}
                        onChangeSchedule={(e) => {
                          setFromDate(e);
                        }}
                        value={details?.beneficiary}
                        onChange={(e) => {
                          handleAddBeneficiaryAll(e.target.checked);
                        }}
                        list={details?.bulk_list}
                        collapse
                        bulkAmount={
                          details?.bulk_list?.length > 0
                            ? formatNumWithCommaNairaSymbol(
                                getAllAmount(details?.bulk_list)
                              )
                            : formatNumWithCommaNairaSymbol(0)
                        }
                        previewOpen={previewOpen}
                        setPreviewOpen={setPreviewOpen}
                        account_name={details?.username}
                        account_num={details?.account_num}
                        bank={details?.bank?.label}
                        amount={details?.amount}
                        name={details?.username}
                        imgCode={details?.bank?.value}
                        narration={details?.narration}
                        checked={details?.beneficiary}
                        handleRemoveFromList={handleRemoveFromList}
                        details={details}
                        setDetails={setDetails}
                        handleChange={handleChange}
                        onSourceSelect={(param) => {
                          setDetails((prev) => {
                            return { ...prev, source_account: param };
                          });
                        }}
                        sourceAccount={
                          details?.source_account || actionCtx?.currentAccount
                        }
                      />
                    </>
                  ) : (
                    <>
                      {/* <PrevieDetailsBox
                        value={details?.beneficiary}
                        onChange={(e) => {
                          setDetails((prev) => {
                            return { ...prev, beneficiary: e.target.checked };
                          });
                        }}
                        id="isu"
                        account_num={details?.account_num}
                        bank={details?.bank?.label}
                        amount={details?.amount}
                        name={details?.username}
                        narration={details?.narration}
                        checked={details?.beneficiary}
                      /> */}
                      <NewPreviewDetailBox
                        totalFee={
                          user?.transfer_fee_details?.transfer_fee_style ===
                          "cbn"
                            ? formatNumWithCommaNairaSymbol(
                                returnCbnTransferFee(
                                  user?.transfer_fee_details?.transfer_fee,
                                  formatNumWithoutCommaNaira(details?.amount)
                                )
                              )
                            : formatNumWithCommaNairaSymbol(
                                user?.transfer_fee_details?.transfer_fee?.flat
                              )
                        }
                        onReset={() => setFromDate("")}
                        valueSchedule={fromDate}
                        onChangeSchedule={(e) => {
                          setFromDate(e);
                        }}
                        imgCode={details?.bank?.value}
                        value={details?.beneficiary}
                        onChange={(e) => {
                          setDetails((prev) => {
                            return { ...prev, beneficiary: e.target.checked };
                          });
                        }}
                        previewOpen={previewOpen}
                        setPreviewOpen={setPreviewOpen}
                        account_name={details?.username}
                        account_num={details?.account_num}
                        bank={details?.bank?.label}
                        amount={details?.amount}
                        name={details?.username}
                        narration={details?.narration}
                        checked={details?.beneficiary}
                        onSourceSelect={(param) => {
                          setDetails((prev) => {
                            return { ...prev, source_account: param };
                          });
                        }}
                        sourceAccount={
                          details?.source_account || actionCtx?.currentAccount
                        }
                      />
                    </>
                  )}
                  {/* initiar attachment start */}
                  {actionCtx?.currentAccount?.authorization_level ===
                    "initiator" && (
                    <>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                          gap: "2rem",
                        }}
                        className="initiator-input-box-wrapper"
                      >
                        <RavenInputField
                          placeholder="Enter description"
                          color="black-light"
                          label="Add description *"
                          name="description"
                          onChange={handleChange}
                          value={details?.description}
                        />
                        {/* add and preview images box start */}
                        <DescriptionPreviewBox
                          onAdd={(param) => {
                            const newList = [param, ...details?.des_imges];
                            setDetails((prev) => {
                              return { ...prev, des_imges: newList };
                            });
                          }}
                          imgList={details?.des_imges}
                          onRemove={(param) => {
                            setDetails((prev) => {
                              return { ...prev, des_imges: param };
                            });
                          }}
                          onView={(param) => {
                            actionCtx.setImgPreviewVal(
                              URL.createObjectURL(param)
                            );
                          }}
                        />
                        {/* add and preview images box end */}
                      </div>
                    </>
                  )}
                  {/* intitiator attachment end */}
                </>
              )}
              {/* step two end ------------------ */}
            </form>
          </div>
        </ContainerWrapper>
        {/* title box end */}
      </DashboardLayout>
      {/* pin modal start */}

      <TransactionPinModal
        pin={details?.transfer_pin}
        setPin={(e) => {
          setDetails((prev) => {
            return { ...prev, transfer_pin: e };
          });
        }}
        onPay={(pin, token) => {
          // console.log(pin, token);
          handleSubmit(pin, token);
        }}
        value={details?.transfer_pin}
        visible={showModal.pin}
        onCancel={() => {
          setDetails((prev) => {
            return { ...prev, transfer_pin: "" };
          });
          setShowModal((prev) => {
            return { ...prev, pin: false };
          });
        }}
        loading={loading}
      />
      {/* pin modal end */}
      {/* sign up modal start */}
      <SuccessModal
        visible={showModal.success}
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, success: false };
          });
        }}
        title="Transaction Successful."
        text={`You should also receive a confirmation email with the details of the transaction. We appreciate your business and look forward to serving you in the future`}
        btnText={`Close`}
        onBtnClick={() => {
          setShowModal((prev) => {
            return { ...prev, success: false };
          });
        }}
      />
      {/* sign up modal end */}
    </>
  );
};

export default DashboardSendToBank;
