import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  body, .verification-layout-wrap .right-wrap{
  color:  ${({ theme }) => theme.black_white_color};
  background-color: ${({ theme }) => theme.white_grey_fade_bg};
  }
  .black-white-color, .new-preview-detail-box-wrap .bottom-content-wrap .new-reusable-schedule-box .date-arrow-wrap .input-date .wrap input::placeholder, .form-group__black-light .form-label, reusable-add-and-preview-images-initiator-box .label{
    color:  ${({ theme }) => theme.black_white_color};
  }
  .step-box .box .num span {
    color: ${({ theme }) => theme.step_num_color} !important;
  }
  .sidebar-wrap {
    background-color: ${({ theme }) => theme.fade_black_bg};
    // border-right:  ${({ theme }) => theme.no_border_border} !important;
  }

  .btn-outline-black-light{
    border-color:  ${({ theme }) => theme.black_white_color} !important;
    color: ${({ theme }) => theme.black_white_color} !important;
  }
   .side-menu-wrap{
    background-color: ${({ theme }) => theme.black_fade_white_bg};
    // border-right:  ${({ theme }) => theme.no_border_border} !important;
  }
.white-black-color-stoke{
  stroke: ${({ theme }) => theme.white_black_bg} !important;
}
  .white-black-bg, .label-value-dot-box::before{
    background-color: ${({ theme }) => theme.white_black_bg} !important;
  }
  .black-fade-white-bg ,
  .notification-wrap-modal-index .select-view-box .select-view-item::after, .notification-wrap-modal-index-big  .select-view-box .select-view-item::after{
    background-color: ${({ theme }) => theme.black_fade_white_bg};
  }
  .grey-bg, .avatar-box, .success-modal-reuse-wrap .raven-modal-content-wrap .button-wrapper {
    background-color: ${({ theme }) => theme.white_grey_fade_bg} !important; 
  }
  .grey-bg-two, .swap-fee-exchange-rate-box::after, .label-value-dot-box::after{
    background-color: ${({ theme }) =>
      theme.white_grey_fade_two_bg} !important; 
  }
  .black-white-bg{
    background-color:  ${({ theme }) => theme.black_white_color} !important;
  }
  .yellow-grey-dark-bg{
    background-color: ${({ theme }) =>
      theme.yellow_white_grey_fade_bg} !important; 
  }
  .grey-nav-side-bg{
    background-color: ${({ theme }) =>
      theme.white_side_nav_grey_fade_bg} !important; 
  }
  .all_invoice, .paid_invoice, .unpaid_invoice{
    background-color: ${({ theme }) => theme.invoice_card_wrap} !important; 
  }
  .darken-action-bg{
    background-color: ${({ theme }) => theme.action_box_wrap_bg} !important; 
  }
   .preview-details-common-box,  {
    background-color: ${({ theme }) => theme.card_white_black_bg} !important; 
    border: ${({ theme }) => theme.border_theme_remove};
  }
  .status-type-wrap-action span, .status-type-wrap-action-two span{
    color:  ${({ theme }) => theme.black_white_color} ;
  }
  .main-wrap, new-verification-body-content-wrap{
    background-color: ${({ theme }) => theme.fade_black_bg};
  }
  .header-wrap{
    background-color: ${({ theme }) => theme.white_black_bg};
  }
  .black-white-color, .input-group, .form-group .display-wrap .display-box .text-box .name{
    color:  ${({ theme }) => theme.black_white_color} !important;
    fill: ${({ theme }) => theme.black_white_color};
  }
  .white-black-color{
    color:  ${({ theme }) => theme.white_black_color} !important;
  }
  .white-black-color-bg{
    background-color:  ${({ theme }) => theme.white_black_color} !important;
  }
  .theme-box{
    background-color: ${({ theme }) => theme.fade_black_bg};
    border: ${({ theme }) => theme.border_white_grey_toggle};
  }
  .box-toggle-theme-active{
    background-color: ${({ theme }) => theme.white_grey_bg};
  }
  .dull-bright-filter, .select-option-class-active .img-box .img, .raven-modal-content-wrap .close-box .img-box .img{
    filter: ${({ theme }) => theme.dull_bright_filter} !important;
   stroke: ${({ theme }) => theme.black_white_color} !important;
  }
  .dull-bright-filter-two{
    filter: ${({ theme }) => theme.dull_bright_filter_two} !important;
  }
  .bright-up{
    filter: ${({ theme }) => theme.bright_up} !important;
  }
  .border-thin{
    border: ${({ theme }) => theme.border_thin};
  }
  .border-profile{
    border: ${({ theme }) => theme.border_profile};
  }
  .card-bg{
    background-color: ${({ theme }) => theme.card_white_black_bg};
    box-shadow: ${({ theme }) => theme.card_box_shadow};
    border:  ${({ theme }) => theme.card_border};
  }
  .global-single-title{
    background-color: ${({ theme }) => theme.card_white_black_bg};
  }
  .table-transaction-cover{
    background-color: ${({ theme }) => theme.card_white_black_bg};
  }
  .box-detail-wrap{
    box-shadow: ${({ theme }) => theme.card_box_shadow};
    border:  ${({ theme }) => theme.card_border};
  }
  .border-theme, .preview-details-common-box{
    border: ${({ theme }) => theme.border_theme};
  }
  .border-theme-top{
    border-top: ${({ theme }) => theme.border_theme};
  }
  .border-theme-bottom{
    border-bottom: ${({ theme }) => theme.border_theme};
  }
  .border-theme-left{
    border-left: ${({ theme }) => theme.border_theme};
  }
  .border-theme-right{
    border-right: ${({ theme }) => theme.border_theme} !important;
  }
  .white-grey-fade-bg{
    background-color: ${({ theme }) => theme.white_grey_fade_bg};
  }
  .remove-dark-border, .form-input{
    border: ${({ theme }) => theme.border_theme_remove};
  }
  .grey-white-color{
    color: ${({ theme }) => theme.grey_white_color} !important;
  }
  .grey-white-color span{
    color: ${({ theme }) => theme.black_white_color} !important;
  }
  .date-time-fragment-box-wrap-date-two{
    color: ${({ theme }) => theme.grey_white_color} !important;
  }
  .no-shadow-box-shadow{
    box-shadow:  ${({ theme }) => theme.no_shadow_box_shadow} !important;
  }
  .shadow-shadow-box-shadow{
    box-shadow:  ${({ theme }) => theme.shadow_shadow_box_shadow} !important;
  }
  .no-border-border{
    border:  ${({ theme }) => theme.no_border_border} !important;
  }
  .purple-border-color{
    border-color: ${({ theme }) => theme.purple_grey_border} !important;
  }
  input, .form-input, .react-select-class > *:nth-child(3), .input-group, .form-group .display-wrap .display-box, .increase-decrease-box .input-box input{
    background-color:  ${({ theme }) => theme.input_bg};
  }
  .toggle-wrap .toggle-label{
    background-color:  ${({ theme }) => theme.toggle_label_bg};
  }
  .increase-decrease-box .input-box input:focus{
    border: ${({ theme }) => theme.border_theme} !important;
  }
  .react-select-class > *:nth-child(3), .input-group, input, .input-group, .form-group .display-wrap .display-box{
    border:  ${({ theme }) => theme.input_border}  !important;  
  }
  .react-select-class > *:nth-child(3) >*:first-child >*:first-child{
    padding-left: .3rem;
    color: white;
  }
  .input-group .seach-box .img-box .img{
    // filter: ${({ theme }) => theme.dull_bright_filter} !important;
  }
  .toggle-input:checked ~ .toggle-label, .toggle-input:checked ~ .toggle-label .child-one{
    background-color: ${({ theme }) => theme.toggle_bg} !important;
  }
  .table .table-head tr th {
    // background-color: ${({ theme }) => theme.table_head_color}  !important;
    background-color: ${({ theme }) => theme.grey_black_bg}  !important;
    border-color:   ${({ theme }) => theme.table_border_color}  !important;
  }
  .table-row-selected{
    background-color: ${({ theme }) => theme.grey_black_bg}  !important;
  }
  .table .table-body tr td , .pagination-wrap .child{
    border-color:   ${({ theme }) => theme.table_border_color}  !important;
  }
  .status-type-wrap, .type-wrap{
    background-color: ${({ theme }) => theme.table_head_color}  !important;
  }
  .pagination-wrap .child{
    color:  ${({ theme }) => theme.pagination_color}  !important;
  }
  .pagination-wrap .current{
    background-color: ${({ theme }) => theme.toggle_bg} !important;
    color:  ${({ theme }) => theme.pagination_current_color}  !important;
  }
  .react-select-class > *:nth-child(4) >*:first-child, .react-select-class > *:nth-child(4) >*:first-child>*{
  //  color: #020202 !important;
  }
  .input-group input::placeholder, .form-input::placeholder, input::placeholder, .react-select-class > *:nth-child(3) > *:nth-child(1) > *:nth-child(1){
    color:  ${({ theme }) => theme.input_placeholder}  !important;
  }
  .input-group input, .form-input, input, .react-select-class-selected > *:nth-child(3) > *:nth-child(1) > *:nth-child(1){
    color:   ${({ theme }) => theme.input_color}  !important;
  }
  .btn-black-light, .tool-tip-wrap__black-light, .tool-tip-wrap::after{
    background-color: ${({ theme }) => theme.black_theme_bg} !important;
    color: ${({ theme }) => theme.black_theme_color} !important;
  }
  .raven-btn .img-box-two .img, .spin .spintula{
    filter: ${({ theme }) => theme.filter_dark_theme} !important;
  }
  .hover-white-class:hover{
   box-shadow: ${({ theme }) => theme.hover_box_shadow} !important;
   background-color: ${({ theme }) => theme.hover_bg} !important;
  }

  .hover-white-class:hover::after{
   border:  ${({ theme }) => theme.hover_bg_after} !important;
  }
  .step-box .box-active .num{
    background-color: ${({ theme }) => theme.step_active_bg} !important;
  }
  .step-box .box .num span{
    filter: ${({ theme }) => theme.bright_up}
  }
  .step-box .box-active .num span{
    color: ${({ theme }) => theme.step_active_color} !important;
  }.step-box .box-active .text{
    color: ${({ theme }) => theme.step_active_text} !important;
  }
  .input-submit{
    background-color: ${({ theme }) => theme.submit_input_bg} !important;
    color: ${({ theme }) => theme.submit_input_text} !important;
  }
  // .form-label:not([data-content="RC"]){
  //   color:  ${({ theme }) => theme.black_white_color} !important;
  // }
  .label-span-theme{
    color:  ${({ theme }) => theme.black_white_color} ;
  }
  .view-all-box{
    background-color: ${({ theme }) => theme.view_all_bg} !important;
  }
  .view-all-box .wrap .child-wrap {
    border:  ${({ theme }) => theme.view_border} !important;
  }
  .test-active-settings{
    color:  ${({ theme }) => theme.text_active_setting} !important;
  }
  .test-active-settings .check-box, .active-sub-account .check-box{
    background-color:  ${({ theme }) =>
      theme.check_active_setting_bg} !important;
  }
  .test-active-settings .check-box .icon , .active-sub-account .check-box .icon{
    // fill:  ${({ theme }) => theme.check_active_setting_icon} !important;
  }
  .select-option-class-active::after, .box-active::after, .select-option-class-active::before{
    border:  ${({ theme }) => theme.hover_bg_after} !important;
  }
  .select-option-class-active .check-box, .box-active .check-box {
    background-color:  ${({ theme }) =>
      theme.check_active_setting_bg} !important;
  }
  .select-option-class-active .check-box .icon, .box-active .check-box .icon {
    // fill:  ${({ theme }) => theme.check_active_setting_icon} !important;
  }
  .add-error-box{
    background-color:  ${({ theme }) => theme.add_error_setting_box} !important;
  }
  .select-view-wrap .option::after, .pos-settlement-wrap-modal .content-box .select-type-box .type-child-active::after{
    background-color:  ${({ theme }) => theme.active_black_color} !important;
  }
  .select-view-wrap .option-active span {
   color:  ${({ theme }) => theme.active_black_color} !important;
  }
  .active-border-color{
    border-color:  ${({ theme }) => theme.active_border_color} !important;
  }
  .input-group__phone .phone-wrap .down-icon {
    fill:  ${({ theme }) => theme.black_white_color} !important;
  }
  input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus  {
  box-shadow: 0 0 0 3rem  ${({ theme }) => theme.inset_color} inset !important;
  -webkit-box-shadow: 0 0 0 3rem  ${({ theme }) =>
    theme.inset_color} inset !important;
    -webkit-text-fill-color: ${({ theme }) =>
      theme.black_white_color} !important;
}
.img-box-debit-credit{
  background-color:  ${({ theme }) => theme.img_box_debit_credit} !important;
}
.raven-modal-content-wrap{
  background-color:   ${({ theme }) => theme.raven_modal_bg} !important;
  border:  ${({ theme }) => theme.no_border_border} !important;
}
// .raven-modal-content-wrap .close-box .img-box .img{
//   filter: ${({ theme }) => theme.dull_bright_filter} !important;
// }
.raven-modal-content-wrap .button-wrap, .raven-modal-content-wrap  .two-button-wrap{
  background-color:  ${({ theme }) => theme.raven_modal_btn_bg} !important;
}
.raven-modal-content-wrap  .two-button-wrap .cancel{
  color: ${({ theme }) => theme.grey_white_color_white} !important;
}
.grey-white-color-white{
  color: ${({ theme }) => theme.grey_white_color_white} !important;
}
.label-radio, .radio-select-check{
  border-color:  ${({ theme }) => theme.radio_border} !important;
}

.label-radio span{
  background-color:  ${({ theme }) => theme.white_black_bg} !important;
}

.input-radio:checked ~ .label-radio span, .input-check:checked ~  .label-check{
  background-color:  ${({ theme }) => theme.black_white_color} !important;
 
}
.check-box-wrap .label-check {
  border-color: ${({ theme }) => theme.black_white_color};
}
.check-box-wrap .label-check .icon{
  color: ${({ theme }) => theme.white_black_color} ;
  fill: ${({ theme }) => theme.white_black_color} ;
}
.input-check:checked ~ .label-check .icon{
  fill:  ${({ theme }) => theme.white_black_bg} !important; 
}

 .input-check:checked ~ .label-check__purple-light .icon{
  fill:  white !important; 
}
.unpaid_invoice span, .paid_invoice span, .all_invoice span{
  background-color:  ${({ theme }) => theme.unpaid_invoice_bg} !important;
}
.input-group input,  .react-select-class > *:nth-child(3) input, .form-textarea, .form-input, input{
  border:  ${({ theme }) => theme.no_bg}  ;  
  caret-color:  ${({ theme }) => theme.black_white_color} !important;
}
.input-group__phone .phone-wrap{
  border-color:  ${({ theme }) => theme.phone_border_color} !important;
  // border: none !important;
}

.react-loading-skeleton {
  background: ${({ theme }) => theme.skeleton_base_background};
}
.react-loading-skeleton::after {
  background: ${({ theme }) => theme.skeleton_base_background};
  background-image: ${({ theme }) => theme.skeleton_highlight_background};
}
.card-detail-top-box::after{
  visibility: ${({ theme }) => theme.card_black_purple_bg} !important;
}
// .purple-skeleton{

// }
.verification-layout-wrap .left-wrap{
  background-color: ${({ theme }) =>
    theme.verification_left_wrap_bg} !important;
}

.link-wrap .link-box::after{
  background-color: ${({ theme }) => theme.black_white_color} !important;
}
.verification-layout-wrap .link-wrap .link-box .check-box{
  border-color: ${({ theme }) => theme.border_color_veri} !important;
}
.verification-layout-wrap .link-wrap .link-box-active .check-box{
  border-width: ${({ theme }) => theme.border_width_veri} !important;
}
.verification-layout-wrap .link-wrap .link-box-active .text{
  color: ${({ theme }) => theme.black_white_color} !important;
}
.verification-layout-wrap .link-wrap .verified .check-box{
  background-color: ${({ theme }) => theme.background_veri} !important;
}
.verification-layout-wrap .link-wrap .verified .check-box .icon{
  fill: ${({ theme }) => theme.background_veri_icon} !important;
  width: ${({ theme }) => theme.background_veri_icon_width} !important;
  height: ${({ theme }) => theme.background_veri_icon_width} !important;
}
.verification-layout-wrap .form-input{
  border: ${({ theme }) => theme.verificatio_input_border} !important;
}
.form-group .input-group__phone .form-input, .input-group__search .form-input, .numInputWrapper input, .react-select-class >*:nth-child(3) >*:first-child >*:nth-child(2) input{
  border: unset !important;
}
.numInputWrapper input{
  background-color:  ${({ theme }) => theme.input_bg};
  color:  ${({ theme }) => theme.black_white_color};
}
.flatpickr-calendar{
  background-color:  ${({ theme }) => theme.input_bg};
  color:  ${({ theme }) => theme.black_white_color};
}
.flatpickr-days .dayContainer span, span.flatpickr-weekday, .flatpickr-next-month.flatpickr-next-month svg, .flatpickr-prev-month.flatpickr-prev-month svg, .flatpickr-current-month .flatpickr-monthDropdown-months, .flatpickr-time .flatpickr-am-pm{
  color:  ${({ theme }) => theme.black_white_color};
  fill:  ${({ theme }) => theme.black_white_color} !important;
}
.flatpickr-days .dayContainer span:hover, .flatpickr-time .flatpickr-am-pm:hover{
  background-color:  ${({ theme }) => theme.cal_hover_bg};
}
.flatpickr-days .dayContainer .selected{
  background-color:  ${({ theme }) => theme.cal_hover_bg_selected} !important;
  color: #ffffff ;
}
.flatpickr-days .dayContainer .today{
  border-bottom:  ${({ theme }) => theme.cal_iborder_bottom_today} !important;
}
span.flatpickr-day, span.flatpickr-day.prevMonthDay, span.flatpickr-day.nextMonthDay, .flatpickr-calendar.inline{
border: ${({ theme }) => theme.cal_hover_border} !important;
}
.flatpickr-disabled{
  color: ${({ theme }) => theme.flatpicker_disable} !important;
}

.numInputWrapper span.arrowUp:after, .numInputWrapper span.arrowDown:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: ${({ theme }) => theme.cal_icon_color} !important;
  top: 26%;
}
.drop-down-wrap-payroll{
  background-color:  ${({ theme }) => theme.drop_down_payroll_box} !important;
}
.copy-box-active{
  background-color: #020202 !important;
}
.filter-sort-wrap-box .status-box-wrap .child-active::after{
  background-color:  ${({ theme }) => theme.black_white_color} !important;
}
.filter-sort-wrap-box .status-box-wrap .child-active span{
 color:  ${({ theme }) => theme.black_white_color} !important;
}

.progress-bar-wrapper{
  background-color:  ${({ theme }) => theme.progress_bar_bg} !important;
}

.mobile-logo-box .img-box .img{
  filter:  ${({ theme }) => theme.mobile_logo_img} !important;
}

.bulk-select-type-transfer, body{
  background-color:  ${({ theme }) =>
    theme.bulk_select_transfer_type_bg} !important;
}
.main-body-wrap{
  background-color:  ${({ theme }) => theme.main_body_bg} !important;
}
.dashboard-layout-wrap .main-body-wrap .action-bar-wrapper-box .action_bar{
  background-color:${({ theme }) => theme.action_bar_bg} !important;
}

.dashboard-overview-wrap .wrap .balance-top-box::after, .pos-wallet-card::after{
 visibility: ${({ theme }) => theme.hero_bg_dark} !important;
}
.dashboard-overview-wrap .wrap .balance-top-box .btn-wrap-box .btn-one{
  background-color: ${({ theme }) => theme.hero_btn_one_bg} !important;
  color: ${({ theme }) => theme.hero_btn_one_color} !important;
}
.dashboard-overview-wrap .wrap .balance-top-box .btn-wrap-box .btn-one .img-box .img{
  stroke: ${({ theme }) => theme.white_black_color} !important;
}
.action-bar-set-pin-info >*:nth-child(2){
  color: ${({ theme }) => theme.black_white_color} !important;
  // display: none;
}
 .spinner-wrap .loader-spiner-react svg{
  stroke: ${({ theme }) => theme.loading_spinner_bg} !important;
  // fill: ${({ theme }) => theme.loading_spinner_bg} !important;
}
 .spinner-wrap .loader-spiner-react svg circle{
  stroke: ${({ theme }) => theme.loading_spinner_bg} !important;
  opacity: .3 !important;
  // fill: ${({ theme }) => theme.loading_spinner_bg} !important;
}
.form-label span .load-wrap >*:first-child svg,  .load-wrap >*:first-child svg{
  fill: ${({ theme }) => theme.black_white_color} !important;
}
.action_bar__warning >*:nth-child(2){
  color: ${({ theme }) => theme.black_white_color} !important;
}
.container-wraaper-box .footer-box .wrap form .form-group input{
  // color: #ffffff !important;
}
.deduct-wrap .toggle-text .toggle-wrap .toggle-label{
  background-color: ${({ theme }) => theme.toggle_two_dark_theme} ;
}
.pos-select-wrap .react-select-class > *:nth-child(3){
  background-color: ${({ theme }) => theme.white_black_bg} !important;
  box-shadow: ${({ theme }) => theme.card_box_shadow};
}
.bottom-content-box-pos-index-new .terminal-show-box .terminal-wrap-box .top-img-box{
  opacity:  ${({ theme }) => theme.pos_terminal_box_opacity};
}
.black-purple-bg{
  background-color: ${({ theme }) => theme.black_purple_bg} !important;
}
.account-detail-box-side-menu{
  background-color: ${({ theme }) =>
    theme.accout_detail_side_menu_box} !important;
}
.account-detail-box-side-menu .img-box{
  background-color: ${({ theme }) =>
    theme.accout_detail_side_menu_box_img} !important;
}
.account-detail-box-side-menu .img-box svg path{
  stroke: ${({ theme }) => theme.white_black_bg} !important;
}
.nav-link .name{
  color: ${({ theme }) => theme.black_white_color} ;
  opacity:  ${({ theme }) => theme.pos_terminal_box_opacity} !important;
}

.nav-link-solid .name{
  color: ${({ theme }) => theme.black_white_color} ;
  opacity:  ${({ theme }) => theme.pos_terminal_box_opacity_solid} !important;
}

.nav-link-active .name{
  color: ${({ theme }) => theme.black_white_color}  ;
  opacity:  1 !important;
}

.children-box .nav-link-active .name{
  color: ${({ theme }) => theme.black_white_color}  !important;
  opacity:  1 !important;
}

.nav-link-active .icon{
  color: ${({ theme }) => theme.black_white_color} ;
  // opacity:  ${({ theme }) => theme.pos_terminal_box_opacity} !important;
}
.nav-link-active::hover .name{
  color: ${({ theme }) => theme.black_white_color} !important;
  // opacity:  ${({ theme }) => theme.pos_terminal_box_opacity} !important;
}
.send-money-icon-bg{
  background-color: ${({ theme }) => theme.send_money_icon_bg} !important;
}
.new-side-menu-wrap-box-dark .top-info-box, .new-side-menu-wrap-box-dark .bottom-info-box{
  background-color: ${({ theme }) => theme.sidebar_two_bg} !important;
}
.new-side-menu-wrap-box-dark .account-detail-box-side-menu .img-box{
  background-color: #ffffff !important;
}

.new-side-menu-wrap-box-dark .account-detail-box-side-menu .img-box svg path{
  stroke: #020202 !important;
}

.new-side-menu-wrap-box-dark  .nav-link .name{
  color: ${({ theme }) => theme.sidebar_two_color} !important;
  // opacity:  ${({ theme }) => theme.pos_terminal_box_opacity} !important;
}
.new-side-menu-wrap-box-dark  .nav-link-active .name{
  color: #ffffff !important;
  // opacity:  ${({ theme }) => theme.pos_terminal_box_opacity} !important;
}
.transaction-main-wrap .transaction-card-bar-chart-wrap .chart-info-content-box .right-box .bottom-info-box .bar-chat-box{
  color: ${({ theme }) => theme.black_white_color} !important;
}
.charge-rate-box-wrap{
  background-color: ${({ theme }) => theme.charge_rate_paymelink_bg} !important;
}
.minimal-before-active-color::before{
background-color: ${({ theme }) => theme.minimal_active_before_bg} !important;
}

.minimal-before-active-color::after{
  background-color: ${({ theme }) => theme.black_white_color} !important;
  }
  
.raven-btn svg rect,  .form-group .input-submit .loader-wrap svg rect, .spin .spintula {
  fill: ${({ theme }) => theme.raven_btn_svg_color} !important;
}
`;

export const lightTheme = {
  // pos_terminal_box_opacity_solid: "1",
  sidebar_two_bg: "#020202",
  sidebar_two_color: "#676767",
  send_money_icon_bg: "#F0EEFF",
  accout_detail_side_menu_box: "#ffffff",
  accout_detail_side_menu_box_img: "#020202",
  black_purple_bg: "#F7F8F7",
  main_body_bg: "#f7f8f7",
  fade_black_bg: "#f7f8f7",
  white_black_bg: "#ffffff",
  black_white_color: "#020202",
  white_black_color: "#ffffff",
  white_grey_bg: "#ffffff",
  border_white_grey_toggle: ".07rem solid #ffffff",
  dull_bright_filter: "unset",
  border_thin: "0.04rem solid #e5e6e6",
  border_profile: "0.04rem solid #e5e6e6",
  card_white_black_bg: "#ffffff",
  card_box_shadow: "-1px 0px 20px rgba(229, 230, 230, 0.35)",
  card_border: ".1rem solid rgba(208, 213, 221, 0.5)",
  border_theme: "0.1rem solid rgba(208, 213, 221, 0.5)",
  white_grey_fade_bg: "#f7f8f7",
  yellow_white_grey_fade_bg: "rgba(255, 246, 237, 1)",
  white_side_nav_grey_fade_bg: "#f7f8f7",
  border_theme_remove: " 0.1rem solid #cccccc",
  grey_white_color: "#676767",
  grey_white_color_white: "#676767",
  bright_up: "unset",
  black_fade_white_bg: "#020202",
  no_shadow_box_shadow: "none",
  shadow_shadow_box_shadow:
    " 0px 24px 48px -8px rgba(51, 51, 51, 0.04), 0px 48px 64px -36px rgba(51, 51, 51, 0.08)",
  no_border_border: "none",
  grey_grey_border: "#cccccc !important",
  purple_grey_border: "#f0eeff",
  input_bg: "",
  input_border: ".1rem solid #cccccc",
  toggle_bg: "",
  no_bg: "unset",
  no_border: "unset",
  hover_box_shadow:
    " 0px 24px 48px -8px rgba(51, 51, 51, 0.04),0px 48px 64px -36px rgba(51, 51, 51, 0.08)",
  hover_bg: "#ffffff",
  step_active_bg: "#333333",
  step_active_color: "#ffffff",
  step_active_text: "#020202",
  view_all_bg: "#ffffff",
  view_border: "0.1rem solid #f7f8f7",
  active_black_color: "#020202",
  active_border_color: " #f0eeff",
  inset_color: "#f7f8f7",
  grey_black_bg: "#f7f8f7",
  radio_border: "#020202",
  sidebar_bg: "#020202",
  skeleton_base_background: "#ebebeb",
  cal_hover_bg_selected: "#755AE2",
  skeleton_highlight_background:
    "linear-gradient( 90deg, #ebebeb, #f5f5f5, #ebebeb)",
  verification_left_wrap_bg: "#f7f8f7",
  verificatio_input_border: ".1rem solid #cccccc",
  bulk_select_transfer_type_bg: "#ffffff",
  hero_bg_dark: "hidden",
  flatpicker_disable: "rgba(64,72,72,0.1)",
  loading_spinner_bg: "#020202",
  minimal_active_before_bg: "rgba(247, 247, 247, 1)",
  raven_btn_svg_color: "#ffffff99",
};
//  background-blend-mode: multiply;
export const darkTheme = {
  sidebar_two_color: "#ffffff",
  sidebar_two_bg: "rgba(247, 248, 247, 0.1)",
  fade_black_bg: "#020202",
  white_black_bg: "#020202",
  black_white_color: "#ffffff",
  white_black_color: "#020202",
  white_grey_bg: "#333333",
  border_white_grey_toggle: ".07rem solid #333333",
  dull_bright_filter: "brightness(1000%)",
  accout_detail_side_menu_box: "rgba(247, 248, 247, 0.1)",
  accout_detail_side_menu_box_img: "#ffffff",
  dull_bright_filter_two: "brightness(500%)",
  border_thin: "0.04rem solid #cccccc",
  border_profile: "0.04rem solid #676767",
  card_white_black_bg: "#161616",
  card_box_shadow: "0px 4px 3px rgba(35, 31, 32, 0.63)",
  card_border: "unset",
  border_theme: "0.1rem solid #333333",
  white_grey_fade_bg: "#ffffff1a",
  white_grey_fade_two_bg: "#ffffff1a",
  yellow_white_grey_fade_bg: "#ffffff1a",
  white_side_nav_grey_fade_bg: "#ffffff1a",
  action_box_wrap_bg: "#ffffff1a",
  invoice_card_wrap: "#333333",
  border_theme_remove: "unset !important",
  grey_white_color: "#676767",
  grey_white_color_white: "#ffffff",
  bright_up: "brightness(400%) !important",
  black_fade_white_bg: "#1B1B1B",
  no_shadow_box_shadow: "0px 0px 20px rgba(0, 0, 0, 0.25)",
  shadow_shadow_box_shadow: "0px 0px 20px rgba(0, 0, 0, 0.25)",
  no_border_border: ".1rem solid #333333",
  purple_grey_border: "#333333",
  grey_grey_border: "#333333 !important",
  input_bg: "#333333 !important",
  input_border: ".1rem solid #333333",
  toggle_bg: "#755ae2",
  table_head_color: "rgba(247, 248, 247, 0.1)",
  table_border_color: "#333333",
  pagination_color: "#676767",
  pagination_current_color: "#ffffff",
  input_color: "#ffffff",
  no_bg: "unset",
  no_border: "unset",
  input_placeholder: "rgba(196, 196, 196, .3)",
  black_theme_bg: "#ffffff",
  black_theme_color: "#020202",
  filter_dark_theme: "brightness(0%)",
  hover_box_shadow: " 0px 0px 20px rgba(0, 0, 0, 0.25)",
  hover_bg: "rgba(247, 248, 247, 0.1)",
  hover_bg_after: ".15rem solid #755AE2",
  step_active_bg: "#ffffff",
  step_active_color: "#020202",
  step_active_text: "#ffffff",
  submit_input_bg: "#ffffff",
  submit_input_text: "#020202",
  view_all_bg: "#333333",
  view_border: "0.01rem solid #ffffff",
  toggle_label_bg: "#020202",
  text_active_setting: "#ffffff",
  check_active_setting_bg: "#755AE2",
  check_active_setting_icon: "#020202",
  add_error_setting_box: "rgba(247, 248, 247, 0.1)",
  active_black_color: "#ffffff",
  active_border_color: "#333333",
  phone_border_color: "#333333",
  inset_color: "#333333",
  grey_black_bg: "#020202",
  img_box_debit_credit: "rgba(247, 248, 247, 0.1)",
  raven_modal_bg: "#020202",
  raven_modal_btn_bg: "rgba(247, 248, 247, 0.1)",
  charge_rate_paymelink_bg: "rgba(247, 248, 247, 0.1)",
  radio_border: "#ffffff",
  sidebar_bg: "#1b1b1b",
  step_num_color: "#ffffff",
  unpaid_invoice_bg: "#020202",
  skeleton_base_background: "#252525",
  skeleton_highlight_background:
    "linear-gradient( 90deg, #252525, #755AE2, #252525)",
  border_color_veri: "#ffffff",
  border_width_veri: ".5rem",
  background_veri: "#ffffff",
  background_veri_icon: "#020202",
  background_veri_icon_width: "1.25rem",
  verification_left_wrap_bg: "#1b1b1b",
  cal_hover_bg: "#020202",
  cal_hover_bg_selected: "#755AE2",
  cal_hover_border: ".1rem solid #1b1b1b",
  cal_icon_color: " 4px solid #ffffff ",
  cal_iborder_bottom_today: " .1rem solid red",
  drop_down_payroll_box: "#333333",
  mobile_logo_img: "brightness(1000%)",
  progress_bar_bg: "rgba(255, 255, 255, .2)",
  toggle_two_dark_theme: "rgba(255, 255, 255, .2)",
  bulk_select_transfer_type_bg: "#020202",
  main_body_bg: "#020202",
  action_bar_bg: "#333333",
  hero_bg_dark: "visible",
  hero_btn_one_bg: "#ffffff",
  hero_btn_one_color: "#020202",
  hero_btn_one_fill: "#020202",
  flatpicker_disable: "#1b1b1b",
  loading_spinner_bg: "#755ae2",
  pos_terminal_box_opacity: ".3",
  pos_terminal_box_opacity_solid: "1",
  black_purple_bg: "#1b1b1b",
  send_money_icon_bg: "rgba(255, 255, 255, .2)",
  minimal_active_before_bg: "#ffffff1a",
  raven_btn_svg_color: "#020202",
  card_black_purple_bg: "visible",
};
