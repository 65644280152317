import React, { useState } from "react";
import "../../../../styles/dashboard/Two-Fa/ConfirmTwoFaModal.css";
import { RavenInputField, RavenModal } from "@ravenpay/raven-bank-ui";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { verifyTwoFaFactorSettings } from "../../../../redux/settings";
import { debounce } from "lodash";
import { getUser } from "../../../../redux/user";
import NoContentBox from "../../../../components/reusables/NoContentBox";

const ConfirmTwoFAModalTwo = ({ onClose, visible, onFinish, detail }) => {
  const { loadingEnable, loadingVerify2fa } = useSelector(
    (state) => state.settings
  );
  const dispatch = useDispatch();
  const [details, setDetails] = useState({
    token: "",
  });
  const [completeToken, setCompleteToken] = useState(false);

  const handleSubmit = debounce(async (num) => {
    const obj = {
      token: num || details?.token,
    };
    // console.log(obj);
    const data = await dispatch(verifyTwoFaFactorSettings(obj));
    // console.log(data);
    if (data?.payload?.data?.status === "success") {
      onFinish();
      dispatch(getUser());
    }
  }, 100);

  const handleClose = () => {
    setDetails((prev) => {
      return { ...prev, token: "" };
    });
    setCompleteToken(false);
    onClose();
  };

  return (
    <>
      <RavenModal
        disabled={!completeToken}
        btnLabel="Verify Now"
        btnColor="black-light"
        visble={visible}
        onClose={handleClose}
        onBtnClick={handleSubmit}
        loading={loadingVerify2fa}
      >
        <div className="confirm-two-fa-modal-wrap-index-type-two">
          <div className="title-text-box">
            <p className="title">Two-factor authentication</p>
            <p className="text grey-white-color">
              Complete this step for added security
            </p>
          </div>
          {loadingEnable ? (
            <div className="loading-fa-box" >
              <NoContentBox  loading={true} />
            </div>
          ) : (
            <>
              {" "}
              {/* number title text box start */}
              <div className="num-text-title-box">
                <div className="num-title-box">
                  <div className="num grey-bg">
                    <span>1</span>
                  </div>
                  <p className="title">Scan the QR Code</p>
                </div>
                <p className="text grey-white-color">
                  Use an authentication app on your smartphone to scan the QR
                  code or you can manually enter the code below
                </p>
              </div>
              {/* number title text box end */}
              {/* scan box start */}
              <div className="scan-big-box grey-bg">
                <div className="scan-inner-wrap white-black-bg">
                  <figure className="bar img-box">
                    <img src={detail?.qrCodeUrl || ""} alt="" className="img" />
                  </figure>
                </div>
              </div>
              {/* scan box end */}
              {/* number title text box start */}
              <div className="num-text-title-box">
                <div className="num-title-box">
                  <div className="num grey-bg">
                    <span>2</span>
                  </div>
                  <p className="title">Enter the code</p>
                </div>
                <p className="text grey-white-color">
                  Enter the 6-digit code generated by your authentication app to
                  confirm that he setup was successful.
                </p>
              </div>
              {/* number title text box end */}
              {/* pin box start */}
              <div className="two-fa-pin-box">
                <div className="pin-two-fa">
                  <RavenInputField
                    value={details?.token}
                    onChange={(e) => {
                      //   console.log(e);
                      setDetails((prev) => {
                        return { ...prev, token: e };
                      });
                      setCompleteToken(false);
                    }}
                    onComplete={(num) => {
                      setCompleteToken(true);
                      handleSubmit(num);
                    }}
                    type="pin"
                    color="black-light"
                  />
                </div>
              </div>
              {/* pin box end */}
            </>
          )}
        </div>
      </RavenModal>
    </>
  );
};

export default ConfirmTwoFAModalTwo;
