import {
  RavenButton,
  RavenModal,
  RavenNumberFormat,
  RavenToolTip,
} from "@ravenpay/raven-bank-ui";
import React, { useContext } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { downloadTransactionReciept } from "../../../redux/transaction";
import "../../../styles/dashboard/transaction/DashboardTransaction.css";
import {
  formatDate,
  formatNumWithCommaNairaSymbol,
  formatTypeFunction,
  lowerCaseWrap,
  trimLongString,
} from "../../../utils/helper/Helper";
import ActionContext from "../../../context/ActionContext";
import { useSelector } from "react-redux";
import DescriptionPreviewBox from "../../../components/initiator-des-box/DescriptionPreviewBox";

const DownloadReceiptModal = ({
  onClose,
  visible,
  detail,
  onRetry,
  onConfirm,
  onReject,
  onView,
}) => {
  // const { loadingDownload } = useSelector((state) => state.transaction);
  // const dispatch = useDispatch();
  const actionCtx = useContext(ActionContext);
  const formatMetaData = (param) => {
    if (param) {
      const obj = JSON?.parse(param);
      // console.log(obj);
      return obj;
    }
  };
  const { payment_requests } = useSelector((state) => state.payment_request);
  // const handleSubmit = async () => {
  //   const obj = {
  //     transaction_id: String(detail?.id),
  //   };
  //   const data = await dispatch(downloadTransactionReciept(obj));
  //   if (data?.payload?.data?.status === "success") {
  //     // setDownloadCsvId("");
  //     downloadCsv(data?.payload?.data?.data?.url);
  //     // downloadCsvId("");
  //     // console.log(data);
  //   }
  // };

  // const downloadCsv = (url) => {
  //   window.open(url, "_blank").focus();
  // };

  const formatList = (list) => {
    if (list?.length > 0) {
      const newList = list?.map(
        (chi) => `${payment_requests?.description_image_url || ""}${chi || ""}`
      );
      // console.log(newList);

      return newList;
    }
  };

  return (
    <RavenModal
      btnColor={`black-light`}
      visble={visible}
      // btnLabel={`Close`}
      //   btnLabel={`Download Reciept`}
      className="transaction-download-receipt-modal"
      onClose={onClose}
      onBtnClick={onClose}
      //   loading={loadingDownload}
      // dontShowClose={true}
    >
      <div className="wrap-modal" style={{ paddingBottom: "9rem" }}>
        <p
          onClick={() => {
            // console.log(formatMetaData(detail?.meta_data));
            // console.log(detail);
            // console.log(payment_requests?.description_image_url);
          }}
          className="title"
        >
          Payment Request
        </p>
        <div className="underline"></div>
        {/* label-value-status box start */}
        <div className="label-value-status-box">
          {/* box start */}
          {formatTypeFunction(detail?.type) === "Reversal" && (
            <div className="box">
              <p className="label-download grey-white-color">Recipient</p>
              <p className="value-download">{`System Reversal`}</p>
            </div>
          )}
          {/* box end */}
          {/* box start */}
          <div className="box">
            <p className="label-download grey-white-color">Amount:</p>
            <p className="value-download">
              {formatNumWithCommaNairaSymbol(detail?.amount || 0)}
            </p>
          </div>
          {/* box end */}
          {/* {detail?.fee  && ( */}
          <div className="box">
            <p className="label-download grey-white-color">Transaction Fee:</p>
            <p className="value-download">
              {RavenNumberFormat(detail?.fee || 0)}
            </p>
          </div>
          {/* )} */}
          {/* box end */}
          {/* box start */}
          {/* {detail?.fee  && ( */}
          {/* <div className="box">
            <p className="label-download grey-white-color">
              Transaction Charges:
            </p>
            <p className="value-download">
              {formatNumWithCommaNairaSymbol(detail?.fee || 0)}
            </p>
          </div> */}
          {/* )} */}
          {/* box end */}
          {/* box start */}
          {/* {detail?.fee  && ( */}
          <div className="box">
            <p className="label-download grey-white-color">Intiator :</p>
            <p className="value-download">
              {`${detail?.intiator_fname || "---"} ${
                detail?.intiator_fname?.length +
                  detail?.intiator_lname?.length >
                12
                  ? trimLongString(detail?.intiator_lname, 10)
                  : detail?.intiator_lname
              }`}
            </p>
          </div>
          {/* )} */}
          {/* box end */}
          {/* box start */}
          {/* {Number(detail?.amount) >= 10000 &&
            formatTypeFunction(detail?.type) === "Money Received" && (
              <div className="box">
                <p className="label-download grey-white-color">Stamp Duty</p>
                <p style={{ width: "80%" }} className="value-download">
                  {formatNumWithCommaNairaSymbol(detail?.stamp_duty || "0")}
                </p>
              </div>
            )} */}
          {/* box end */}
          {/* type start ----------------- */}
          {formatTypeFunction(detail?.type) === "Airtime Recharge" ||
          formatTypeFunction(detail?.type) === "Data Recharge" ? (
            <>
              <div className="box">
                <p className="label-download grey-white-color">Provider:</p>
                <p className="value-download">
                  {lowerCaseWrap(formatMetaData(detail?.meta_data)?.network) ||
                    "---"}
                </p>
              </div>
              {/* box end */}
              {/* box start */}
              <div className="box">
                <p className="label-download grey-white-color">Phone number</p>
                <p className="value-download">
                  {formatMetaData(detail?.meta_data)?.phone_number || "---"}
                </p>
              </div>
              {/* box end */}
            </>
          ) : (
            ""
          )}
          {/* type end ----------------- */}
          {/* type start ----------------- */}
          {formatTypeFunction(detail?.type) === "Electricity Payment" && (
            <>
              <div className="box">
                <p className="label-download grey-white-color">Provider:</p>
                <p className="value-download">
                  {formatMetaData(detail?.meta_data)?.provider || "---"}
                </p>
              </div>
              {/* box end electricity_token*/}
              <div className="box">
                <p className="label-download grey-white-color">Plan</p>
                <p
                  style={{ textTransform: "capitalize" }}
                  className="value-download"
                >
                  {formatMetaData(detail?.meta_data)?.meter_type || "---"}
                </p>
              </div>
              {/* box end */}
              {/* box start */}
              <div className="box">
                <p className="label-download grey-white-color">Meter number</p>
                <p className="value-download">
                  {formatMetaData(detail?.meta_data)?.meter_no || "---"}
                </p>
              </div>
              {/* box end */}
              {/* box start */}
              <div className="box">
                <p className="label-download grey-white-color">Token</p>
                <p className="value-download">
                  {detail?.electricity_token || "---"}
                </p>
              </div>
              {/* box end */}
              {/* box start */}
              {/* <div className="box">
                <p className="label-download grey-white-color">Customer Name</p>
                <p className="value-download">
                  {formatMetaData(detail?.meta_data)?.customer_name || "---"}
                </p>
              </div> */}
              {/* box end */}
            </>
          )}
          {/* type end ----------------- */}
          {/* type start ----------------- */}
          {formatTypeFunction(detail?.type) === "Cable Recharge" && (
            <>
              <div className="box">
                <p className="label-download grey-white-color">Provider:</p>
                <p className="value-download">
                  {formatMetaData(detail?.meta_data)?.provider || "---"}
                </p>
              </div>
              {/* box end */}
              {/* box start */}
              <div className="box">
                <p className="label-download grey-white-color">Meter No.</p>
                <p className="value-download">
                  {formatMetaData(detail?.meta_data)?.smart_card_number ||
                    "---"}
                </p>
              </div>
              {/* box end */}
              {/* box start */}
              <div className="box">
                <p className="label-download grey-white-color">Customer Name</p>
                <p className="value-download">
                  {formatMetaData(detail?.meta_data)?.customer_name || "---"}
                </p>
              </div>
              {/* box end */}
            </>
          )}
          {/* type end ----------------- */}
          {formatTypeFunction(detail?.type) === "Money Received" &&
            formatMetaData(detail?.meta_data)?.first_name &&
            formatMetaData(detail?.meta_data)?.last_name && (
              <>
                <div className="box">
                  <p className="label-download grey-white-color">Sent By:</p>
                  <p className="value-download">
                    {`${
                      formatMetaData(detail?.meta_data)?.first_name || "---"
                    } ${formatMetaData(detail?.meta_data)?.last_name || "---"}`}
                  </p>
                </div>
                {/* box end electricity_token*/}
              </>
            )}
          {/* type start ----------------- */}
          {formatTypeFunction(detail?.type) === "Money Received" ||
          formatTypeFunction(detail?.type) === "Money Sent" ||
          formatTypeFunction(detail?.type) === "Reversal" ||
          formatTypeFunction(detail?.type) === "Transaction Charge" ? (
            <>
              {/* box start */}
              {!formatMetaData(detail?.meta_data)?.identifier && (
                <div className="box">
                  <p className="label-download grey-white-color">Bank:</p>
                  <p className="value-download">
                    {formatMetaData(detail?.meta_data)?.bank || "---"}
                  </p>
                </div>
              )}
              {formatMetaData(detail?.meta_data)?.identifier && (
                <div className="box">
                  <p className="label-download grey-white-color">Bank:</p>
                  <p className="value-download">Raven Bank</p>
                </div>
              )}
              {/* box end */}
              {/* box start */}
              {!formatMetaData(detail?.meta_data)?.identifier && (
                <div className="box">
                  <p className="label-download grey-white-color">Account No.</p>
                  <p className="value-download">
                    {formatMetaData(detail?.meta_data)?.account_number || "---"}
                  </p>
                </div>
              )}
              {formatMetaData(detail?.meta_data)?.identifier && (
                <div className="box">
                  <p className="label-download grey-white-color">
                    Raven Username
                  </p>
                  <p className="value-download">
                    {`@${formatMetaData(detail?.meta_data)?.identifier}` ||
                      "---"}
                  </p>
                </div>
              )}
              {/* box end */}
              {formatTypeFunction(detail?.type) === "Money Sent" && (
                <>
                  {" "}
                  {/* box start */}
                  <div className="box">
                    <p className="label-download grey-white-color">Recipient</p>
                    <p className="value-download">
                      {formatMetaData(detail?.meta_data)?.account_name || "---"}
                    </p>
                  </div>
                  {/* box end */}
                </>
              )}
              {/* box start */}
              <div className="box">
                <p className="label-download grey-white-color">Session ID</p>
                <p style={{ width: "80%" }} className="value-download">
                  {detail?.session_id ||
                    formatMetaData(detail?.meta_data)?.session_id ||
                    "---"}
                </p>
              </div>
              {/* box end */}

              {/* box end */}
            </>
          ) : (
            ""
          )}
          {/* type end ----------------- */}

          {/* box start */}
          {/* {formatTypeFunction(detail?.type) !== "Reversal" && ( */}
          <div className="box">
            <p className="label-download grey-white-color">Transaction Type </p>
            <p className="value-download">
              {formatTypeFunction(detail?.request_type) || "---"}
            </p>
          </div>
          {/* )} */}
          {/* box end */}
          {detail?.type === "payment-link" && (
            <>
              {" "}
              <div className="box">
                <p className="label-download grey-white-color">
                  Customer Email
                </p>
                <p className="value-download">
                  {formatMetaData(detail?.meta_data)?.customer_email || ""}
                </p>
              </div>
            </>
          )}
          {formatTypeFunction(detail?.type) === "Payroll Charge" && (
            <>
              {" "}
              <div className="box">
                <p className="label-download grey-white-color">Narration: </p>
                <p className="value-download">
                  {formatMetaData(detail?.meta_data)?.narration}
                </p>
              </div>
            </>
          )}
          {detail?.type === "invoice-payment" && (
            <>
              <div className="box">
                <p className="label-download grey-white-color">Invoice Id </p>
                <p className="value-download">
                  {formatMetaData(detail?.meta_data)?.narration?.replace(
                    "payment for invoice",
                    ""
                  )}
                </p>
              </div>
              <div className="box">
                <p className="label-download grey-white-color">
                  Customer Email
                </p>
                <p className="value-download">
                  {formatMetaData(detail?.meta_data)?.customer_email || ""}
                </p>
              </div>
            </>
          )}
          {/* box start */}
          <div className="box">
            <p className="label-download grey-white-color">Initiation date. </p>
            <p className="value-download">
              {formatDate(detail?.created_at) || "---"}
            </p>
          </div>
          {/* box end */}
          {formatTypeFunction(detail?.type) !== "Reversal" && (
            <>
              {" "}
              {/* box start */}
              {formatMetaData(detail?.meta_data)?.reference ||
              detail?.merchant_ref ? (
                <div className="box">
                  <p className="label-download grey-white-color">
                    Transaction Ref.
                  </p>
                  <p className="value-download ref">
                    <span>
                      {" "}
                      {formatMetaData(detail?.meta_data)?.reference ||
                        detail?.merchant_ref ||
                        "---"}
                    </span>
                  </p>
                </div>
              ) : (
                ""
              )}
              {/* box end */}
            </>
          )}
          {/* box start */}
          <div className="box">
            <p className="label-download grey-white-color">Status </p>
            <div
              className={`status-type-wrap ${
                String(detail?.status) === "0" && "status-type-wrap-pending"
              } ${
                String(detail?.status) === "3" && "status-type-wrap-success"
              } ${
                String(detail?.status) === "11" && "status-type-wrap-success"
              } ${
                String(detail?.status) === "success" &&
                "status-type-wrap-success"
              } ${
                String(detail?.status) === "paid" && "status-type-wrap-success"
              } ${String(detail?.status) === "2" && "status-type-wrap-fail"} ${
                String(detail?.status) === "overdue" &&
                "status-type-wrap-pending"
              } ${
                String(detail?.status) === "26" && "status-type-wrap-pending"
              } ${
                String(detail?.status) === "pending" &&
                "status-type-wrap-pending"
              }`}
            >
              <span>
                {" "}
                {String(detail?.status) === "1"
                  ? "Processing"
                  : String(detail?.status) === "26"
                  ? "Pending"
                  : String(detail?.status) === "3"
                  ? "Completed"
                  : String(detail?.status) === "2"
                  ? "Cancelled"
                  : String(detail?.status) === "11"
                  ? "Success"
                  : detail?.status}
              </span>
            </div>
          </div>
          {/* box end */}
          {/* box start */}
          <div className="box">
            <p className="label-download grey-white-color">Description</p>
            <p
              className={`value-download ${
                String(detail?.narration)?.length > 29
                  ? "tooltip-hover-wrap"
                  : ""
              }`}
            >
              <RavenToolTip
                position={`top-center`}
                text={detail?.narration}
                color="black-light"
              />
              {trimLongString(detail?.narration, 30) || `---`}
            </p>
          </div>
          {/* box end */}
        </div>
        {/* label-value-status box end */}
        {/* add and preview images box start */}
        {formatList(formatMetaData(detail?.description_file_name))?.length >
          0 && (
          <DescriptionPreviewBox
            hideLabel={true}
            hideAdd={true}
            imgList={formatList(formatMetaData(detail?.description_file_name))}
            hideRemove={true}
            onView={(param) => {
              onView && onView(param);
            }}
            normalUrl={true}
          />
        )}
        {/* add and preview images box end */}
      </div>
      <div className="two-button-wrap">
        {detail?.status === 3 ? (
          <>
            {" "}
            <RavenButton
              style={{ gridColumn: `1 / -1` }}
              className={`btn-outline-base-bg-black`}
              onClick={onClose}
              color={`error-light`}
              textColor={`black-light`}
              label={`Close`}
            />
          </>
        ) : (
          <>
            {detail?.status !== 2 && (
              <>
                <RavenButton
                  className={`btn-outline-error-light`}
                  onClick={onReject}
                  color={`error-light`}
                  textColor={`error-light`}
                  label={`Cancel Payment`}
                  style={{
                    gridColumn:
                      actionCtx?.currentAccount?.authorization_level ===
                        "initiator" && `1 / -1`,
                  }}
                />
              </>
            )}
            {actionCtx?.currentAccount?.authorization_level !== "initiator" && (
              <RavenButton
                onClick={() => {
                  if (detail?.status === 2) {
                    onRetry();
                  } else {
                    onConfirm();
                  }
                }}
                style={{ gridColumn: detail?.status === 2 && `1 / -1` }}
                color={`black-light`}
                label={
                  detail?.status === 2 ? `Retry Payment` : `Confirm Payment`
                }
                //   style={{width: reschedule && "max-content"}}
                className={detail?.status === 2 && `btn-outline-base-bg-black`}
                textColor={detail?.status === 2 && `black-light`}
              />
            )}
          </>
        )}
      </div>
    </RavenModal>
  );
};

export default DownloadReceiptModal;
