/* eslint-disable no-use-before-define */
import React, { useContext, useEffect, useState } from "react";
import ContainerWrapper from "../../../components/reusables/ContainerWrapper";
// import { useNavigate } from 'react-router-dom';
import TitletextBox from "../../../components/reusables/TitletextBox";
import DashboardLayout from "../../../layout/dashboard/DashboardLayout";
import "../../../styles/dashboard/transaction/DashboardTransaction.css";
// import { CircularProgressbar } from "react-circular-progressbar";
import {
  RavenDateTime,
  // RavenDateTime,
  // RavenButton,
  RavenInputField,
  RavenNumberFormat,
  RavenPagination,
  RavenTable,
  RavenTableRow,
  RavenToggleSlide,
  RavenToolTip,
} from "@ravenpay/raven-bank-ui";
import {
  formatDate,
  // formatNumWithCommaNaira,
  formatNumWithCommaNairaSymbol,
  formatTypeFunction,
  getTotalPage,
  lowerCaseWrap,
  reactSelectStyle,
  trimLongString,
} from "../../../utils/helper/Helper";
import DownloadReceiptModal from "./DownloadReceiptModal";
// import TwoLineTransactionComponent from "./TwoLineTransactionComponent";
import ActionContext from "../../../context/ActionContext";
import noContentImg from "../../../assets/no-content-transaction.png";
import { useDispatch, useSelector } from "react-redux";

import {
  // downloadTransactionReciept,
  getTransactionsTwo,
  getTransactionsTwoPagination,
} from "../../../redux/transaction";
// import moment from "moment";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import useDebounce from "../../../utils/helper/UseDebounce";
import NoContentBox from "../../../components/reusables/NoContentBox";
// import { useNavigate } from "react-router-dom";
// import ElectricityGenerateModal from "../settings/sections/modal/ElectricityGenerateModal";
import GenerateStatementModal from "../settings/sections/modal/GenerateStatementModal";
import useWindowSize from "../../../utils/helper/UseWindowSize";
import MobileTableCard from "../../../components/reusables/MobileTableCard";
import NocontentPage from "../../../components/reusables/NocontentPage";
import TransactionCardBarChart from "./TransactionCardBarChart";
import CustomDateSelectModal from "../pos/modal/CustomDateSelectModal";
import SpecificDateSelectModal from "../pos/modal/SpecificDateSelectModal";
import { FaTimes } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useOnClickOutside } from "../../../utils/helper/UseOnClickOutside";
import { CSVLink } from "react-csv";
// import DateFragmentBox from "../../../components/fragments/DateFragmentBox";
// import PerPageComponent from "../../../components/reusables/PerPageComponent";

const DashboardTransaction = () => {
  const [singleObj, setSingleObj] = useState({});
  const { transactions_two, loading } = useSelector(
    (state) => state.transaction
  );
  const { user, user_resource_and_permissions, all_account } = useSelector(
    (state) => state.user
  );
  const actionCtx = useContext(ActionContext);
  const [showModal, setShowModal] = useState({
    view: false,
    statement: false,
  });
  // const [checkCustom, setCheckCustom] = useState(false);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [selectOptionVal, setSelectOptionVal] = useState({
    one: "",
    two: "",
    three: "",
  });
  // const [dateFilter, setDateFilter] = useState("");
  const [typeFilter] = useState("");
  // const [perPage] = useState(10);

  const today = new Date();

  // Subtract one day from the current date
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);
  const year = yesterday.getFullYear();
  const month = yesterday.getMonth() + 1; // Months are zero-indexed, so we add 1
  const day = yesterday.getDate();

  // Format the date as a string in the format "YYYY-MM-DD"
  const formattedDate = `${year}-${month < 10 ? "0" : ""}${month}-${
    day < 10 ? "0" : ""
  }${day}`;

  const selectOption = [
    { label: "All Time", value: "all" },
    { label: "Today", value: "today" },
    { label: "Yesterday", value: `${formattedDate}_${formattedDate}` },
    { label: "1 Week", value: "7_days" },
    { label: "2 Weeks", value: "14_days" },
    { label: "1 Month", value: "30_days" },
    { label: "3 Months", value: "90_days" },
    { label: "Specific Date", value: "specific_date" },
    { label: "Custom", value: "custom" },
  ];

  const formatSelectOptionTwo = () => {
    const list = [
      "money_received",
      "money_sent",
      "transfer_reversal",
      "transfer_fee_reversal",
      "card_funding_reversal",
      "card_fee_reversal",
      "transfer_fee",
      "payroll_charge",
      "virtual_card_charge",
      "physical_card_charge",
      "virtual_card_withdrawal",
      "virtual_card_debit",
      "virtual_card_processing_fee_refund",
      "airtime_reversal",
      "airtime_recharge",
      "data_recharge",
      "cable_recharge",
      "electricity_recharge",
      "bet_recharge",
    ];
    const newList = list.map((chi) => {
      return { label: formatTypeFunction(chi), value: chi };
    });

    const newListAll = [{ label: "All Type", value: "all" }, ...newList];
    return newListAll;
  };

  const dispatch = useDispatch();

  const headerList = ["NARRATION", "AMOUNT", "TYPE", " DATE", "STATUS"];

  const rowTypeText = (type, text, textTwo) => {
    return (
      <div className="type-text-box">
        {loading ? (
          <div style={{ display: "flex", alignItems: "center" }}>
            {" "}
            <Skeleton
              width={30}
              height={30}
              circle
              style={{ marginRight: "1rem" }}
            />{" "}
            <div className="">
              {" "}
              <Skeleton width={110} height={17} />
              <Skeleton width={200} height={10} />
            </div>
          </div>
        ) : (
          <>
            {" "}
            <div className="img-wrap">
              <figure
                className={`img-box-cre-deb ${
                  type === "debit" && "img-box-debit"
                } img-box-debit-credit`}
              >
                {type === "credit" ? (
                  <svg
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="img"
                  >
                    <path
                      d="M9 1L1 9M1 9H6.33333M1 9V3.66667"
                      stroke="#1ACE37"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                ) : (
                  <svg
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="img"
                  >
                    <path
                      d="M1 9L9 1M9 1H3.66667M9 1V6.33333"
                      stroke="#FF0F00"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                )}
              </figure>
            </div>
            <div className=" text-box">
              <p className="text">{trimLongString(text, 30)}</p>
              <p
                className="text-small grey-white-color"
                style={{ textTransform: "capitalize" }}
              >
                {textTwo}
              </p>
            </div>
          </>
        )}
      </div>
    );
  };

  const formatTypeWrap = (param) => {
    return (
      <div
        className={`status-type-wrap ${
          param === "0" && "status-type-wrap-pending"
        } ${param === "3" && "status-type-wrap-success"} ${
          param === "success" && "status-type-wrap-success"
        } ${param === "paid" && "status-type-wrap-success"} ${
          param === "2" && "status-type-wrap-fail"
        } ${param === "overdue" && "status-type-wrap-pending"} ${
          param === "1" && "status-type-wrap-pending"
        } ${param === "pending" && "status-type-wrap-pending"}`}
      >
        <span style={{ textTransform: "capitalize" }}>
          {param === "1"
            ? "Processing"
            : param === "0"
            ? "Pending"
            : param === "3"
            ? "Success"
            : param === "2"
            ? "Failed"
            : param === "11"
            ? "Reversal"
            : param}
        </span>
      </div>
    );
  };

  const CsvHeader = [
    { label: "S/N", key: "num" },
    { label: "Narration One", key: "narration_one" },
    { label: "Narration Two", key: "narration_two" },
    { label: "Amount", key: "_value" },
    { label: "Balance Before", key: "bal_before" },
    { label: "Balance After", key: "bal_after" },
    { label: "Trx Type", key: "type" },
    { label: "Trx Direction", key: "direction" },
    { label: "Reference", key: "reference" },
    { label: "Date Executed", key: "created_at" },
    { label: "Status", key: "status" },
  ];

  const formatExportData = (data) => {
    const list = data?.map((chi, idx) => {
      const {
        amount,
        direction,
        status,
        type,
        created_at,
        trx_name,
        meta_data,
        bulk_transfer_reference,
        bal_after,
        bal_before,
        trx_ref,
      } = chi;
      return {
        num: idx < 10 ? `0${idx + 1}` : idx + 1,
        narration_one:
          formatTypeFunction(type) === "Money Received"
            ? formatMetaData(meta_data)?.last_name
              ? `${formatMetaData(meta_data)?.first_name} ${
                  formatMetaData(meta_data)?.last_name
                }`
              : lowerCaseWrap(
                  formatMetaData(meta_data)?.narration?.split("/")[0]
                )
            : bulk_transfer_reference
            ? `${
                formatMetaData(meta_data)?.summary?.total_beneficiaries || ""
              } ${
                Number(
                  formatMetaData(meta_data)?.summary?.total_beneficiaries
                ) > 1
                  ? "Beneficiaries"
                  : "Beneficiary"
              }`
            : formatTypeFunction(type) === "Money Sent"
            ? lowerCaseWrap(
                formatMetaData(meta_data)?.account_name?.replace(
                  "Raven/Raven - ",
                  ""
                )
              )
            : formatTypeFunction(type) === "Bet Account Recharge"
            ? `Bet Recharge`
            : formatTypeFunction(type) === "Cable Recharge"
            ? lowerCaseWrap(formatMetaData(meta_data)?.code?.replace("-", " "))
            : formatTypeFunction(type) === "Electricity Payment"
            ? `${formatMetaData(meta_data)?.provider} ${lowerCaseWrap(
                formatMetaData(meta_data)?.meter_type
              )}`
            : formatTypeFunction(type) === "Airtime Recharge"
            ? `${formatMetaData(meta_data)?.network || ""} ${formatTypeFunction(
                type
              )}`
            : type === "invoice-payment"
            ? lowerCaseWrap(
                formatMetaData(meta_data)?.narration?.replace("payment for", "")
              )
            : type === "payment-link"
            ? `${formatMetaData(meta_data)?.title || "Payment Link"}`
            : type === "payroll_charge"
            ? formatTypeFunction(type)
            : `${
                isJsonString(trx_name)
                  ? formatMetaData(trx_name)?.top_narration
                  : formatMetaData(meta_data)?.account_name?.split("-")[0] ||
                    formatMetaData(meta_data)?.customer_name ||
                    formatMetaData(meta_data)?.meter_no ||
                    formatMetaData(meta_data)?.phone_number ||
                    formatTypeFunction(type)
              }`,
        narration_two:
          formatTypeFunction(type) === "Money Sent" &&
          formatMetaData(meta_data)?.identifier
            ? `${`Raven Bank`} • @${`${formatMetaData(meta_data)?.identifier}`}`
            : bulk_transfer_reference
            ? `Bulk ${formatTypeFunction(type) || ""}`
            : formatTypeFunction(type) === "Money Received" ||
              formatTypeFunction(type) === "Money Sent"
            ? `${formatMetaData(meta_data)?.account_number || "---"} • ${
                trimLongString(formatMetaData(meta_data)?.bank, 25) || `---`
              }`
            : formatTypeFunction(type) === "Transaction Charge" && !meta_data
            ? `${trimLongString(trx_name.split("Transaction Charge —")[1], 40)}`
            : formatTypeFunction(type) === "Cable Recharge"
            ? `${
                formatMetaData(meta_data)?.smart_card_number
              } • ${trimLongString(
                formatMetaData(meta_data)?.customer_name,
                20
              )}`
            : formatTypeFunction(type) === "Electricity Payment"
            ? `${formatMetaData(meta_data)?.meter_no} • ${trimLongString(
                formatMetaData(meta_data)?.customer_name,
                20
              )}`
            : formatTypeFunction(type) === "Airtime Recharge"
            ? formatMetaData(meta_data)?.phone_number
            : formatTypeFunction(type) === "Bet Account Recharge"
            ? `${formatMetaData(meta_data)?.name}`
            : type === "invoice-payment"
            ? `Payment from ${formatMetaData(meta_data)?.customer_email}`
            : type === "payment-link"
            ? formatMetaData(meta_data)?.narration
            : type === "payroll_charge"
            ? formatMetaData(meta_data)?.narration
            : `${
                isJsonString(trx_name)
                  ? formatMetaData(trx_name)?.bottom_narration
                  : formatMetaData(meta_data)?.bank ||
                    formatMetaData(meta_data)?.network ||
                    formatMetaData(meta_data)?.provider ||
                    ""
              }`,
        _value: formatNumWithCommaNairaSymbol(amount),
        bal_after: formatNumWithCommaNairaSymbol(String(bal_after)),
        bal_before: formatNumWithCommaNairaSymbol(String(bal_before)),
        direction: lowerCaseWrap(direction),
        type: bulk_transfer_reference
          ? `Bulk ${formatTypeFunction(type) || ""}`
          : type === "invoice-payment"
          ? "Invoice Payment"
          : type === "payment-link"
          ? "Payment Link"
          : formatTypeFunction(type),
        status:
          status === "1"
            ? "Processing"
            : status === "0"
            ? "Pending"
            : status === "3"
            ? "Success"
            : status === "2"
            ? "Failed"
            : status === "11"
            ? "Reversal"
            : status,
        reference: trx_ref || bulk_transfer_reference || "---",
        created_at: formatDate(created_at),
      };
    });
    return list;
  };

  const transactionActions = (param) => {
    return (
      <div className="transaction-action-wrap">
        {loading ? (
          <>
            {/* <Skeleton width={35} height={35} circle /> */}
            <Skeleton width={35} height={35} circle />
          </>
        ) : (
          <>
            {/* {downloadId === param?.id ? (
              <div className="">
                {" "}
                <ThreeDots
                  height="10"
                  width="80"
                  radius="9"
                  color="#020202"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />
              </div>
            ) : (
              <figure
                className="img-box grey-bg darken-action-bg"
                onClick={() => {
                  setDownloadId(param?.id);
                  handledownloadFunc(param?.id);
                }}
              >
                <svg
                  className="img dull-bright-filter"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.75 14.75H1.25M12.5 7.25L8 11.75M8 11.75L3.5 7.25M8 11.75V1.25"
                    stroke="#676767"
                    strokeWidth="1.4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </figure>
            )}  */}
            {/* <p>jhj</p> */}
            <figure
              onClick={() => {
                // console.log(param);
                if (param?.bulk_transfer_reference) {
                  localStorage.setItem(
                    "bulk-ref-single-id",
                    param?.bulk_transfer_reference?.replace("FEE:", "")
                  );
                  navigate("/dashboard-transaction-single-bulk");
                } else {
                  setShowModal((prev) => {
                    return { ...prev, view: true };
                  });
                  setSingleObj(param);
                }
              }}
              className="img-box grey-bg darken-action-bg tooltip-hover-wrap"
            >
              <RavenToolTip
                color={`black-light`}
                text={`View transaction details`}
                position={`left`}
              />
              <svg
                className="img dull-bright-filter"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.81509 9.53488C1.71295 9.37315 1.66188 9.29229 1.63329 9.16756C1.61182 9.07387 1.61182 8.92613 1.63329 8.83244C1.66188 8.70771 1.71295 8.62685 1.81509 8.46512C2.65915 7.12863 5.17155 3.75 9.0003 3.75C12.8291 3.75 15.3415 7.12863 16.1855 8.46512C16.2877 8.62685 16.3387 8.70771 16.3673 8.83244C16.3888 8.92613 16.3888 9.07387 16.3673 9.16756C16.3387 9.29229 16.2877 9.37315 16.1855 9.53488C15.3415 10.8714 12.8291 14.25 9.0003 14.25C5.17155 14.25 2.65915 10.8714 1.81509 9.53488Z"
                  stroke="#676767"
                  strokeWidth="1.4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9.0003 11.25C10.2429 11.25 11.2503 10.2426 11.2503 9C11.2503 7.75736 10.2429 6.75 9.0003 6.75C7.75766 6.75 6.7503 7.75736 6.7503 9C6.7503 10.2426 7.75766 11.25 9.0003 11.25Z"
                  stroke="#676767"
                  strokeWidth="1.4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </figure>
          </>
        )}
      </div>
    );
  };

  useEffect(() => {
    const payload = {
      per_page:
        formatMetaData(user?.theme_display_settings)?.table_size ||
        actionCtx?.perPageVal?.value,
      current_page: 1,
      transaction_type: selectOptionVal?.two?.value,
      date: selectOptionVal?.one?.value,
      bank_account_id: selectOptionVal?.three?.value,
    };
    const getTransactions = async () => {
      const data = await fetchTransactions(payload);

      if (data?.payload?.data?.transactions?.data?.length > 0) {
        setTableData(true);
        // console.log(data.payload.data.transactions.data);
      }
      // else{
      //   navigate("/dashboard-transaction_");
      // }
    };
    getTransactions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectOptionVal]);

  const fetchTransactions = async (payload) => {
    const dataVal = await dispatch(getTransactionsTwo(payload));
    if (dataVal?.payload?.status === "success") {
      setFromDate("");
      setToDate("");
      // setCheckCustom(false);
      return dataVal;
    }
  };

  useEffect(() => {
    if (
      selectOptionVal?.one?.label === "Today" ||
      selectOptionVal?.one?.value === "" ||
      selectOptionVal?.one?.value === "all" ||
      selectOptionVal?.one?.label === "1 Week" ||
      selectOptionVal?.one?.label === "1 Month" ||
      selectOptionVal?.one?.label === "3 Months" ||
      selectOptionVal?.one?.label === "2 Weeks"
    ) {
      const payload = {
        per_page: actionCtx?.perPageVal?.value,
        current_page: 1,
        transaction_type: selectOptionVal?.two?.value,
        date: selectOptionVal?.one?.value,
        bank_account_id: selectOptionVal?.three?.value,
      };
      fetchTransactions(payload);
    }
    if (selectOptionVal?.one?.name === "Specific Date") {
      const payload = {
        per_page: actionCtx?.perPageVal?.value,
        current_page: 1,
        transaction_type: selectOptionVal?.two?.value,
        date: `${selectOptionVal?.one?.value}_${selectOptionVal?.one?.value}`,
        bank_account_id: selectOptionVal?.three?.value,
      };
      // console.log(payload);
      // return;
      fetchTransactions(payload);
    }
    if (selectOptionVal?.one?.name === "Custom Date") {
      const payload = {
        per_page: actionCtx?.perPageVal?.value,
        current_page: 1,
        transaction_type: selectOptionVal?.two?.value,
        date: `${selectOptionVal?.one?.value?.split("_")[0]}_${
          selectOptionVal?.one?.value?.split("_")[1]
        }`,
        bank_account_id: selectOptionVal?.three?.value,
      };
      // console.log(payload);
      // return;
      fetchTransactions(payload);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectOptionVal, actionCtx?.perPageVal]);

  // useEffect(() => {
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [selectOptionVal, actionCtx?.perPageVal]);

  // useEffect(() => {

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [selectOptionVal, actionCtx?.perPageVal]);

  const handleFetchTransactionByREcSend = (param) => {
    const payload = {
      per_page: actionCtx?.perPageVal?.value,
      current_page: 1,
      transaction_type: param,
      date: selectOptionVal?.one?.value,
      bank_account_id: selectOptionVal?.three?.value,
    };
    setSelectOptionVal((prev) => {
      return { ...prev, two: { label: param, value: param } };
    });
    fetchTransactions(payload);
  };

  const handleFetTransactionPagination = (param, numb) => {
    const num = param?.split("&current_page=")[1]?.split("&")[0];
    // console.log(num);
    const url = `?per_page=${actionCtx?.perPageVal?.value}&current_page=${
      num || numb
    }&currency=NGN&transaction_type=${
      selectOptionVal?.two?.value || "all"
    }&date=${
      selectOptionVal?.one?.name === "Specific Date"
        ? `${selectOptionVal?.one?.value}_${selectOptionVal?.one?.value}`
        : selectOptionVal?.one
        ? selectOptionVal?.one?.value
        : toDate && fromDate
        ? `${fromDate}_${toDate}`
        : "all"
    }&search_term=${"" || "none"}`;
    dispatch(getTransactionsTwoPagination(url));
  };

  const handleAllAccountFormat = (list) => {
    if (list?.length > 0) {
      const newList = list?.map((chi) => {
        return { ...chi, value: chi?.id, label: chi?.custom_account_name };
      });
      return [...newList, { label: "All Account", value: "" }];
    }
  };

  // Debounce search term so that it only gives us latest value
  // If search has not been updated within last 500ms, query the Cosmic API
  // const [userInfo, setUserInfo] = useState(false);
  const [search, setSearch] = useState("");
  const debouncedSearchTerm = useDebounce(search, 1000);
  useEffect(() => {
    let isMount = true;

    if (isMount && debouncedSearchTerm?.length > 1) {
      const payload = {
        per_page: actionCtx?.perPageVal?.value,
        current_page: 1,
        transaction_type: selectOptionVal?.two?.value,
        date: selectOptionVal?.one?.value,
        search_term: search,
        bank_account_id: selectOptionVal?.three?.value,
      };
      fetchTransactions(payload);
    }

    if (isMount && debouncedSearchTerm?.length < 1) {
      const payload = {
        per_page: actionCtx?.perPageVal?.value,
        current_page: 1,
        transaction_type: selectOptionVal?.two?.value,
        date: selectOptionVal?.one?.value,
        search_term: "none",
        bank_account_id: selectOptionVal?.three?.value,
      };
      fetchTransactions(payload);
    }

    return () => {
      isMount = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);
  const [showFilterOption, setShowFilterOption] = useState(false);
  const formatMetaData = (obj) => {
    if (obj) {
      const val = JSON.parse(obj);
      return Object.keys(val).length > 0 ? val : obj;
    }
  };

  // const navigate = useNavigate();
  const size = useWindowSize();
  const [tableData, setTableData] = useState(false);

  const exportTableIcon = (
    <svg
      className="img "
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 15V16.2C21 17.8802 21 18.7202 20.673 19.362C20.3854 19.9265 19.9265 20.3854 19.362 20.673C18.7202 21 17.8802 21 16.2 21H7.8C6.11984 21 5.27976 21 4.63803 20.673C4.07354 20.3854 3.6146 19.9265 3.32698 19.362C3 18.7202 3 17.8802 3 16.2V15M17 10L12 15M12 15L7 10M12 15V3"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="dull-bright-filter"
      />
    </svg>
  );

  function isJsonString(str) {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
  }
  const navigate = useNavigate();
  const formatFilterDate = (obj) => {
    const dateOne = formatDate(obj?.split("_")[0])?.split("—")[0];
    const dateTwo = formatDate(obj?.split("_")[1])?.split("—")[0];
    const val = `${dateOne} to ${dateTwo}`;
    // console.log(val);
    return val;
  };

  const testingRef = useOnClickOutside(() => {
    setShowFilterOption(false);
  });

  if (
    !user_resource_and_permissions?.allowed_permissions?.includes(
      "can_view_transactions"
    )
  ) {
    return (
      <DashboardLayout>
        <TitletextBox
          title={`Transactions`}
          text={`See all transaction and give insight into each transactions`}
          // exportBtn
          // onExport={() => {
          //   setShowModal((prev) => {
          //     return { ...prev, statement: true };
          //   });
          // }}
          // exportText={`Generate Statement`}
        />
        {/* title box end */}
        <ContainerWrapper dontShowHeader>
          <NoContentBox
            title={`No permission to view Transactions`}
            text={`Transaction history will appear here when transaction is made`}
            bgColor
            loading={loading}
          />
        </ContainerWrapper>
      </DashboardLayout>
    );
  }

  return (
    <>
      <DashboardLayout>
        {transactions_two?.transactions?.data?.length > 0 || tableData ? (
          <>
            {/* title box start */}
            <TitletextBox
              title={`Transactions`}
              text={`See all transaction and give insight into each transactions`}
              exportBtn
              onExport={() => {
                setShowModal((prev) => {
                  return { ...prev, statement: true };
                });
              }}
              exportText={
                user_resource_and_permissions?.allowed_permissions?.includes(
                  "can_download_bank_statements"
                ) && `Generate Statement`
              }
            />
            {/* title box end */}
            {/* cointainer wrap start */}
            <ContainerWrapper
              pagination={
                loading ? (
                  ""
                ) : actionCtx?.showChart ? (
                  ""
                ) : !actionCtx?.showChart ||
                  transactions_two?.transactions?.next_page_url_params ||
                  transactions_two?.transactions?.prev_page_url_params ? (
                  <RavenPagination
                    color={`black-light`}
                    blackHover
                    numSpacing={size.width < 900 && 1}
                    removeNext={size.width < 900}
                    removePrev={size.width < 900}
                    currentPage={
                      Number(transactions_two?.transactions?.current_page) || 1
                    }
                    nextPage={
                      transactions_two?.transactions?.next_page_url_params
                    }
                    prevPage={
                      transactions_two?.transactions?.prev_page_url_params
                    }
                    onNext={(e) => {
                      handleFetTransactionPagination(e);
                    }}
                    onPrev={(e) => {
                      handleFetTransactionPagination(e);
                    }}
                    totalPage={
                      getTotalPage(
                        transactions_two?.transactions?.per_page,
                        transactions_two?.transactions?.total
                      ) || 1
                    }
                    onNumView={(e) => {
                      handleFetTransactionPagination("", e);
                    }}
                  />
                ) : loading ? (
                  ""
                ) : (
                  ""
                )
              }
              width={`100%`}
              dontShowHeader
            >
              <div
                onClick={() => {
                  // showFilterOption && setShowFilterOption(false);
                }}
                className="transaction-main-wrap"
              >
                <div className="new-search-filter-chart-box">
                  <div className="search-filter-box">
                    {/* per page start */}
                    {/* <PerPageComponent /> */}
                    {/* per page end */}
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                      }}
                      autoComplete="off"
                      action=""
                    >
                      <div className="search-group">
                        <RavenInputField
                          type={`search`}
                          color={`black-light`}
                          placeholder={`Search`}
                          value={search}
                          onChange={(e) => {
                            setSearch(e.target.value);
                            showFilterOption && setShowFilterOption(false);
                          }}
                        />
                      </div>
                    </form>
                    {/* filter-drop box start */}
                    <div ref={testingRef} className="filter-drop-box">
                      {/* drop box start */}
                      {showFilterOption && (
                        <div className="drop-box-wrap  card-bg shadow-shadow-box-shadow">
                          <form
                            onSubmit={(e) => {
                              e.preventDefault();
                            }}
                            autoComplete="off"
                            action=""
                          >
                            {" "}
                            <RavenInputField
                              selectOption={selectOption}
                              label={`Filter By Transaction date`}
                              color={`black-light`}
                              type={"select"}
                              placeholder={`Transaction date`}
                              selectStyles={reactSelectStyle}
                              value={selectOptionVal?.one}
                              onChange={(e) => {
                                if (
                                  e.label !== "Specific Date" &&
                                  e.label !== "Custom"
                                ) {
                                  setSelectOptionVal((prev) => {
                                    return { ...prev, one: e };
                                  });
                                }

                                if (e.label === "Specific Date") {
                                  setShowModal((prev) => {
                                    return {
                                      ...prev,
                                      selectSpecificDate: true,
                                    };
                                  });
                                }
                                if (e.label === "Custom") {
                                  setShowModal((prev) => {
                                    return { ...prev, customDate: true };
                                  });
                                }
                                showFilterOption && setShowFilterOption(false);
                                // setCheckCustom(true);
                              }}
                              selectValue={selectOptionVal?.one}
                            />
                          </form>
                          <form
                            onSubmit={(e) => {
                              e.preventDefault();
                            }}
                            autoComplete="off"
                            action=""
                          >
                            {" "}
                            <RavenInputField
                              selectOption={formatSelectOptionTwo()}
                              color={`black-light`}
                              type={"select"}
                              label={`Filter By Transaction type`}
                              placeholder={`Transaction type`}
                              selectStyles={reactSelectStyle}
                              value={selectOptionVal?.two}
                              onChange={(e) => {
                                setSelectOptionVal((prev) => {
                                  return { ...prev, two: e };
                                });
                                showFilterOption && setShowFilterOption(false);
                                // setCheckCustom(false);
                              }}
                              selectValue={typeFilter}
                            />
                          </form>
                          {all_account?.length > 1 && (
                            <form
                              onSubmit={(e) => {
                                e.preventDefault();
                              }}
                              autoComplete="off"
                              action=""
                            >
                              {" "}
                              <RavenInputField
                                selectOption={handleAllAccountFormat(
                                  all_account
                                )}
                                color={`black-light`}
                                type={"select"}
                                label={`Filter By Sub Account`}
                                placeholder={`Sub Account`}
                                selectStyles={reactSelectStyle}
                                value={selectOptionVal?.three}
                                onChange={(e) => {
                                  setSelectOptionVal((prev) => {
                                    return { ...prev, three: e };
                                  });
                                  showFilterOption &&
                                    setShowFilterOption(false);
                                  // setCheckCustom(false);
                                  // console.log(e);
                                }}
                                // selectValue={typeFilter}
                              />
                            </form>
                          )}
                        </div>
                      )}
                      {/* drop box end */}
                      {/* filter box start */}
                      <div
                        className="filter-box grey-bg"
                        onClick={() => {
                          setShowFilterOption(!showFilterOption);
                        }}
                      >
                        <p className="text grey-white-color-white">Filter</p>
                        <div className="line-box">
                          <span className=""></span>
                          <span></span>
                          <span></span>
                        </div>
                      </div>
                      {/* filter box end */}
                      {/* drop box start */}
                      <div className="drop-filter-wrap"></div>
                      {/* drop box end */}
                    </div>
                    {/* filter-drop box end */}

                    {/* badge filter options start */}
                    {selectOptionVal?.one.value ||
                    selectOptionVal?.two.value ||
                    selectOptionVal?.three.value ? (
                      <div className="badge-filter-option-box">
                        {/* box start */}
                        {selectOptionVal?.one.label && (
                          <div className="option-box grey-bg">
                            <span>{selectOptionVal?.one?.label || "--"}</span>{" "}
                            <FaTimes
                              onClick={() => {
                                setSelectOptionVal((prev) => {
                                  return {
                                    ...prev,
                                    one: "",
                                  };
                                });
                              }}
                              className="icon grey-white-color-white"
                            />{" "}
                          </div>
                        )}
                        {selectOptionVal?.two.label && (
                          <div className="option-box grey-bg">
                            <span>{selectOptionVal?.two?.label || "--"}</span>{" "}
                            <FaTimes
                              onClick={() => {
                                setSelectOptionVal((prev) => {
                                  return {
                                    ...prev,
                                    two: "",
                                  };
                                });
                              }}
                              className="icon grey-white-color-white"
                            />{" "}
                          </div>
                        )}
                        {selectOptionVal?.three.value && (
                          <div className="option-box grey-bg">
                            <span>{selectOptionVal?.three?.label || "--"}</span>{" "}
                            <FaTimes
                              onClick={() => {
                                setSelectOptionVal((prev) => {
                                  return {
                                    ...prev,
                                    three: "",
                                  };
                                });
                              }}
                              className="icon grey-white-color-white"
                            />{" "}
                          </div>
                        )}
                        {/* box end */}
                      </div>
                    ) : (
                      ""
                    )}
                    {/* badge filter options end */}
                    {/* export table content start */}
                    <CSVLink
                      // ref={download}
                      className="export-table-content-box tooltip-hover-wrap grey-bg"
                      filename={"Raven Business Banking Transactions.csv"}
                      data={
                        formatExportData(
                          transactions_two?.transactions?.data
                        ) || ""
                      }
                      headers={CsvHeader}
                    >
                      {" "}
                      <RavenToolTip
                        color="black-light"
                        text="Export Table Content"
                        position={`right`}
                      />
                      <figure className="img-box">{exportTableIcon}</figure>
                    </CSVLink>
                    {/* export table content endd */}
                  </div>
                  <div
                    onClick={() => {
                      setShowFilterOption(false);
                    }}
                    className="chart-hide-show-box"
                  >
                    <div className="box hide-show-box">
                      <p className="text grey-white-color">
                        {actionCtx?.showChart ? "Hide Stats" : "Show Stats"}
                      </p>
                      <RavenToggleSlide
                        color={`black-light`}
                        checked={actionCtx?.showChart}
                        onChange={() => {
                          actionCtx?.setShowChart(!actionCtx?.showChart);
                        }}
                      />
                    </div>
                  </div>
                </div>
                {/* title text box end */}
                {/* transaction card bar chart start */}
                <TransactionCardBarChart
                  fetchTransaction={handleFetchTransactionByREcSend}
                />
                {/* transaction card bar chart end */}
                {/* underline start */}
                {/* <div className="underline"></div> */}
                {/* underline end */}
                {/* table start */}
                {transactions_two?.transactions?.data?.length > 0 ? (
                  <div
                    className="table-wrap table-transaction-cover"
                    style={{ height: "100%" }}
                    onClick={() => {
                      showFilterOption && setShowFilterOption(false);
                    }}
                  >
                    <div className="wrap-scroll">
                      <RavenTable
                        className={`table-fixed-type ${
                          formatMetaData(user?.theme_display_settings)
                            ?.table_font === "bold" && "table-business"
                        }`}
                        headerList={headerList}
                        action
                      >
                        {transactions_two?.transactions?.data?.map(
                          (chi, idx) => {
                            const {
                              amount,
                              direction,
                              status,
                              type,
                              // created_at,
                              trx_name,
                              meta_data,
                              bulk_transfer_reference,
                            } = chi;
                            return (
                              <RavenTableRow
                                onEdit={() => {
                                  setShowModal(true);
                                }}
                                key={idx}
                                one={rowTypeText(
                                  direction,
                                  lowerCaseWrap(
                                    formatTypeFunction(type) ===
                                      "Money received"
                                      ? formatMetaData(meta_data)?.last_name
                                        ? `${
                                            formatMetaData(meta_data)
                                              ?.first_name
                                          } ${
                                            formatMetaData(meta_data)?.last_name
                                          }`
                                        : lowerCaseWrap(
                                            formatMetaData(
                                              meta_data
                                            )?.narration?.split("/")[0]
                                          )?.replace("Sent -from- Raven", "")
                                      : bulk_transfer_reference
                                      ? `Bulk ${formatTypeFunction(type) || ""}`
                                      : formatTypeFunction(type) ===
                                        "Money sent"
                                      ? lowerCaseWrap(
                                          formatMetaData(
                                            meta_data
                                          )?.account_name?.replace(
                                            "Raven/Raven - ",
                                            ""
                                          )
                                        )
                                      : formatTypeFunction(type) ===
                                        "Bet account recharge"
                                      ? `Bet Recharge`
                                      : formatTypeFunction(type) ===
                                        "Cable recharge"
                                      ? lowerCaseWrap(
                                          formatMetaData(
                                            meta_data
                                          )?.code?.replace("-", " ")
                                        )
                                      : formatTypeFunction(type) ===
                                        "Electricity payment"
                                      ? `${
                                          formatMetaData(meta_data)?.provider
                                        } ${lowerCaseWrap(
                                          formatMetaData(meta_data)?.meter_type
                                        )}`
                                      : formatTypeFunction(type) ===
                                        "Airtime recharge"
                                      ? `${
                                          formatMetaData(meta_data)?.network ||
                                          ""
                                        } ${formatTypeFunction(type)}`
                                      : type === "invoice-payment"
                                      ? lowerCaseWrap(
                                          formatMetaData(
                                            meta_data
                                          )?.narration?.replace(
                                            "payment for",
                                            ""
                                          )
                                        )
                                      : type === "payment-link"
                                      ? `${
                                          formatMetaData(meta_data)?.title ||
                                          "Payment Link"
                                        }`
                                      : type === "payroll_charge"
                                      ? formatTypeFunction(type)
                                      : `${
                                          isJsonString(trx_name)
                                            ? formatMetaData(trx_name)
                                                ?.top_narration
                                            : formatMetaData(
                                                meta_data
                                              )?.account_name?.split("-")[0] ||
                                              formatMetaData(meta_data)
                                                ?.customer_name ||
                                              formatMetaData(meta_data)
                                                ?.meter_no ||
                                              formatMetaData(meta_data)
                                                ?.phone_number ||
                                              formatTypeFunction(type)
                                        }`
                                  ),
                                  formatTypeFunction(type) === "Money sent" &&
                                    formatMetaData(meta_data)?.identifier
                                    ? `${`Raven Bank`} • @${`${
                                        formatMetaData(meta_data)?.identifier
                                      }`}`
                                    : bulk_transfer_reference
                                    ? `${
                                        formatMetaData(meta_data)?.summary
                                          ?.total_beneficiaries || ""
                                      } ${
                                        Number(
                                          formatMetaData(meta_data)?.summary
                                            ?.total_beneficiaries
                                        ) > 1
                                          ? "Beneficiaries"
                                          : "Beneficiary"
                                      }`
                                    : formatTypeFunction(type) ===
                                        "Money received" ||
                                      formatTypeFunction(type) === "Money sent"
                                    ? `${
                                        formatMetaData(meta_data)
                                          ?.account_number || "---"
                                      } • ${
                                        trimLongString(
                                          formatMetaData(meta_data)?.bank,
                                          25
                                        ) ||
                                        "Transfer within sub-accounts" ||
                                        `---`
                                      }`
                                    : formatTypeFunction(type) ===
                                        "Transaction charge" && !meta_data
                                    ? `${trimLongString(
                                        trx_name.split(
                                          "Transaction Charge —"
                                        )[1],
                                        40
                                      )}`
                                    : chi?.type === "swap" &&
                                      direction === "debit"
                                    ? `Swap from ${
                                        formatMetaData(chi?.meta_data)?.from
                                      } to ${
                                        formatMetaData(chi?.meta_data)?.to
                                      }`
                                    : chi?.type === "swap" &&
                                      direction === "credit"
                                    ? `Swap to ${
                                        formatMetaData(chi?.meta_data)?.to
                                      } from ${
                                        formatMetaData(chi?.meta_data)?.from
                                      }`
                                    : formatTypeFunction(type) ===
                                      "Cable recharge"
                                    ? `${
                                        formatMetaData(meta_data)
                                          ?.smart_card_number
                                      } • ${trimLongString(
                                        formatMetaData(meta_data)
                                          ?.customer_name,
                                        20
                                      )}`
                                    : formatTypeFunction(type) ===
                                      "Electricity payment"
                                    ? `${
                                        formatMetaData(meta_data)?.meter_no
                                      } • ${trimLongString(
                                        formatMetaData(meta_data)
                                          ?.customer_name,
                                        20
                                      )}`
                                    : formatTypeFunction(type) ===
                                      "Airtime recharge"
                                    ? formatMetaData(meta_data)?.phone_number
                                    : formatTypeFunction(type) ===
                                      "Bet Account recharge"
                                    ? `${formatMetaData(meta_data)?.name}`
                                    : type === "invoice-payment"
                                    ? `Payment from ${
                                        formatMetaData(meta_data)
                                          ?.customer_email
                                      }`
                                    : type === "payment-link"
                                    ? formatMetaData(meta_data)?.narration
                                    : type === "payroll_charge"
                                    ? formatMetaData(meta_data)?.narration
                                    : `${
                                        isJsonString(trx_name)
                                          ? formatMetaData(trx_name)
                                              ?.bottom_narration
                                          : formatMetaData(meta_data)?.bank ||
                                            formatMetaData(meta_data)
                                              ?.network ||
                                            formatMetaData(meta_data)
                                              ?.provider ||
                                            ""
                                      }`
                                )}
                                onRowClick={() => {
                                  if (bulk_transfer_reference) {
                                    localStorage.setItem(
                                      "bulk-ref-single-id",
                                      bulk_transfer_reference?.replace(
                                        "FEE:",
                                        ""
                                      )
                                    );
                                    navigate(
                                      "/dashboard-transaction-single-bulk"
                                    );
                                  } else {
                                    showFilterOption
                                      ? setShowFilterOption(false)
                                      : setShowModal((prev) => {
                                          return { ...prev, view: true };
                                        });
                                    setSingleObj(chi);
                                    // console.log(formatMetaData(meta_data));
                                  }
                                }}
                                two={RavenNumberFormat(amount, {
                                  symbol: chi?.currency,
                                })}
                                three={
                                  bulk_transfer_reference
                                    ? `Bulk ${formatTypeFunction(type) || ""}`
                                    : type === "invoice-payment"
                                    ? "Invoice Payment"
                                    : type === "payment-link"
                                    ? "Payment Link"
                                    : formatTypeFunction(type)
                                }
                                // four={formatDate(created_at)}
                                four={
                                  // <DateFragmentBox
                                  //   date={chi?.created_at}
                                  //   // dontShowTime={true}
                                  // />
                                  <RavenDateTime
                                    date={chi?.created_at}
                                    // dontShowAgo={true}
                                  />
                                }
                                five={formatTypeWrap(status || "Not set")}
                                ManualAddActions={() => transactionActions(chi)}
                                loading={loading}
                              />
                            );
                          }
                        )}
                      </RavenTable>
                    </div>

                    <div
                      onClick={() => {
                        showFilterOption && setShowFilterOption(false);
                        // console.log(transactions_two?.transactions?.data);
                      }}
                      className="mobile-table-box table-transaction-cover"
                    >
                      {transactions_two?.transactions?.data?.map((chi, idx) => {
                        const {
                          amount,
                          direction,
                          type,
                          created_at,
                          meta_data,
                          bulk_transfer_reference,
                          trx_name,
                        } = chi;
                        return (
                          <MobileTableCard
                            key={idx}
                            amount={`${
                              direction === "debit" ? "-" : "+"
                            }${formatNumWithCommaNairaSymbol(amount)}`}
                            text={
                              formatTypeFunction(type) === "Money sent" &&
                              formatMetaData(meta_data)?.identifier
                                ? `${`Raven Bank`} • @${`${
                                    formatMetaData(meta_data)?.identifier || ""
                                  }`}`
                                : bulk_transfer_reference
                                ? `${
                                    formatMetaData(meta_data)?.summary
                                      ?.total_beneficiaries || ""
                                  } ${
                                    Number(
                                      formatMetaData(meta_data)?.summary
                                        ?.total_beneficiaries
                                    ) > 1
                                      ? "Beneficiaries"
                                      : "Beneficiary"
                                  }`
                                : formatTypeFunction(type) ===
                                    "Money received" ||
                                  formatTypeFunction(type) === "Money sent"
                                ? `${
                                    formatMetaData(meta_data)?.account_number ||
                                    "---"
                                  } • ${
                                    trimLongString(
                                      formatMetaData(meta_data)?.bank,
                                      25
                                    ) || `---`
                                  }`
                                : formatTypeFunction(type) ===
                                    "Transaction charge" && !meta_data
                                ? `${trimLongString(
                                    trx_name.split("Transaction charge —")[1],
                                    40
                                  )}`
                                : formatTypeFunction(type) === "Cable recharge"
                                ? `${
                                    formatMetaData(meta_data)?.smart_card_number
                                  } • ${
                                    trimLongString(
                                      formatMetaData(meta_data)?.customer_name,
                                      20
                                    ) || ""
                                  }`
                                : formatTypeFunction(type) ===
                                  "Electricity payment"
                                ? `${formatMetaData(meta_data)?.meter_no} • ${
                                    trimLongString(
                                      formatMetaData(meta_data)?.customer_name,
                                      20
                                    ) || ""
                                  }`
                                : formatTypeFunction(type) ===
                                  "Airtime recharge"
                                ? formatMetaData(meta_data)?.phone_number || ""
                                : formatTypeFunction(type) ===
                                  "Bet account recharge"
                                ? `${formatMetaData(meta_data)?.name || ""}`
                                : type === "invoice-payment"
                                ? `Payment from ${
                                    formatMetaData(meta_data)?.customer_email
                                  }`
                                : type === "payment-link"
                                ? formatMetaData(meta_data)?.narration
                                : type === "payroll_charge"
                                ? formatMetaData(meta_data)?.narration
                                : `${
                                    isJsonString(trx_name)
                                      ? formatMetaData(trx_name)
                                          ?.bottom_narration || ""
                                      : formatMetaData(meta_data)?.bank ||
                                        formatMetaData(meta_data)?.network ||
                                        formatMetaData(meta_data)?.provider ||
                                        ""
                                  }`
                            }
                            textTwo={`${
                              formatDate(created_at).split("—")[0] || ""
                            }`}
                            onRowClick={() => {
                              if (bulk_transfer_reference) {
                                localStorage.setItem(
                                  "bulk-ref-single-id",
                                  bulk_transfer_reference?.replace("FEE:", "")
                                );
                                navigate("/dashboard-transaction-single-bulk");
                              } else {
                                showFilterOption
                                  ? setShowFilterOption(false)
                                  : setShowModal((prev) => {
                                      return { ...prev, view: true };
                                    });
                                setSingleObj(chi);
                                // console.log(formatMetaData(meta_data));
                              }
                            }}
                            loading={loading}
                            title={lowerCaseWrap(
                              formatTypeFunction(type) === "Money Received"
                                ? formatMetaData(meta_data)?.last_name
                                  ? `${formatMetaData(meta_data)?.first_name} ${
                                      formatMetaData(meta_data)?.last_name
                                    }`
                                  : lowerCaseWrap(
                                      formatMetaData(
                                        meta_data
                                      )?.narration?.split("/")[0]
                                    )
                                : bulk_transfer_reference
                                ? `Bulk ${formatTypeFunction(type) || ""}`
                                : formatTypeFunction(type) === "Money Sent"
                                ? lowerCaseWrap(
                                    formatMetaData(
                                      meta_data
                                    )?.account_name?.replace(
                                      "Raven/Raven - ",
                                      ""
                                    )
                                  )
                                : formatTypeFunction(type) ===
                                  "Bet Account Recharge"
                                ? `Bet Recharge`
                                : formatTypeFunction(type) === "Cable Recharge"
                                ? lowerCaseWrap(
                                    formatMetaData(meta_data)?.code?.replace(
                                      "-",
                                      " "
                                    )
                                  )
                                : formatTypeFunction(type) ===
                                  "Electricity Payment"
                                ? `${
                                    formatMetaData(meta_data)?.provider
                                  } ${lowerCaseWrap(
                                    formatMetaData(meta_data)?.meter_type
                                  )}`
                                : formatTypeFunction(type) ===
                                  "Airtime Recharge"
                                ? `${
                                    formatMetaData(meta_data)?.network || ""
                                  } ${formatTypeFunction(type)}`
                                : type === "invoice-payment"
                                ? lowerCaseWrap(
                                    formatMetaData(
                                      meta_data
                                    )?.narration?.replace("payment for", "")
                                  )
                                : type === "payment-link"
                                ? `${
                                    formatMetaData(meta_data)?.title ||
                                    "Payment Link"
                                  }`
                                : type === "payroll_charge"
                                ? formatTypeFunction(type)
                                : `${
                                    isJsonString(trx_name)
                                      ? formatMetaData(trx_name)?.top_narration
                                      : formatMetaData(
                                          meta_data
                                        )?.account_name?.split("-")[0] ||
                                        formatMetaData(meta_data)
                                          ?.customer_name ||
                                        formatMetaData(meta_data)?.meter_no ||
                                        formatMetaData(meta_data)
                                          ?.phone_number ||
                                        formatTypeFunction(type)
                                  }`
                            )}
                            showImg
                            type={direction}
                            amountColor={
                              direction === "debit" ? `#FF0F00` : "#1ACE37"
                            }
                          />
                        );
                      })}
                    </div>

                    {/* pagination start */}
                    <div className="table-pagination-box ">
                      {actionCtx?.showChart && (
                        <RavenPagination
                          numSpacing={size.width < 900 && 1}
                          removeNext={size.width < 900}
                          removePrev={size.width < 900}
                          color={`black-light`}
                          blackHover
                          currentPage={
                            Number(
                              transactions_two?.transactions?.current_page
                            ) || 1
                          }
                          nextPage={
                            transactions_two?.transactions?.next_page_url_params
                          }
                          prevPage={
                            transactions_two?.transactions?.prev_page_url_params
                          }
                          onNext={(e) => {
                            handleFetTransactionPagination(e);
                          }}
                          onPrev={(e) => {
                            handleFetTransactionPagination(e);
                          }}
                          totalPage={
                            getTotalPage(
                              transactions_two?.transactions?.per_page,
                              transactions_two?.transactions?.total
                            ) || 1
                          }
                          onNumView={(e) => {
                            handleFetTransactionPagination("", e);
                          }}
                        />
                      )}
                    </div>
                    {/* pagination end */}
                  </div>
                ) : (
                  <NoContentBox
                    title={`No transaction found`}
                    text={`Transaction history will appear here when transaction is made`}
                    bgColor
                    loading={loading}
                  />
                )}
                {/* table end */}
              </div>
            </ContainerWrapper>
            {/* cointainer wrap end */}
          </>
        ) : (
          <NocontentPage
            loading={loading}
            img={noContentImg}
            type="two"
            title={`No Transactions yet`}
            normtext={`You haven’t made any transaction yet, make your first Transfer, and we would help you keep tab of all your transactions here.`}
          />
        )}
      </DashboardLayout>
      <DownloadReceiptModal
        visible={showModal?.view}
        onClose={() =>
          setShowModal((prev) => {
            return { ...prev, view: false };
          })
        }
        detail={singleObj}
      />
      <GenerateStatementModal
        visible={showModal?.statement}
        onCancel={() =>
          setShowModal((prev) => {
            return { ...prev, statement: false };
          })
        }
      />
      {/* specific date start  */}
      <SpecificDateSelectModal
        visible={showModal?.selectSpecificDate}
        onFinish={(e) => {
          // console.log(e);
          setSelectOptionVal((prev) => {
            return {
              ...prev,
              one: {
                label: formatDate(e)?.split("—")[0],
                value: e,
                name: "Specific Date",
              },
            };
          });
          setShowModal((prev) => {
            return { ...prev, selectSpecificDate: false };
          });
        }}
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, selectSpecificDate: false };
          });
        }}
      />
      {/* specific date end */}
      {/* Custom date start */}
      <CustomDateSelectModal
        visible={showModal?.customDate}
        onFinish={(e) => {
          // formatFilterDate(e);
          setSelectOptionVal((prev) => {
            return {
              ...prev,
              one: {
                label: formatFilterDate(e),
                value: e,
                name: "Custom Date",
              },
            };
          });
          // setFilterVal({
          //   label: formatFilterDate(e),
          //   value: e,
          //   name: "Custom Date",
          // });
          setShowModal((prev) => {
            return { ...prev, customDate: false };
          });
        }}
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, customDate: false };
          });
        }}
      />
      {/* custom date end */}
    </>
  );
};

export default DashboardTransaction;
