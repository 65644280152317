import React, { useState } from "react";
import "../../styles/payment-web-pay/RefereeInvitePublic.css";
import RefereeLayout from "./RefereeLayout";
import InstructionContent from "./InstructionContent";
import RefereeInviteForm from "./RefereeInviteForm";
import SuccessOrFailedContent from "./SuccessOrFailedContent";
import { useEffect } from "react";
// import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getInvitedRefereeByRefId } from "../../redux/verifications";
import { fetchBanks } from "../../redux/transaction";
import { useSelector } from "react-redux";

const RefereeInvitePublic = () => {
  const [step, setStep] = useState(1);
  const dispatch = useDispatch();
  const { bankList } = useSelector((state) => state.transaction);

  useEffect(() => {
    const urlRef = window?.location?.href?.split("?invite-ref=")[1];
    getRefInfo(urlRef);
    if (urlRef) {
    }
    if (bankList?.length < 1) {
      dispatch(fetchBanks());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getRefInfo = async (param) => {
    const obj = {
      ref: param,
    };
    const data = await dispatch(getInvitedRefereeByRefId(obj));
    // console.log(data);

    if (data?.payload?.status === "success") {
      const refObj = data?.payload?.data?.referee;
      // console.log(refObj);
      if (String(refObj?.status) === "3") {
        setStep(4);
      }
      if (String(refObj?.status) === "2") {
        setStep(5);
      }
    } else {
      setStep(5);
    }
    // console.log(data);
  };

  return (
    <RefereeLayout>
      {/* step one start -------------------- */}
      {step === 1 && (
        <>
          <InstructionContent setStep={setStep} />
        </>
      )}
      {/* step one end ----------------------- */}
      {/* step two and three start --------------- */}
      {step === 2 || step === 3 ? (
        <RefereeInviteForm step={step} setStep={setStep} />
      ) : (
        ""
      )}
      {/* step two and three end --------------- */}
      {/* step four start --------------- */}
      {step === 4 && (
        <>
          <SuccessOrFailedContent
            text={`Thank you for your cooperation. Your details will be verified, and we will use them for the required recipient information. We appreciate your time and effort in this process.`}
            title={`Thank you for your submission`}
            type={`success`}
          />
        </>
      )}
      {/* step four end ---------------- */}
      {/* step five start --------------- */}
      {step === 5 && (
        <>
          <SuccessOrFailedContent
            text={`You seem lost, please reach out to the person who sent you the link to get a new link or a correct link.`}
            title={`Link Broken...`}
            type={`failed`}
          />
        </>
      )}
      {/* step five end ---------------- */}
    </RefereeLayout>
  );
};

export default RefereeInvitePublic;
