/* eslint-disable jsx-a11y/alt-text */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../utils/axios";
import { toast } from "@ravenpay/raven-bank-ui";

export const quickTransfer = createAsyncThunk(
  "transfer/initiate_bank_transfer",
  async (payload, thunkAPI) => {
    const obj = {
      payload,
      type: "formData",
    };
    try {
      const data = await axios.post("transfer/initiate_bank_transfer", obj);
      // console.log(data, 'this guy');
      // if (data?.response?.data?.status === "fail") {
      //   toast.error(` ${data?.response?.data?.message}`, {});
      // }
      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
        return;
      }

      if (data?.data?.status === "fail") {
        toast.error(data.data.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        // console.log("data status)");
        // console.log(data);
        toast.success(data.data.message, {});
        // await thunkAPI.dispatch(login(data));
        return data;
      }
    } catch (err) {
      // console.log(err);
      if (err.response.data.status === "fail") {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const bulkTransfer = createAsyncThunk(
  "transfer/initiate_bulk_bank_transfer",
  async (payload, thunkAPI) => {
    const obj = {
      payload,
      type: "formData",
    };
    try {
      const data = await axios.post(
        "transfer/initiate_bulk_bank_transfer",
        obj
      );
      if (data.message === "Network Error") {
        toast.error(data?.message, {});
      }
      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
        return;
      }

      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message, {});
      }
      if (data?.data?.status === "success") {
        toast.success(data.data.message, {
          theme: "colored",
        });
        // await thunkAPI.dispatch(login(data));
        return data;
      }
    } catch (err) {
      // console.log(err);
      if (err.response.data.status === "fail") {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const downloadTransactionReciept = createAsyncThunk(
  "transactions/download-transaction-receipt-la",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post(
        "transactions/download-transaction-receipt",
        payload
      );
      if (data.message === "Network Error") {
        toast.error(data?.message, {});
      }

      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
        return;
      }
      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message, {});
      }
      if (data?.data?.status === "success") {
        toast.success(data.data.message, {
          theme: "colored",
        });
        // await thunkAPI.dispatch(login(data));
        return data;
      }
    } catch (err) {
      // console.log(err);
      if (err.response.data.status === "fail") {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const bulkTransferManual = createAsyncThunk(
  "transfer/initiate_bulk_bank_transfer-manual",
  async (payload, thunkAPI) => {
    const obj = {
      payload,
      type: "formData",
    };
    try {
      const data = await axios.post(
        "/transfer/initiate_manual_bulk_bank_transfer",
        obj
      );
      if (data.message === "Network Error") {
        toast.error(data?.message, {});
      }

      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
      }
      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message, {});
      }
      if (data?.data?.status === "success") {
        toast.success(data.data.message, {
          theme: "colored",
        });
        return data;
      }
    } catch (err) {
      // console.log(err);
      if (err.response.data.status === "fail") {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const singleTransferManualRaven = createAsyncThunk(
  "transfer/initiate_single_bank_transfer-manual-raven",
  async (payload, thunkAPI) => {
    const obj = {
      payload,
      type: "formData",
    };
    // console.log(obj);
    
    try {
      const data = await axios.post("/internal_transfers/single_transfer", obj);
      if (data.message === "Network Error") {
        toast.error(data?.message, {});
      }

      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
        return;
      }
      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message, {});
      }
      if (data?.data?.status === "success") {
        toast.success(data.data.message, {
          theme: "colored",
        });
        return data;
      }
    } catch (err) {
      // console.log(err);
      if (err.response.data.status === "fail") {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const bulkTransferManualRaven = createAsyncThunk(
  "transfer/initiate_bulk_bank_transfer-manual-raven",
  async (payload, thunkAPI) => {
    const obj = {
      payload,
      type: "formData",
    };
    try {
      const data = await axios.post(
        "/internal_transfers/manual_bulk_transfer",
        obj
      );
      if (data.message === "Network Error") {
        toast.error(data?.message, {});
      }
      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
        return;
      }
      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message, {});
      }
      if (data?.data?.status === "success") {
        toast.success(data.data.message, {
          theme: "colored",
        });
        return data;
      }
    } catch (err) {
      // console.log(err);
      if (err.response.data.status === "fail") {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const withinSubAccountTransfer = createAsyncThunk(
  "transfer/within-account-sub-account",
  async (payload, thunkAPI) => {
    const obj = {
      payload,
      type: "formData",
    };
    try {
      const data = await axios.post(
        "/transfer/initiate_sub_account_transfer",
        obj
      );
      if (data.message === "Network Error") {
        toast.error(data?.message, {});
      }
      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
        return;
      }
      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message, {});
      }
      if (data?.data?.status === "success") {
        toast.success(data.data.message, {
          theme: "colored",
        });
        return data;
      }
    } catch (err) {
      // console.log(err);
      if (err.response.data.status === "fail") {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const validateBulkTransferData = createAsyncThunk(
  "transfer/validate-bulk-transfer-data",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post(
        "/transfer/validate_bulk_transfer_datas",
        payload
      );
      if (data.message === "Network Error") {
        toast.error(data?.message, {});
      }
      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
        return;
      }
      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message, {});
      }
      if (data?.data?.status === "success") {
        toast.success(data.data.message, {
          theme: "colored",
        });
        return data;
      }
    } catch (err) {
      // console.log(err);
      if (err.response.data.status === "fail") {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const getAllSaveRecordsRaven = createAsyncThunk(
  "transfer/fetch_raven_all_records",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.get(
        "/internal_transfers/get_raven_bank_previous_bulk_transfer_data_records"
      );
      // console.log("bank lister", data);
      if (data.status !== "success") {
        // console.log("bank lister", data);
        // await thunkAPI.dispatch(setBankList(data.data));
        // return thunkAPI.rejectWithValue(data.data);
      }
      if (data?.data?.status === "success") {
        // toast.success(data.data.message, {
        //   theme: "colored",setRavenBulkRecord
        // });
        // console.log(data.data.data.records);
        await thunkAPI.dispatch(setRavenBulkRecord(data.data.data.records));
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const turnOffBulkValidationProcess = createAsyncThunk(
  "transfer/bulk-turn-off-validation",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.get(
        `/transfer/update_verification_data_send_status?custom_unique_key=${payload?.key}`
      );
      // console.log("bank lister", data);
      if (data.status !== "success") {
        // console.log("bank lister", data);
        // await thunkAPI.dispatch(setBankList(data.data));
        // return thunkAPI.rejectWithValue(data.data);
      }
      if (data?.data?.status === "success") {
        // toast.success(data.data.message, {
        //   theme: "colored",setRavenBulkRecord
        // });
        // console.log(data.data.data.records);
        // await thunkAPI.dispatch(setRavenBulkRecord(data.data.data.records));
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const requestBulkValidationProcess = createAsyncThunk(
  "transfer/bulk-request-validation",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.get(
        `/transfer/get_verified_data?custom_unique_key=${payload?.key}`
      );
      // console.log("bank lister", data);
      if (data.status !== "success") {
        // console.log("bank lister", data);
        // await thunkAPI.dispatch(setBankList(data.data));
        // return thunkAPI.rejectWithValue(data.data);
      }
      if (data?.data?.status === "success") {
        // toast.success(data.data.message, {
        //   theme: "colored",setRavenBulkRecord
        // });
        // console.log(data.data.data.records);
        // await thunkAPI.dispatch(setRavenBulkRecord(data.data.data.records));
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const getAllSaveRecordsBank = createAsyncThunk(
  "transfer/fetch_bank_all_records",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.get(
        "/bulk_transfers/get_other_banks_previous_bulk_transfer_data_records"
      );
      // console.log("bank lister", data);
      if (data.status !== "success") {
        // console.log("bank lister", data);
        // await thunkAPI.dispatch(setBankList(data.data));
        // return thunkAPI.rejectWithValue(data.data);
      }
      if (data?.data?.status === "success") {
        // toast.success(data.data.message, {
        //   theme: "colored",setRavenBulkRecord
        // });
        // console.log(data.data.data.records);
        await thunkAPI.dispatch(setBankBulkRecord(data.data.data.records));
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const fetchBanks = createAsyncThunk(
  "transfer/fetch_bank",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.get("transfer/fetch_bank");
      // console.log("bank lister", data);
      if (data.status === "success") {
        // console.log("bank lister", data);
        await thunkAPI.dispatch(setBankList(data.data));
        return thunkAPI.rejectWithValue(data.data);
      }
      // return data;
      // if (data.status !== 'success') {
      //     toast.error(data.data.message, {
      //       theme: "colored",
      //     });
      //   // await thunkAPI.dispatch(login(data));
      //     return data;
      // }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const validateBankAccount = createAsyncThunk(
  "transfer/validate_bank_account",
  async (payload, thunkAPI) => {
    // console.log("hia", payload);
    try {
      const data = await axios.post("transfer/validate_bank_account", payload);
      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
        return data;
      }

      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message, {});
        return data;
      }
      if (data.data.status === "success") {
        //   toast.success(data.data.message, {
        //     theme: "colored",
        //   });
        // await thunkAPI.dispatch(login(data));
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const validateRavenUsername = createAsyncThunk(
  "transfer/validate_raven_username",
  async (payload, thunkAPI) => {
    // console.log("hia", payload);
    try {
      const data = await axios.post(
        "transfer/validate_raven_username",
        payload
      );
      // console.log("register", data);

      if (data.status !== "success") {
        toast.error(data.message, {});
        return data;
      }
      if (data.status === "success") {
        //   toast.success(data.data.message, {
        //     theme: "colored",
        //   });
        // await thunkAPI.dispatch(login(data));
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const getTransactions = createAsyncThunk(
  "get-transactions",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `transactions?per_page=${payload.per_page}&current_page=${payload.current_page}`,
        payload
      );
      // console.log("transactions fetch", data?.data?.all_transactions); transactions/filter_transactions?per_page=2&current_page=1&range=3
      // console.log(data);
      if (data.status !== "success") {
        // toast.error(data.message, {
        //   theme: 'colored'
        // });
        return data;
      }
      if (data.status === "success") {
        //   toast.success(data.data.message, {
        //     theme: "colored",
        //   });
        // console.log(data);
        await thunkAPI.dispatch(setTransactions(data?.data?.all_transactions));
        return data;
      }
    } catch (err) {
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const getBulkTransactions = createAsyncThunk(
  "get-bulk-transactions",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `bulk_transfer/fetch?per_page=${payload.per_page}&current_page=${payload.current_page}`,
        payload
      );
      // console.log("transactions fetch", data?.data?.all_transactions); transactions/filter_transactions?per_page=2&current_page=1&range=3
      // console.log(data);
      if (data.status !== "success") {
        // toast.error(data.message, {
        //   theme: 'colored'
        // });
        return data;
      }
      if (data.status === "success") {
        //   toast.success(data.data.message, {
        //     theme: "colored",
        //   });
        // console.log(data.data.transfers);
        await thunkAPI.dispatch(setBulkTransactions(data.data.transfers));
        return data;
      }
    } catch (err) {
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const getSingleBulkTransactions = createAsyncThunk(
  "get-transactions-single",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/bulk_transaction/fetch/${payload?.ref || ""}?per_page=${
          payload.per_page
        }&current_page=${payload.current_page}`,
        payload
      );

      if (data.status !== "success") {
        // toast.error(data.message, {
        //   theme: 'colored'
        // });
        return data;
      }
      if (data.status === "success") {
        //   toast.success(data.data.message, {
        //     theme: "colored",
        //   });
        // console.log(data.data.transfers);
        await thunkAPI.dispatch(setSingleBulkTransactions(data.data));
        return data;
      }
    } catch (err) {
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const getTransactionsChart = createAsyncThunk(
  "get-transactions-chart",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/transactions/get_chat_data?date=${payload?.date || "all"}`,
        payload
      );
      // console.log("transactions fetch", data?.data?.all_transactions); transactions/filter_transactions?per_page=2&current_page=1&range=3
      // console.log(data?.data);
      if (data.status === "fail") {
        // toast.error(data.message, {
        //   theme: 'colored'
        // });
        return data;
      }
      if (data.status === "success") {
        //   toast.success(data.data.message, {
        //     theme: "colored",
        //   });
        // console.log(data);
        await thunkAPI.dispatch(setTransactionsChart(data?.data));
        return data;
      }
    } catch (err) {
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const getTransactionsTwo = createAsyncThunk(
  "get-transactions-two",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/transactions/filter_transactions?per_page=${
          payload?.per_page
        }&current_page=${payload?.current_page}&currency=NGN&transaction_type=${
          payload?.transaction_type || "all"
        }&date=${payload?.date || "all"}&search_term=${
          payload?.search_term || "none"
        }&bank_account_id=${payload?.bank_account_id || ""}`
      );
      // console.log(data, "-----------------------------------------");
      // console.log("transactions fetch", data?.data?.all_transactions); transactions/filter_transactions?per_page=2&current_page=1&range=3
      if (data.status !== "success") {
        // toast.error(data.message, {
        //   theme: 'colored'
        // });
        return data;
      }
      if (data.status === "success") {
        await thunkAPI.dispatch(setTransactionsTwo(data?.data));
        return data;
      }
    } catch (err) {
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const getTransactionsTwoSort = createAsyncThunk(
  "get-transactions-two-sort",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/transactions/filter_transactions?per_page=${
          payload?.per_page
        }&current_page=${payload?.current_page}&currency=NGN&transaction_type=${
          payload?.transaction_type || "all"
        }&date=${payload?.date || "all"}&search_term=${
          payload?.search_term || "none"
        }&bank_account_id=${payload?.bank_account_id || ""}`
      );
      // console.log(data);
      // console.log("transactions fetch", data?.data?.all_transactions); transactions/filter_transactions?per_page=2&current_page=1&range=3
      if (data.status !== "success") {
        // toast.error(data.message, {
        //   theme: 'colored'
        // });
        return data;
      }
      if (data.status === "success") {
        await thunkAPI.dispatch(setTransactionsTwoSort(data?.data));
        return data;
      }
    } catch (err) {
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const getTransactionsTwoPagination = createAsyncThunk(
  "get-transactions-two-pagination",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/transactions/filter_transactions${payload}`
      );
      // console.log(data);
      // console.log("transactions fetch", data?.data?.all_transactions); transactions/filter_transactions?per_page=2&current_page=1&range=3
      if (data.status !== "success") {
        // toast.error(data.message, {
        //   theme: 'colored'
        // });
        return data;
      }
      if (data.status === "success") {
        await thunkAPI.dispatch(setTransactionsTwo(data?.data));
        return data;
      }
    } catch (err) {
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const getTransactionsBySearch = createAsyncThunk(
  "get-transactions-search",
  async (payload, thunkAPI) => {
    try {
      const { search_term } = payload;
      const { data } = await axios.get(
        `transactions/search_through_transactions?search_term=${search_term}`,
        payload
      );
      // console.log("transactions fetch", data?.data?.all_transactions);
      if (data.status !== "success") {
        // toast.error(data.message, {
        //   theme: 'colored'
        // });
        return data;
      }
      if (data.status === "success") {
        //   toast.success(data.data.message, {
        //     theme: "colored",
        //   });
        // console.log(data);
        // await thunkAPI.dispatch(setTransactions(data?.data?.all_transactions));
        return data;
      }
    } catch (err) {
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const getTransactionFilter = createAsyncThunk(
  "admin/get-transaction-filter",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.get("", payload);
      // console.log("register", data);

      if (!data.success) {
        // toast.error(data.message, {
        //   theme: 'colored'
        // });
        // return data;
      }
      if (data.success) {
        //   toast.success(data.data.message, {
        //     theme: "colored",
        //   });
        // await thunkAPI.dispatch(login(data));
        //   return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {
          theme: "colored",
          position: "top-right",
        });
      }
      return err;
    }
  }
);

export const getTransaction = createAsyncThunk(
  "admin/get-transaction-single",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.get("", payload);
      //       // console.log("register", data);

      if (!data.success) {
        // toast.error(data.message, {
        //   theme: 'colored'
        // });
        // return data;
      }
      if (data.success) {
        //   toast.success(data.data.message, {
        //     theme: "colored",
        //   });
        // await thunkAPI.dispatch(login(data));
        //   return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const getExpenditures = createAsyncThunk(
  "transactions/expenditures",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.get("transactions/expenditures", payload);
      // console.log("expenditures", data.data.all_expenditure);
      if (data.status !== "success") {
        toast.error(data.message, {});
        return data;
      }
      if (data.status === "success") {
        //   toast.success(data.data.message, {
        //     theme: "colored",
        //   });
        await thunkAPI.dispatch(setExpenditures(data?.data));
        return data;
      }
    } catch (err) {
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {
          theme: "colored",
          position: "top-right",
        });
      }
      return err;
    }
  }
);

export const getIncome = createAsyncThunk(
  "transactions/incomes",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.get("transactions/incomes", payload);
      // console.log("incomes", data);
      if (data.status !== "success") {
        toast.error(data.message, {});
        return data;
      }
      if (data.status === "success") {
        //   toast.success(data.data.message, {
        //     theme: "colored",
        //   });
        await thunkAPI.dispatch(setIncome(data.data));
        return data;
      }
    } catch (err) {
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const getTransactionLimits = createAsyncThunk(
  "transactions/incomes",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.get("/transfer_limit_setup/fetch", payload);
      // console.log("incomes-----------------", data);
      if (data?.status !== "success") {
        toast.error(data.message, {});
        return data;
      }
      if (data?.status === "success") {
        //   toast.success(data.data.message, {
        //     theme: "colored",
        //   });
        await thunkAPI.dispatch(
          setTransactionLimit(data.data.transfer_config_details)
        );
        // console.log(data);
        return data;
      }
    } catch (err) {
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const getTransactionsPagination = createAsyncThunk(
  "admin/get-transactions-pagination",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.get("", payload);
      // console.log("register", data);

      if (!data.success) {
        // toast.error(data.message, {
        //   theme: 'colored'
        // });
        // return data;
      }
      if (data.success) {
        //   toast.success(data.data.message, {
        //     theme: "colored",
        //   });
        // await thunkAPI.dispatch(login(data));
        //   return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const generateTransactionStatement = createAsyncThunk(
  "admin/generate-transaction-statement",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.post("", payload);
      // console.log("register", data);

      if (!data.success) {
        // toast.error(data.message, {
        //   theme: 'colored'
        // });
        // return data;
      }
      if (data.success) {
        //   toast.success(data.data.message, {
        //     theme: "colored",
        //   });
        // await thunkAPI.dispatch(login(data));
        //   return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {
          theme: "colored",
          position: "top-right",
        });
      }
      return err;
    }
  }
);

export const transaction = createSlice({
  name: "transaction",
  initialState: {
    transactions: [],
    transactions_two: [],
    transactions_two_sort: [],
    bulk_transactions: [],
    single_bulk_transactions: [],
    transaction: {},
    ravenBulkRecord: [],
    bankBulkRecord: [],
    income: [],
    expenditures: [],
    bankList: [],
    transaction_limit: {},
    isAuth: false,
    loading: false,
    bulkTransferData: {},
    loadingVerify: false,
    loadingSearch: false,
    loadingDownload: false,
    transactionChart: {},
    // token: JSON.parse(localStorage.getItem('token')) ,
  },
  reducers: {
    setTransactions: (state, action) => {
      // localStorage.setItem("user", JSON.stringify(action.payload));
      state.isAuth = true;
      state.transactions = action.payload;
    },
    setRavenBulkRecord: (state, action) => {
      // localStorage.setItem("user", JSON.stringify(action.payload));
      state.isAuth = true;
      state.ravenBulkRecord = action.payload;
    },
    setBankBulkRecord: (state, action) => {
      // localStorage.setItem("user", JSON.stringify(action.payload));
      state.isAuth = true;
      state.bankBulkRecord = action.payload;
    },
    setBulkTransactions: (state, action) => {
      // localStorage.setItem("user", JSON.stringify(action.payload));
      state.isAuth = true;
      state.bulk_transactions = action.payload;
    },
    setSingleBulkTransactions: (state, action) => {
      // localStorage.setItem("user", JSON.stringify(action.payload));
      state.isAuth = true;
      state.single_bulk_transactions = action.payload;
    },
    setBulkTransferData: (state, action) => {
      // localStorage.setItem("user", JSON.stringify(action.payload));
      state.isAuth = true;
      state.bulkTransferData = action.payload;
    },
    setTransactionsChart: (state, action) => {
      // localStorage.setItem("user", JSON.stringify(action.payload));
      state.isAuth = true;
      state.transactionChart = action.payload;
    },
    setTransactionsTwo: (state, action) => {
      state.isAuth = true;
      state.transactions_two = action.payload;
    },
    setTransactionsTwoSort: (state, action) => {
      state.isAuth = true;
      state.transactions_two_sort = action.payload;
    },
    setBankList: (state, action) => {
      // localStorage.setItem("user", JSON.stringify(action.payload));
      state.bankList = action.payload;
      state.isAuth = true;
      // state.transactions = action.payload;
    },

    setIncome: (state, action) => {
      state.income = action.payload;
      state.isAuth = true;
      // state.transactions = action.payload;
    },
    setTransactionLimit: (state, action) => {
      state.transaction_limit = action.payload;
      state.isAuth = true;
      // state.transactions = action.payload;
    },
    setExpenditures: (state, action) => {
      state.expenditures = action.payload;
      state.isAuth = true;
      // state.transactions = action.payload;
    },
  },
  extraReducers: {
    [getTransactions.pending]: (state) => {
      state.loading = true;
    },
    [getTransactions.fulfilled]: (state) => {
      state.loading = false;
    },
    [getTransactions.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [bulkTransferManual.pending]: (state) => {
      state.loading = true;
    },
    [bulkTransferManual.fulfilled]: (state) => {
      state.loading = false;
    },
    [bulkTransferManual.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [getTransactionsBySearch.pending]: (state) => {
      state.loadingSearch = true;
    },
    [getTransactionsBySearch.fulfilled]: (state) => {
      state.loadingSearch = false;
    },
    [getTransactionsBySearch.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loadingSearch = false;
      state.isAuth = false;
      state = null;
    },

    [quickTransfer.pending]: (state) => {
      state.loading = true;
    },
    [quickTransfer.fulfilled]: (state) => {
      state.loading = false;
    },
    [quickTransfer.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [getBulkTransactions.pending]: (state) => {
      state.loading = true;
    },
    [getBulkTransactions.fulfilled]: (state) => {
      state.loading = false;
    },
    [getBulkTransactions.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },

    [validateBankAccount.pending]: (state) => {
      // state.loading = true;
      state.loadingVerify = true;
    },
    [validateBankAccount.fulfilled]: (state) => {
      // state.loading = false;
      state.loadingVerify = false;
    },
    [validateBankAccount.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loadingVerify = false;
      state.isAuth = false;
      // state.loadingVerify = false;
      state = null;
    },

    [validateRavenUsername.pending]: (state) => {
      state.loading = true;
      state.loadingVerify = true;
    },
    [validateRavenUsername.fulfilled]: (state) => {
      state.loading = false;
      state.loadingVerify = false;
    },
    [validateRavenUsername.rejected]: (state) => {
      state.loading = false;
      state.isAuth = false;
      state.loadingVerify = false;
      state = null;
    },
    [getTransactionsTwo.pending]: (state) => {
      state.loading = true;
    },
    [getTransactionsTwo.fulfilled]: (state) => {
      state.loading = false;
    },
    [getTransactionsTwo.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [bulkTransfer.pending]: (state) => {
      state.loading = true;
    },
    [bulkTransfer.fulfilled]: (state) => {
      state.loading = false;
    },
    [bulkTransfer.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [bulkTransferManualRaven.pending]: (state) => {
      state.loading = true;
    },
    [bulkTransferManualRaven.fulfilled]: (state) => {
      state.loading = false;
    },
    [bulkTransferManualRaven.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [withinSubAccountTransfer.pending]: (state) => {
      state.loading = true;
    },
    [withinSubAccountTransfer.fulfilled]: (state) => {
      state.loading = false;
    },
    [withinSubAccountTransfer.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [getSingleBulkTransactions.pending]: (state) => {
      state.loading = true;
    },
    [getSingleBulkTransactions.fulfilled]: (state) => {
      state.loading = false;
    },
    [getSingleBulkTransactions.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [singleTransferManualRaven.pending]: (state) => {
      state.loading = true;
    },
    [singleTransferManualRaven.fulfilled]: (state) => {
      state.loading = false;
    },
    [singleTransferManualRaven.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [getTransactionsTwoPagination.pending]: (state) => {
      state.loading = true;
    },
    [getTransactionsTwoPagination.fulfilled]: (state) => {
      state.loading = false;
    },
    [getTransactionsTwoPagination.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [downloadTransactionReciept.pending]: (state) => {
      state.loadingDownload = true;
      // state.loading = true;
    },
    [downloadTransactionReciept.fulfilled]: (state) => {
      state.loadingDownload = false;
    },
    [downloadTransactionReciept.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loadingDownload = false;
      state.isAuth = false;
      state = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  login,
  setTransactions,
  setBankList,
  setIncome,
  setBulkTransferData,
  setExpenditures,
  setTransactionsTwo,
  setTransactionsChart,
  setTransactionLimit,
  setTransactionsTwoSort,
  setBulkTransactions,
  setSingleBulkTransactions,
  setRavenBulkRecord,
  setBankBulkRecord,
} = transaction.actions;

export default transaction.reducer;
