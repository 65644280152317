// import { RavenButton, RavenInputField } from "@ravenpay/raven-bank-ui";
import React, { useContext, useEffect } from "react";
// import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import ProgressBar from "../../../../components/fragments/ProgreeBar";
import ActionContext from "../../../../context/ActionContext";
// import CryptoJS from "crypto-js";
import "../../../../styles/dashboard/verification/VerificationFiveModal.css";
// import { validateEmail } from "../../../utils/helper/ValidateInput";
import { useDarkMode } from "../../../../utils/themes/useDarkMode";
import { useLocation, useNavigate } from "react-router-dom";
import { RavenToolTip } from "@ravenpay/raven-bank-ui";
import NoContentBox from "../../../../components/reusables/NoContentBox";
// import { sumAllNum } from "../../../../utils/helper/Helper";

const ModalLayout = ({ stage, children , step}) => {
  const actionCtx = useContext(ActionContext);
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const [theme, toggleTheme] = useDarkMode(localStorage.getItem("theme"));
  // const [step,] = useState(1);
  // const dispatch = useDispatch();
  const location = useLocation();
  // const [popUp, setPopUp] = useState(false);
  const { business, loadUser } = useSelector((state) => state.user);
  const closeIcon = (
    <svg
      className="img"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="dull-bright-filter"
        d="M4.5 4.5L13.5 13.5M13.5 4.5L4.5 13.5"
        stroke="#020202"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const routeList = [
    {
      name: "BVN Details",
      link: "/dashboard-verification-bvn-new",
      // disable: false,
    },
    {
      name: "NIN Details",
      link: "/dashboard-verification-nin-new",
      disable: Number(business?.bvn) !== 3,
      disable_text: "Verify BVN",
    },
    // {
    //   name: "Address Verifications",
    //   link: "/dashboard-verification-address-new",
    //   disable: Number(business?.nin) !== 3,
    //   disable_text: "Verify NIN",
    // },
  ];

  useEffect(() => {
    setTimeout(() => {
      actionCtx?.setVerificationPopUp(true);
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //   const getPercentageFunc = () => {
  //      const total = sumAllNum([business?.bvn === 3 ? 30 : 0, ])
  //   }

  return (
    <div className="verification-five-modal-create-index-wrap">
      <div
        className={`content-wrap card-bg  ${
          actionCtx?.verificationPopUp && "content-wrap-no-animation"
        }  `}
      >
        <div className="top-wrap grey-bg">
          <div className="top-content">
            <div
              onClick={() => {
                navigate("/dashboard-verification");
                actionCtx?.setVerificationPopUp(false);
              }}
              className="close-wrap"
            >
              <figure className="img-box">{closeIcon}</figure>
              <p className="text">Close</p>
            </div>
            {/* step show start */}
            <div className="step-box">
              <span>STEP</span>
              <p>
                {step}/{routeList?.length || "3"}
              </p>
            </div>
            {/* step show send */}
          </div>
          {/* bar content start */}
          <div className="bar-wrap">
            {" "}
            <ProgressBar
              bgcolor={theme === "light" ? `#020202` : "rgba(117, 90, 226, 1)"}
              completed={stage || 30}
              //   baseColor={theme !== "light" && `#020202`}
            />
          </div>

          {/* bar content end */}
        </div>
        {/* bottom wrap start */}
        <div className="bottom-wrap">
          {Object.keys(business)?.length < 1 ? (
            <>
              {" "}
              <NoContentBox
                loading={true}
                // title={`No team Member`}
                // text="Add team member by clicking on the invite team member button above"
              />
            </>
          ) : (
            <div className="new-verification-layout-wrap-index">
              <div className="side-menu-box">
                {routeList?.map((chi, idx) => {
                  return (
                    <div
                      key={idx}
                      className={`item-row ${
                        location?.pathname === chi?.link &&
                        `item-row-active grey-bg`
                      } 
                    
                    `}
                      onClick={() => {
                        if (!chi?.disable) {
                          navigate(chi?.link);
                        }
                      }}
                    >
                      <div
                        className={`name  ${
                          location?.pathname !== chi?.link && "grey-white-color"
                        } ${chi?.disable && "tooltip-hover-wrap"}`}
                        style={{
                          cursor: chi?.disable ? "not-allowed" : "pointer",
                        }}
                      >
                        {chi?.disable && (
                          <>
                            <RavenToolTip
                              text={chi?.disable_text}
                              position={`right`}
                              color="black-light"
                            />
                          </>
                        )}
                        {chi?.name}
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="content-right-box">
                {loadUser ? (
                  <>
                    <NoContentBox loading={true} />
                  </>
                ) : (
                  <div className="centered-div">{children}</div>
                )}
              </div>
            </div>
          )}
        </div>
        {/* bottom wrap end */}
      </div>
    </div>
  );
};

export default ModalLayout;
