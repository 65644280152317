import React from "react";
import "../../styles/reusables/ContainerWrapper.css";
// import arrowRight from "../../assets/arrow-right-icon.svg";
import { FaAngleLeft } from "react-icons/fa";
import { RavenInputField } from "@ravenpay/raven-bank-ui";
// import { useDarkMode } from "../../utils/themes/useDarkMode";
import {
  // formatTitleTemplate,
  // reactSelectStyle,
} from "../../utils/helper/Helper";

const ContainerWrapper = ({
  children,
  onBack,
  stepList,
  activeStep,
  btnText,
  onBtnClick,
  loading,
  disabled,
  width,
  back,
  dontShowHeader,
  className,
  pagination,
  onStepClick,
  stepClickable,
  bulkSelect,
  // bulkSelectoptions,
  bulkSelectValue,
  onBulkSelect,
  onClearList,
}) => {
  // const [theme, toggleTheme] = useDarkMode(localStorage.getItem("theme"));
  // const [checkTheme, setCheckTheme] = useState(localStorage.getItem("theme"));
  return (
    <div className={`container-wraaper-box card-bg ${className && className}`}>
      {/* header wrap start */}
      {!dontShowHeader && (
        <div className="header-box border-theme-bottom">
          {/* bulk select start */}
          {bulkSelect && (
            <div style={{ display: "flex", alignItems: "center", gap: "2rem" }}>
              {/* <RavenInputField
                placeholder="Select from existing record"
                type={`select`}
                color={`black-light`}
                className={`bulk-select`}
                selectOption={bulkSelectoptions}
                value={bulkSelectValue}
                onChange={(e) => {
                  onBulkSelect(e);
                }}
                selectStyles={reactSelectStyle}
              /> */}
              {bulkSelect && (
                <p
                  onClick={() => {
                    !bulkSelectValue && onBulkSelect();
                  }}
                  className="clear-bulk-list"
                >
                  {bulkSelectValue
                    ? bulkSelectValue?.template_title ||
                      "--"
                    : `Select from Existing record`}
                </p>
              )}
              {bulkSelectValue && (
                <p
                  style={{ color: "#FF0F00" }}
                  onClick={onClearList}
                  className="clear-bulk-list"
                >
                  Clear list
                </p>
              )}
            </div>
          )}
          {/* bulk select end */}
          {/* back box start */}
          {back && (
            <div
              onClick={() => {
                onBack && onBack();
              }}
              className="back-box"
            >
              <FaAngleLeft className="icon grey-white-color" />
              <p className="text">back</p>
            </div>
          )}
          {/* back box end */}
          {/* step box start */}
          {stepList && (
            <div className="step-box">
              {/* box start */}
              {stepList?.map((chi, idx) => {
                return (
                  <div
                    className={`box ${activeStep >= idx + 1 && "box-active"}`}
                    key={idx}
                    onClick={() => {
                   onStepClick   &&   onStepClick(idx + 1);
                    }}
                    style={{ cursor: stepClickable && "pointer" }}
                  >
                    <div className="num">
                      <span>{idx + 1}</span>
                    </div>
                    <p className="text">{chi || "0ieje"}</p>
                  </div>
                );
              })}
              {/* box end */}
            </div>
          )}
          {/* step box end */}
        </div>
      )}
      {/* header box end */}
      {/* main box start */}
      <div
        className="content-box"
        style={{ paddingBottom: btnText ? "10rem" : "0rem" }}
      >
        <div className="wrap" style={{ width: width && width }}>
          {children}
        </div>
      </div>
      {/* main box end */}
      {/* footer box start */}
      {btnText && (
        <div className="footer-box card-bg border-theme-top">
          <div className="wrap">
            <form
              action=""
              onSubmit={(e) => {
                e.preventDefault();
                // onBtnClick()
              }}
              //   className="form"
            >
              <RavenInputField
                // purpleGradient
                type={`submit`}
                loading={loading}
                onSubmit={onBtnClick}
                color={`black-light`}
                value={btnText || `btn text`}
                disabled={disabled}
                //   textColor={`white-${theme}`}
              />
            </form>
          </div>
        </div>
      )}
      {pagination && (
        <div className="footer-box card-bg border-theme-top">
          <div className="table-pagination-box">{pagination}</div>
        </div>
      )}
      {/* footer wrap end */}
    </div>
  );
};

export default ContainerWrapper;
