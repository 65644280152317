import {
  RavenCheckBox,
  RavenInputField,
  RavenModal,
} from "@ravenpay/raven-bank-ui";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import NoContentBox from "../../../components/reusables/NoContentBox";
import { addRoleToSubAccount } from "../../../redux/sub_account";
import "../../../styles/dashboard/pos/DashboardPosIndex.css";
import {
  formatTypeFunction,
  lowerCaseWrap,
  reactSelectStyle,
  SPECIAL_ROLE_NAME,
  trimLongString,
} from "../../../utils/helper/Helper";
import { getRolesAndPermissions } from "../../../redux/roleAndPermission";

const AddRoleToAccountModal = ({ visible, onClose, detail, onFinish }) => {
  const {
    subAccoutDetailList,
    loadingStatement,
    loadingTeamUpdate,
    single_sub_account_role_info,
  } = useSelector((state) => state.sub_account);
  const { roles_and_users } = useSelector((state) => state.role_and_permission);
  const [details, setDetails] = useState({
    custom_account_name: "",
    currency: "Naira",
    purpose: "",
    managers: [],
    selected_roles: [],
    authorized_users: [],
    primary: false,
  });
  const dispatch = useDispatch();

  const formAuthLevel = (list) => {
    // console.log(list);
    if (list?.length > 0) {
      const filterList = list?.filter((chi) => chi !== "verifier");
      const newList = filterList?.map((chi) => {
        return {
          ...chi,
          value: chi,
          label: lowerCaseWrap(formatTypeFunction(chi)),
        };
      });
      return newList;
    }
  };

  useEffect(() => {
    if (roles_and_users?.length < 1) {
    }
    dispatch(getRolesAndPermissions());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const defaultList = {
  //   roles_and_users: [
  //     {
  //       role: "support",
  //       user_array: [
  //         {
  //           user_email: "visep15798e233@vreaa.com",
  //           clearance: SPECIAL_ROLE_NAME,
  //           business_email: "visep15798e233@vreaa.com",
  //           business_id: 2,
  //           user_id: 2,
  //           user_phone: "07000000000",
  //           fname: "samico",
  //           lname: "company",
  //           blocked: 0,
  //           created_at: "2023-08-11T07:36:20.000Z",
  //           updated_at: "2023-08-12T20:23:22.000Z",
  //           deleted_at: null,
  //           email_verified: 1,
  //           email_verified_at: "2023-08-11T07:36:20.000Z",
  //           phone_verified: 0,
  //           phone_verified_at: null,
  //           pin_status: 1,
  //           initial_account_number_generation: 1,
  //           country_code: "234",
  //           theme_display_settings:
  //             '{"theme":"light","side_nav_style":"solid","quick_action":["payroll","pay bills","invoice","pos"],"dashboard_overview":"dark","table_font":"bold","table_size":50}',
  //         },
  //       ],
  //     },
  //     {
  //       role: "finance",
  //       user_array: [
  //         {
  //           user_email: "visep15798e233@vreaa.com",
  //           clearance: "uio",
  //           business_email: "visep15798e233@vreaa.com",
  //           business_id: 2,
  //           user_id: 2,
  //           user_phone: "07000000000",
  //           fname: "samico",
  //           lname: "company",
  //           blocked: 0,
  //           created_at: "2023-08-11T07:36:20.000Z",
  //           updated_at: "2023-08-12T20:23:22.000Z",
  //           deleted_at: null,
  //           email_verified: 1,
  //           email_verified_at: "2023-08-11T07:36:20.000Z",
  //           phone_verified: 0,
  //           phone_verified_at: null,
  //           pin_status: 1,
  //           initial_account_number_generation: 1,
  //           country_code: "234",
  //           theme_display_settings:
  //             '{"theme":"light","side_nav_style":"solid","quick_action":["payroll","pay bills","invoice","pos"],"dashboard_overview":"dark","table_font":"bold","table_size":50}',
  //         },
  //         {
  //           user_email: "visep15798e233@vreaa.com",
  //           clearance: "udsio",
  //           business_email: "visep15798e233@vreaa.com",
  //           business_id: 2,
  //           user_id: 23,
  //           user_phone: "07000000000",
  //           fname: "paulo",
  //           lname: "124asdfr",
  //           blocked: 0,
  //           created_at: "2023-08-11T07:36:20.000Z",
  //           updated_at: "2023-08-12T20:23:22.000Z",
  //           deleted_at: null,
  //           email_verified: 1,
  //           email_verified_at: "2023-08-11T07:36:20.000Z",
  //           phone_verified: 0,
  //           phone_verified_at: null,
  //           pin_status: 1,
  //           initial_account_number_generation: 1,
  //           country_code: "234",
  //           theme_display_settings:
  //             '{"theme":"light","side_nav_style":"solid","quick_action":["payroll","pay bills","invoice","pos"],"dashboard_overview":"dark","table_font":"bold","table_size":50}',
  //         },
  //       ],
  //     },
  //   ],
  // };

  const [arrayOfNoneExisting, setArrayOfNoneExisting] = useState({});

  // const getUserInRole = (param) => {
  //   // const newList = arrayOfNoneExisting?.map((chi))
  // };

  useEffect(() => {
    setArrayOfNoneExisting(single_sub_account_role_info);
    // console.log(arrayOfNoneExisting);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [single_sub_account_role_info]);

  const formatOptionObj = (obj) => {
    // console.log(obj);
    const newObj = {
      ...obj,
      label:
        obj?.clearance === SPECIAL_ROLE_NAME
          ? "Initiator Approver"
          : obj?.label || "None",
      value:
        obj?.clearance === SPECIAL_ROLE_NAME
          ? "initiator_approver"
          : obj?.value || "none",
    };
    // console.log(newObj);
    return newObj;
  };

  const getObjFromList = (role, id) => {
    const firstFilterList = arrayOfNoneExisting?.roles_and_users?.filter(
      (chi) => {
        return chi?.role?.toLowerCase() === role?.toLowerCase();
      }
    );
    // console.log(firstFilterList[0]?.user_array);
    const newTarget = firstFilterList[0]?.user_array;

    const secondFilterList = newTarget?.filter((chi) => chi?.user_id === id);
    // console.log(formatOptionObj(secondFilterList[0]));
    return formatOptionObj(secondFilterList[0]);
  };

  const changeAuthLevel = (role, id, param) => {
    const firstFilterList = arrayOfNoneExisting?.roles_and_users?.filter(
      (chi) => {
        return chi?.role?.toLowerCase() === role?.toLowerCase();
      }
    );
    // console.log(firstFilterList[0]?.user_array);
    // return;
    const newTarget = firstFilterList[0]?.user_array;

    const secondFilterList = newTarget?.map((chi) =>
      chi?.user_id === id
        ? {
            ...chi,
            label: lowerCaseWrap(formatTypeFunction(param)),
            value: param,
          }
        : chi
    );

    // console.log(secondFilterList);
    // console.log(firstFilterList[0]?.user_array);
    // return;

    const lastfilterlist = arrayOfNoneExisting?.roles_and_users?.map((chi) =>
      chi?.role?.toLowerCase() === role?.toLowerCase()
        ? { ...chi, user_array: secondFilterList }
        : chi
    );
    const newList = { roles_and_users: lastfilterlist };
    // console.log(arrayOfNoneExisting);
    // console.log(newList);

    setArrayOfNoneExisting(newList);
  };

  const checkUserToShow = () => {
    const filteredObjects = arrayOfNoneExisting?.roles_and_users?.filter(
      (obj) => {
        const lowerCaseName = obj?.role?.toLowerCase();
        return details?.selected_roles?.some((filter) =>
          lowerCaseName?.includes(filter?.toLowerCase())
        );
      }
    );
    const combinedUserArray = [];

    filteredObjects.forEach((obj) => {
      combinedUserArray.push(...obj.user_array);
    });
    // console.log(combinedUserArray);
    const finalArray = combinedUserArray?.map((chi) => {
      return { user_id: chi?.user_id, auth_level: chi?.value || "none" };
    });
    // console.log(finalArray);
    return finalArray;
  };

  const handleSubmit = async () => {
    const newList = checkUserToShow();
    const obj = {
      account_id: String(detail?.id),
      selected_roles: details?.selected_roles,
      authorized_users: newList,
    };
    // console.log(obj);
    // return
    const data = await dispatch(addRoleToSubAccount(obj));
    if (data?.payload?.data?.status === "success") {
      onFinish();
      setDetails({
        custom_account_name: "",
        currency: "Naira",
        purpose: "",
        managers: [],
        selected_roles: [],
        authorized_users: [],
        primary: false,
      });
    }
  };

  const [listToAdd, setListToAdd] = useState([]);
  const getRolesToAddButNoUsers = (list) => {
    if (list?.length > 0) {
      const newList = list?.filter(
        (chi) => String(chi?.number_of_users) === "0"
      );

      const namesList = newList?.map((chi) => chi?.role_object?.name);
      if (namesList?.length > 0) {
        setListToAdd(namesList);
      } else {
        setListToAdd([]);
      }
    }
  };

  useEffect(() => {
    if (roles_and_users?.length > 0) {
      getRolesToAddButNoUsers(roles_and_users);
    }
  }, [roles_and_users]);

  return (
    <RavenModal
      visble={visible}
      onClose={onClose}
      outerClose
      btnColor={`black-light`}
      btnLabel={
        arrayOfNoneExisting?.roles_and_users?.length > 0 ? `Add Role` : ""
      }
      loading={loadingTeamUpdate}
      disabled={details?.selected_roles?.length < 1}
      onBtnClick={handleSubmit}
    >
      <div
        onClick={() => {
          // console.log(arrayOfNoneExisting?.roles_and_users);
          // getRolesToAddButNoUsers(roles_and_users);
        }}
        className="cashier-assign-modal-wrap add-role-to-for-sub-account-modal-wrap"
      >
        <p className="title">{`Add a Role to ${detail?.custom_account_name}`}</p>
        <p className="text grey-white-color">
          Add a team mate to your business and give them permission to access
          your business banking dashboard
        </p>
        <div className="underline"></div>
        {/* form wrap start */}
        <div
          style={{
            paddingBottom:
              Object.keys(arrayOfNoneExisting)?.length > 0 &&
              details?.selected_roles?.includes(
                arrayOfNoneExisting?.roles_and_users[
                  arrayOfNoneExisting?.roles_and_users?.length - 1
                ]?.role
              ) &&
              "8rem",
            overflowY: details?.selected_roles?.length > 1 ? "scroll" : "unset",
            // backgroundColor: "red"
          }}
          className={
            arrayOfNoneExisting?.roles_and_users?.length > 0 &&
            "role-select-wrap-box border-theme"
          }
        >
          {loadingStatement ? (
            <>
              {" "}
              <NoContentBox
                loading={true}
                title={`No Role found`}
                // text="Add Sub Account by clicking on the Create a sub account button above"
              />
            </>
          ) : (
            <>
              {" "}
              {arrayOfNoneExisting?.roles_and_users?.map((chi, idx) => {
                const { role, user_array } = chi;
                return (
                  <div key={idx} className="role-and-user-box">
                    <div
                      className={`top-box ${
                        details?.selected_roles?.includes(role) && "grey-bg"
                      }`}
                    >
                      <div className="role-box">
                        <RavenCheckBox
                          onChange={() => {
                            if (details?.selected_roles?.includes(role)) {
                              const newList = details?.selected_roles.filter(
                                (chi) => chi !== role
                              );
                              setDetails((prev) => {
                                return { ...prev, selected_roles: newList };
                              });
                            } else {
                              const newList = [
                                ...details?.selected_roles,
                                role,
                              ];
                              setDetails((prev) => {
                                return { ...prev, selected_roles: newList };
                              });
                            }
                          }}
                          checked={details?.selected_roles?.includes(role)}
                          color="black-light"
                          id={`${role}-${idx}`}
                        />
                        <p className="text">{role || "---"}</p>
                      </div>
                    </div>
                    {/* bottom wrap start */}
                    <div
                      className={`bottom-box ${
                        details?.selected_roles?.includes(role) &&
                        "bottom-box-show"
                      }`}
                    >
                      {user_array?.map((chid, i) => {
                        const { clearance, id } = chid;
                        return (
                          <div
                            //   htmlFor={`${name}-${i}`}
                            key={`${clearance}-${i}-${id}`}
                            onClick={() => {
                              // console.log("yes");
                              // handleAddManagerToList(name);
                            }}
                            className={`select-item ${
                              user_array?.length - 1 !== i &&
                              "border-theme-bottom"
                            }`}
                          >
                            <div className="avatar-box ">
                              <span className="">{`${
                                chid?.fname?.charAt(0) || "-"
                              } ${chid?.lname?.charAt(0) || "-"}`}</span>
                            </div>
                            <div className="name-role-box">
                              <p className="name">{`${
                                lowerCaseWrap(chid?.fname) || "---"
                              } ${
                                chid?.lname?.length + chid?.fname.length > 12 &&
                                getObjFromList(role, chid?.user_id)?.label
                                  .length > 12
                                  ? trimLongString(chid?.lname, 3)
                                  : lowerCaseWrap(chid?.lname) || "---"
                              }`}</p>
                              <p className="role grey-white-color">
                                {lowerCaseWrap(chid?.clearance) || "---"}
                              </p>
                            </div>
                            <div
                              className={`select-option-wrap ${
                                chid?.clearance === SPECIAL_ROLE_NAME &&
                                "select-react-disabled"
                              }`}
                            >
                              <form autoComplete="off" action="">
                                <RavenInputField
                                  menuPlacement={
                                    arrayOfNoneExisting?.roles_and_users
                                      ?.length -
                                      1 ===
                                      idx &&
                                    user_array?.length - 1 === i &&
                                    details?.selected_roles?.length > 1
                                      ? `top`
                                      : `bottom`
                                  }
                                  color="black-light"
                                  selectStyles={reactSelectStyle}
                                  className={`select-react`}
                                  type="select"
                                  placeholder="select"
                                  disabled={
                                    chid?.clearance === SPECIAL_ROLE_NAME
                                  }
                                  onChange={(e) => {
                                    // console.log(e, role, chid.user_id);
                                    changeAuthLevel(
                                      role,
                                      chid?.user_id,
                                      e.value
                                    );
                                  }}
                                  value={
                                    getObjFromList(role, chid?.user_id) ||
                                    formatOptionObj(chid)
                                  }
                                  selectOption={formAuthLevel(
                                    subAccoutDetailList?.authorization_values
                                  )}
                                />
                              </form>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    {/* bottom wrap ----end */}
                    {arrayOfNoneExisting?.roles_and_users?.length - 1 !==
                      idx && (
                      <div
                        style={{
                          marginTop: details?.selected_roles?.includes(role)
                            ? "2rem"
                            : "1rem",
                        }}
                        className="underline border-theme-bottom"
                      ></div>
                    )}
                  </div>
                );
              })}
              {listToAdd?.length > 0 && arrayOfNoneExisting?.roles_and_users?.length < 1 ? (
                <div
                  style={{
                    // minHeight: "30rem",
                    display: "grid",
                    placeItems: "center",
                    margin: "5rem 0rem 2rem 0rem",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "rgba(255, 246, 237, 1)",
                      padding: "1.5rem",
                      borderRadius: "1.2rem",
                    }}
                    className="grey-bg-two"
                  >
                    <p style={{ lineHeight: "2rem" }}>
                      {" "}
                      Please add team member(s) to any of the following
                      available {`role${listToAdd?.length > 1 ? "s" : ""}`} :
                      <span
                        // className="black-white-color"
                        style={{
                          margin: "0rem .6rem 0rem 1rem",
                          color: "rgba(234, 135, 45, 1)",
                          textTransform: "capitalize",
                          fontWeight: "600",
                        }}
                      >
                        {listToAdd?.map((chi, idx) => {
                          return (
                            <span key={idx}>
                              {idx + 1 !== listToAdd?.length ? `${chi},` : chi}{" "}
                            </span>
                          );
                        })}
                      </span>
                      to enable adding role to this sub-account.
                    </p>
                  </div>
                </div>
              ) : (
                ""
              )}
            </>
          )}
        </div>
        {/* form wrap end */}
      </div>
    </RavenModal>
  );
};

export default AddRoleToAccountModal;
