import { RavenInputField, RavenModal } from "@ravenpay/raven-bank-ui";
import React, { useContext, useEffect, useState } from "react";
// import "@ravenpay/raven-bank-ui/dist/esm/styles/index.css";
import "../../../styles/auth/SignUp.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CryptoJS from "crypto-js";
import {
  authenticateLogin,
  authenticateLoginSso,
  CheckUserLoginDetails,
  // getUser,
  resendEmailLogin,
} from "../../../redux/user";
import { clearCache, formatMetaData } from "../../../utils/helper/Helper";
import ActionContext from "../../../context/ActionContext";
import { debounce } from "lodash";

const LoginModal = ({
  showModal,
  onClose,
  userEmail,
  sso,
  setPin,
  setBusiness,
  loginDetails,
  deviceId,
  twoFactor,
}) => {
  const actionCtx = useContext(ActionContext);
  const navigate = useNavigate();
  const { loading } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [toggleKey, setKeyToggle] = useState(false);
  const [completeToken, setCompleteToken] = useState(false);
  // const [checkLoader, setCheckLoader] = useState(false)
  const [details, setDetails] = useState({
    token: "",
  });

  useEffect(() => {
    setKeyToggle(!toggleKey);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal]);

  const handleResendEmailOtp = async () => {
    const obj = {
      email: userEmail,
    };
    const data = await dispatch(resendEmailLogin(obj));
    if (data?.payload?.data?.status === "success") {
      setKeyToggle(!toggleKey);
    }
  };

  const encryptTokenFunc = (tok) => {
    const token = tok;
    const secretKey =
      "ygb0728hnw7eyhidh7t762y2bdxr6abxjbaxr6wuetyehjwu73ehuyst7gduu";

    // Encrypt the token
    const encryptedToken = CryptoJS.AES.encrypt(token, secretKey).toString();
    return encryptedToken;
  };

  const formSelectList = (list) => {
    // if (list?.length > 0) {
    //   const newList = list?.filter(
    //     (chi) => Number(chi?.confirmation_status) === 1
    //   );
    //   return newList;
    // }
    return list;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    actionCtx?.setIsLogout(false);

    const obj = {
      email: userEmail,
      otp: details?.token,
    };
    if (sso) {
      // console.log("yeah");
      // return;
      const data = await dispatch(authenticateLoginSso(obj));
      // console.log(data);
      if (data?.payload?.data?.status === "success") {
        clearCache();
        const token = data?.payload?.data?.data?.token;
        localStorage?.setItem("token", encryptTokenFunc(token));
        const business = data?.payload?.data?.data?.business;
        localStorage.setItem("bvn_status", business?.bvn);
        const user = data?.payload?.data?.data?.user;
        localStorage?.setItem(
          "sidebar-theme-style",
          formatMetaData(user?.theme_display_settings)?.side_nav_style
        );
        actionCtx?.setPerPage({
          label: `${formatMetaData(user?.theme_display_settings)?.table_size}`,
          value: `${formatMetaData(user?.theme_display_settings)?.table_size}`,
        });
        localStorage?.setItem(
          "theme",
          formatMetaData(user?.theme_display_settings)?.theme
        );
        checkNavigate(business);
      }
    }
    if (!sso) {
      const data = await dispatch(authenticateLogin(obj));
      // console.log(data);
      if (data?.payload?.data?.status === "success") {
        const token = data?.payload?.data?.data?.token;
        clearCache();
        actionCtx?.setIsLogout(false);
        // console.log(data?.payload?.data?.data);
        localStorage?.setItem("token", encryptTokenFunc(token));
        const user = data?.payload?.data?.data?.user;
        localStorage?.setItem(
          "sidebar-theme-style",
          formatMetaData(user?.theme_display_settings)?.side_nav_style
        );
        localStorage?.setItem(
          "theme",
          formatMetaData(user?.theme_display_settings)?.theme
        );
        actionCtx?.setPerPage({
          label: `${formatMetaData(user?.theme_display_settings)?.table_size}`,
          value: `${formatMetaData(user?.theme_display_settings)?.table_size}`,
        });
        const business = data?.payload?.data?.data?.business;
        localStorage.setItem("bvn_status", business?.bvn);
        checkNavigate(business);
      }
    }
  };

  const handleSubmitDirect = debounce(async (objDetails) => {
    // e.preventDefault();

    if (sso) {
      const data = await dispatch(authenticateLoginSso(objDetails));
      clearCache();
      if (data?.payload?.data?.status === "success") {
        actionCtx?.setIsLogout(false);
        const token = data?.payload?.data?.data?.token;
        localStorage?.setItem("token", encryptTokenFunc(token));
        const business = data?.payload?.data?.data?.business;
        const user = data?.payload?.data?.data?.user;
        localStorage?.setItem(
          "sidebar-theme-style",
          formatMetaData(user?.theme_display_settings)?.side_nav_style
        );
        localStorage?.setItem(
          "theme",
          formatMetaData(user?.theme_display_settings)?.theme
        );
        actionCtx?.setPerPage({
          label: `${formatMetaData(user?.theme_display_settings)?.table_size}`,
          value: `${formatMetaData(user?.theme_display_settings)?.table_size}`,
        });
        localStorage.setItem("bvn_status", business?.bvn);
        checkNavigate(business);
      }
    }
    if (!sso) {
      const data = await dispatch(authenticateLogin(objDetails));
      // console.log(data);
      if (data?.payload?.data?.status === "success") {
        actionCtx?.setIsLogout(false);
        // return
        clearCache();
        const token = data?.payload?.data?.data?.token;
        localStorage?.setItem("token", encryptTokenFunc(token));
        const business = data?.payload?.data?.data?.business;
        const user = data?.payload?.data?.data?.user;
        localStorage?.setItem(
          "sidebar-theme-style",
          formatMetaData(user?.theme_display_settings)?.side_nav_style
        );
        actionCtx?.setPerPage({
          label: `${formatMetaData(user?.theme_display_settings)?.table_size}`,
          value: `${formatMetaData(user?.theme_display_settings)?.table_size}`,
        });
        localStorage?.setItem(
          "theme",
          formatMetaData(user?.theme_display_settings)?.theme
        );
        localStorage.setItem("bvn_status", business?.bvn);
        checkNavigate(business);
      }
    }
  }, 50);

  const checkNavigate = (business) => {
    if (business?.bvn !== 3) {
      const urlLink = window?.location.href.split("?")[1];
      navigate(`/dashboard-verification${urlLink ? `?${urlLink}` : ""}`);
      return;
    }
    if (business?.nin !== 3) {
      const urlLink = window?.location.href.split("?")[1];
      navigate(`/dashboard-verification${urlLink ? `?${urlLink}` : ""}`);
      // navigate("/dashboard-verification-cac");
      return;
    }
    if (business?.cac !== 3) {
      const urlLink = window?.location.href.split("?")[1];
      navigate(`/dashboard-verification${urlLink ? `?${urlLink}` : ""}`);
      // navigate("/dashboard-verification-cac");
      return;
    }
    if (business?.address !== 3) {
      const urlLink = window?.location.href.split("?")[1];
      navigate(`/dashboard-verification${urlLink ? `?${urlLink}` : ""}`);
      // navigate("/dashboard-verification-address");
      return;
    }
    if (business?.business_info !== 3) {
      const urlLink = window?.location.href.split("?")[1];
      navigate(`/dashboard-verification${urlLink ? `?${urlLink}` : ""}`);
      // navigate("/dashboard-verification-business");
      return;
    }
    // onSuccess();
    const urlLink = window?.location.href.split("?")[1];
    if (urlLink) {
      navigate(`/${urlLink}`);
    } else {
      navigate("/dashboard-overview");
    }
  };

  const checkUserDetails = debounce(async (pin) => {
    const obj = {
      email: userEmail,
      otp: pin || details?.token,
      device_id: deviceId,
    };
    const data = await dispatch(CheckUserLoginDetails(obj));
    // console.log(data);
    if (data?.payload?.data.status === "success") {
      // localStorage?.removeItem("invite--email");
      const businesses = formSelectList(data?.payload?.data?.data?.businesses);
      if (businesses?.length > 1) {
        setBusiness(businesses);
        setPin(pin);
        // console.log(businesses);
      }
      if (businesses?.length === 1) {
        const obj = {
          business_id: String(businesses[0]?.business_id),
          email: businesses[0]?.user_email || loginDetails?.email,
          otp: pin || details?.token,
          password: loginDetails?.password,
          device_id: deviceId,
        };
        handleSubmitDirect(obj);
      }
    }
  }, 50);

  return (
    <RavenModal
      onClose={onClose}
      visble={showModal}
      loading={loading}
      btnLabel="Verify Account"
      btnColor={`black-light`}
      effect={`fadeInUp`}
      disabled={!completeToken}
      onBtnClick={handleSubmit}
      // onBtnClick={checkUserDetails}
      className={`auth-pin-modal-wrap`}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
        action=""
        className="form form-modal-auth"
      >
        <div className="text-box">
          <p className="big-title">
            {twoFactor ? `Enter 2FA Code` : `Complete Login with OTP`}
          </p>
          <p className="small-title">
            {twoFactor
              ? `Please provide the 6 digit token from your Authenticator app.`
              : ` Please kindly provide the 6-Digit OTP sent to the email provided to
            verify account.`}
          </p>
        </div>
        <RavenInputField
          type={`pin`}
          inputPattern="[0-9]+"
          // label={`Enter token sent to your email`}
          color={`black-light`}
          onChange={(e) => {
            setCompleteToken(false);
            setDetails((prev) => {
              return { ...prev, token: e };
            });
            setPin(e);
          }}
          onComplete={(pin) => {
            setCompleteToken(true);
            checkUserDetails(pin);
            // handleSubmitVersionFive(pin);
          }}
          value={details?.token}
          showCountDown={twoFactor ? false : true}
          // countDownTime={50}
          key={toggleKey}
          countdownFinishClick={handleResendEmailOtp}
          countdownFinishText={` Resend OTP`}
          countdownFinishClassName={`resend-text`}
          // onCountdownFinish
          countdownLabelClassName={`black-white-color`}
        />
        {/* <p onClick={handleResendEmailOtp} className="resend-text">
          Resend OTP
        </p> */}

        {/* <ReCAPTCHA
            sitekey="6Lcy3JEnAAAAAHRXxYglghhB5UlqqeEvMh562twv"
            onChange={handleCaptchaVerify}
            className="recaptcha-wrapper"
          /> */}
      </form>
    </RavenModal>
  );
};

export default LoginModal;
