/* eslint-disable no-unused-vars */
// import { RavenButton, RavenInputField } from "@ravenpay/raven-bank-ui";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import ProgressBar from "../../../../components/fragments/ProgreeBar";
import ActionContext from "../../../../context/ActionContext";
// import CryptoJS from "crypto-js";
import "../../../../styles/dashboard/verification/VerificationFiveModal.css";
// import { validateEmail } from "../../../utils/helper/ValidateInput";
import { useDarkMode } from "../../../../utils/themes/useDarkMode";
import { useLocation, useNavigate } from "react-router-dom";
import { RavenToolTip } from "@ravenpay/raven-bank-ui";
import NoContentBox from "../../../../components/reusables/NoContentBox";
// import TierTwoIndex from "./TierTwoIndex";
import ReUsuableStatus from "../ReUsuableStatus";
// import { sumAllNum } from "../../../../utils/helper/Helper";

const ModalLayout = ({ stage, children, step }) => {
  const actionCtx = useContext(ActionContext);
  const navigate = useNavigate();
  const { business, loadUser } = useSelector((state) => state.user);
  // eslint-disable-next-line no-unused-vars
  const [theme, toggleTheme] = useDarkMode(localStorage.getItem("theme"));
  // eslint-disable-next-line no-unused-vars
  // const [step, setStep] = useState(1);
  // const dispatch = useDispatch();
  const location = useLocation();
  const [popUp, setPopUp] = useState(false);
  //   const { loadingNotify, user, business } = useSelector((state) => state.user);
  const closeIcon = (
    <svg
      className="img"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="dull-bright-filter"
        d="M4.5 4.5L13.5 13.5M13.5 4.5L4.5 13.5"
        stroke="#020202"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const routeList = [
    {
      name: "CAC Information",
      link: "/dashboard-verification-cac-new",
      disable:
        Number(business?.address) !== 3 && Number(business?.address) !== 1,
      disable_text: "Verify Address",
    },
    // { name: "CAC Documents", link: "/dashboard-verification-nin-new" },
    {
      name: "Directors Information",
      link: "/dashboard-verification-directors-new",
      disable: Number(business?.cac) !== 3 && Number(business?.cac) !== 1,
      disable_text: "Verify CAC",
    },
    {
      name: "Sign Indemnity",
      link: "/dashboard-verification-sign-idenmity-new",
      disable: Number(business?.directors) !== 3 && Number(business?.cac) !== 1,
      disable_text: "Verify Directors",
    },
  ];

  useEffect(() => {
    setTimeout(() => {
      actionCtx?.setVerificationPopUp(true);
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //   const getPercentageFunc = () => {
  //      const total = sumAllNum([business?.bvn === 3 ? 30 : 0, ])
  //   }

  const successImg = (
    <svg
      className="img"
      width="95"
      height="107"
      viewBox="0 0 95 107"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_19484_33706)">
        <rect
          x="11.1499"
          y="9"
          width="77.7"
          height="119.7"
          rx="3.5"
          fill="white"
        />
        <rect
          x="8.1499"
          y="6"
          width="83.7"
          height="125.7"
          rx="6.5"
          stroke="#755AE2"
          strokeWidth="6"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M38.8524 45.5413C40.833 45.3832 42.7133 44.6044 44.2256 43.3156C47.7547 40.3081 52.9452 40.3081 56.4743 43.3156C57.9866 44.6044 59.8669 45.3832 61.8476 45.5413C66.4696 45.9101 70.1399 49.5804 70.5087 54.2024C70.6668 56.183 71.4456 58.0634 72.7344 59.5756C75.7418 63.1047 75.7418 68.2953 72.7344 71.8243C71.4456 73.3366 70.6668 75.2169 70.5087 77.1976C70.1399 81.8196 66.4696 85.4899 61.8476 85.8587C59.8669 86.0168 57.9866 86.7956 56.4743 88.0844C52.9452 91.0919 47.7547 91.0919 44.2256 88.0844C42.7133 86.7956 40.833 86.0168 38.8524 85.8587C34.2303 85.4899 30.5601 81.8196 30.1912 77.1976C30.0332 75.2169 29.2543 73.3366 27.9656 71.8243C24.9581 68.2953 24.9581 63.1047 27.9656 59.5756C29.2543 58.0634 30.0332 56.183 30.1912 54.2024C30.5601 49.5804 34.2303 45.9101 38.8524 45.5413ZM61.7678 61.7179C62.9707 60.5151 62.9707 58.5649 61.7678 57.3621C60.565 56.1593 58.6149 56.1593 57.4121 57.3621L47.27 67.5042L43.2878 63.5221C42.085 62.3193 40.1349 62.3193 38.9321 63.5221C37.7293 64.7249 37.7293 66.6751 38.9321 67.8779L45.0921 74.0379C46.2949 75.2407 48.245 75.2407 49.4478 74.0379L61.7678 61.7179Z"
          fill="url(#paint0_linear_19484_33706)"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_19484_33706"
          x="0.149902"
          y="0"
          width="94.7002"
          height="134.7"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-5" dy="-3" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.858824 0 0 0 0 0.839216 0 0 0 0 0.988235 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_19484_33706"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_19484_33706"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_19484_33706"
          x1="50.35"
          y1="41.06"
          x2="50.35"
          y2="90.34"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFA554" />
          <stop offset="1" stopColor="#E66F04" />
        </linearGradient>
      </defs>
    </svg>
  );

  const bacImg = (
    <svg
      width="90"
      height="91"
      viewBox="0 0 90 91"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_20109_1926)">
        <path d="M21 67.36H69V19.36H21V67.36Z" fill="#676767" />
      </g>
      <defs>
        <filter
          id="filter0_d_20109_1926"
          x="0.299999"
          y="0.660107"
          width="89.4"
          height="89.4"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="10.35" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_20109_1926"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_20109_1926"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );

  return (
    <div
      onClick={() => {
        // console.log(business?.cac);
      }}
      className="verification-five-modal-create-index-wrap"
    >
      <div
        className={`content-wrap card-bg  ${
          actionCtx?.verificationPopUp && "content-wrap-no-animation"
        }  `}
      >
        <div className="top-wrap grey-bg">
          <div className="top-content">
            <div
              onClick={() => {
                navigate("/dashboard-verification");
                actionCtx?.setVerificationPopUp(false);
              }}
              className="close-wrap"
            >
              <figure className="img-box">{closeIcon}</figure>
              <p className="text">Close</p>
            </div>
            {/* step show start */}
            <div className="step-box">
              <span>STEP</span>
              <p>
                {step || 1}/{routeList?.length || "3"}
              </p>
            </div>
            {/* step show send */}
          </div>
          {/* bar content start */}
          <div className="bar-wrap">
            {" "}
            <ProgressBar
              bgcolor={theme === "light" ? `#020202` : "rgba(117, 90, 226, 1)"}
              completed={stage || 30}
              //   baseColor={theme !== "light" && `#020202`}
            />
          </div>

          {/* bar content end */}
        </div>
        {/* bottom wrap start */}
        <div className="bottom-wrap">
          {Object.keys(business)?.length < 1 ? (
            <>
              {" "}
              <NoContentBox
                loading={true}
                // title={`No team Member`}
                // text="Add team member by clicking on the invite team member button above"
              />
            </>
          ) : (
            <div className="new-verification-layout-wrap-index">
              <div className="side-menu-box">
                {routeList?.map((chi, idx) => {
                  return (
                    <div
                      key={idx}
                      className={`item-row ${
                        location?.pathname === chi?.link &&
                        `item-row-active grey-bg`
                      } 
                  
                  `}
                      onClick={() => {
                        if (!chi?.disable) {
                          navigate(chi?.link);
                        }
                      }}
                    >
                      <div
                        className={`name  ${
                          location?.pathname !== chi?.link && "grey-white-color"
                        } ${chi?.disable && "tooltip-hover-wrap"}`}
                        style={{
                          cursor: chi?.disable ? "not-allowed" : "pointer",
                        }}
                      >
                        {chi?.disable && (
                          <>
                            <RavenToolTip
                              text={chi?.disable_text}
                              position={`right`}
                              color="black-light"
                            />
                          </>
                        )}
                        {chi?.name}
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="content-right-box">
                {loadUser ? (
                  <>
                    <NoContentBox loading={true} />
                  </>
                ) : (
                  <div
                    className={`centered-div ${
                      location?.pathname?.includes(
                        "/dashboard-verification-cac-new"
                      ) && "centered-div-two"
                    }`}
                  >
                    {String(business?.address) !== "3" &&
                    Number(business?.address) !== 1 ? (
                      <>
                        {" "}
                        <div className="centered-div">
                          {" "}
                          <ReUsuableStatus
                            img={bacImg}
                            text={`You cannot access this verification yet, until you complete your Tier 2 verifications, click the back button to continue verification`}
                            title={`Go Back`}
                            imgBg={`rgba(247, 247, 247, 1)`}
                          />
                        </div>
                      </>
                    ) : (
                      <> {children}</>
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        {/* bottom wrap end */}
      </div>
    </div>
  );
};

export default ModalLayout;
