import React, { useRef } from "react";
import "./DescriptionPreviewBox.css";
import ImageFragment from "../fragments/ImageFragment";
import fileImg from "../../assets/report-file-initiatior.png";
// import ActionContext from "../../context/ActionContext";

const uploadIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    className="img"
    viewBox="0 0 24 24"
  >
    <path
      fill="#B4B4B4"
      fillRule="evenodd"
      d="M12.079 3.71c.101-.344-.142-.71-.5-.71H5.6A3.6 3.6 0 002 6.6v12a3.6 3.6 0 003.6 3.6h12a3.6 3.6 0 003.6-3.6v-5.89c0-.356-.362-.6-.703-.502a6.82 6.82 0 01-8.418-8.499zM7 10a2 2 0 100-4 2 2 0 000 4zm-.082 9.136l7.27-5.843a.5.5 0 01.65-.03l3.969 3.087a.5.5 0 01.193.394V18a2 2 0 01-2 2H7.26a.5.5 0 01-.342-.864z"
      clipRule="evenodd"
    ></path>
    <path
      fill="#B4B4B4"
      d="M18.898.897a1 1 0 011 1v2.394h2.394a1 1 0 110 2h-2.394v2.395a1 1 0 11-2 0V6.29h-2.394a1 1 0 110-2h2.394V1.897a1 1 0 011-1z"
    ></path>
  </svg>
);

const removeIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    fill="none"
    viewBox="0 0 14 14"
    className="img"
  >
    <circle cx="7" cy="7" r="7" fill="#FF0F00"></circle>
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="0.612"
      d="M8.75 5.25l-3.5 3.5m0-3.5l3.5 3.5"
    ></path>
  </svg>
);

const DescriptionPreviewBox = ({
  imgList,
  onAdd,
  onRemove,
  onView,
  canView,
  hideAdd,
  hideRemove,
  normalUrl,
  hideLabel,
  label,
}) => {
  // const actionCtx = useContext(ActionContext);

  const removeFromList = (idx) => {
    const newArray = imgList?.filter((_, index) => index !== idx);

    onRemove && onRemove(newArray);
  };

  const fileInputRef = useRef(null);

  const clearFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = ""; // Reset the file input value
    }
  };

  const eyeIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      fill="none"
      viewBox="0 0 14 14"
      className="img"
    >
      <path fill="#fff" d="M7 8.4a1.4 1.4 0 100-2.8 1.4 1.4 0 000 2.8z"></path>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M.32 7a7.003 7.003 0 0113.36 0A7.003 7.003 0 01.32 7zM9.8 7a2.8 2.8 0 11-5.6 0 2.8 2.8 0 015.6 0z"
        clipRule="evenodd"
      ></path>
    </svg>
  );

  return (
    <div className="reusable-add-and-preview-images-initiator-box">
      {!hideLabel && <p className="label">{label || `Add Image`}</p>}
      {/* add preview img box start */}
      <div className="add-preview-img-box ">
        <div className="inner-scroll">
          {/* preview list box here start */}
          {imgList?.map((chi, idx) => {
            return (
              <ImageFragment
                onClick={() => {
                  onView && onView(chi);
                }}
                key={idx}
                url={normalUrl ? chi : ``}
                className={`box border-theme`}
                imgContent={
                  <>
                    <div className="view-box shadow-box-shadow-box">
                      <figure className="img">{eyeIcon}</figure>
                    </div>
                  </>
                }
              >
                <div
                  onClick={() => {
                    // console.log(imgList);
                  }}
                  style={{
                    backgroundImage: chi?.type?.includes("image/")
                      ? `url(${URL.createObjectURL(chi)})`
                      : fileImg,
                  }}
                  key={idx}
                  className="box border-theme"
                >
                  {" "}
                  {/* remove box start */}
                  {!hideRemove && (
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        removeFromList(idx);
                      }}
                      className="remove-box"
                    >
                      <figure className="img-box">{removeIcon}</figure>
                    </div>
                  )}
                  {/* remove box end */}
                </div>
              </ImageFragment>
            );
          })}
          {/* preview list box here end */}
          {/* add box start */}
          {!hideAdd && (
            <div className="box add-box grey-bg">
              <input
                type="file"
                // accept="image/*"
                accept=".jpeg,.jpg,.png,.gif"
                name="report-img-unique-id"
                id="report-img-unique-id"
                className="input"
                onChange={(e) => {
                  if (e.target.files[0]) {
                    onAdd && onAdd(e.target.files[0]);
                  }
                  setTimeout(() => {
                    clearFileInput();
                  }, 100);
                }}
                ref={fileInputRef}
              />
              <label style={{cursor: "pointer"}} className="label" htmlFor="report-img-unique-id">
                <figure className="img-box">{uploadIcon}</figure>
              </label>
            </div>
          )}
          {/* add box end */}
        </div>
      </div>
      {/* add preview img box end */}
    </div>
  );
};

export default DescriptionPreviewBox;
