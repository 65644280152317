import React, { useState } from "react";
import TierTwoIndex from "../TierTwoIndex";
import { RavenButton, RavenInputField, toast } from "@ravenpay/raven-bank-ui";
import SignRavenModal from "./SignModal";
// import { textToRead } from "./ReadUpContent";
import BigContentViewModal from "./BigContentViewModal";
import useWindowSize from "../../../../../utils/helper/UseWindowSize";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import pendingImgCac from "../../../../../assets/cac-pending-img.png";
import {
  changeVerificationStatus,
  getIndenmityTexts,
  signIndenmity,
} from "../../../../../redux/verifications";
import NoContentBox from "../../../../../components/reusables/NoContentBox";
import { getUser } from "../../../../../redux/user";
import ReUsuableStatus from "../../ReUsuableStatus";
import { useNavigate } from "react-router-dom";
import {
  formatMetaData,
  hasTimePassed,
} from "../../../../../utils/helper/Helper";

const SignIdenmityFive = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { business, loading } = useSelector((state) => state.user);
  const { loadingFetch, loadingCac, indemnity_text } = useSelector(
    (state) => state.verifications
  );
  const [details, setDetails] = useState({
    signature: "",
    type: "",
  });
  const size = useWindowSize();
  const [showModal, setShowModal] = useState({
    sign: false,
    read: false,
  });
  const fileIcon = (
    <svg
      className="img"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 7V6.8C20 5.11984 20 4.27976 19.673 3.63803C19.3854 3.07354 18.9265 2.6146 18.362 2.32698C17.7202 2 16.8802 2 15.2 2H8.8C7.11984 2 6.27976 2 5.63803 2.32698C5.07354 2.6146 4.6146 3.07354 4.32698 3.63803C4 4.27976 4 5.11984 4 6.8V17.2C4 18.8802 4 19.7202 4.32698 20.362C4.6146 20.9265 5.07354 21.3854 5.63803 21.673C6.27976 22 7.11984 22 8.8 22H12.5M12.5 11H8M11.5 15H8M16 7H8M18 18V12.5C18 11.6716 18.6716 11 19.5 11C20.3284 11 21 11.6716 21 12.5V18C21 19.6569 19.6569 21 18 21C16.3431 21 15 19.6569 15 18V14"
        stroke="#ACACAC"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const viewIcon = (
    <svg
      className="img"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 8L21 3M21 3H16M21 3V8M8 8L3 3M3 3L3 8M3 3L8 3M8 16L3 21M3 21H8M3 21L3 16M16 16L21 21M21 21V16M21 21H16"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  useEffect(() => {
    if (!indemnity_text) {
      dispatch(getIndenmityTexts());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (e) => {
    e && e?.preventDefault();
    const obj = {
      indemnity_signature: details?.signature,
    };
    const formData = new FormData();
    formData.append("indemnity_signature", obj?.indemnity_signature);
    const data = await dispatch(signIndenmity(formData));
    if (data?.payload?.data?.status === "success") {
      dispatch(getUser());
      // setSelectCac("");
      setDetails((prev) => {
        return {
          ...prev,
          type: "",
          signature: "",
        };
      });
    }
  };

  const [statusCheck, setStatusCheck] = useState(0);
  useEffect(() => {
    if (Object.keys(business).length > 0) {
      setStatusCheck(Number(business?.indemnity));
    }
    if (Number(business?.directors) !== 3 && Number(business?.cac) !== 1) {
      navigate("/dashboard-verification");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [business]);

  const handleRetryFunction = async () => {
    const obj = {
      type: "indemnity",
    };
    const data = await dispatch(changeVerificationStatus(obj));
    if (data?.payload?.data?.status === "success") {
      dispatch(getUser());
    }
  };

  const pendImg = (
    <svg
      className="img"
      width="90"
      height="91"
      viewBox="0 0 90 91"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_19492_680)">
        <path d="M21 67.36H69V19.36H21V67.36Z" fill="#676767" />
      </g>
      <defs>
        <filter
          id="filter0_d_19492_680"
          x="0.299999"
          y="0.659985"
          width="89.4"
          height="89.4"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="10.35" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_19492_680"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_19492_680"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );

  if (String(statusCheck) === "1") {
    return (
      <TierTwoIndex step={3} stage={95}>
        <ReUsuableStatus
          img={pendImg}
          text={`We are currently verifying your Indenmity details this process typically takes approximately 2-3 days you can continue other verification once your Indenmity document is verified..`}
          title={`Indenmity Verification On-going`}
          imgBg={`rgba(247, 247, 247, 1)`}
          imgSrc={pendingImgCac}
        />
        {hasTimePassed(
          formatMetaData(business?.verification_time)?.indemnity,
          30
        ) && (
          <div
            style={{ minHeight: "unset", width: " 100%", cursor: "pointer" }}
            className="form-inputs-wrap"
            // onClick={() => {console.log(formatMetaData(business?.verification_time));}}
          >
            {" "}
            <RavenButton
              // type={`submit`}

              color="black-light"
              label={`Retry Verification`}
              style={{
                backgroundColor: "#ffffff",
                border: ".1rem solid #020202",
                color: "#020202",
                width: "100%",
                cursor: "pointer",
              }}
              loading={loading}
              onClick={handleRetryFunction}
              className="btn-submit"
            />
          </div>
        )}
      </TierTwoIndex>
    );
  }

  const failedIcon = (
    <svg
      className="img"
      width="86"
      height="86"
      viewBox="0 0 86 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M37.678 23.1388L18.328 56.6465C15.9391 60.7901 18.9252 65.9632 23.7113 65.9632H62.4054C67.1903 65.9632 70.18 60.7841 67.7887 56.6465L48.4387 23.1388C46.0498 18.9917 40.0704 18.9917 37.678 23.1388Z"
        fill="url(#paint0_linear_20124_9100)"
      />
      <path
        d="M62.4052 66.0671H23.7147C22.6066 66.0671 21.518 65.7753 20.5583 65.2213C19.5986 64.6672 18.8016 63.8703 18.2475 62.9106C17.6933 61.951 17.4015 60.8624 17.4014 59.7542C17.4012 58.6461 17.6927 57.5574 18.2466 56.5976L37.5966 23.0898C38.1505 22.1299 38.9475 21.3327 39.9072 20.7784C40.867 20.2242 41.9558 19.9324 43.0641 19.9324C44.1725 19.9324 45.2613 20.2242 46.221 20.7784C47.1808 21.3327 47.9778 22.1299 48.5317 23.0898L67.8817 56.5976C68.4356 57.5574 68.7271 58.6461 68.7269 59.7542C68.7268 60.8624 68.4349 61.951 67.8808 62.9106C67.3266 63.8703 66.5297 64.6672 65.57 65.2213C64.6103 65.7753 63.5217 66.0671 62.4135 66.0671H62.4052ZM43.0552 20.13C41.9803 20.1237 40.9231 20.4036 39.9921 20.941C39.0611 21.4783 38.29 22.2538 37.7578 23.1878L18.4078 56.6955C17.8711 57.6254 17.5886 58.6801 17.5887 59.7538C17.5888 60.8274 17.8714 61.8821 18.4083 62.8119C18.9451 63.7417 19.7172 64.5138 20.647 65.0506C21.5768 65.5874 22.6315 65.87 23.7052 65.8701H62.4052C63.4788 65.87 64.5335 65.5874 65.4633 65.0506C66.3931 64.5138 67.1652 63.7417 67.7021 62.8119C68.2389 61.8821 68.5216 60.8274 68.5217 59.7538C68.5218 58.6801 68.2393 57.6254 67.7025 56.6955L48.3525 23.1878C47.8208 22.2545 47.0504 21.4795 46.1203 20.9422C45.1903 20.4048 44.1341 20.1245 43.06 20.13H43.0552Z"
        fill="url(#paint1_linear_20124_9100)"
      />
      <path
        d="M46.3971 34.3546C46.1451 38.7 45.8883 43.1111 45.6279 47.5269C45.5932 48.1349 45.6195 48.7536 45.5192 49.3508C45.4228 49.917 45.1288 50.4306 44.6895 50.8004C44.2501 51.1702 43.6938 51.3723 43.1196 51.3706C41.8964 51.3814 40.8322 50.5692 40.6948 49.3281C40.5037 47.6141 40.4452 45.8846 40.3365 44.1622C40.1311 40.8619 39.9495 37.5593 39.7226 34.2614C39.5553 31.8367 41.2944 29.9614 43.5197 30.223C44.2678 30.3157 44.9613 30.6631 45.4833 31.207C46.0054 31.7508 46.3243 32.4578 46.3864 33.2091C46.421 33.5639 46.3971 33.9222 46.3971 34.3546Z"
        fill="white"
      />
      <path
        d="M39.7751 56.9344C39.7916 56.0632 40.1482 55.233 40.7687 54.6212C41.3891 54.0094 42.2243 53.6646 43.0956 53.6604C44.9315 53.6604 46.3553 55.1535 46.3397 57.0526C46.33 57.9136 45.9816 58.7361 45.37 59.3421C44.7584 59.9481 43.9327 60.2889 43.0717 60.2908C42.6348 60.2872 42.2028 60.1975 41.8006 60.0268C41.3983 59.8561 41.0337 59.6078 40.7275 59.2961C40.4213 58.9843 40.1796 58.6153 40.0162 58.21C39.8527 57.8048 39.7708 57.3713 39.7751 56.9344Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_20124_9100"
          x1="43.0588"
          y1="20.0284"
          x2="43.0588"
          y2="65.9632"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FFA554" />
          <stop offset="1" stop-color="#E66F04" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_20124_9100"
          x1="43.0641"
          y1="19.9324"
          x2="43.0641"
          y2="66.0671"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FFA554" />
          <stop offset="1" stop-color="#E66F04" />
        </linearGradient>
      </defs>
    </svg>
  );

  if (String(statusCheck) === "2") {
    return (
      <TierTwoIndex step={3} stage={30}>
        <ReUsuableStatus
          text={`We tried verifying your Indenmity but we noticed some errors, kindly retry this verification to continue.`}
          title={`Indenmity Verification Failed`}
          imgBg={`rgba(255, 246, 237, 1)`}
          img={failedIcon}
        />
        <div
          style={{ minHeight: "unset", width: " 100%" }}
          className="form-inputs-wrap"
        >
          {" "}
          <RavenButton
            // type={`submit`}

            color="black-light"
            label={`Retry Verification`}
            style={{
              backgroundColor: "#ffffff",
              border: ".1rem solid #020202",
              color: "#020202",
              width: "100%",
            }}
            loading={loading}
            onClick={handleRetryFunction}
            className="btn-submit"
          />
        </div>
      </TierTwoIndex>
    );
  }

  if (String(statusCheck) === "5") {
    return (
      <TierTwoIndex step={3} stage={30}>
        <ReUsuableStatus
          text={`We tried verifying your Indenmity but we noticed some errors, kindly retry this verification to continue.`}
          title={`Indenmity Verification Revoked`}
          imgBg={`rgba(255, 246, 237, 1)`}
          img={failedIcon}
        />
        <div
          style={{ minHeight: "unset", width: " 100%" }}
          className="form-inputs-wrap"
        >
          {" "}
          <RavenButton
            // type={`submit`}

            color="black-light"
            label={`Retry Verification`}
            style={{
              backgroundColor: "#ffffff",
              border: ".1rem solid #020202",
              color: "#020202",
              width: "100%",
            }}
            loading={loading}
            onClick={handleRetryFunction}
            className="btn-submit"
          />
        </div>
      </TierTwoIndex>
    );
  }

  const successImg = (
    <svg
      className="img"
      width="95"
      height="107"
      viewBox="0 0 95 107"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_19484_33706)">
        <rect
          x="11.1499"
          y="9"
          width="77.7"
          height="119.7"
          rx="3.5"
          fill="white"
        />
        <rect
          x="8.1499"
          y="6"
          width="83.7"
          height="125.7"
          rx="6.5"
          stroke="#755AE2"
          strokeWidth="6"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M38.8524 45.5413C40.833 45.3832 42.7133 44.6044 44.2256 43.3156C47.7547 40.3081 52.9452 40.3081 56.4743 43.3156C57.9866 44.6044 59.8669 45.3832 61.8476 45.5413C66.4696 45.9101 70.1399 49.5804 70.5087 54.2024C70.6668 56.183 71.4456 58.0634 72.7344 59.5756C75.7418 63.1047 75.7418 68.2953 72.7344 71.8243C71.4456 73.3366 70.6668 75.2169 70.5087 77.1976C70.1399 81.8196 66.4696 85.4899 61.8476 85.8587C59.8669 86.0168 57.9866 86.7956 56.4743 88.0844C52.9452 91.0919 47.7547 91.0919 44.2256 88.0844C42.7133 86.7956 40.833 86.0168 38.8524 85.8587C34.2303 85.4899 30.5601 81.8196 30.1912 77.1976C30.0332 75.2169 29.2543 73.3366 27.9656 71.8243C24.9581 68.2953 24.9581 63.1047 27.9656 59.5756C29.2543 58.0634 30.0332 56.183 30.1912 54.2024C30.5601 49.5804 34.2303 45.9101 38.8524 45.5413ZM61.7678 61.7179C62.9707 60.5151 62.9707 58.5649 61.7678 57.3621C60.565 56.1593 58.6149 56.1593 57.4121 57.3621L47.27 67.5042L43.2878 63.5221C42.085 62.3193 40.1349 62.3193 38.9321 63.5221C37.7293 64.7249 37.7293 66.6751 38.9321 67.8779L45.0921 74.0379C46.2949 75.2407 48.245 75.2407 49.4478 74.0379L61.7678 61.7179Z"
          fill="url(#paint0_linear_19484_33706)"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_19484_33706"
          x="0.149902"
          y="0"
          width="94.7002"
          height="134.7"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-5" dy="-3" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.858824 0 0 0 0 0.839216 0 0 0 0 0.988235 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_19484_33706"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_19484_33706"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_19484_33706"
          x1="50.35"
          y1="41.06"
          x2="50.35"
          y2="90.34"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFA554" />
          <stop offset="1" stopColor="#E66F04" />
        </linearGradient>
      </defs>
    </svg>
  );

  if (String(statusCheck) === "3") {
    return (
      <TierTwoIndex step={3} stage={97}>
        <ReUsuableStatus
          text={`Thanks for taking your time to verify your Indenmity profile, we are confident about your details, and your limit has been increased successfully.`}
          title={`Congrats Indenmity Verified`}
          imgBg={`rgba(240, 238, 255, 1)`}
          img={successImg}
          bottomImg={true}
        />
        {String(business?.referees) !== "3" && (
          <div
            style={{ minHeight: "unset", marginTop: "1.5rem" }}
            className="form-inputs-wrap"
          >
            {" "}
            <RavenInputField
              type={`submit`}
              color="black-light"
              value={`Proceed`}
              onSubmit={() => {
                navigate("/dashboard-verification-invite-referee-new");
              }}
              className="btn-submit"
            />
          </div>
        )}
      </TierTwoIndex>
    );
  }

  return (
    <>
      <TierTwoIndex step={3} stage={90}>
        <div className="form-wrap">
          <div className="form-title-text">
            <p className="title">Sign Indemnity</p>
            <p className="text grey-white-color">
              Before signing scroll to read the whole document.
            </p>
          </div>

          {/* download-pdf- wrap box start */}
          <div
            // onClick={() => {
            //   console.log(business);
            // }}
            className="download-pdf-wrap-box grey-bg"
          >
            <div className="download-pdf-wrap card-bg">
              <div className="file-view-box">
                <div className="file-text-box">
                  <div className="file-icon">
                    <figure className="img-box">{fileIcon}</figure>
                  </div>
                  <p className="text border-theme-bottom">DOWNLOAD PDF</p>
                </div>
                {size.width > 900 && !loadingCac && indemnity_text && (
                  <div
                    onClick={() => {
                      setShowModal((prev) => {
                        return { ...prev, read: true };
                      });
                    }}
                    className="view-box"
                  >
                    <figure className="img-box">{viewIcon}</figure>
                  </div>
                )}
              </div>
              {loadingCac ? (
                <NoContentBox loading={true} />
              ) : (
                <div className="read-text-box">
                  <p className="text">{indemnity_text}</p>
                </div>
              )}
            </div>
          </div>
          {/* download-pdf- wrap box end */}
          {/* ---upload here */}
          {/* <img src={details?.signature} alt="" /> */}
          {details?.signature && (
            <RavenInputField
              label={` `}
              //   name="file-for-upload"
              className={
                details?.type === "sign" ? `border-theme-inner-signnature` : ""
              }
              customPreviewSrc={
                details?.type === "sign"
                  ? URL.createObjectURL(details?.signature)
                  : URL.createObjectURL(details?.signature)
              }
              type={`upload`}
              color={`black-light`}
              maxSize={5.1}
              onChange={(e) => {
                setDetails((prev) => {
                  return { ...prev, signature: e };
                });
              }}
              //   value={details?.signature}
              upploadText="Drop your file here or"
              uploadSpan=" browse"
              onRemoveFile={(e) => {
                setDetails((prev) => {
                  return { ...prev, signature: "" };
                });
              }}
              customPreviewName="Signature .Jpeg"
              customPreviewSize="30387"
              onSizeError={() => {
                toast.error("File size cannot exceed 5MB", {});
              }}
              //   id="file"
            />
          )}
          {/* uploaded end here */}
          {/* two btn box start */}
          {!details?.signature && (
            <div className="two-btn-box">
              <input
                onChange={(e) => {
                  setDetails((prev) => {
                    return { ...prev, signature: e.target.files[0] };
                  });
                }}
                accept="image/*"
                type="file"
                name="file-upload-signature"
                style={{ display: "none" }}
                id="file-upload-signature"
              
              />
              <label htmlFor="file-upload-signature">
                <div
                  onClick={() => {
                    setDetails((prev) => {
                      return { ...prev, type: "upload" };
                    });
                  }}
                  className="btn-upload border-theme"
                >
                  <p>Upload signature</p>
                </div>
              </label>

              <RavenButton
                className="btn border-theme"
                color="black-light"
                label="Sign here"
                onClick={() => {
                  setShowModal((prev) => {
                    return { ...prev, sign: true };
                  });
                  setDetails((prev) => {
                    return { ...prev, type: "sign" };
                  });
                }}
              />
            </div>
          )}
          {/* two btn box end */}
          {details?.signature && (
            <div className="btn-wrap">
              <RavenInputField
                type="submit"
                color={`black-light`}
                disabled={!details?.signature}
                value="Submit information"
                loading={loadingFetch}
                onSubmit={() => {
                  handleSubmit();
                  // console.log("jshjs");
                  // console.log(details);
                }}
              />
            </div>
          )}
        </div>
      </TierTwoIndex>
      <SignRavenModal
        visible={showModal?.sign}
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, sign: false };
          });
        }}
        onFinish={(param) => {
          setDetails((prev) => {
            return { ...prev, signature: param };
          });
          // console.log(param);
          setShowModal((prev) => {
            return { ...prev, sign: false };
          });
        }}
      />
      <BigContentViewModal
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, read: false };
          });
        }}
        visible={showModal?.read}
      />
    </>
  );
};

export default SignIdenmityFive;
