// import { RavenButton, RavenInputField } from "@ravenpay/raven-bank-ui";
import React, { useContext, useEffect, useState } from "react";
// import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import ProgressBar from "../../../../components/fragments/ProgreeBar";
import ActionContext from "../../../../context/ActionContext";
// import CryptoJS from "crypto-js";
import "../../../../styles/dashboard/verification/VerificationFiveModal.css";
// import { validateEmail } from "../../../utils/helper/ValidateInput";
import { useDarkMode } from "../../../../utils/themes/useDarkMode";
import { useLocation, useNavigate } from "react-router-dom";
import NoContentBox from "../../../../components/reusables/NoContentBox";
import { RavenToolTip } from "@ravenpay/raven-bank-ui";
import ReUsuableStatus from "../ReUsuableStatus";
// import { sumAllNum } from "../../../../utils/helper/Helper";

const ModalLayout = ({ stage, children }) => {
  const actionCtx = useContext(ActionContext);
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const [theme, toggleTheme] = useDarkMode(localStorage.getItem("theme"));
  // eslint-disable-next-line no-unused-vars
  const [step, setStep] = useState(1);
  // const dispatch = useDispatch();
  const location = useLocation();
  const { business, loadUser } = useSelector((state) => state.user);
  // const [popUp, setPopUp] = useState(false);
  //   const { loadingNotify, user, business } = useSelector((state) => state.user);
  const closeIcon = (
    <svg
      className="img"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="dull-bright-filter"
        d="M4.5 4.5L13.5 13.5M13.5 4.5L4.5 13.5"
        stroke="#020202"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const routeList = [
    {
      name: "Invite Referees",
      link: "/dashboard-verification-invite-referee-new",
    },
    // { name: "CAC Documents", link: "/dashboard-verification-nin-new" },
    {
      name: "Shareholder Informations",
      link: "/dashboard-verification-shareholder-new",
      disable:
        Number(business?.referees) !== 3 && Number(business?.referees) !== 1,
      disable_text: "Verify Referees",
    },
  ];

  useEffect(() => {
    setTimeout(() => {
      actionCtx?.setVerificationPopUp(true);
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //   const getPercentageFunc = () => {
  //      const total = sumAllNum([business?.bvn === 3 ? 30 : 0, ])
  //   }

  const bacImg = (
    <svg
      width="90"
      height="91"
      viewBox="0 0 90 91"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_20109_1926)">
        <path d="M21 67.36H69V19.36H21V67.36Z" fill="#676767" />
      </g>
      <defs>
        <filter
          id="filter0_d_20109_1926"
          x="0.299999"
          y="0.660107"
          width="89.4"
          height="89.4"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="10.35" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_20109_1926"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_20109_1926"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );

  useEffect(() => {
    if (String(business?.cac) === "7") {
      navigate("/dashboard-verification");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [business]);

  return (
    <div className="verification-five-modal-create-index-wrap">
      <div
        className={`content-wrap card-bg  ${
          actionCtx?.verificationPopUp && "content-wrap-no-animation"
        }  `}
      >
        <div className="top-wrap grey-bg">
          <div className="top-content">
            <div
              onClick={() => {
                navigate("/dashboard-verification");
                actionCtx?.setVerificationPopUp(false);
              }}
              className="close-wrap"
            >
              <figure className="img-box">{closeIcon}</figure>
              <p className="text">Close</p>
            </div>
            {/* step show start */}
            <div className="step-box">
              <span>STEP</span>
              <p>
                {step}/{routeList?.length || "3"}
              </p>
            </div>
            {/* step show send */}
          </div>
          {/* bar content start */}
          <div className="bar-wrap">
            {" "}
            <ProgressBar
              bgcolor={theme === "light" ? `#020202` : "rgba(117, 90, 226, 1)"}
              completed={stage || 30}
              //   baseColor={theme !== "light" && `#020202`}
            />
          </div>

          {/* bar content end */}
        </div>
        {/* bottom wrap start */}
        <div className="bottom-wrap">
          {Object.keys(business)?.length < 1 ? (
            <>
              {" "}
              <NoContentBox
                loading={true}
                // title={`No team Member`}
                // text="Add team member by clicking on the invite team member button above"
              />
            </>
          ) : (
            <div className="new-verification-layout-wrap-index">
              <div className="side-menu-box">
                {routeList?.map((chi, idx) => {
                  return (
                    <div
                      key={idx}
                      className={`item-row ${
                        location?.pathname === chi?.link &&
                        `item-row-active grey-bg`
                      } 
                
                `}
                      onClick={() => {
                        if (!chi?.disable) {
                          navigate(chi?.link);
                        }
                      }}
                    >
                      <div
                        className={`name  ${
                          location?.pathname !== chi?.link && "grey-white-color"
                        } ${chi?.disable && "tooltip-hover-wrap"}`}
                        style={{
                          cursor: chi?.disable ? "not-allowed" : "pointer",
                        }}
                      >
                        {chi?.disable && (
                          <>
                            <RavenToolTip
                              text={chi?.disable_text}
                              position={`right`}
                              color="black-light"
                            />
                          </>
                        )}
                        {chi?.name}
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="content-right-box">
                {loadUser ? (
                  <>
                    <NoContentBox loading={true} />
                  </>
                ) : (
                  <>
                    {" "}
                    {(String(business?.cac) !== "3" ||
                      String(business?.directors) !== "3" ||
                      String(business?.indemnity) !== "3") &&
                    Number(business?.cac) !== 1 ? (
                      <>
                        {" "}
                        <div className="centered-div">
                          {" "}
                          <ReUsuableStatus
                            img={bacImg}
                            text={`You cannot access this verification yet, until you complete your Tier 3 verifications, click the back button to continue verification`}
                            title={`Go Back`}
                            imgBg={`rgba(247, 247, 247, 1)`}
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          className={`centered-div ${
                            location?.pathname?.includes(
                              "/dashboard-verification-invite-referee-new"
                            ) && "centered-div-two"
                          }`}
                        >
                          {children}
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          )}
        </div>
        {/* bottom wrap end */}
      </div>
    </div>
  );
};

export default ModalLayout;
