import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ContainerWrapper from "../../../components/reusables/ContainerWrapper";
import NoContentBox from "../../../components/reusables/NoContentBox";
import TitletextBox from "../../../components/reusables/TitletextBox";
import ActionContext from "../../../context/ActionContext";
import DashboardLayout from "../../../layout/dashboard/DashboardLayout";
import {
  approvePaymentRequest,
  getSinglePaymentRequest,
} from "../../../redux/paymentRequest";
import "../../../styles/dashboard/pauyment-request/PayentRequestIndex.css";
import {
  filterEmptyColumns,
  // formatMetaData,
  // formatMetaData,
  formatNumWithCommaNairaSymbol,
} from "../../../utils/helper/Helper";
import NewPreviewDetailBox from "./NewPreviewDetailBox";
import TransactionPinModal from "../../../components/reusables/TransactionPinModal";
import BigPreviewModal from "../../../components/reusables/BigPreviewModal";

const ConfirmPaymentRequest = () => {
  const [detail, setDetail] = useState({});
  const actionCtx = useContext(ActionContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [accountObj, setAccountObj] = useState({});
  const [showModal, setShowModal] = useState({
    pin: false,
    success: false,
    view: false,
  });
  const [bigImg, setBigImg] = useState("");
  const [details, setDetails] = useState({
    transfer_pin: "",
    source_account: "",
  });
  const { loading, loadingStatement } = useSelector(
    (state) => state.payment_request
  );
  const { all_account } = useSelector((state) => state.user);
  useEffect(() => {
    const id = localStorage?.getItem("single-payment-request-id");
    if (id) {
      fetchSingleRequestDetail(id);
    } else {
      navigate("/dashboard-payment-requests");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchSingleRequestDetail = async (id) => {
    const obj = {
      id: id,
    };

    const data = await dispatch(getSinglePaymentRequest(obj));
    if (data?.payload?.data.status === "success") {
      const accountObj = all_account?.find(
        (chi) => chi?.id === data?.payload?.data?.data?.requestData.account_id
      );
      setAccountObj(accountObj);
      setDetail(data?.payload?.data?.data);
      // console.log(data?.payload?.data?.data);
    }
  };

  const handleSubmit = async (pin, token) => {
    const obj = {
      request_id:
        String(detail?.requestData?.id) ||
        String(localStorage?.getItem("single-payment-request-id")),
      account_number:
        details?.source_account?.account_number || accountObj?.account_number,
      transfer_pin: pin,
      two_factor_token: token,
    };
    // console.log(obj);
    // // console.log(detail);
    // return;
    const data = await dispatch(approvePaymentRequest(filterEmptyColumns(obj)));
    if (data?.payload?.data?.status === "success") {
      setShowModal((prev) => {
        return { ...prev, pin: false };
      });
      navigate("/dashboard-payment-requests");
    }
  };

  useEffect(() => {
    if (
      actionCtx?.currentAccount?.authorization_level !==
        ("initiator_approver" || "approver") &&
      actionCtx?.currentAccount?.authorization_level !==
        ("approver" || "initiator_approver")
    ) {
      navigate("/dashboard-payment-requests");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <DashboardLayout>
        {/* title box start */}
        <TitletextBox
          title={`Confirm Transaction`}
          text={`Confirm your transaction for this payment.`}
          back
          onBack={() => {
            // console.log(single_sub_account);
            navigate("/dashboard-payment-requests");
          }}
        />
        {/* title box end */}
        {/* container wrapper start */}
        <ContainerWrapper
          dontShowHeader={loading}
          btnText={!loading && `Complete Transfer`}
          onBtnClick={() => {
            setShowModal((prev) => {
              return { ...prev, pin: true };
            });
          }}
          loading={loadingStatement}
        >
          {loading ? (
            <>
              <NoContentBox
                title={`No Request found`}
                text={`Payment request will appear here when transaction is made`}
                bgColor
                loading={loading}
              />
            </>
          ) : (
            <div
              // onClick={() => {
              //   console.log(detail?.requestData);

              //   console.log(
              //     formatMetaData(detail?.requestData?.transaction_data)
              //   );
              // }}
              className="confirm-payment-request-index-wrap"
            >
              <NewPreviewDetailBox
                collapse
                amount={formatNumWithCommaNairaSymbol(
                  detail?.requestData?.amount
                )}
                bulkAmount={formatNumWithCommaNairaSymbol(
                  detail?.requestData?.amount
                )}
                narration={detail?.requestData?.narration}
                list={detail?.recepentDetails}
                accountObj={accountObj}
                detail={detail}
                onSourceSelect={(param) => {
                  setDetails((prev) => {
                    return { ...prev, source_account: param };
                  });
                }}
                sourceAccount={
                  details?.source_account || actionCtx?.currentAccount
                }
                onView={(param) => {
                  setBigImg(param);
                  setShowModal((prev) => {
                    return { ...prev, big_image: true };
                  });
                }}
              />
            </div>
          )}
        </ContainerWrapper>
        {/* container wrapper end */}
      </DashboardLayout>
      {/* pin modal start */}

      <TransactionPinModal
        pin={details?.transfer_pin}
        setPin={(e) => {
          setDetails((prev) => {
            return { ...prev, transfer_pin: e };
          });
        }}
        onPay={(pin, token) => {
          // console.log(pin, token);
          handleSubmit(pin, token);
        }}
        value={details?.transfer_pin}
        visible={showModal.pin}
        onCancel={() => {
          setDetails((prev) => {
            return { ...prev, transfer_pin: "" };
          });
          setShowModal((prev) => {
            return { ...prev, pin: false };
          });
        }}
        loading={loadingStatement}
      />
      {/* pin modal end */}
      {/* switch business account end */}
      <BigPreviewModal
        visible={showModal?.big_image}
        onClose={() => {
          setBigImg("");
          setShowModal((prev) => {
            return { ...prev, big_image: false };
          });
        }}
        url={bigImg}
      />
      {/* notification modal start */}
    </>
  );
};

export default ConfirmPaymentRequest;
