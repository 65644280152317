/* eslint-disable no-lone-blocks */
import React, { useState } from "react";
import "../../../styles/fragments/PreviewDetailsCommonBox.css";
// import bankIconDefault from "../../../assets/bank-general-img.svg";
import bankIconDefaultRav from "../../../assets/raven-default-bank-img.svg";
import bankIcon from "../../../assets/bank-general-img.svg";
import moment from "moment";
import mtnICon from "../../../assets/bill-mtn-icon.svg";
// import bankIconDefaultTwo from "../../../assets/raven-default-bank-img.svg";
import airtelICon from "../../../assets/bill-airtel-icon.svg";
// import mobileICon from "../../../assets/bill-moblie-icon.svg";
import gloICon from "../../../assets/bill-glo-icon.svg";
import {
  capitalizeAllWords,
  convertToLowercase,
  detectProvider,
  formatDate,
  formatNumWithCommaNairaSymbol,
  formatTypeFunction,
  trimLongString,
} from "../../../utils/helper/Helper";
import { RavenNumberFormat, RavenToolTip } from "@ravenpay/raven-bank-ui";
import ImageFragment from "../../../components/fragments/ImageFragment";
import NewAccountSelectDrop from "../../../components/account-drop/NewAccountSelectDrop";
import { useOnClickOutside } from "../../../utils/helper/UseOnClickOutside";
import DescriptionPreviewBox from "../../../components/initiator-des-box/DescriptionPreviewBox";
import { useSelector } from "react-redux";

const defaultImg = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="64"
    height="64"
    fill="none"
    viewBox="0 0 64 64"
    className="img"
  >
    <g filter="url(#filter0_dd_16136_90738)">
      <circle cx="32" cy="28" r="26" fill="#EEE"></circle>
      <circle cx="32" cy="28" r="24.5" stroke="#fff" strokeWidth="3"></circle>
    </g>
    <circle cx="50" cy="46" r="11" fill="#E8FFF6"></circle>
    <circle cx="50" cy="46" r="8" fill="#1ACE37"></circle>
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M46.667 46h6.666m0 0l-2.5-2.5m2.5 2.5l-2.5 2.5"
    ></path>
    <path
      fill="#020202"
      d="M40.578 25.713c0-.49-.234-.95-.63-1.239l-7.098-5.179a1.534 1.534 0 00-1.785-.017l-7.412 5.198c-.41.287-.653.756-.653 1.256 0 .34.276.616.616.616h16.327c.35 0 .634-.284.634-.635zM23 34.662v.804c0 .847.687 1.534 1.535 1.534h14.509c.847 0 1.534-.687 1.534-1.534v-.804H23z"
    ></path>
    <path
      fill="#EA872D"
      d="M32.134 26.947h-.689a.924.924 0 00-.924.923v5.239c0 .51.414.924.924.924h.689c.51 0 .923-.414.923-.924V27.87a.924.924 0 00-.923-.924zM37.173 26.947h-.688a.924.924 0 00-.924.923v5.239c0 .51.414.924.924.924h.688c.51 0 .924-.414.924-.924V27.87a.924.924 0 00-.924-.924zM27.094 26.947h-.688a.924.924 0 00-.924.923v5.239c0 .51.413.924.924.924h.688c.51 0 .924-.414.924-.924V27.87a.924.924 0 00-.924-.924z"
    ></path>
    <defs>
      <filter
        id="filter0_dd_16136_90738"
        width="64"
        height="64"
        x="0"
        y="0"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feMorphology
          in="SourceAlpha"
          radius="2"
          result="effect1_dropShadow_16136_90738"
        ></feMorphology>
        <feOffset dy="2"></feOffset>
        <feGaussianBlur stdDeviation="2"></feGaussianBlur>
        <feComposite in2="hardAlpha" operator="out"></feComposite>
        <feColorMatrix values="0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0.06 0"></feColorMatrix>
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_16136_90738"
        ></feBlend>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feMorphology
          in="SourceAlpha"
          radius="2"
          result="effect2_dropShadow_16136_90738"
        ></feMorphology>
        <feOffset dy="4"></feOffset>
        <feGaussianBlur stdDeviation="4"></feGaussianBlur>
        <feComposite in2="hardAlpha" operator="out"></feComposite>
        <feColorMatrix values="0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0.1 0"></feColorMatrix>
        <feBlend
          in2="effect1_dropShadow_16136_90738"
          result="effect2_dropShadow_16136_90738"
        ></feBlend>
        <feBlend
          in="SourceGraphic"
          in2="effect2_dropShadow_16136_90738"
          result="shape"
        ></feBlend>
      </filter>
    </defs>
  </svg>
);

// import { useDispatch } from "react-redux";
// import { getBeneficiaries } from "../../../../redux/settings";
// import { useSelector } from "react-redux";

// const restructureName = (name) => {
//     let val;
//     const nameSplit = name?.split(" ");
//     if (nameSplit?.length > 2) {
//       const newVal = `${nameSplit[0]} ${nameSplit[1]}  ...`;
//       val = newVal;
//       // eslint-disable-next-line no-unused-expressions
//     } else {
//       val = name;
//     }
//     return val;
//   };

const NewPreviewDetailBox = ({
  collapse,
  amount,
  narration,
  totalFee,
  onChangeSchedule,
  valueSchedule,
  enableTime,
  onReset,
  details,
  bulkAmount,
  // narration,
  accountObj,
  detail,
  list,
  sourceAccount,
  onSourceSelect,
  onView,
}) => {
  const [viewAll, setViewAll] = useState(false);
  // const { user } = useSelector((state) => state.user);
  // const actionCtx = useContext(ActionContext);
  const { payment_requests } = useSelector((state) => state.payment_request);
  const testingPreviewRef = useOnClickOutside(() => {
    setViewAll(false);
  });
  const formatMetaData = (param) => {
    if (param) {
      const newParam = JSON.parse(param);
      // console.log(newParam);
      return newParam;
    }
  };
  // console.log(list);

  function isObject(value) {
    return typeof value === "object" && value !== null;
  }

  const formatList = (list) => {
    if (list?.length > 0) {
      const newList = list?.map(
        (chi) =>
          `${
            payment_requests?.description_image_url ||
            detail?.requestData?.description_image_url ||
            ""
          }${chi || ""}`
      );
      // console.log(newList);

      return newList;
    }
  };

  return (
    <div
      onClick={() => {
        // console.log(detail);
        // console.log(formatMetaData(detail?.requestData?description_image_url.transaction_data));
        // console.log(
        //   isObject(formatMetaData(detail?.requestData?.transaction_data))
        // );
      }}
      className="new-preview-detail-box-wrap card-bg border-theme"
    >
      {/* img top box start */}
      <div className="img-top-box-wrap border-theme-bottom">
        <div
          style={{ borderRadius: "50%", borderWidth: ".15rem" }}
          className="img-wrap"
        >
          <ImageFragment
            url={
              `https://businessapi.getraventest.com/static/media/banks/${
                isObject(formatMetaData(detail?.requestData?.transaction_data))
                  ? formatMetaData(detail?.requestData?.transaction_data)
                      ?.bank_code
                  : list?.length < 2
                  ? list[0]?.bank_code
                  : detail?.bank_code || detail?.bank?.value
              }.png ` ||
              `https://personalbanking.getraventest.com/static/media/mobile/${convertToLowercase(
                detail?.network
              )}.png`
            }
            className={` border-theme`}
          >
            {" "}
            <figure
              style={{ width: "100%", height: "100%", marginBottom: "-1.5rem" }}
              className="img-box"
            >
              {defaultImg}
              {/* <img src={bankIconDefaultTwo} alt="" className="img" /> */}
            </figure>
          </ImageFragment>
        </div>
        {/* amount date box styart */}
        <div className="amount-date-box">
          <p className="amount">
            {list?.length > 0 ? bulkAmount : amount || ""}
          </p>
          <p className="date grey-white-color-white">
            {valueSchedule
              ? formatDate(valueSchedule)
              : formatDate(moment().format("YYYY-MM-DD HH:mm:ss"))}
          </p>
        </div>
        {/* amount date box end */}
      </div>
      {/* img top box end */}
      {/* middle content wrap start */}
      <div className="middle-content-wrap border-theme-bottom">
        {detail?.requestData?.request_type && (
          <>
            {" "}
            <div className="box">
              <p className="label grey-white-color-white">Request Type:</p>
              <p style={{ textTransform: "capitalize" }} className="value">
                {formatTypeFunction(detail?.requestData?.request_type) || ""}
              </p>
            </div>
          </>
        )}
        {list?.length > 1 ||
        detail?.requestData?.request_type === "bulk_internal_transfer" ||
        formatMetaData(detail?.requestData?.transaction_data)?.length > 0 ? (
          <>
            {/* box start */}
            <div style={{ position: "relative", zIndex: 95 }} className="box">
              <p className="label grey-white-color-white">Total Recipients:</p>
              <div className="value">
                <div
                  onClick={() => {
                    viewAll ? setViewAll(false) : setViewAll(true);
                  }}
                  className="see-all-box"
                  ref={testingPreviewRef}
                >
                  <span>
                    (
                    {list?.length ||
                      formatMetaData(detail?.requestData?.transaction_data)
                        ?.length ||
                      0}
                    )
                  </span>
                  <p className="see-all-text">
                    {viewAll ? `Close` : `See all`}
                  </p>
                </div>
                {/*  */}
                <div
                  className={`view-all-box card-bg ${
                    viewAll && "view-all-box-show"
                  }`}
                >
                  {" "}
                  <div className="wrap">
                    {list?.length > 0 && (
                      <>
                        {" "}
                        {list?.map((chi, idx) => {
                          return (
                            <div key={idx} className="child-wrap">
                              <ImageFragment
                                url={
                                  `https://businessapi.getraventest.com/static/media/banks/${
                                    chi?.bank_code ||
                                    chi?.bank?.value ||
                                    formatMetaData(chi?.meta_data)?.bank_code
                                  }.png ` ||
                                  `https://personalbanking.getraventest.com/static/media/mobile/${convertToLowercase(
                                    chi?.network ||
                                      formatMetaData(chi?.meta_data)?.network
                                  )}.png`
                                }
                                className={`border-theme`}
                                styles={{ borderRadius: "50%" }}
                              >
                                <figure className="img-box">
                                  <img
                                    src={
                                      chi?.network === "mtn"
                                        ? mtnICon
                                        : chi?.network === "glo"
                                        ? gloICon
                                        : chi?.network === "airtel"
                                        ? airtelICon
                                        : chi?.identifier
                                        ? bankIconDefaultRav
                                        : bankIcon
                                    }
                                    alt=""
                                    className="img"
                                  />
                                </figure>
                              </ImageFragment>

                              <div className="text-box">
                                <p className="name">
                                  {`${
                                    trimLongString(
                                      chi?.account_name ||
                                        chi?.phone ||
                                        formatMetaData(chi?.meta_data)
                                          ?.account_name ||
                                        `@${chi?.identifier}`,
                                      12
                                    ) || "----"
                                  } • ${formatNumWithCommaNairaSymbol(
                                    chi?.amount
                                  )}`}
                                </p>
                                <p className="text grey-white-color">{`${trimLongString(
                                  chi?.bank?.label ||
                                    chi?.bank ||
                                    formatMetaData(chi?.meta_data)?.bank ||
                                    formatMetaData(chi?.meta_data)?.code ||
                                    "---",
                                  17
                                )} • ${
                                  chi?.identifier
                                    ? ""
                                    : trimLongString(
                                        chi?.account_number ||
                                          formatMetaData(chi?.meta_data)
                                            ?.account_number ||
                                          "--- ",
                                        11
                                      )
                                }`}</p>
                              </div>
                              {/* <div
                            onClick={() => {
                              handleRemoveFromList(chi);
                            }}
                            className="cancel-box"
                          >
                            <FaTimes className="icon" />
                          </div> */}
                            </div>
                          );
                        })}
                      </>
                    )}
                    {formatMetaData(detail?.requestData?.transaction_data)
                      ?.length > 0 && (
                      <>
                        {formatMetaData(
                          detail?.requestData?.transaction_data
                        )?.map((chi, idx) => {
                          return (
                            <div key={idx} className="child-wrap">
                              <ImageFragment
                                url={`https://personalbanking.getraventest.com/static/media/mobile/${detectProvider(
                                  chi?.phone
                                )}.png`}
                                className={`border-theme`}
                                styles={{ borderRadius: "50%" }}
                              >
                                <figure className="img-box">
                                  <img
                                    src={
                                      chi?.network === "mtn"
                                        ? mtnICon
                                        : chi?.network === "glo"
                                        ? gloICon
                                        : chi?.network === "airtel"
                                        ? airtelICon
                                        : chi?.identifier
                                        ? bankIconDefaultRav
                                        : bankIcon
                                    }
                                    alt=""
                                    className="img"
                                  />
                                </figure>
                              </ImageFragment>

                              <div className="text-box">
                                <p className="name">
                                  {`${
                                    trimLongString(
                                      chi?.account_name ||
                                        chi?.phone ||
                                        formatMetaData(chi?.meta_data)
                                          ?.account_name ||
                                        `@${chi?.identifier}`,
                                      12
                                    ) || "----"
                                  } • ${formatNumWithCommaNairaSymbol(
                                    chi?.amount
                                  )}`}
                                </p>
                                <p className="text grey-white-color">{`${trimLongString(
                                  chi?.bank?.label ||
                                    chi?.bank ||
                                    formatMetaData(chi?.meta_data)?.bank ||
                                    formatMetaData(chi?.meta_data)?.code ||
                                    capitalizeAllWords(
                                      detectProvider(chi?.phone)
                                    ) ||
                                    "Raven Bank" ||
                                    "",
                                  17
                                )} • ${
                                  chi?.identifier
                                    ? ""
                                    : trimLongString(
                                        chi?.account_number ||
                                          formatMetaData(chi?.meta_data)
                                            ?.account_number ||
                                          " ",
                                        11
                                      )
                                }`}</p>
                              </div>
                              {/* <div
        onClick={() => {
          handleRemoveFromList(chi);
        }}
        className="cancel-box"
      >
        <FaTimes className="icon" />
      </div> */}
                            </div>
                          );
                        })}
                      </>
                    )}
                  </div>
                </div>

                {/*  */}
              </div>
            </div>
            {/* box end */}
            {/* box start */}
            <div className="box">
              <p className="label grey-white-color-white">Total Fee:</p>
              <div className="value">
                {RavenNumberFormat(detail?.requestData?.fee) || totalFee || 0}
              </div>
            </div>
            {/* box end */}
            {/* box start */}
            {/* box start */}
            {/* <div className="box">
              <p className="label grey-white-color-white">Narration:</p>
              <div
                className={`value ${
                  narration?.length > 14 && "tooltip-hover-wrap"
                }`}
              >
                <RavenToolTip
                  position={`left`}
                  text={narration}
                  color="black-light"
                />
                {trimLongString(
                  narration ||
                    formatMetaData(detail?.requestData?.transaction_data)[0]
                      ?.narration,
                  20
                ) || ""}
              </div>
            </div> */}
            {/* box end */}
            {/* box end */}
          </>
        ) : (
          <>
            {list?.length > 0 && list[0]?.account_name && (
              <div className="box">
                <p className="label grey-white-color-white">Account Name:</p>
                <p className="value">
                  {list?.length > 0 ? list[0]?.account_name : ""}
                </p>
              </div>
            )}
            {/* box start */}
            {list?.length > 0 && list[0]?.account_number && (
              <div className="box">
                <p className="label grey-white-color-white">Account Number:</p>
                <p className="value">
                  {list?.length > 0
                    ? formatMetaData(list[0]?.meta_data)?.account_number ||
                      list[0]?.account_number
                    : ""}
                </p>
              </div>
            )}

            {list?.length > 0 &&
              formatMetaData(list[0]?.meta_data)?.account_number && (
                <div className="box">
                  <p className="label grey-white-color-white">
                    Account Number:
                  </p>
                  <p className="value">
                    {list?.length > 0
                      ? formatMetaData(list[0]?.meta_data)?.account_number ||
                        list[0]?.account_number
                      : ""}
                  </p>
                </div>
              )}

            {/* box end */}
            {/* box start */}
            {list?.length > 0 && formatMetaData(list[0]?.meta_data)?.bank && (
              <div className="box">
                <p className="label grey-white-color-white">Bank Name:</p>
                <p className="value">
                  {list?.length > 0
                    ? formatMetaData(list[0]?.meta_data)?.bank || list[0]?.bank
                    : trimLongString(
                        formatMetaData(detail?.requestData?.transaction_data)
                          ?.bank,
                        25
                      ) || ""}
                </p>
              </div>
            )}
            {/* box end */}
            {isObject(formatMetaData(detail?.requestData?.transaction_data)) ? (
              <>
                {formatMetaData(detail?.requestData?.transaction_data)
                  ?.account_number && (
                  <div className="box">
                    <p className="label grey-white-color-white">
                      Account Number:
                    </p>
                    <p className="value">
                      {formatMetaData(detail?.requestData?.transaction_data)
                        ?.account_number || ""}
                    </p>
                  </div>
                )}
                {formatMetaData(detail?.requestData?.transaction_data)
                  ?.phone && (
                  <div className="box">
                    <p className="label grey-white-color-white">
                      Reciepient Number:
                    </p>
                    <p className="value">
                      {formatMetaData(detail?.requestData?.transaction_data)
                        ?.phone || ""}
                    </p>
                  </div>
                )}
                {formatMetaData(detail?.requestData?.transaction_data)
                  ?.network && (
                  <div className="box">
                    <p className="label grey-white-color-white">
                      Reciepient Number:
                    </p>
                    <p className="value">
                      {capitalizeAllWords(
                        formatMetaData(detail?.requestData?.transaction_data)
                          ?.network
                      ) || ""}
                    </p>
                  </div>
                )}
                {formatMetaData(detail?.requestData?.transaction_data)
                  ?.account_name && (
                  <div className="box">
                    <p className="label grey-white-color-white">
                      Account Name:
                    </p>
                    <p className="value">
                      {trimLongString(
                        formatMetaData(detail?.requestData?.transaction_data)
                          ?.account_name,
                        25
                      ) || ""}
                    </p>
                  </div>
                )}
                {formatMetaData(detail?.requestData?.transaction_data)
                  ?.narration ? (
                  <>
                    {" "}
                    <div className="box">
                      <p className="label grey-white-color-white">
                        Trx Narration:
                      </p>
                      <p className="value">
                        {trimLongString(
                          formatMetaData(detail?.requestData?.transaction_data)
                            ?.narration,
                          25
                        ) || ""}
                      </p>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}
            {/* end of one transfer */}
            {list?.length > 0 && list[0]?.identifier && (
              <>
                {" "}
                <div className="box">
                  <p className="label grey-white-color-white">
                    Raven Username:
                  </p>
                  <p className="value">
                    {list?.length > 0 ? `@${list[0]?.identifier}` : ""}
                  </p>
                </div>
                {/* <div className="box">
                  <p className="label grey-white-color-white">Account Name:</p>
                  <p className="value">
                    {list?.length > 0 ? list[0]?.account_name : ""}
                  </p>
                </div> */}
              </>
            )}
            {/* box start */}

            {/* box end */}
            {/* box start */}
            {/* <div className="box">
              <p className="label grey-white-color-white">Account Name:</p>
              <p className="value">{account_name || ""}</p>
            </div> */}
            {/* box end */}
            {/* box start */}
            <div className="box">
              <p className="label grey-white-color-white">Total Fee:</p>
              <div className="value">
                {" "}
                {RavenNumberFormat(detail?.requestData?.fee) || totalFee || 0}
              </div>
            </div>
            {/* box end */}

            {/* <div className="box">
              <p className="label grey-white-color-white">
                Initiator Description:
              </p>
              <div
                className={`value ${
                  narration?.length > 14 && "tooltip-hover-wrap"
                }`}
              >
                <RavenToolTip
                  position={`left`}
                  text={narration}
                  color="black-light"
                />
                {trimLongString(narration, 15) || ""}
              </div>
            </div> */}
            {/* box end */}
          </>
        )}
        {detail?.requestData?.intiator_fname ? (
          <>
            <div className="box">
              <p className="label grey-white-color-white">Initiator Name:</p>
              <p className="value">
                {`${detail?.requestData?.intiator_fname || ""} ${
                  detail?.requestData?.intiator_lname || ""
                }`}
              </p>
            </div>
          </>
        ) : (
          <></>
        )}
        {/* box start */}
        {narration ? (
          <div className="box">
            <p className="label grey-white-color-white">
              Initiator Description:
            </p>
            <div
              className={`value ${
                narration?.length > 14 && "tooltip-hover-wrap"
              }`}
            >
              <RavenToolTip
                position={`left`}
                text={narration}
                color="black-light"
              />
              {trimLongString(narration, 15) || ""}
            </div>
          </div>
        ) : (
          ""
        )}
        {/* box end */}

        {/* box start */}
        <div style={{ position: "relative", zIndex: 80 }} className="box">
          <p className="label grey-white-color">Source Account:</p>
          <div className="value">
            <div className="select-toggle-box">
              <NewAccountSelectDrop
                position={`bottom`}
                selected={sourceAccount}
                onSelect={onSourceSelect}
              />
            </div>
          </div>
        </div>
        {/* box end */}
      </div>
      {/* middle content wrap end */}
      {/* add and preview images box start */}
      {formatList(formatMetaData(detail?.requestData?.description_file_name))
        ?.length > 0 && (
        <DescriptionPreviewBox
          hideLabel={true}
          hideAdd={true}
          imgList={formatList(
            formatMetaData(detail?.requestData?.description_file_name)
          )}
          hideRemove={true}
          onView={(param) => {
            onView && onView(param);
          }}
          normalUrl={true}
        />
      )}
      {/* add and preview images box end */}
      {/* bottom- content wrap start */}
      <div className="bottom-content-wrap">
        {/* <NewSelectScheduleBox
          id={`onudjhahabafw`}
          value={valueSchedule}
          textSchedule="Schedule this transaction"
          onChange={(e) => {
            onChangeSchedule(e);
          }}
          onReset={onReset}
        /> */}
        {/* bottom box start */}
        {/* <div className="bottom-box">
          <p className="text grey-white-color">
            {collapse
              ? `Save all as beneficiary`
              : `Save recipient as beneficiary`}
          </p>
          <RavenToggleSlide
            onChange={onChange}
            id={id}
            value={value}
            color={`purple-light`}
            checked={checked}
            //   className={`toggle-box-wrap`}
          />
        </div> */}
        {/* bottom box end */}
        {/* account drop wrap start */}
        {/* <ChangeSourceAccount
          darkType={true}
          defaultObj={accountObj}
          dontShowChange
        /> */}
        {/* account drop wrap end */}
        {/* template start ------------------ */}
      </div>
      {/* bottom- content wrap end */}
      {/* account drop wrap start */}

      {/* account drop wrap end */}
    </div>
  );
};

export default NewPreviewDetailBox;

{
  /* <div className="account-change-drop-wrap border-theme-top">
<p className="title grey-white-color">Source Account.</p>
<div className="change-wrap grey-bg-two">
  <div className="change-name-info">
    <div className="name-icon">
      <p className="name">{actionCtx?.currentAccount?.name}</p>
      <figure className="img-box">{InfoIcon}</figure>
    </div>
    <p className="label grey-white-color">N80,320.00</p>
  </div>
  <div
    onClick={() => {
      actionCtx.setCurrentAccountDrop(!actionCtx.currentAccountDrop);
    }}
    className="change-text"
  >
    <p className="text">Change</p>
  </div>
</div>
</div> */
}
