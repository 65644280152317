import React from "react";
import "./ReUsuableStatus.css";
import ImageFragment from "../../../components/fragments/ImageFragment";

const ReUsuableStatus = ({
  img,
  title,
  text,
  imgTwo,
  bottomImg,
  imgBg,
  imgSrc,
}) => {
  return (
    <div className="verification-five-status-resuable-box-wrap">
      <div className="img-big-box">
        <ImageFragment
          className={`img-box  grey-bg-two ${
            bottomImg && "img-box-bottom"
          } grey-bg ${imgBg ? imgBg : ""}`}
          url={imgSrc}
        >
          <figure
            style={{ backgroundColor: imgBg ? imgBg : "" }}
            className={`img-box  grey-bg-two ${bottomImg && "img-box-bottom"}`}
          >
            {imgTwo ? <img src={imgTwo} alt="" className="img" /> : img}
          </figure>
        </ImageFragment>
      </div>
      <p className="title">{title}</p>
      {text && (
        <>
          {text?.split("\n")?.map((chi, idx) => {
            return (
              <p key={idx} className="text grey-white-color">
                {chi}
              </p>
            );
          })}
        </>
      )}
    </div>
  );
};

export default ReUsuableStatus;
