import React, { useState } from "react";
import NewSelectScheduleBox from "../../../../components/reusables/NewSelectScheduleBox";
// import SelectScheduleBox from "../../../../components/reusables/SelectScheduleBox";Group 238182raven-default-bank-img.svg
import "../../../../styles/fragments/PreviewDetailsCommonBox.css";
// import bankIconDefault from "../../../../assets/bank-general-img.svg"
import bankIconDefault from "../../../../assets/raven-default-bank-img.svg";
import { RavenInputField, RavenToggleSlide } from "@ravenpay/raven-bank-ui";
import moment from "moment";
import {
  formatDate,
  formatNumWithCommaNairaSymbol,
  trimLongString,
} from "../../../../utils/helper/Helper";
import { FaArrowRight, FaTimes } from "react-icons/fa";
import ChangeSourceAccount from "../../../../components/reusables/ChangeSourceAccount";
import NewAccountSelectDrop from "../../../../components/account-drop/NewAccountSelectDrop";
import { useOnClickOutside } from "../../../../utils/helper/UseOnClickOutside";

const NewPreviewDetailBoxAuto = ({
  collapse,
  amount,
  name,
  bank,
  account_name,
  narration,
  onChange,
  value,
  id,
  checked,
  onChangeSchedule,
  valueSchedule,
  onReset,
  details,
  bulkAmount,
  setDetails,
  handleRemoveFromList,
  handleChange,
  transferFee,
  onViewAll,
sourceAccount,
onSourceSelect,
  list,
}) => {
  const [viewAll, setViewAll] = useState(false);
  const testingPreviewRef = useOnClickOutside(() => {
    setViewAll(false);
  });
  return (
    <div className="new-preview-detail-box-wrap card-bg border-theme">
      {/* img top box start */}
      <div className="img-top-box-wrap border-theme-bottom">
        <div className="img-wrap">
          <figure className="img-box">
            <img src={bankIconDefault} alt="" className="img" />
          </figure>
          <div className="arrow-wrap grey-bg">
            <div className="arrow-box">
              <FaArrowRight className="icon" />
            </div>
          </div>
        </div>
        {/* amount date box styart */}
        <div className="amount-date-box">
          <p className="amount">
            {list?.length > 0 ? bulkAmount : amount || ""}
          </p>
          <p className="date grey-white-color-white">
            {valueSchedule
              ? formatDate(valueSchedule)
              : formatDate(moment().format("YYYY-MM-DD HH:mm:ss"))}
          </p>
        </div>
        {/* amount date box end */}
      </div>
      {/* img top box end */}
      {/* middle content wrap start */}
      <div className="middle-content-wrap border-theme-bottom">
        {collapse ? (
          <>
            {/* box start */}
            <div className="box">
              <p className="label grey-white-color-white">Total Recipients:</p>
              <div ref={testingPreviewRef} className="value">
                <div
                  onClick={() => {
                    viewAll ? setViewAll(false) : setViewAll(true);
                  }}
                  className="see-all-box"
                >
                  <span>({list?.length || 0})</span>
                  <p className="see-all-text">
                    {viewAll ? `Close` : `See all`}
                  </p>
                </div>
                {/*  */}
                <div
                  className={`view-all-box card-bg ${
                    viewAll && "view-all-box-show"
                  }`}
                >
                  {" "}
                  <div className="wrap">
                    {list?.map((chi, idx) => {
                      return (
                        <div key={idx} className="child-wrap">
                          <figure className="img-box">
                            <img src={bankIconDefault} alt="" className="img" />
                          </figure>
                          <div className="text-box">
                            <p className="name">
                              @{trimLongString(chi?.raven_username, 12)}
                            </p>
                            <p className="text grey-white-color">{`${
                              trimLongString(
                                chi?.meta_data?.account_name,
                                15
                              ) || "---"
                            } • ${formatNumWithCommaNairaSymbol(
                              chi?.amount
                            )}`}</p>
                          </div>
                          <div
                            onClick={() => {
                              handleRemoveFromList(chi);
                            }}
                            className="cancel-box"
                          >
                            <FaTimes className="icon" />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>

                {/*  */}
              </div>
            </div>
            {/* box end */}
            {/* box start */}
            <div className="box">
              <p className="label grey-white-color-white">Total Fee:</p>
              <div className="value">0</div>
            </div>
            {/* box end */}
            {/* box start */}
            <div style={{ position: "relative", zIndex: 80 }} className="box">
              <p className="label grey-white-color">Source Account:</p>
              <div className="value">
                <div className="select-toggle-box">
                  <NewAccountSelectDrop
                    position={`bottom`}
                    selected={sourceAccount}
                    onSelect={onSourceSelect}
                  />
                </div>
              </div>
            </div>
            {/* box end */}
          </>
        ) : (
          <>
            {/* box start */}
            <div className="box">
              <p className="label grey-white-color-white">To:</p>
              <p className="value">@{name || ""}</p>
            </div>
            {/* box end */}
            {/* box start */}
            <div className="box">
              <p className="label grey-white-color-white">Account Name:</p>
              <p className="value">{account_name || ""}</p>
            </div>
            {/* box end */}
            {/* box start */}
            <div className="box">
              <p className="label grey-white-color-white">Total Fee:</p>
              <div className="value">0</div>
            </div>
            {/* box end */}
            {/* box start */}
            <div className="box">
              <p className="label grey-white-color-white">Narration:</p>
              <p className="value">{narration || ""}</p>
            </div>
            {/* box end */}
          </>
        )}
      </div>
      {/* middle content wrap end */}
      {/* bottom- content wrap start */}
      <div className="bottom-content-wrap">
        <NewSelectScheduleBox
          id={`onudjhahabafw`}
          value={valueSchedule}
          textSchedule="Schedule this transaction"
          onChange={(e) => {
            onChangeSchedule(e);
          }}
          onReset={onReset}
        />
        {/* bottom box start */}
        <div className="bottom-box">
          <p className="text grey-white-color">
            {collapse
              ? `Save all as beneficiary`
              : `Save recipient as beneficiary`}
          </p>
          <RavenToggleSlide
            onChange={onChange}
            id={id}
            value={value}
            color={`purple-light`}
            checked={checked}
            //   className={`toggle-box-wrap`}
          />
        </div>
        {/* bottom box end */}

        {/* template start ------------------ */}
        {collapse && (
          <>
            {/* .save bulk option box start */}
            <div className="save-bulk-template-box">
              <div
                style={{ marginBottom: !details?.save_template && "-1rem" }}
                className="toggle-select-text-box"
              >
                <p className="text grey-white-color">
                  Save bulk list for later
                </p>
                <RavenToggleSlide
                  value={details?.save_template}
                  id={`jywfvsb`}
                  onChange={(e) => {
                    setDetails((prev) => {
                      return {
                        ...prev,
                        save_template: details?.save_template ? false : true,
                        template_title:
                          e.target?.checked && details?.template_title
                            ? details?.template_title
                            : "",
                      };
                    });
                  }}
                  checked={details?.save_template}
                  color={`purple-light`}
                />
              </div>
              {/* template title name start */}
              <div
                className={`template-title-box ${
                  details?.save_template && "template-title-box-show"
                }`}
              >
                <RavenInputField
                  color="black-light"
                  type={`text`}
                  label={`Bulk List Title`}
                  name={`template_title`}
                  onChange={(e) => {
                    setDetails((prev) => {
                      return { ...prev, template_title: e.target.value };
                    });
                  }}
                  value={details?.template_title}
                  placeholder={`E.g Salary bonus template.`}
                />
              </div>
              {/* template title name end */}
            </div>
            {/* select schedule start */}
          </>
        )}
        {/* template end ----------------- */}
        {collapse && (
          <RavenInputField
            type={`text`}
            color={`black-light`}
            label={`Add a narration`}
            value={details?.narration}
            onChange={handleChange}
            placeholder={`E.g From timi`}
            name={`narration`}
          />
        )}
      </div>
      {/* bottom- content wrap end */}
      {/* source acount change box */}
      <ChangeSourceAccount />
      {/* source account change box end */}
    </div>
  );
};

export default NewPreviewDetailBoxAuto;
