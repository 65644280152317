import React, { useState } from "react";
// import TierOneIndex from "../../tier-one/TierOneIndex";
import TierThreeIndex from "../TierThreeIndex";
import { RavenButton,} from "@ravenpay/raven-bank-ui";
import BadgeComponent from "../../../../../components/fragments/badge/BadgeComponent";
import { formatMetaData, hasTimePassed, returnInitial } from "../../../../../utils/helper/Helper";
import successImgCac from "../../../../../assets/cac-verified-success.png";
import pendingImgCac from "../../../../../assets/cac-pending-img.png";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getShareholders } from "../../../../../redux/settings";
import ViewShareHolderModal from "./DirectorModal";
import TierOneIndex from "../../tier-one/TierOneIndex";
import ReUsuableStatus from "../../ReUsuableStatus";
import { useNavigate } from "react-router-dom";
import { changeVerificationStatus } from "../../../../../redux/verifications";
import { getUser } from "../../../../../redux/user";

const ShareHolderVerifyFive = () => {
  // const actionCtx = useContext(ActionContext);
  const { business } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const { loading } = useSelector((state) => state.verifications);
  // eslint-disable-next-line no-unused-vars
  const [step, setStep] = useState(1);
  const dispatch = useDispatch();
  const { share_holders } = useSelector((state) => state.settings);
  // const [details, setDetails] = useState({
  //   rc_number: "",
  //   option: "",
  //   cac_doc: "",
  //   report_doc: "",
  // });
  const [singleChild, setSingleChild] = useState({});
  const [showModal, setShowModal] = useState({
    holders: false,
  });

  const arrowIcon = (
    <svg
      className="img"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="dull-bright-filter"
        d="M6 12L10 8L6 4"
        stroke="#292929"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  useEffect(() => {
    if (share_holders?.length < 1) {
      dispatch(getShareholders());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [statusCheck, setStatusCheck] = useState(0);
  useEffect(() => {
    if (Object.keys(business).length > 0) {
      setStatusCheck(Number(business?.shareholders));
    }
    if(Number(business?.referees) !== 3  && Number(business?.cac) !== 1){
      navigate("/dashboard-verification")
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [business]);

  const handleRetryFunction = async () => {
    const obj = {
      type: "shareholders",
    };
    const data = await dispatch(changeVerificationStatus(obj));
    if (data?.payload?.data?.status === "success") {
      dispatch(getUser());
    }
  };

  const pendImg = (
    <svg
      className="img"
      width="90"
      height="91"
      viewBox="0 0 90 91"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_19492_680)">
        <path d="M21 67.36H69V19.36H21V67.36Z" fill="#676767" />
      </g>
      <defs>
        <filter
          id="filter0_d_19492_680"
          x="0.299999"
          y="0.659985"
          width="89.4"
          height="89.4"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="10.35" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_19492_680"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_19492_680"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );

  if (String(statusCheck) === "1") {
    return (
      <TierOneIndex stage={70}>
        <ReUsuableStatus
          img={pendImg}
          text={`Thanks for taking your time to verify your Shareholders profile, we are confident about your details, and your limit has been increased successfully.`}
          title={`Shareholders Verification Pending`}
          imgBg={`rgba(247, 247, 247, 1)`}
          imgSrc={pendingImgCac}
        />
        {hasTimePassed(
          formatMetaData(business?.verification_time)?.shareholders,
          30
        ) && (
          <div
            style={{ minHeight: "unset", width: " 100%", cursor: "pointer" }}
            className="form-inputs-wrap"
          >
            {" "}
            <RavenButton
              // type={`submit`}

              color="black-light"
              label={`Retry Verification`}
              style={{
                backgroundColor: "#ffffff",
                border: ".1rem solid #020202",
                color: "#020202",
                width: "100%",
                cursor: "pointer",
              }}
              loading={loading}
              onClick={handleRetryFunction}
              className="btn-submit"
            />
          </div>
        )}
      </TierOneIndex>
    );
  }

  const failedIcon = (
    <svg
      className="img"
      width="86"
      height="86"
      viewBox="0 0 86 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M37.678 23.1388L18.328 56.6465C15.9391 60.7901 18.9252 65.9632 23.7113 65.9632H62.4054C67.1903 65.9632 70.18 60.7841 67.7887 56.6465L48.4387 23.1388C46.0498 18.9917 40.0704 18.9917 37.678 23.1388Z"
        fill="url(#paint0_linear_20124_9100)"
      />
      <path
        d="M62.4052 66.0671H23.7147C22.6066 66.0671 21.518 65.7753 20.5583 65.2213C19.5986 64.6672 18.8016 63.8703 18.2475 62.9106C17.6933 61.951 17.4015 60.8624 17.4014 59.7542C17.4012 58.6461 17.6927 57.5574 18.2466 56.5976L37.5966 23.0898C38.1505 22.1299 38.9475 21.3327 39.9072 20.7784C40.867 20.2242 41.9558 19.9324 43.0641 19.9324C44.1725 19.9324 45.2613 20.2242 46.221 20.7784C47.1808 21.3327 47.9778 22.1299 48.5317 23.0898L67.8817 56.5976C68.4356 57.5574 68.7271 58.6461 68.7269 59.7542C68.7268 60.8624 68.4349 61.951 67.8808 62.9106C67.3266 63.8703 66.5297 64.6672 65.57 65.2213C64.6103 65.7753 63.5217 66.0671 62.4135 66.0671H62.4052ZM43.0552 20.13C41.9803 20.1237 40.9231 20.4036 39.9921 20.941C39.0611 21.4783 38.29 22.2538 37.7578 23.1878L18.4078 56.6955C17.8711 57.6254 17.5886 58.6801 17.5887 59.7538C17.5888 60.8274 17.8714 61.8821 18.4083 62.8119C18.9451 63.7417 19.7172 64.5138 20.647 65.0506C21.5768 65.5874 22.6315 65.87 23.7052 65.8701H62.4052C63.4788 65.87 64.5335 65.5874 65.4633 65.0506C66.3931 64.5138 67.1652 63.7417 67.7021 62.8119C68.2389 61.8821 68.5216 60.8274 68.5217 59.7538C68.5218 58.6801 68.2393 57.6254 67.7025 56.6955L48.3525 23.1878C47.8208 22.2545 47.0504 21.4795 46.1203 20.9422C45.1903 20.4048 44.1341 20.1245 43.06 20.13H43.0552Z"
        fill="url(#paint1_linear_20124_9100)"
      />
      <path
        d="M46.3971 34.3546C46.1451 38.7 45.8883 43.1111 45.6279 47.5269C45.5932 48.1349 45.6195 48.7536 45.5192 49.3508C45.4228 49.917 45.1288 50.4306 44.6895 50.8004C44.2501 51.1702 43.6938 51.3723 43.1196 51.3706C41.8964 51.3814 40.8322 50.5692 40.6948 49.3281C40.5037 47.6141 40.4452 45.8846 40.3365 44.1622C40.1311 40.8619 39.9495 37.5593 39.7226 34.2614C39.5553 31.8367 41.2944 29.9614 43.5197 30.223C44.2678 30.3157 44.9613 30.6631 45.4833 31.207C46.0054 31.7508 46.3243 32.4578 46.3864 33.2091C46.421 33.5639 46.3971 33.9222 46.3971 34.3546Z"
        fill="white"
      />
      <path
        d="M39.7751 56.9344C39.7916 56.0632 40.1482 55.233 40.7687 54.6212C41.3891 54.0094 42.2243 53.6646 43.0956 53.6604C44.9315 53.6604 46.3553 55.1535 46.3397 57.0526C46.33 57.9136 45.9816 58.7361 45.37 59.3421C44.7584 59.9481 43.9327 60.2889 43.0717 60.2908C42.6348 60.2872 42.2028 60.1975 41.8006 60.0268C41.3983 59.8561 41.0337 59.6078 40.7275 59.2961C40.4213 58.9843 40.1796 58.6153 40.0162 58.21C39.8527 57.8048 39.7708 57.3713 39.7751 56.9344Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_20124_9100"
          x1="43.0588"
          y1="20.0284"
          x2="43.0588"
          y2="65.9632"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FFA554" />
          <stop offset="1" stop-color="#E66F04" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_20124_9100"
          x1="43.0641"
          y1="19.9324"
          x2="43.0641"
          y2="66.0671"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FFA554" />
          <stop offset="1" stop-color="#E66F04" />
        </linearGradient>
      </defs>
    </svg>
  );

  if (String(statusCheck) === "2") {
    return (
      <TierOneIndex stage={30}>
        <ReUsuableStatus
          text={`We tried verifying your Shareholders but we noticed some errors, kindly retry this verification to continue.`}
          title={`Shareholders Verification Failed`}
          imgBg={`rgba(255, 246, 237, 1)`}
          img={failedIcon}
        />
        <div
          style={{ minHeight: "unset", width: " 100%" }}
          className="form-inputs-wrap"
        >
          {" "}
          <RavenButton
            // type={`submit`}

            color="black-light"
            label={`Retry Verification`}
            style={{
              backgroundColor: "#ffffff",
              border: ".1rem solid #020202",
              color: "#020202",
              width: "100%",
            }}
            loading={loading}
            onClick={handleRetryFunction}
            className="btn-submit"
          />
        </div>
      </TierOneIndex>
    );
  }

  const successImg = (
    <svg
      className="img"
      width="95"
      height="107"
      viewBox="0 0 95 107"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_19484_33706)">
        <rect
          x="11.1499"
          y="9"
          width="77.7"
          height="119.7"
          rx="3.5"
          fill="white"
        />
        <rect
          x="8.1499"
          y="6"
          width="83.7"
          height="125.7"
          rx="6.5"
          stroke="#755AE2"
          strokeWidth="6"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M38.8524 45.5413C40.833 45.3832 42.7133 44.6044 44.2256 43.3156C47.7547 40.3081 52.9452 40.3081 56.4743 43.3156C57.9866 44.6044 59.8669 45.3832 61.8476 45.5413C66.4696 45.9101 70.1399 49.5804 70.5087 54.2024C70.6668 56.183 71.4456 58.0634 72.7344 59.5756C75.7418 63.1047 75.7418 68.2953 72.7344 71.8243C71.4456 73.3366 70.6668 75.2169 70.5087 77.1976C70.1399 81.8196 66.4696 85.4899 61.8476 85.8587C59.8669 86.0168 57.9866 86.7956 56.4743 88.0844C52.9452 91.0919 47.7547 91.0919 44.2256 88.0844C42.7133 86.7956 40.833 86.0168 38.8524 85.8587C34.2303 85.4899 30.5601 81.8196 30.1912 77.1976C30.0332 75.2169 29.2543 73.3366 27.9656 71.8243C24.9581 68.2953 24.9581 63.1047 27.9656 59.5756C29.2543 58.0634 30.0332 56.183 30.1912 54.2024C30.5601 49.5804 34.2303 45.9101 38.8524 45.5413ZM61.7678 61.7179C62.9707 60.5151 62.9707 58.5649 61.7678 57.3621C60.565 56.1593 58.6149 56.1593 57.4121 57.3621L47.27 67.5042L43.2878 63.5221C42.085 62.3193 40.1349 62.3193 38.9321 63.5221C37.7293 64.7249 37.7293 66.6751 38.9321 67.8779L45.0921 74.0379C46.2949 75.2407 48.245 75.2407 49.4478 74.0379L61.7678 61.7179Z"
          fill="url(#paint0_linear_19484_33706)"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_19484_33706"
          x="0.149902"
          y="0"
          width="94.7002"
          height="134.7"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-5" dy="-3" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.858824 0 0 0 0 0.839216 0 0 0 0 0.988235 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_19484_33706"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_19484_33706"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_19484_33706"
          x1="50.35"
          y1="41.06"
          x2="50.35"
          y2="90.34"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFA554" />
          <stop offset="1" stopColor="#E66F04" />
        </linearGradient>
      </defs>
    </svg>
  );

  if (String(statusCheck) === "3") {
    return (
      <TierOneIndex stage={80}>
        <ReUsuableStatus
          text={`Thanks for taking your time to verify your Shareholders profile, we are confident about your details, and your limit has been increased successfully.`}
          title={`Congrats Shareholders Verified`}
          imgBg={`rgba(240, 238, 255, 1)`}
          img={successImg}
          bottomImg={true}
          imgSrc={successImgCac}
        />
        {/* {String(business?.address !== "3") && (
          <div
            style={{ minHeight: "unset", marginTop: "1.5rem" }}
            className="form-inputs-wrap"
          >
            {" "}
            <RavenInputField
              type={`submit`}
              color="black-light"
              value={`Proceed`}
              onSubmit={() => {
                navigate("/dashboard-verification-address-new");
              }}
              className="btn-submit"
            />
          </div>
        )} */}
      </TierOneIndex>
    );
  }

  if (String(statusCheck) === "5") {
    return (
      <TierOneIndex stage={30}>
        <ReUsuableStatus
          text={`We tried verifying your Shareholders but we noticed some errors, kindly retry this verification to continue.`}
          title={`Shareholders Verification Revoked`}
          imgBg={`rgba(255, 246, 237, 1)`}
          img={failedIcon}
        />
        <div
          style={{ minHeight: "unset", width: " 100%" }}
          className="form-inputs-wrap"
        >
          {" "}
          <RavenButton
            // type={`submit`}

            color="black-light"
            label={`Retry Verification`}
            style={{
              backgroundColor: "#ffffff",
              border: ".1rem solid #020202",
              color: "#020202",
              width: "100%",
            }}
            loading={loading}
            onClick={handleRetryFunction}
            className="btn-submit"
          />
        </div>
      </TierOneIndex>
    );
  }

  // shareholder_revoke_or_decline_reason

  return (
    <>
      <TierThreeIndex stage={50}>
        <div className="form-wrap">
          <div className="form-title-text">
            <p className="title">Shareholders Verification</p>
            <p className="text grey-white-color">
              You need to verify atleast two of your shareholders
            </p>
          </div>

          {/* setp one start ------------- */}
          {step === 1 && (
            <>
              <div
                onClick={() => {
                  // console.log(share_holders);
                }}
                className="form-inputs-wrap"
              >
                {/* directors list start */}
                {share_holders?.length > 0 && (
                  <div className="directors-list-box border-theme ">
                    {share_holders?.map((chi, idx) => {
                      const { id, name, status } = chi;
                      return (
                        <div
                          className={`director-item-row ${
                            idx !== share_holders?.length - 1 &&
                            "border-theme-bottom"
                          }`}
                          key={idx || id}
                          onClick={() => {
                            setSingleChild(chi);
                            setShowModal((prev) => {
                              return { ...prev, holders: true };
                            });
                          }}
                        >
                          <div className="avatar-box">
                            <span>{returnInitial(name)}</span>
                          </div>
                          <p className="name">{name}</p>
                          <div className="badge-box">
                            <BadgeComponent
                              className={
                                status === 0
                                  ? "grey-bg black-light-color"
                                  : status === 1
                                  ? "pending"
                                  : status === 2
                                  ? "failed"
                                  : "success"
                              }
                              text={
                                status === 0
                                  ? ""
                                  : status === 1
                                  ? "pending"
                                  : status === 2
                                  ? "failed"
                                  : "Verified"
                              }
                            >
                              {status === 0 && "Start"}
                            </BadgeComponent>
                          </div>
                          <div className="arrow-icon">
                            <figure className="img-box">{arrowIcon}</figure>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
                {/* directors list end */}
              </div>
            </>
          )}
          {/* step two end --------------- */}
        </div>
      </TierThreeIndex>
      <ViewShareHolderModal
        onClose={() => {
          setSingleChild({});
          setShowModal((prev) => {
            return { ...prev, holders: false };
          });
        }}
        detail={singleChild}
        visible={showModal?.holders}
      />
    </>
  );
};

export default ShareHolderVerifyFive;
