import React, { useContext, useEffect, useState } from "react";
import DashboardSettingsLayout from "./SettingsLayout";
import { RavenToggleSlide } from "@ravenpay/raven-bank-ui";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
// import UseNotificationSound from "../../../../components/reusables/newSoundReusable";
import { FaAngleRight } from "react-icons/fa";
import ActionContext from "../../../../context/ActionContext";
import {
  enableTwoFaFactorSettings,
  //   notificationCreditSettings,
} from "../../../../redux/settings";
// import { getUser } from "../../../../redux/user";
// import ConfirmTwoFaModal from "../modal/ConfirmTwoFaModal";
import DisableTwoFaModal from "../modal/Disable2faFactorModal";
import ResetTransactionPin from "../modal/ResetTransactionPin";
import ConfirmTwoFAModalTwo from "../modal/ConfirmTwoFAModalTwo";

const DashboardSettingsSecurity = () => {
  const actionCtx = useContext(ActionContext);
  const { user } = useSelector((state) => state.user);
  //   const [emailStatus, setEmailStatus] = useState(0);
  const [enableDetails, setEnableDetails] = useState({});
  //   const [creditStatus, setCreditStatus] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [notificationSound, setNotificationSound] = useState("");
  //   const { loadingEnable } = useSelector((state) => state.settings);
  const [showModal, setShowModal] = useState({
    enable: false,
    disable: false,
    reset_pin: false,
  });
  //   const [disableSms, setDisableSms] = useState(false);
  //   const [appearanceObj, setAppearanceObj] = useState({});
  const [enable2Fa, setEnable2Fa] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (Object.keys(user)?.length > 0) {
      //   console.log(user?.two_factor);
      if (String(user?.two_factor) === "0") {
        setEnable2Fa(false);
      } else {
        setEnable2Fa(true);
      }
      // console.log(business?.email_notification_control);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  //   useEffect(() => {
  //     Number(user?.credit_alert_notification_status) === 1
  //       ? setCreditStatus(1)
  //       : setCreditStatus(0);
  //   }, [user]);

  // useEffect(() => {}, [])

  const handleEnableTwoFaFactor = async (param) => {
    const data = await dispatch(enableTwoFaFactorSettings());
    if (data?.payload?.data?.status === "success") {
      const obj = data?.payload?.data?.data;
      //   console.log(obj);
      setEnableDetails(obj);
    }
  };

  const resetIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="55"
      height="54"
      fill="none"
      viewBox="0 0 55 54"
      className="img"
    >
      <g filter="url(#filter0_dd_17708_2713)">
        <ellipse
          cx="27.219"
          cy="23.437"
          fill="#fff"
          rx="21.431"
          ry="20.796"
        ></ellipse>
        <path
          stroke="#F0EEFF"
          strokeWidth="0.924"
          d="M48.188 23.437c0 11.217-9.375 20.334-20.969 20.334-11.594 0-20.968-9.117-20.968-20.334 0-11.217 9.375-20.334 20.968-20.334 11.594 0 20.97 9.117 20.97 20.334z"
        ></path>
      </g>
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.386"
        d="M27.696 23.9h.004m3.964 0h.004m-7.941 0h.004m-1.433-3.852h10.795c.89 0 1.334 0 1.673.168.299.148.542.383.694.673.173.33.173.761.173 1.624v2.773c0 .862 0 1.294-.173 1.623-.152.29-.395.526-.694.674-.34.167-.784.167-1.673.167H22.298c-.889 0-1.333 0-1.673-.168a1.565 1.565 0 01-.694-.673c-.173-.33-.173-.76-.173-1.623v-2.773c0-.863 0-1.294.173-1.624.152-.29.395-.525.694-.673.34-.168.784-.168 1.673-.168zm5.596 3.851a.196.196 0 01-.198.193.196.196 0 01-.199-.193c0-.106.09-.192.199-.192.11 0 .198.086.198.192zm3.969 0a.196.196 0 01-.199.193.196.196 0 01-.198-.193c0-.106.089-.192.198-.192.11 0 .199.086.199.192zm-7.938 0a.196.196 0 01-.198.193.196.196 0 01-.198-.193c0-.106.088-.192.198-.192.11 0 .198.086.198.192z"
      ></path>
      <defs>
        <filter
          id="filter0_dd_17708_2713"
          width="53.953"
          height="52.684"
          x="0.243"
          y="0.792"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feMorphology
            in="SourceAlpha"
            radius="1.849"
            result="effect1_dropShadow_17708_2713"
          ></feMorphology>
          <feOffset dy="1.849"></feOffset>
          <feGaussianBlur stdDeviation="1.849"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0.06 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_17708_2713"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feMorphology
            in="SourceAlpha"
            radius="1.849"
            result="effect2_dropShadow_17708_2713"
          ></feMorphology>
          <feOffset dy="3.697"></feOffset>
          <feGaussianBlur stdDeviation="3.697"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0.1 0"></feColorMatrix>
          <feBlend
            in2="effect1_dropShadow_17708_2713"
            result="effect2_dropShadow_17708_2713"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect2_dropShadow_17708_2713"
            result="shape"
          ></feBlend>
        </filter>
      </defs>
    </svg>
  );

  const changeIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="55"
      height="53"
      fill="none"
      viewBox="0 0 55 53"
      className="img"
    >
      <g filter="url(#filter0_dd_20206_11114)">
        <ellipse
          cx="27.219"
          cy="22.907"
          fill="#fff"
          rx="21.431"
          ry="20.796"
        ></ellipse>
        <path
          stroke="#F0EEFF"
          strokeWidth="0.924"
          d="M48.188 22.907c0 11.217-9.375 20.334-20.969 20.334-11.594 0-20.968-9.117-20.968-20.334 0-11.218 9.375-20.335 20.968-20.335 11.594 0 20.97 9.117 20.97 20.335z"
        ></path>
      </g>
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.386"
        d="M31.664 22.598v-2.31c0-2.127-1.777-3.852-3.968-3.852-2.192 0-3.97 1.725-3.97 3.852v2.31m1.43 7.703h5.08c1.333 0 2 0 2.51-.252a2.347 2.347 0 001.04-1.01c.26-.494.26-1.141.26-2.436v-.308c0-1.294 0-1.94-.26-2.435a2.347 2.347 0 00-1.04-1.01c-.51-.252-1.177-.252-2.51-.252h-5.08c-1.334 0-2 0-2.51.252a2.347 2.347 0 00-1.04 1.01c-.26.494-.26 1.141-.26 2.435v.308c0 1.295 0 1.942.26 2.436.227.435.592.788 1.04 1.01.51.252 1.176.252 2.51.252z"
      ></path>
      <defs>
        <filter
          id="filter0_dd_20206_11114"
          width="53.953"
          height="52.684"
          x="0.243"
          y="0.261"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feMorphology
            in="SourceAlpha"
            radius="1.849"
            result="effect1_dropShadow_20206_11114"
          ></feMorphology>
          <feOffset dy="1.849"></feOffset>
          <feGaussianBlur stdDeviation="1.849"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0.06 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_20206_11114"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feMorphology
            in="SourceAlpha"
            radius="1.849"
            result="effect2_dropShadow_20206_11114"
          ></feMorphology>
          <feOffset dy="3.697"></feOffset>
          <feGaussianBlur stdDeviation="3.697"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0.1 0"></feColorMatrix>
          <feBlend
            in2="effect1_dropShadow_20206_11114"
            result="effect2_dropShadow_20206_11114"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect2_dropShadow_20206_11114"
            result="shape"
          ></feBlend>
        </filter>
      </defs>
    </svg>
  );
  return (
    <>
      <DashboardSettingsLayout>
        <div className="detail-wrap animate-move-up-class">
          <p className="title">Security</p>
          <p className="sub-title grey-white-color">
            All your security changes in one place.
          </p>
          <div style={{ cursor: "pointer" }} className="box-wrap grey-bg">
            {/* Set && change transaction pin start */}
            <div
              onClick={() => {
                if (user?.pin_status === 1) {
                  actionCtx?.setShowUpdate(true);
                } else {
                  actionCtx?.setTrxPin(true);
                }
                console.log(user);
                // actionCtx?.setShowUpdate(true);
                // actionCtx?.setShowProfileDrop("close");
              }}
              style={{ cursor: "pointer" }}
              className="noti-wrap"
            >
              <figure className="img-box">{changeIcon}</figure>
              <div className="text-box">
                <p className="value">
                  {user?.pin_status === 1
                    ? `Change Transaction Pin`
                    : `Set Transaction Pin`}
                </p>
                <p className="label grey-white-color">
                  Enter a new transaction PIN for your usage.
                  {/* {user?.pin_status !== 1
                    ? `Set a new transaction PIN for your usage.`
                    : ` Enter a new transaction PIN for your usage.`} */}
                </p>
              </div>
              {/* right arrow styart */}
              <div className="right-arrow">
                <FaAngleRight className="icon black-white-color" />
              </div>
              {/* right arrow end */}
            </div>
            {/* set / change transaction Pin */}
            {/* reset trnsaction pin start ------------------- */}
            {user?.pin_status === 1 && (
              <div className="noti-wrap">
                <figure className="img-box">{resetIcon}</figure>
                <div
                  onClick={() => {
                    setShowModal((prev) => {
                      return { ...prev, reset_pin: true };
                    });
                  }}
                  className="text-box"
                >
                  <p className="value">Reset Transaction PIN</p>
                  <p className="label grey-white-color">
                    Enter a new transaction PIN for your usage.
                  </p>
                </div>
                {/* right arrow styart */}
                <div className="right-arrow">
                  <FaAngleRight className="icon black-white-color" />
                </div>
                {/* right arrow end */}
              </div>
            )}
            {/* reset transaction pin end ----------------------- */}
            {/* startr here ------ */}

            {/* sound type end --------- */}
            <div className="noti-wrap">
              <figure className="img-box">{changeIcon}</figure>
              <div className="text-box">
                <p className="value">2FA Authentication</p>
                <p className="label grey-white-color">
                  Setup your 2FA security check for safer onboarding
                </p>
              </div>
              <RavenToggleSlide
                className="sms-notify-toggle"
                // style={{cursor: "default"}}
                onChange={(param) => {
                  if (String(user?.two_factor) !== "0") {
                    // setEnable2Fa(false);
                    setShowModal((prev) => {
                      return { ...prev, disable: true };
                    });
                  } else {
                    // setEnable2Fa(true);
                    handleEnableTwoFaFactor();
                    setShowModal((prev) => {
                      return { ...prev, enable: true };
                    });
                  }
                }}
                // style={{cursor: "pointer"}}
                value={enable2Fa}
                checked={enable2Fa}
                id="ioh8"
                color="black-light"
              />
            </div>
            {/* {enable2Fa &&  <div className="noti-wrap">
              <figure className="img-box">{changeIcon}</figure>
              <div className="text-box">
                <p className="value">Use 2Fa for transaction</p>
                <p className="label grey-white-color">
                  Setup your 2FA security check for safer onboarding
                </p>
              </div>
              <RavenToggleSlide
                className="sms-notify-toggle"
                // style={{cursor: "default"}}
                onChange={(param) => {
                  if (String(user?.two_factor) !== "0") {
                    // setEnable2Fa(false);
                    setShowModal((prev) => {
                      return { ...prev, disable: true };
                    });
                  } else {
                    // setEnable2Fa(true);
                    handleEnableTwoFaFactor();
                    setShowModal((prev) => {
                      return { ...prev, enable: true };
                    });
                  }
                }}
                // style={{cursor: "pointer"}}
                value={enable2Fa}
                checked={enable2Fa}
                id="ioh8"
                color="black-light"
              />
            </div>} */}
          </div>
        </div>
      </DashboardSettingsLayout>
      {/* <ConfirmTwoFaModal
        detail={enableDetails}
        visible={showModal?.enable}
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, enable: false };
          });
        }}
        onFinish={() => {
          setShowModal((prev) => {
            return { ...prev, enable: false };
          });
        }}
      /> */}
      <ConfirmTwoFAModalTwo
        detail={enableDetails}
        visible={showModal?.enable}
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, enable: false };
          });
        }}
        onFinish={() => {
          setShowModal((prev) => {
            return { ...prev, enable: false };
          });
        }}
      />
      <DisableTwoFaModal
        detail={enableDetails}
        visible={showModal?.disable}
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, disable: false };
          });
        }}
        onFinish={() => {
          setShowModal((prev) => {
            return { ...prev, disable: false };
          });
        }}
      />
      <ResetTransactionPin
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, reset_pin: false };
          });
        }}
        visible={showModal?.reset_pin}
      />
    </>
  );
};

export default DashboardSettingsSecurity;
