// import DashboardPosIndex from "../../../pages/dashboard/pos";
import DashboardPosIndexNew from "../../../pages/dashboard/pos/NewPosIndex";
import NewRequestTerminal from "../../../pages/dashboard/pos/NewRequestTerminal";
import NewTerminalInfomation from "../../../pages/dashboard/pos/NewTerminalInfomation";
import PosRequestTerminal from "../../../pages/dashboard/pos/PosRequestTerminal";
import PosTerminalInformationSingle from "../../../pages/dashboard/pos/PosSingleTransactionPage";
// import PosTerminalInformation from "../../../pages/dashboard/pos/PosTerminalInformation";
import SettleBalancePos from "../../../pages/dashboard/pos/SettleBalancePos";
import WithdrawPosPage from "../../../pages/dashboard/pos/WithdrawPosPage";


export const dashboard_pos_route_group = [
    {path: "/dashboard-pos", element: <DashboardPosIndexNew />},
    {path: "/dashboard-pos-management-request-pos", element: <PosRequestTerminal />},
    {path: "/dashboard-pos-management-request-pos-new", element: <NewRequestTerminal />},
    {path: "/dashboard-pos-management-settle-balance", element: <SettleBalancePos />},
    {path: "/dashboard-pos-management-withdraw", element: <WithdrawPosPage />},
    {path: "/dashboard-pos-terminal-information", element: <NewTerminalInfomation />},
    {path: "/dashboard-pos-terminal-information-single", element: <PosTerminalInformationSingle />},
]