import { RavenModal } from "@ravenpay/raven-bank-ui";
import React, { useContext } from "react";
import ActionContext from "../../context/ActionContext";
import ImageFragment from "../fragments/ImageFragment";

const BigPreviewModal = ({ visible, onClose, detail, url }) => {
  const actionCtx = useContext(ActionContext);
  return (
    <>
      <RavenModal
        className={`raven-big-content-view-text-box-modal raven-big-content-view-text-box-modal-img-preview`}
        visble={visible}
        onClose={onClose}
      >
        {url ? (
          <>
            <ImageFragment
              key={`vbnm,m`}
              url={url}
              loadSize={700}
              className={`raven-big-content-view-text-box raven-big-content-view-text-box-two`}
            >
              <div
                style={{
                  backgroundImage: url
                    ? `url(${String(url)})`
                    : `url(${actionCtx?.imgPreviewUrl})`,
                }}
                className="raven-big-content-view-text-box raven-big-content-view-text-box-two"
              ></div>
            </ImageFragment>
          </>
        ) : (
          <div
            style={{
              backgroundImage: url
                ? `url(${String(url)})`
                : `url(${actionCtx?.imgPreviewUrl})`,
            }}
            className="raven-big-content-view-text-box "
          ></div>
        )}
      </RavenModal>
    </>
  );
};

export default BigPreviewModal;
