import React from "react";
import VerificationIndexOneFive from "../VerificationIndexOneFive";
import ModalLayout from "./ModalLayout";
import { useSelector } from "react-redux";
// import AdressVerificationFive from "./adress/AdressVerificationFive";

const TierOneAddressIndex = ({ children, stage }) => {
  const { business } = useSelector((state) => state.user);
  // const [step, setStep] = useState(1);
  return (
    <>
      <VerificationIndexOneFive />

      <ModalLayout stage={String(business?.address) === "3" ? 100 : stage}>
        {children}
      </ModalLayout>
    </>
  );
};

export default TierOneAddressIndex;
