import React, {  useState } from "react";
import NewSelectScheduleBox from "../../../../components/reusables/NewSelectScheduleBox";
// import SelectScheduleBox from "../../../../components/reusables/SelectScheduleBox";Group 238182raven-default-bank-img.svg
import "../../../../styles/fragments/PreviewDetailsCommonBox.css";
import mtnICon from "../../../../assets/bill-mtn-icon.svg";
import airtelICon from "../../../../assets/bill-airtel-icon.svg";
import mobileICon from "../../../../assets/bill-moblie-icon.svg";
import gloICon from "../../../../assets/bill-glo-icon.svg";
// import showmaxICon from "../../../../assets/showmax-icon.svg";
// import dstvICon from "../../../../assets/dstv-icon.svg";
// import gotvICon from "../../../../assets/gotv-icon.svg";
// import startimeICon from "../../../../assets/startime-icon.svg";
import moment from "moment";
import {
  formatDate,
  formatNumWithCommaNairaSymbol,
  formatNumWithoutCommaNaira,
  //   trimLongString,
} from "../../../../utils/helper/Helper";
import { FaTimes } from "react-icons/fa";
// import ActionContext from "../../../../context/ActionContext";
// import ChangeSourceAccount from "../../../../components/reusables/ChangeSourceAccount";
import NewAccountSelectDrop from "../../../../components/account-drop/NewAccountSelectDrop";
import { useOnClickOutside } from "../../../../utils/helper/UseOnClickOutside";

const NewPreviewDetailBox = ({
  collapse,
  amount,
  name,
  bank,
  account_name,
  narration,
  onChange,
  value,
  id,
  checked,
  previewOpen,
  setPreviewOpen,
  onChangeSchedule,
  valueSchedule,
  enableTime,
  onReset,
  details,
  bulkAmount,
  setDetails,
  handleRemoveFromList,
  handleChange,
  list,
  provider,
  number,
  phone,
  sourceAccount,
  onSourceSelect,
}) => {
  const [viewAll, setViewAll] = useState(false);
  // const actionCtx = useContext(ActionContext);
  const testingPreviewRef = useOnClickOutside(() => {
    setViewAll(false);
  });
  // const InfoIcon = (
  //   <svg
  //     className="img"
  //     width="12"
  //     height="12"
  //     viewBox="0 0 12 12"
  //     fill="none"
  //     xmlns="http://www.w3.org/2000/svg"
  //   >
  //     <path
  //       d="M6 8V6M6 4H6.005M11 6C11 8.76142 8.76142 11 6 11C3.23858 11 1 8.76142 1 6C1 3.23858 3.23858 1 6 1C8.76142 1 11 3.23858 11 6Z"
  //       stroke="#8B8B8B"
  //       strokeWidth="1.5"
  //       stroke-linecap="round"
  //       stroke-linejoin="round"
  //     />
  //   </svg>
  // );

  return (
    <div className="new-preview-detail-box-wrap card-bg border-theme">
      {/* img top box start */}
      <div className="img-top-box-wrap border-theme-bottom">
        <div className="img-wrap">
          {collapse ? (
            <div className="img-collapse">
              {list?.some((chi) => chi?.provider === "mtn") && (
                <figure className="img-box">
                  <img src={mtnICon} alt="" className="img" />
                </figure>
              )}
              {list?.some((chi) => chi?.provider === "glo") && (
                <figure className="img-box">
                  <img src={gloICon} alt="" className="img" />
                </figure>
              )}
              {list?.some((chi) => chi?.provider === "airtel") && (
                <figure className="img-box">
                  <img src={airtelICon} alt="" className="img" />
                </figure>
              )}
              {list?.some((chi) => chi?.provider === "mobile") && (
                <figure className="img-box">
                  <img src={mobileICon} alt="" className="img" />
                </figure>
              )}
            </div>
          ) : (
            <></>
            // <figure style={{backgroundColor: "white"}} className="img-box">
            //   <img
            //     src={
            //         provider === "dstv"
            //         ? dstvICon
            //         : provider === "gotv"
            //         ? gotvICon
            //         : provider === "consat"
            //         ? showmaxICon
            //         : startimeICon
            //     }
            //     alt=""
            //     className="img"
            //   />
            // </figure>
          )}
          {/* <div className="arrow-wrap grey-bg">
            <div className="arrow-box">
              <FaArrowRight className="icon" />
            </div>
          </div> */}
        </div>
        {/* amount date box styart */}
        <div style={{ marginTop: "2rem" }} className="amount-date-box">
          <p className="amount">
            {list?.length > 0
              ? bulkAmount
              : formatNumWithCommaNairaSymbol(amount) || ""}
          </p>
          <p className="date grey-white-color-white">
            {valueSchedule
              ? formatDate(valueSchedule)
              : formatDate(moment().format("YYYY-MM-DD HH:mm:ss"))}
          </p>
        </div>
        {/* amount date box end */}
      </div>
      {/* img top box end */}
      {/* middle content wrap start */}
      <div className="middle-content-wrap border-theme-bottom">
        {collapse ? (
          <>
            {/* box start */}
            <div style={{ position: "relative", zIndex: 81 }} className="box">
              <p className="label grey-white-color-white">Total Recipients:</p>
              <div ref={testingPreviewRef} className="value">
                <div
                  onClick={() => {
                    viewAll ? setViewAll(false) : setViewAll(true);
                  }}
                  className="see-all-box"
                >
                  <span>({list?.length || 0})</span>
                  <p className="see-all-text">
                    {viewAll ? `Close` : `See all`}
                  </p>
                </div>
                {/*  */}
                <div
                  className={`view-all-box card-bg ${
                    viewAll && "view-all-box-show"
                  }`}
                >
                  {" "}
                  <div className="wrap">
                    {list?.map((chi, idx) => {
                      return (
                        <div key={idx} className="child-wrap">
                          <figure className="img-box">
                            <img
                              src={
                                chi?.provider === "mtn"
                                  ? mtnICon
                                  : chi?.provider === "glo"
                                  ? gloICon
                                  : chi?.provider === "airtel"
                                  ? airtelICon
                                  : mobileICon
                              }
                              alt=""
                              className="img"
                            />
                          </figure>
                          <div className="text-box">
                            <p className="name">{`+234 ${chi?.phone}`}</p>
                            <p className="text grey-white-color">{` ₦ ${chi?.amount}`}</p>
                          </div>
                          <div
                            onClick={() => {
                              handleRemoveFromList(chi);
                            }}
                            className="cancel-box"
                          >
                            <FaTimes className="icon" />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>

                {/*  */}
              </div>
            </div>
            {/* box end */}
            {/* box start */}
            {/* <div className="box">
              <p className="label grey-white-color-white">Total Fee:</p>
              <div className="value">{bulkAmount || 0}</div>
            </div> */}
            {/* box end */}
          </>
        ) : (
          <>
            {/* box start */}
            <div className="box">
              <p className="label grey-white-color-white">To:</p>
              <p className="value">{name || ""}</p>
            </div>
            {/* box end */}
            {/* box start */}
            <div className="box">
              <p className="label grey-white-color-white">Meter No:</p>
              <p className="value">{number || ""}</p>
            </div>
            {/* box end */}
            {/* box start */}
            <div className="box">
              <p className="label grey-white-color-white">Phone No:</p>
              <p className="value">
                +234{" "}
                {formatNumWithoutCommaNaira(
                  phone.slice(0, 3) === "234"
                    ? phone.replace("234", "0")
                    : phone
                ) || ""}
              </p>
            </div>
            {/* box end */}
            {/* box start */}
            <div className="box">
              <p className="label grey-white-color-white">Cable Plan:</p>
              <p className="value">{narration || ""}</p>
            </div>
            {/* box end */}
          </>
        )}
        {/* box start */}
        <div style={{ position: "relative", zIndex: 80 }} className="box">
          <p className="label grey-white-color">Source Account:</p>
          <div className="value">
            <div className="select-toggle-box">
              <NewAccountSelectDrop
                position={`bottom`}
                selected={sourceAccount}
                onSelect={onSourceSelect}
              />
            </div>
          </div>
        </div>
        {/* box end */}
      </div>
      {/* middle content wrap end */}
      {/* bottom- content wrap start */}
      <div className="bottom-content-wrap">
        <NewSelectScheduleBox
          id={`onudjhahabafw`}
          value={valueSchedule}
          textSchedule="Schedule this transaction"
          onChange={(e) => {
            onChangeSchedule(e);
          }}
          onReset={onReset}
        />
      </div>
      {/* bottom- content wrap end */}
      {/* account drop wrap start */}
      {/* <ChangeSourceAccount /> */}
      {/* account drop wrap end */}
    </div>
  );
};

export default NewPreviewDetailBox;
