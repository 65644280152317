import { RavenModal } from "@ravenpay/raven-bank-ui";
import React from "react";
import "../../../../styles/dashboard/pos/AlertRecieversModal.css";
import { useSelector } from "react-redux";
import NoContentBox from "../../../../components/reusables/NoContentBox";

const AlertRecieversModal = ({ visible, onClose, onDelete, onAdd }) => {
  const { loading_alert } = useSelector((state) => state.pos);
  const receiversList = [
    { name: "Adeeko Emmanuel", number: "07015263711" },
    { name: "Adeeko Emmanuel", number: "07015263711" },
    { name: "Adeeko Emmanuel", number: "07015263711" },
    { name: "Adeeko Emmanuel", number: "07015263711" },
    { name: "Adeeko Emmanuel", number: "07015263711" },
    { name: "Adeeko Emmanuel", number: "07015263711" },
  ];

  const deleteIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
      className="img"
    >
      <path
        fill="#FF0F00"
        fillRule="evenodd"
        d="M7.2 1.6a.8.8 0 00-.715.442L5.906 3.2H3.2a.8.8 0 100 1.6v8a1.6 1.6 0 001.6 1.6h6.4a1.6 1.6 0 001.6-1.6v-8a.8.8 0 000-1.6h-2.705l-.58-1.158A.8.8 0 008.8 1.6H7.2zM5.6 6.4a.8.8 0 111.6 0v4.8a.8.8 0 01-1.6 0V6.4zm4-.8a.8.8 0 00-.8.8v4.8a.8.8 0 001.6 0V6.4a.8.8 0 00-.8-.8z"
        clipRule="evenodd"
      ></path>
    </svg>
  );

  const whatsappIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39"
      height="25"
      fill="none"
      viewBox="0 0 39 25"
      className="img"
    >
      <path
        fill="#00E510"
        d="M12.027.5h-.054C5.36.5 0 5.86 0 12.473v.054C0 19.14 5.36 24.5 11.973 24.5h.054C18.64 24.5 24 19.14 24 12.527v-.054C24 5.86 18.64.5 12.027.5z"
      ></path>
      <path
        fill="#FDFDFD"
        d="M14.339 16.01c-3.072 0-5.57-2.5-5.571-5.57a1.415 1.415 0 011.646-1.392c.166.028.325.084.47.169a.079.079 0 01.039.056l.324 2.046a.078.078 0 01-.02.065c-.178.199-.407.342-.662.413l-.122.035.046.118a4.155 4.155 0 002.338 2.339l.119.047.034-.123c.072-.255.215-.483.413-.663a.078.078 0 01.053-.02l.014.001 2.045.325a.081.081 0 01.057.038 1.417 1.417 0 01.189.705c0 .778-.633 1.41-1.412 1.412z"
      ></path>
      <path
        fill="#FDFDFD"
        d="M19.786 11.826a7.537 7.537 0 00-2.415-4.888 7.536 7.536 0 00-5.116-1.998c-4.169 0-7.56 3.392-7.56 7.56 0 1.4.385 2.763 1.116 3.95l-1.63 3.607 5.218-.555a7.495 7.495 0 003.65.518c.235-.025.473-.062.706-.109 3.492-.705 6.04-3.804 6.06-7.37v-.04c0-.227-.01-.454-.03-.675zM9.6 17.918l-2.887.308.862-1.91-.172-.23a5.983 5.983 0 01-1.183-3.584 6.042 6.042 0 016.035-6.036c3.118 0 5.76 2.433 6.016 5.538a6.19 6.19 0 01.019.641 6.02 6.02 0 01-5.362 5.855 5.972 5.972 0 01-3.218-.527l-.11-.053v-.002z"
      ></path>
      <path
        fill="url(#paint0_linear_20958_37029)"
        d="M39 12.5c0 6.627-5.373 12-12 12s-12-5.373-12-12 5.373-12 12-12 12 5.373 12 12z"
      ></path>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M29.546 10.454a3.6 3.6 0 10-.592 5.57.6.6 0 11.653 1.008 4.802 4.802 0 01-6-7.426A4.8 4.8 0 0131.8 13a1.8 1.8 0 01-2.88 1.44A2.4 2.4 0 1129.4 13a.6.6 0 101.2 0c0-.922-.351-1.842-1.054-2.546zM28.2 13a1.2 1.2 0 10-2.4 0 1.2 1.2 0 002.4 0z"
        clipRule="evenodd"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear_20958_37029"
          x1="27"
          x2="27"
          y1="0.5"
          y2="24.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF4500"></stop>
          <stop offset="0.266" stopColor="#FF5733"></stop>
          <stop offset="0.51" stopColor="#FF704D"></stop>
          <stop offset="0.755" stopColor="#FF8C66"></stop>
          <stop offset="1" stopColor="#FFA07A"></stop>
        </linearGradient>
      </defs>
    </svg>
  );

  return (
    <>
      <RavenModal
        visble={visible}
        onClose={onClose}
        onBtnClick={onAdd}
        btnColor="black-light"
        btnLabel="Add new alert receiver"
      >
        <div className="alert-receiver-list-modal-wrap-index">
          <div className="title-text-box">
            <p className="title">Alert Receiver</p>
            <p className="text grey-white-color">
              This is the list of all alert receiver added to your account.{" "}
            </p>
          </div>
          <div className="list-box-wrap">
            {loading_alert ? (
              <>
                <NoContentBox
                  loading={loading_alert}
                  title={`No Alert Reciever Yet`}
                  text={`Alert recievers will show here when added.`}

                />
              </>
            ) : (
              <>
                {receiversList?.map((chi, idx) => {
                  return (
                    <div key={idx} className={`list-item border-theme-bottom`}>
                      <div className="whatsapp-icon">
                        <figure className="img-box">{whatsappIcon}</figure>
                      </div>

                      <div className="name-num-box">
                        <p className="name">{chi?.name}</p>
                        <p className="num grey-white-color">{chi?.number}</p>
                      </div>

                      {/* delete start */}
                      <div
                        onClick={() => {
                          onDelete && onDelete(chi);
                        }}
                        className="delete-icon "
                      >
                        <figure className="img-box grey-bg-two">
                          {deleteIcon}
                        </figure>
                      </div>
                      {/* delete end */}
                    </div>
                  );
                })}
              </>
            )}
          </div>
        </div>
      </RavenModal>
    </>
  );
};

export default AlertRecieversModal;
