import { RavenButton, RavenToolTip } from "@ravenpay/raven-bank-ui";
import React, { useContext, useState } from "react";
import { useSelector } from "react-redux";
import AccountDropDownBox from "../../../components/fragments/AccountDropDownBox";
import {
  capitalizeAllWords,
  formatMetaData,
  formatNumWithCommaNaira,
  formatTypeFunction,
  getAuthLevelDes,
  getCurrencySymbol,
} from "../../../utils/helper/Helper";
import { useOnClickOutside } from "../../../utils/helper/UseOnClickOutside";
import closeEye from "../../../assets/close-eye-amount-icon.svg";
import openEye from "../../../assets/open-eye-amount-icon.svg";
import ActionContext from "../../../context/ActionContext";
import { useNavigate } from "react-router-dom";

const sendIcon = (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="img"
  >
    <path
      d="M1.42322 10.4182L10.754 1.08748M10.754 1.08748V10.4182M10.754 1.08748H1.42322"
      stroke="white"
      strokeWidth="1.67953"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const addIcon = (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="img"
  >
    <path
      d="M10.8545 1.0874L1.52376 10.4181M1.52376 10.4181V1.0874M1.52376 10.4181H10.8545"
      stroke="#020202"
      strokeWidth="1.67953"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const NewTopBalance = () => {
  const navigate = useNavigate();
  const actionCtx = useContext(ActionContext);
  // eslint-disable-next-line no-unused-vars
  const { user, all_account } = useSelector((state) => state.user);
  const [showAccountDrop, setShowAccountDrop] = useState(false);
  const dropDownIcon = (
    <svg
      className="img"
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.2423 6.0083L8.24774 10.0137L12.2532 6.0083"
        stroke="white"
        strokeWidth="1.20163"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const testingAccountDropRef = useOnClickOutside(() => {
    setShowAccountDrop(false);
  });

  const infoIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="11"
      fill="none"
      viewBox="0 0 10 11"
      className="img"
    >
      <path
        fill="#EA872D"
        fillRule="evenodd"
        d="M9 5.1a4 4 0 11-8 0 4 4 0 018 0zm-3.5-2a.5.5 0 11-1 0 .5.5 0 011 0zm-1 1.5a.5.5 0 000 1v1.5a.5.5 0 00.5.5h.5a.5.5 0 000-1V5.1a.5.5 0 00-.5-.5h-.5z"
        clipRule="evenodd"
      ></path>
    </svg>
  );

  return (
    <div
      style={{ backgroundImage: "unset" }}
      className={`balance-top-box black-fade-white-bg ${
        formatMetaData(user?.theme_display_settings)?.dashboard_overview ===
          "purple" && "balance-top-box-purple"
      }`}
    >
      {/* toggle accout select start */}
      <div ref={testingAccountDropRef} className="toggle-account-select-box">
        <div
          onClick={() => {
            setShowAccountDrop(!showAccountDrop);
          }}
          className="value-box"
        >
          <p className="value">
            {capitalizeAllWords(actionCtx?.currentAccount?.custom_account_name) || "---"}
          </p>
          <figure className={`img-box ${showAccountDrop && "img-box-up"}`}>
            {dropDownIcon}
          </figure>
        </div>
        {/* toggle account box start */}
        {showAccountDrop && (
          <div className="select-toggle-box">
            <AccountDropDownBox
              big={false}
              onSelect={(obj) => {
                localStorage.setItem("current_sub_account_id", obj?.id);
                actionCtx?.setCurrentAccount(obj);
                // console.log(obj);
                setShowAccountDrop(false);
              }}
            />
          </div>
        )}
        {/* toggle account box end */}
      </div>
      {/* toggle accout select end */}

      {/* role and auth level start */}
      {actionCtx?.currentAccount?.authorization_level ||
      actionCtx?.currentAccount?.user_role ? (
        <div
          // onClick={() => {
          //   console.log(actionCtx?.currentAccount);
          // }}
          className="role-auth-level-box "
        >
          {actionCtx?.currentAccount?.user_role && (
            <p className="text ">
              <span style={{ color: "rgba(172, 172, 172, 1)" }}>
                {actionCtx?.currentAccount?.user_role || ""}{" "}
              </span>{" "}
              <span className="text-two">
                •{" "}
                {formatTypeFunction(
                  actionCtx?.currentAccount?.authorization_level
                ) || ""}
              </span>
            </p>
          )}
          {actionCtx?.currentAccount?.authorization_level && (
            <div className="icon-img">
              <figure className="img-box tooltip-hover-wrap">
                {" "}
                <RavenToolTip
                  position="bottom-left"
                  color="black-light"
                  text={getAuthLevelDes(
                    actionCtx?.currentAccount?.authorization_level
                  )}
                />{" "}
                {infoIcon}
              </figure>
            </div>
          )}
        </div>
      ) : (
        ""
      )}
      {/* role and auth level end */}
      {/* amount and toggle value box start */}
      <div className="amount-value-box">
        {actionCtx?.showAmount && (
          <sup>{getCurrencySymbol(actionCtx?.currentAccount?.currency)}</sup>
        )}
        <p className="amount">
          <span>
            {actionCtx?.showAmount
              ? formatNumWithCommaNaira(
                  actionCtx?.currentAccount?.balance || "0"
                )?.split(".")[0]
              : "********"}
          </span>
          <span className="decimal">
            {actionCtx?.showAmount
              ? `.${
                  formatNumWithCommaNaira(
                    actionCtx?.currentAccount?.balance || "0"
                  )?.split(".")[1]
                }`
              : ""}
          </span>
        </p>
        {/* .toggle text box start */}
        <div className="toggle-text-box">
          <figure
            onClick={() => {
              actionCtx?.setShowAmount(!actionCtx?.showAmount);
            }}
            className="img-box"
          >
            <img
              src={actionCtx?.showAmount ? closeEye : openEye}
              alt=""
              className="img"
            />
          </figure>
        </div>
        {/* .toggle text box end */}
      </div>
      {/* amount and toggle value box end */}
      {/* btn wrap box start */}
      <div className="btn-wrap-box">
        <RavenButton
          className="btn-one"
          color={`white-light`}
          // label="Add Money"

          onClick={() => {
            if (all_account?.length > 0) {
              navigate("/dashboard-add-money?dashboard-overview");
            }
          }}
          disabled={all_account?.length < 1}
        >
          <span>Add </span>
          <figure className="img-box">{addIcon}</figure>{" "}
        </RavenButton>
        <RavenButton
          className="btn-two"
          color={`purple-light`}
          onClick={() => {
            if (
              actionCtx?.currentAccount?.authorization_level !==
                ("initiator_approver" || "initiator") &&
              actionCtx?.currentAccount?.authorization_level !==
                ("initiator" || "initiator_approver")
            ) {
              return;
            }
            navigate("/dashboard-send-money?dashboard-overview");
          }}
          disabled={
            actionCtx?.currentAccount?.authorization_level !==
              ("initiator_approver" || "initiator") &&
            actionCtx?.currentAccount?.authorization_level !==
              ("initiator" || "initiator_approver")
          }
        >
          <span>Send</span>
          <figure className="img-box">
            {/* <img src={sendIcon} alt="" className="img" /> */}
            {sendIcon}
          </figure>{" "}
        </RavenButton>
      </div>
      {/* btn wrap box end */}
    </div>
  );
};

export default NewTopBalance;
