import { RavenInputField, toast } from "@ravenpay/raven-bank-ui";
import React from "react";
import "./ReuseUpload.css";

const ReuseUpload = ({
  label,
  onChange,
  onRemoveFile,
  onSizeError,
  maxSize,
  key,
  descriptionText,
}) => {
  const fileIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="18"
    //   fill="none"
      viewBox="0 0 23 18"
      className="img"
    >
      <path
        fill="#EEE"
        fillRule="evenodd"
        d="M1.4 0A1.4 1.4 0 000 1.4v14.461a1.4 1.4 0 001.4 1.4h16.586a1.4 1.4 0 001.4-1.4V4.181a1.4 1.4 0 00-1.4-1.4H5.376V1.4a1.4 1.4 0 00-1.4-1.4H1.4z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#000"
        d="M2.405 6.335a1.4 1.4 0 011.368-1.1h16.833a1.4 1.4 0 011.358 1.741l-2.312 9.226a1.4 1.4 0 01-1.358 1.06H1.742A1.4 1.4 0 01.374 15.56l2.031-9.225z"
      ></path>
    </svg>
  );

//   const fileTextComp = () => {
//     return (
//       <div className="file-text-box">
//         <div className="file-box">
//           <figure className="img-box">{fileIcon}</figure>
//         </div>
//         <p className="text grey-white-color">Click to upload image</p>
//       </div>
//     );
//   };

  return (
    <div
      className="upload-business-custom-two-type-wrapper"
      style={{ position: "relative" }}
    >
      <RavenInputField
        upploadText=" "
        uploadSpan=" "
        label={label || `Upload image showing front view of the above address*`}
        type={`upload`}
        color={`black-light`}
        maxSize={maxSize || 5.1}
        onChange={onChange}
        id="file"
        name="file"
        onRemoveFile={onRemoveFile}
        onSizeError={() => {
          toast.error("File size cannot exceed 5MB", {});
          onSizeError && onSizeError();
        }}
        key={key}
        fileTypes={["PNG", "JPG", "PDF", "DOC", "DOCX", "JPEG"]}
        uploadComponentChildren={
          <div className="file-text-box">
            <div className="file-box">
              <figure className="img-box">{fileIcon}</figure>
            </div>
            <p className="text grey-white-color">{descriptionText || `Click to upload image`}</p>
          </div>
        }
        onTypeError={(param) => {
          toast.error(param);
        }}
      />

      {/* <label htmlFor="file"  className="file-text-box">

      </label> */}
    </div>
  );
};

export default ReuseUpload;
