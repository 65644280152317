import {
  RavenDateTime,
  RavenInputField,
  RavenPagination,
  RavenTable,
  RavenTableRow,
  RavenToolTip,
} from "@ravenpay/raven-bank-ui";
import React, { useContext, useEffect, useState } from "react";
import { FaTimes } from "react-icons/fa";
import Skeleton from "react-loading-skeleton";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import noContentImg from "../../../assets/no-content-transaction.png";
import ContainerWrapper from "../../../components/reusables/ContainerWrapper";
import MobileTableCard from "../../../components/reusables/MobileTableCard";
import NoContentBox from "../../../components/reusables/NoContentBox";
import NocontentPage from "../../../components/reusables/NocontentPage";
import TitletextBox from "../../../components/reusables/TitletextBox";
import ActionContext from "../../../context/ActionContext";
import DashboardLayout from "../../../layout/dashboard/DashboardLayout";
import { getAllPaymentRequest } from "../../../redux/paymentRequest";
import "../../../styles/dashboard/pauyment-request/PayentRequestIndex.css";
import {
  formatDate,
  formatMetaData,
  formatNumWithCommaNairaSymbol,
  formatTypeFunction,
  getPageNum,
  getTotalPage,
  lowerCaseWrap,
  reactSelectStyle,
  // removeSpace,
  trimLongString,
} from "../../../utils/helper/Helper";
import useDebounce from "../../../utils/helper/UseDebounce";
import { useOnClickOutside } from "../../../utils/helper/UseOnClickOutside";
import DownloadReceiptModal from "./PreviewRequestModal";
import RejectPaymentRequestModal from "./RejectPayementModal";
import RetryPaymentRequest from "./ReturnPaymentRequestModal";
import BigPreviewModal from "../../../components/reusables/BigPreviewModal";

const PayentRequestIndex = () => {
  const navigate = useNavigate();
  // const [] = useState(false);
  const [singleChild, setSingleChild] = useState({});
  const [tableData, setTableData] = useState(false);
  const [refreshComponent, setRefreshComponent] = useState(false);
  const { user } = useSelector((state) => state.user);
  const { payment_requests, loading } = useSelector(
    (state) => state.payment_request
  );
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState({
    view: false,
    statement: false,
    reject: false,
    return: false,
    big_image: false,
  });
  const [bigImg, setBigImg] = useState("");
  const actionCtx = useContext(ActionContext);
  const [selectOptionVal, setSelectOptionVal] = useState({
    one: "",
    two: "",
  });
  const [showFilterOption, setShowFilterOption] = useState(false);
  const today = new Date();

  // Subtract one day from the current date
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);
  // const year = yesterday.getFullYear();
  // const month = yesterday.getMonth() + 1; // Months are zero-indexed, so we add 1
  // const day = yesterday.getDate();

  // Format the date as a string in the format "YYYY-MM-DD"

  const selectOption = [
    { label: "All Time", value: "" },
    { label: "Pending", value: 26 },
    { label: "Completed", value: 3 },
    { label: "Canceled", value: 2 },
  ];

  const eyeIcon = (
    <svg
      className="img dull-bright-filter"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_16935_15458)">
        <path
          className="dull-bright-filter"
          d="M1.64214 8.6716C1.55208 8.52901 1.50706 8.45771 1.48185 8.34775C1.46292 8.26514 1.46292 8.13488 1.48185 8.05228C1.50706 7.94231 1.55208 7.87101 1.64214 7.72842C2.38632 6.55008 4.60144 3.57123 7.97714 3.57123C11.3529 3.57123 13.568 6.55007 14.3121 7.72842C14.4022 7.87101 14.4472 7.94231 14.4724 8.05228C14.4914 8.13488 14.4914 8.26514 14.4724 8.34775C14.4472 8.45771 14.4022 8.52901 14.3121 8.6716C13.568 9.84995 11.3529 12.8288 7.97714 12.8288C4.60144 12.8288 2.38632 9.84995 1.64214 8.6716Z"
          stroke="#020202"
          strokeWidth="1.23434"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          className="dull-bright-filter"
          d="M7.97714 10.1838C9.07275 10.1838 9.96091 9.29561 9.96091 8.20001C9.96091 7.10441 9.07275 6.21625 7.97714 6.21625C6.88154 6.21625 5.99338 7.10441 5.99338 8.20001C5.99338 9.29561 6.88154 10.1838 7.97714 10.1838Z"
          stroke="#020202"
          strokeWidth="1.23434"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_16935_15458">
          <rect
            width="15.8701"
            height="15.8701"
            fill="white"
            transform="translate(0.0418091 0.264954)"
          />
        </clipPath>
      </defs>
    </svg>
  );

  const approveIcon = (
    <svg
      className="img "
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="dull-bright-filter"
        d="M13.2669 4.63248L5.9931 11.9063L2.68683 8.60001"
        stroke="#020202"
        strokeWidth="1.23434"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const retryIcon = (
    <svg
      className="img dull-bright-filter"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_16935_15530)">
        <path
          className="dull-bright-filter"
          d="M8.63812 15.1125L6.65436 13.1288M6.65436 13.1288L8.63812 11.145M6.65436 13.1288H9.96063C12.517 13.1288 14.5894 11.0564 14.5894 8.5C14.5894 6.65351 13.5082 5.05955 11.9444 4.31666M4.00934 12.6833C2.44551 11.9404 1.36432 10.3465 1.36432 8.5C1.36432 5.94359 3.4367 3.87122 5.9931 3.87122H9.29938M9.29938 3.87122L7.31561 1.88745M9.29938 3.87122L7.31561 5.85498"
          stroke="#020202"
          strokeWidth="1.23434"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_16935_15530">
          <rect
            width="15.8701"
            height="15.8701"
            fill="white"
            transform="translate(0.0418091 0.564941)"
          />
        </clipPath>
      </defs>
    </svg>
  );

  const rejectIcon = (
    <svg
      className="img"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0281 4.93246L4.09302 12.8675M4.09302 4.93246L12.0281 12.8675"
        stroke="#FF0F00"
        strokeWidth="1.23434"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const dropList = [
    { text: "View request detail", icon: eyeIcon },
    { text: "Confirm payment request", icon: approveIcon },
    { text: "Retry payment request", icon: retryIcon },
    { text: "Cancel payment request", icon: rejectIcon, red: true },
  ];

  //   const loading = false;
  const [dropToshow, setDropToShow] = useState("");
  // const testingPreviewRequestRef = useOnClickOutside(() => {
  //   setDropToShow("");
  // });
  const paymeRequestAction = (param, idx) => {
    return (
      <div
        style={{ position: "relative", zIndex: 1000 }}
        className="transaction-action-wrap"
      >
        {loading ? (
          <>
            <Skeleton width={35} height={35} circle />
            {/* {param?.status === 26 ? (
              <>
                <div className="icon-wraps">
                  {" "}
                  <Skeleton width={35} height={35} circle />{" "}
                  <Skeleton width={35} height={35} circle />
                </div>
              </>
            ) : (
              <Skeleton width={35} height={35} circle />
            )} */}
          </>
        ) : (
          <>
            {param?.status === 3 ? (
              <>
                <figure
                  onClick={() => {
                    setSingleChild(param);
                    setShowModal((prev) => {
                      return { ...prev, view: true };
                    });
                  }}
                  className="img-box img-box-main tooltip-hover-wrap grey-bg"
                >
                  {" "}
                  <RavenToolTip
                    color={`black-light`}
                    text={`View request details from ${
                      param?.intiator_fname || "---"
                    } ${
                      param?.intiator_fname?.length +
                        param?.intiator_lname?.length >
                      12
                        ? trimLongString(param?.intiator_lname, 5)
                        : param?.intiator_lname
                    }`}
                    position={`left`}
                  />{" "}
                  {eyeIcon}
                </figure>
              </>
            ) : (
              <>
                <figure
                  className="img-box img-box-main grey-bg drop-down-box-payroll darken-action-bg"
                  onClick={() => {
                    // console.log(param);
                    dropToshow === param?.id
                      ? setDropToShow("")
                      : setDropToShow(param?.id);
                  }}
                >
                  {/* drop down box start */}
                  <div
                    className={`drop-down-wrap-payroll border-theme shadow-shadow-box-shadow ${
                      dropToshow === param?.id && "drop-down-wrap-payroll-show"
                    } ${idx >= 3 && "drop-down-wrap-payroll-up"}`}
                  >
                    {dropList?.map((chi, idx) => {
                      if (
                        chi?.text === "Cancel payment request" &&
                        param?.status === 2
                      ) {
                        return <React.Fragment key={idx}></React.Fragment>;
                      }
                      if (
                        chi?.text === "Confirm payment request" &&
                        param?.status === 2
                      ) {
                        return <React.Fragment key={idx}></React.Fragment>;
                      }
                      if (
                        actionCtx?.currentAccount?.authorization_level ===
                          "initiator" &&
                        chi?.text === "Confirm payment request"
                      ) {
                        return <React.Fragment key={idx}></React.Fragment>;
                      }
                      if (
                        chi?.text === "Retry payment request" &&
                        param?.status !== 2
                      ) {
                        return <React.Fragment key={idx}></React.Fragment>;
                      }
                      return (
                        <div
                          onClick={() => {
                            if (chi?.text === "View request detail") {
                              setSingleChild(param);
                              setShowModal((prev) => {
                                return { ...prev, view: true };
                              });
                            }
                            if (chi?.text === "Retry payment request") {
                              setSingleChild(param);
                              setShowModal((prev) => {
                                return { ...prev, return: true };
                              });
                            }
                            if (chi?.text === "Cancel payment request") {
                              setSingleChild(param);
                              setShowModal((prev) => {
                                return { ...prev, reject: true };
                              });
                            }
                            if (chi?.text === "Confirm payment request") {
                              localStorage.setItem(
                                "single-payment-request-id",
                                param?.id
                              );
                              //   console.log(param);
                              navigate("/dashboard-payment-requests-confirm");
                            }
                            setDropToShow("");
                          }}
                          key={idx}
                          className="row-child"
                        >
                          <div className="img-wrap">
                            <figure className="img-box">{chi?.icon}</figure>
                          </div>
                          <p className={`text ${chi?.red && "text-red"}`}>
                            {chi?.text}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                  {/* drop down box end */}
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="img dull-bright-filter"
                  >
                    <path
                      d="M9 9.75C9.41421 9.75 9.75 9.41421 9.75 9C9.75 8.58579 9.41421 8.25 9 8.25C8.58579 8.25 8.25 8.58579 8.25 9C8.25 9.41421 8.58579 9.75 9 9.75Z"
                      stroke="#676767"
                      strokeWidth="1.4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M14.25 9.75C14.6642 9.75 15 9.41421 15 9C15 8.58579 14.6642 8.25 14.25 8.25C13.8358 8.25 13.5 8.58579 13.5 9C13.5 9.41421 13.8358 9.75 14.25 9.75Z"
                      stroke="#676767"
                      strokeWidth="1.4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M3.75 9.75C4.16421 9.75 4.5 9.41421 4.5 9C4.5 8.58579 4.16421 8.25 3.75 8.25C3.33579 8.25 3 8.58579 3 9C3 9.41421 3.33579 9.75 3.75 9.75Z"
                      stroke="#676767"
                      strokeWidth="1.4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </figure>
                {/* <div className="icon-wraps">
                  <figure
                    onClick={() => {
                      localStorage.setItem(
                        "single-payment-request-id",
                        param?.id
                      );
                      //   console.log(param);
                      navigate("/dashboard-payment-requests-confirm");
                    }}
                    className="img-box tooltip-hover-wrap grey-bg"
                  >
                    {" "}
                    <RavenToolTip
                      color={`black-light`}
                      text={`Approve request from ${
                        param?.intiator_fname || "---"
                      } ${
                        param?.intiator_fname?.length +
                          param?.intiator_lname?.length >
                        12
                          ? trimLongString(param?.intiator_lname, 5)
                          : param?.intiator_lname
                      }`}
                      position={`left`}
                    />{" "}
                    {approveIcon}
                  </figure>
                  <figure
                    onClick={() => {
                      setShowModal((prev) => {
                        return { ...prev, reject: true };
                      });
                      setSingleChild(param);
                    }}
                    className="img-box tooltip-hover-wrap img-box-delete grey-bg-two"
                  >
                    {" "}
                    <RavenToolTip
                      color={`black-light`}
                      text={`Reject request from ${
                        param?.intiator_fname || "---"
                      } ${
                        param?.intiator_fname?.length +
                          param?.intiator_lname?.length >
                        12
                          ? trimLongString(param?.intiator_lname, 5)
                          : param?.intiator_lname
                      }`}
                      position={index < 1 ? `left` : `top-left`}
                    />{" "}
                    {rejectIcon}
                  </figure>
                </div> */}
              </>
            )}
          </>
        )}
      </div>
    );
  };

  const formatSelectOptionTwo = () => {
    const list = [
      "bulk_transfer",
      "single_transfer",
      "bulk_internal_transfer",
      "single_internal_transfer",
      "single_internal_schedule_transfer",
      "bulk_internal_schedule_transfer",
      "bulk_schedule_transfer",
      "single_schedule_transfer",
      "bulk_airtime_schedule_transfer",
      "single_airtime_schedule_transfer",
      "bulk_airtime",
      "bulk_data_schedule_transfer",
      "single_data_schedule_transfer",
      "bulk_data",
      "bulk_cable_schedule_transfer",
      "single_cable_schedule_transfer",
      "bulk_cable",
      "bulk_electricity_schedule_transfer",
      "single_electricity_schedule_transfer",
      "bulk_electricity",
      "single_bet_schedule_transfer",
      "electricity_recharge",
      "airtime_recharge",
      "data_recharge",
      "cable_recharge",
      "bet_recharge",
    ];
    const newList = list.map((chi) => {
      return { label: formatTypeFunction(chi), value: chi };
    });

    const newListAll = [{ label: "All Type", value: "" }, ...newList];
    return newListAll;
  };

  const [search, setSearch] = useState("");
  const debouncedSearchTerm = useDebounce(search, 1000);
  useEffect(() => {
    let isMount = true;

    if (isMount && debouncedSearchTerm?.length > 1) {
      const payload = {
        per_page: actionCtx?.perPageVal?.value,
        current_page: 1,
        // transaction_type: selectOptionVal?.two?.value,
        // date: selectOptionVal?.one?.value,
        search_term: search,
      };
      fetchAllPaymentRequest(payload);
    }

    if (isMount && debouncedSearchTerm?.length < 1) {
      const payload = {
        per_page: actionCtx?.perPageVal?.value,
        current_page: 1,
        // transaction_type: selectOptionVal?.two?.value,
        // date: selectOptionVal?.one?.value,
        search_term: "",
      };
      fetchAllPaymentRequest(payload);
    }

    return () => {
      isMount = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  const testingPaymentRequestRef = useOnClickOutside(() => {
    setShowFilterOption(false);
  });

  const formatTypeWrap = (param) => {
    return (
      <div
        className={`status-type-wrap ${
          param === "0" && "status-type-wrap-pending"
        } ${param === "3" && "status-type-wrap-success"} ${
          param === "Completed" && "status-type-wrap-success"
        } ${param === "success" && "status-type-wrap-success"} ${
          param === "paid" && "status-type-wrap-success"
        } ${param === "2" && "status-type-wrap-fail"} ${
          param === "failed" && "status-type-wrap-fail"
        } ${param === "Canceled" && "status-type-wrap-fail"} ${
          param === "overdue" && "status-type-wrap-pending"
        } ${param === "1" && "status-type-wrap-pending"} ${
          param === "Pending" && "status-type-wrap-pending"
        } ${param === "pending" && "status-type-wrap-pending"}`}
      >
        <span style={{ textTransform: "capitalize" }}>
          {String(param) === "1"
            ? "Processing"
            : String(param) === "0"
            ? "Pending"
            : String(param) === "3"
            ? "Success"
            : String(param) === "2"
            ? "Failed"
            : String(param) === "11"
            ? "Reversal"
            : param === "success"
            ? "Completed"
            : param === "failed"
            ? "Canceled"
            : param}
        </span>
      </div>
    );
  };

  const headerList = ["INITIATOR", "AMOUNT", "DATE", "REQUEST TYPE", "STATUS"];

  useEffect(() => {
    fetchAllPaymentRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshComponent]);

  const fetchAllPaymentRequest = async (obj) => {
    // console.log(obj);
    if (obj) {
      dispatch(getAllPaymentRequest(obj));
    } else {
      const defaultObj = {
        per_page: actionCtx?.perPageVal?.value,
        current_page: 1,
      };
      dispatch(getAllPaymentRequest(defaultObj));
    }
  };

  useEffect(() => {
    // console.log(payment_requests);
    if (payment_requests?.data?.length > 0) {
      setTableData(true);
    }
  }, [payment_requests]);

  useEffect(() => {
    // console.log(selectOptionVal?.one);
    if (selectOptionVal?.one || selectOptionVal?.two) {
      const defaultObj = {
        per_page: actionCtx?.perPageVal?.value,
        current_page: 1,
        status: String(selectOptionVal?.one?.value || "") || "",
        type: selectOptionVal?.two?.value || "",
      };
      fetchAllPaymentRequest(defaultObj);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectOptionVal]);

  // const formatDateToday = (param) => {
  //   let val;
  //   function isDateToday(date) {
  //     const currentDate = new Date();

  //     return (
  //       param?.getFullYear() === currentDate?.getFullYear() &&
  //       param?.getMonth() === currentDate?.getMonth() &&
  //       param?.getDate() === currentDate?.getDate()
  //     );
  //   }
  //   if (isDateToday()) {
  //     val = `Today`;
  //   } else {
  //     val = formatDate(param);
  //   }
  //   return val;
  // };

  const getPaymentPagination = (param) => {
    const obj = {
      per_page: actionCtx?.perPageVal?.value,
      current_page: param,
    };
    fetchAllPaymentRequest(obj);
  };

  const handleFinish = () => {
    setRefreshComponent(!refreshComponent);
    setShowModal((prev) => {
      return {
        ...prev,
        view: false,
        statement: false,
        reject: false,
        return: false,
      };
    });
  };

  if (
    // actionCtx?.currentAccount?.authorization_level !==
    //   ("initiator_approver" || "approver") &&
    // actionCtx?.currentAccount?.authorization_level !==
    //   ("approver" || "initiator_approver")
    actionCtx?.currentAccount?.authorization_level === "none"
  ) {
    return (
      <DashboardLayout>
        <TitletextBox
          title={`Approval Request`}
          text={`All Approval request created show here.`}
          // exportBtn
          // onExport={() => {
          //   setShowModal((prev) => {
          //     return { ...prev, statement: true };
          //   });
          // }}
          // exportText={`Generate Statement`}
        />
        {/* title box end */}
        <ContainerWrapper dontShowHeader>
          <NoContentBox
            title={`No permission to view this page`}
            text={`You will be able to view this screen when you have the permission`}
            bgColor
            // loading={loading}
          />
        </ContainerWrapper>
      </DashboardLayout>
    );
  }

  return (
    <>
      <DashboardLayout>
        {/* title box start */}
        <TitletextBox
          title={`Approval Request`}
          text={`All Approval request created show here.`}
          //   back
          //   onBack={() => {
          //     // console.log(single_sub_account);
          //     navigate("/dashboard-settings?settingsPath=Roles_Permission");
          //   }}
        />
        {/* title box end */}
        {/* container wrapper start */}
        <ContainerWrapper
          pagination={
            payment_requests?.next_page_url_params ||
            payment_requests?.prev_page_url_params ? (
              <RavenPagination
                color={`black-light`}
                blackHover
                currentPage={Number(payment_requests?.current_page) || 1}
                nextPage={payment_requests?.next_page_url_params}
                prevPage={payment_requests?.prev_page_url_params}
                onNext={(e) => {
                  getPaymentPagination(getPageNum(e));
                }}
                onPrev={(e) => {
                  getPaymentPagination(getPageNum(e));
                }}
                totalPage={
                  getTotalPage(
                    Number(payment_requests?.per_page),
                    Number(payment_requests?.total)
                  ) || 1
                }
                onNumView={(e) => {
                  getPaymentPagination(e);
                }}
              />
            ) : (
              ""
            )
          }
          dontShowHeader
          width={`100%`}
        >
          {payment_requests?.data?.length > 0 || tableData ? (
            <div className="payment-request-index-wrap">
              {/* transaction filter start */}
              <div className="new-search-filter-chart-box">
                <div className="search-filter-box">
                  {/* per page start */}
                  {/* <PerPageComponent /> */}
                  {/* per page end */}
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                    }}
                    autoComplete="off"
                    action=""
                    style={{ marginRight: "auto" }}
                  >
                    <div className="search-group">
                      <RavenInputField
                        type={`search`}
                        color={`black-light`}
                        placeholder={`Search`}
                        value={search}
                        onChange={(e) => {
                          setSearch(e.target.value);
                          showFilterOption && setShowFilterOption(false);
                        }}
                      />
                    </div>
                  </form>
                  {/* badge filter options start */}
                  {selectOptionVal?.one.label || selectOptionVal?.two.label ? (
                    <div className="badge-filter-option-box">
                      {/* box start */}
                      {selectOptionVal?.one.label && (
                        <div className="option-box grey-bg">
                          <span>{selectOptionVal?.one?.label || "--"}</span>{" "}
                          <FaTimes
                            onClick={() => {
                              setSelectOptionVal((prev) => {
                                return {
                                  ...prev,
                                  one: "",
                                };
                              });
                              fetchAllPaymentRequest();
                            }}
                            className="icon grey-white-color-white"
                          />{" "}
                        </div>
                      )}
                      {selectOptionVal?.two.label && (
                        <div className="option-box grey-bg">
                          <span>{selectOptionVal?.two?.label || "--"}</span>{" "}
                          <FaTimes
                            onClick={() => {
                              setSelectOptionVal((prev) => {
                                return {
                                  ...prev,
                                  two: "",
                                };
                              });
                              fetchAllPaymentRequest();
                            }}
                            className="icon grey-white-color-white"
                          />{" "}
                        </div>
                      )}
                      {/* box end */}
                    </div>
                  ) : (
                    ""
                  )}
                  {/* badge filter options end */}
                  {/* filter-drop box start */}
                  <div
                    style={{ marginLeft: "unset" }}
                    ref={testingPaymentRequestRef}
                    className="filter-drop-box"
                  >
                    {/* drop box start */}
                    {showFilterOption && (
                      <div className="drop-box-wrap  card-bg shadow-shadow-box-shadow">
                        <form
                          onSubmit={(e) => {
                            e.preventDefault();
                          }}
                          autoComplete="off"
                          action=""
                        >
                          {" "}
                          <RavenInputField
                            selectOption={selectOption}
                            label={`Filter By Status`}
                            color={`black-light`}
                            type={"select"}
                            placeholder={`Transaction status`}
                            selectStyles={reactSelectStyle}
                            value={selectOptionVal?.one}
                            onChange={(e) => {
                              setSelectOptionVal((prev) => {
                                return { ...prev, one: e };
                              });
                              showFilterOption && setShowFilterOption(false);
                              // setCheckCustom(true);
                            }}
                            selectValue={selectOptionVal?.one}
                          />
                        </form>
                        <form
                          onSubmit={(e) => {
                            e.preventDefault();
                          }}
                          autoComplete="off"
                          action=""
                        >
                          {" "}
                          <RavenInputField
                            selectOption={formatSelectOptionTwo()}
                            color={`black-light`}
                            type={"select"}
                            label={`Filter By Transaction type`}
                            placeholder={`Transaction type`}
                            selectStyles={reactSelectStyle}
                            value={selectOptionVal?.two}
                            onChange={(e) => {
                              setSelectOptionVal((prev) => {
                                return { ...prev, two: e };
                              });
                              showFilterOption && setShowFilterOption(false);
                              // setCheckCustom(false);
                            }}
                            //   selectValue={typeFilter}
                          />
                        </form>
                      </div>
                    )}
                    {/* drop box end */}
                    {/* filter box start */}
                    <div
                      className="filter-box grey-bg"
                      onClick={() => {
                        setShowFilterOption(!showFilterOption);
                      }}
                    >
                      <p className="text grey-white-color-white">Filter</p>
                      <div className="line-box">
                        <span className=""></span>
                        <span></span>
                        <span></span>
                      </div>
                    </div>
                    {/* filter box end */}
                    {/* drop box start */}
                    <div className="drop-filter-wrap"></div>
                    {/* drop box end */}
                  </div>
                  {/* filter-drop box end */}

                  {/* export table content start */}
                  {/* <CSVLink
                      // ref={download}
                      className="export-table-content-box tooltip-hover-wrap grey-bg"
                      filename={"Raven Business Banking Transactions.csv"}
                      data={formatExportData(
                        transactions_two?.transactions?.data
                      )}
                      headers={CsvHeader}
                    >
                      {" "}
                      <RavenToolTip
                        color="black-light"
                        text="Export Table Content"
                        position={`right`}
                      />
                      <figure className="img-box">{exportTableIcon}</figure>
                    </CSVLink> */}
                  {/* export table content endd */}
                </div>
              </div>
              {/* transaction filter end */}
              {payment_requests?.data?.length > 0 ? (
                <>
                  {" "}
                  {/* raven  table start */}
                  <div className="table-wrap">
                    {/* wrap scroll start */}
                    <div className="wrap-scroll">
                      <RavenTable
                        className={`table-fixed-type ${
                          formatMetaData(user?.theme_display_settings)
                            ?.table_font === "bold" && "table-business"
                        }`}
                        headerList={headerList}
                        action
                      >
                        {payment_requests?.data?.map((chi, idx) => {
                          const {
                            amount,
                            request_type,
                            intiator_fname,
                            intiator_lname,
                            status,
                            // type,
                            created_at,
                          } = chi;
                          return (
                            <RavenTableRow
                              key={idx}
                              loading={loading}
                              one={`${lowerCaseWrap(
                                intiator_fname || "---"
                              )} ${lowerCaseWrap(intiator_lname || "---")}`}
                              two={formatNumWithCommaNairaSymbol(amount)}
                              three={<RavenDateTime date={created_at} />}
                              four={
                                formatTypeFunction(request_type || "---") ||
                                "---"
                              }
                              onRowClick={() => {
                                // console.log(
                                //   formatMetaData(chi?.description_file_name)
                                // );
                                // console.log(payment_requests);

                                setSingleChild(chi);
                                setShowModal((prev) => {
                                  return { ...prev, view: true };
                                });
                              }}
                              five={formatTypeWrap(
                                status === 26
                                  ? "pending"
                                  : status === 3
                                  ? "success"
                                  : status === 2
                                  ? "failed"
                                  : ""
                              )}
                              ManualAddActions={() =>
                                paymeRequestAction(chi, idx)
                              }
                              action
                            />
                          );
                        })}
                      </RavenTable>
                    </div>
                    {/* wrap scroll send */}
                  </div>
                  {/* raven  table end */}
                  {/* moble table start */}
                  <div className="mobile-table-box table-transaction-cover">
                    {payment_requests?.data?.map((chi, idx) => {
                      const {
                        amount,
                        request_type,
                        intiator_fname,
                        intiator_lname,
                        // status,
                        // type,
                        created_at,
                      } = chi;
                      return (
                        <React.Fragment>
                          <MobileTableCard
                            key={idx}
                            amount={formatNumWithCommaNairaSymbol(amount)}
                            text={`${trimLongString(
                              formatTypeFunction(request_type),
                              20
                            )} • ${formatDate(created_at)}`}
                            avatar={`${intiator_fname?.charAt(0) || " "} ${
                              intiator_lname?.charAt(0) || " "
                            }`}
                            onRowClick={() => {
                              setSingleChild(chi);
                              setShowModal((prev) => {
                                return { ...prev, view: true };
                              });
                            }}
                            loading={loading}
                            title={`${lowerCaseWrap(intiator_fname) || "---"} ${
                              lowerCaseWrap(intiator_lname) || "---"
                            }`}
                            // showImg
                          />
                        </React.Fragment>
                      );
                    })}
                  </div>
                  {/* mobile table end */}
                </>
              ) : (
                <>
                  {" "}
                  <NoContentBox
                    title={`No Approval Request found `}
                    text={`Approval Request will appear here when request is made`}
                    bgColor
                    loading={loading}
                    btnText={`Refresh`}
                    onBtnClick={() => {
                      setSearch("");
                      fetchAllPaymentRequest();
                    }}
                  />
                </>
              )}
            </div>
          ) : (
            <NocontentPage
              loading={loading}
              img={noContentImg}
              type="two"
              title={`No Approval Request yet`}
              normtext={`Transaction pending approvals and reviews can be found here.`}
            />
          )}
        </ContainerWrapper>
        {/* container wrapper end */}
      </DashboardLayout>
      {/* genrate start */}
      <RejectPaymentRequestModal
        visible={showModal?.reject}
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, reject: false };
          });
        }}
        detail={singleChild}
        onFinish={handleFinish}
      />
      {/* genrate end */}
      {/* genrate start */}
      <RetryPaymentRequest
        visible={showModal?.return}
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, return: false };
          });
        }}
        detail={singleChild}
        onFinish={handleFinish}
      />
      {/* genrate end */}
      {/* genrate start */}
      <DownloadReceiptModal
        onConfirm={() => {
          setShowModal((prev) => {
            return { ...prev, view: false };
          });
          localStorage.setItem("single-payment-request-id", singleChild?.id);
          //   console.log(param);
          navigate("/dashboard-payment-requests-confirm");
        }}
        onReject={() => {
          setShowModal((prev) => {
            return { ...prev, view: false, reject: true };
          });
        }}
        onRetry={() => {
          setShowModal((prev) => {
            return { ...prev, view: false, return: true };
          });
        }}
        visible={showModal?.view}
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, view: false };
          });
        }}
        detail={singleChild}
        onFinish={handleFinish}
        onView={(param) => {
          setBigImg(param);
          setShowModal((prev) => {
            return { ...prev, big_image: true, view: false };
          });
        }}
      />
      {/* genrate end */}
      {/* switch business account end */}
      <BigPreviewModal
        visible={showModal?.big_image}
        onClose={() => {
          setBigImg("");
          setShowModal((prev) => {
            return { ...prev, big_image: false, view: true };
          });
        }}
        url={bigImg}
      />
      {/* notification modal start */}
    </>
  );
};

export default PayentRequestIndex;
