import React, { useContext, useEffect, useState } from "react";
import DashboardLayout from "../../../layout/dashboard/DashboardLayout";
import TitletextBox from "../../../components/reusables/TitletextBox";
import { useNavigate } from "react-router-dom";
import ContainerWrapper from "../../../components/reusables/ContainerWrapper";
import "../../../styles/dashboard/pos/NewRequestTerminal.css";
import { FaCheck, FaMinus, FaPlus } from "react-icons/fa";
import { RavenInputField } from "@ravenpay/raven-bank-ui";
import BadgeComponent from "../../../components/fragments/badge/BadgeComponent";
import {
  filterEmptyColumns,
  reactSelectStyle,
} from "../../../utils/helper/Helper";
import CollapseFragment from "../../../components/fragments/collapse/CollapseFragment";
import NewPreviewDetailBox from "./NewPreviewDetailBox";
import { useDispatch } from "react-redux";
import ActionContext from "../../../context/ActionContext";
import { useSelector } from "react-redux";
import { requestPos } from "../../../redux/pos";
import TransactionPinModal from "../../../components/reusables/TransactionPinModal";
import { getLgaByStates, getStates } from "../../../redux/verifications";

const NewRequestTerminal = () => {
  //   const navigate = useNavigate();
  const { loading } = useSelector((state) => state.pos);
  const actionCtx = useContext(ActionContext);
  const { user } = useSelector((state) => state.user);
  const [showModal, setShowModal] = useState({
    pin: false,
    success: false,
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { states, Lgas } = useSelector((state) => state.verifications);
  const [step, setStep] = useState(1);
  const stepList = [
    "Terminal Type",
    "Request details",
    "Shipping details",
    "Confirm Information",
  ];

  const handleNextStep = () => {
    if (step !== 4 && step < 4) {
      setStep(step + 1);
      return;
    }
    if (step === 4) {
      setShowModal((prev) => {
        return { ...prev, pin: true };
      });
    }
  };

  const priceIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
      className="img dull-bright-filter"
    >
      <path
        fill="#333"
        d="M2.667 6H4V2h1.333l2.28 4h3.053V2H12v4h1.333v1.333H12v1.334h1.333V10H12v4h-1.334L8.38 10H5.333v4H4v-4H2.667V8.667H4V7.333H2.667V6zm2.666 0h.753l-.753-1.313V6zm0 1.333v1.334h2.28l-.76-1.334h-1.52zm5.333 4V10H9.9l.766 1.333zm-2.293-4l.767 1.334h1.526V7.333H8.373z"
      ></path>
    </svg>
  );

  const transFee = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
      className="img "
    >
      <path
        fill={`#333`}
        d="M8 1.333A6.67 6.67 0 001.333 8 6.67 6.67 0 008 14.667 6.67 6.67 0 0014.667 8 6.669 6.669 0 008 1.333zm-2 4c.367 0 .667.3.667.667A.664.664 0 016 6.667.669.669 0 015.333 6c0-.367.3-.667.667-.667zm.354 5.02c-.1.1-.227.147-.354.147a.495.495 0 01-.353-.147.503.503 0 010-.706l4-4a.503.503 0 01.707 0 .503.503 0 010 .706l-4 4zm3.646.314A.67.67 0 019.327 10c0-.367.3-.667.666-.667.367 0 .667.3.667.667a.663.663 0 01-.66.667z"
      ></path>
    </svg>
  );

  const ravenBankBox = [
    {
      text: "Terminal price",
      right_value: "₦35,000",
      title: "",
      icon: priceIcon,
    },
    {
      text: "Transaction fees",
      right_value: "1.5%",
      title: "",
      icon: transFee,
    },
  ];

  const cardIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      fill="none"
      viewBox="0 0 16 17"
      className="img "
    >
      <path
        fill="#333"
        d="M14.667 5.533c0 .44-.36.8-.8.8H2.133c-.44 0-.8-.36-.8-.8v-.006a2.757 2.757 0 012.76-2.76H11.9a2.768 2.768 0 012.767 2.766zM1.333 8.133v3.34a2.757 2.757 0 002.76 2.76H11.9a2.768 2.768 0 002.767-2.766V8.133c0-.44-.36-.8-.8-.8H2.133c-.44 0-.8.36-.8.8zm4 3.867H4a.504.504 0 01-.5-.5c0-.273.227-.5.5-.5h1.333c.274 0 .5.227.5.5s-.226.5-.5.5zm4.334 0H7a.504.504 0 01-.5-.5c0-.273.227-.5.5-.5h2.667c.273 0 .5.227.5.5s-.227.5-.5.5z"
      ></path>
    </svg>
  );

  const batteryIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      fill="none"
      viewBox="0 0 16 17"
      className="img"
    >
      <path
        fill="#333"
        d="M13.613 6.5a4.855 4.855 0 00-.346-.013.464.464 0 00-.467.466v3.1c0 .26.207.467.466.467.134 0 .247-.007.36-.013 1.04-.12 1.04-.854 1.04-1.387V7.88c0-.533 0-1.267-1.053-1.38zM8.62 3.693H4.9c-2.733 0-3.567.834-3.567 3.567v2.48c0 2.733.834 3.567 3.567 3.567h3.72c2.734 0 3.567-.834 3.567-3.567V7.26c0-2.733-.833-3.567-3.567-3.567zm-3.886 6.26a.499.499 0 01-.607.367.504.504 0 01-.367-.607c.2-.793.2-1.633 0-2.426a.5.5 0 11.973-.24c.24.953.24 1.953 0 2.906zm2.333 0a.499.499 0 01-.607.367.504.504 0 01-.367-.607c.2-.793.2-1.633 0-2.426a.499.499 0 01.367-.607.499.499 0 01.607.367c.24.953.24 1.953 0 2.906zm2.333 0a.499.499 0 01-.607.367.504.504 0 01-.366-.607c.2-.793.2-1.633 0-2.426a.499.499 0 01.367-.607.499.499 0 01.606.367C9.64 8 9.64 9 9.4 9.953z"
      ></path>
    </svg>
  );

  const nfcEnableIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      fill="none"
      viewBox="0 0 16 17"
      className="img"
    >
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M12 6.047a4.954 4.954 0 000 4.906M9.693 4.64a7.84 7.84 0 000 7.72m-2.3-9.127a10.607 10.607 0 00-.006 10.534m-2.3-11.934a13.44 13.44 0 000 13.334"
      ></path>
    </svg>
  );

  const fundIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      fill="none"
      viewBox="0 0 16 17"
      className="img"
    >
      <path
        fill="#292D32"
        d="M8 1.833A6.67 6.67 0 001.333 8.5 6.67 6.67 0 008 15.167 6.67 6.67 0 0014.667 8.5 6.669 6.669 0 008 1.833zm2.354 7.487L6.9 11.713c-.506.354-.8.147-.653-.446l.633-2.56-1.1-.274c-.5-.12-.56-.466-.14-.76L9.094 5.28c.506-.353.8-.147.653.447l-.633 2.56 1.1.273c.5.127.56.467.14.76z"
      ></path>
    </svg>
  );

  const featureList = [
    {
      text: "Credit and debit card payments easily",
      //   right_value: "₦35,000",
      title: "Accept card payments anywhere",
      icon: cardIcon,
    },
    {
      text: "enjoy up to 12 hour battery life",
      //   right_value: "1.5%",
      title: "All day battery life",
      icon: batteryIcon,
    },
    {
      text: "Get your funds 24 hours after end of business",
      //   right_value: "₦35,000",
      title: "Instant settlements",
      icon: fundIcon,
    },
    {
      text: "Enjoy tap to pay with NFC payments",
      //   right_value: "1.5%",
      title: "NFC enabled",
      icon: nfcEnableIcon,
    },
  ];

  const [details, setDetails] = useState({
    owner_type: "Full Ownership",
    terminal_label: "",
    tax: "",
    purpose: "",
    delinery_method: "Home Delivery",
    state: "",
    local_gov: "",
    address: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    const newObj = { ...details, [name]: value };
    setDetails(newObj);
  };
  const ownerTypeList = [
    {
      name: "Full Ownership",
      active: details?.owner_type === "Full Ownership",
    },
    {
      name: "Lease T&C applies",
      active: details?.owner_type === "Lease T&C applies",
    },
  ];

  const homeIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="13"
      fill="none"
      viewBox="0 0 13 13"
      className=""
    >
      <path
        fill="#333"
        d="M7.566 1.666V6.09a.986.986 0 01-.985.985H1.709a.534.534 0 01-.532-.532V3.14c0-1.087.878-1.965 1.964-1.965h3.93a.49.49 0 01.495.49z"
      ></path>
      <path
        fill="#333"
        d="M11.559 8.363c.149 0 .266.118.266.267v.532c0 .884-.713 1.597-1.597 1.597 0-.878-.72-1.597-1.598-1.597-.878 0-1.597.719-1.597 1.597H5.968c0-.878-.718-1.597-1.597-1.597-.878 0-1.597.719-1.597 1.597a1.595 1.595 0 01-1.597-1.597V8.097c0-.293.24-.532.532-.532h5.058c.735 0 1.331-.597 1.331-1.331V3.306c0-.293.24-.533.532-.533h.448c.383 0 .734.208.926.538l.34.596a.134.134 0 01-.116.197c-.735 0-1.331.596-1.331 1.331v1.597c0 .735.596 1.331 1.33 1.331h1.332z"
      ></path>
      <path
        fill="#333"
        d="M4.37 11.824a1.065 1.065 0 100-2.13 1.065 1.065 0 000 2.13zM8.63 11.824a1.065 1.065 0 100-2.13 1.065 1.065 0 000 2.13zM11.824 6.782v.783h-1.597a.534.534 0 01-.533-.533V5.435c0-.293.24-.532.533-.532h.687l.771 1.352c.091.16.139.34.139.527z"
      ></path>
    </svg>
  );

  const pickIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      fill="none"
      viewBox="0 0 14 14"
      className=""
    >
      <path
        fill="#333"
        d="M12.555 12.139H1.444a.42.42 0 00-.417.417.42.42 0 00.417.416h11.111a.42.42 0 00.417-.416.42.42 0 00-.417-.417z"
      ></path>
      <path
        fill="#333"
        d="M9.778 1.444H4.222C2.556 1.444 2 2.44 2 3.667v8.889h10v-8.89c0-1.227-.556-2.222-2.222-2.222zm-3.89 8.473H4.223a.42.42 0 01-.416-.417.42.42 0 01.416-.417H5.89a.42.42 0 01.417.417.42.42 0 01-.417.417zm0-2.5H4.223A.42.42 0 013.806 7a.42.42 0 01.416-.417H5.89A.42.42 0 016.306 7a.42.42 0 01-.417.417zm0-2.5H4.223a.42.42 0 01-.416-.417.42.42 0 01.416-.417H5.89a.42.42 0 01.417.417.42.42 0 01-.417.417zm3.89 5H8.11a.42.42 0 01-.417-.417.42.42 0 01.417-.417h1.667a.42.42 0 01.416.417.42.42 0 01-.416.417zm0-2.5H8.11A.42.42 0 017.694 7a.42.42 0 01.417-.417h1.667a.42.42 0 01.416.417.42.42 0 01-.416.417zm0-2.5H8.11a.42.42 0 01-.417-.417.42.42 0 01.417-.417h1.667a.42.42 0 01.416.417.42.42 0 01-.416.417z"
      ></path>
    </svg>
  );

  const deliveryTypeList = [
    {
      name: "Home Delivery",
      active: details?.delinery_method === "Home Delivery",
      icon: homeIcon,
    },
    {
      name: "Pickup",
      active: details?.delinery_method === "Pickup",
      icon: pickIcon,
    },
  ];

  const purposeOption = [
    { label: "Option 1", value: 1 },
    { label: "Option 2", value: 2 },
  ];

  const handleDisableCheck = () => {
    let val;
    if (step === 2) {
      const check =
        !details?.owner_type ||
        !details?.purpose ||
        !details?.tax ||
        !details?.terminal_label;
      val = check;
    }
    if (step === 3) {
      if (details?.delinery_method === "Home Delivery") {
        const check =
          !details?.state || !details?.local_gov || !details?.address;
        val = check;
      }
    }

    return val;
  };

  const locationIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      className="img"
      viewBox="0 0 20 20"
    >
      <path
        fill="#755AE2"
        fillRule="evenodd"
        d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
        clipRule="evenodd"
      ></path>
    </svg>
  );

  const handleSubmitDetails = async (pin, token) => {
    let val;
    if (details?.delinery_method === "Pickup") {
      const obj = {
        quantity: details?.tax,
        delivery_address:
          "The Quad, Lagos NG, Plot 20 Layi Yusuf Cresent, Lagos Island, Lekki 500001",
        state: "Lagos",
        lga: "Eti Osa",
        reason: details?.owner_type,
        pos_pin: pin,
        two_factor_token: token,
        landmark: details?.landmark,
        pos_request_type: "bankbox",
        delivery_state: details?.state?.label,
        delivery_lga: details?.local_gov?.label,
        source_account_number: actionCtx?.currentAccount?.account_number,
        purpose: details?.purpose?.value,
        isleased: details?.owner_type === "Full Ownership" ? 0 : 1
      };
      val = obj;
    } else {
      const obj = {
        quantity: details?.tax,
        delivery_address: details?.address,
        state: details?.state?.label,
        lga: details?.local_gov?.label,
        reason: details?.owner_type,
        pos_pin: pin,
        two_factor_token: token,
        landmark: details?.landmark,
        pos_request_type: "bankbox",
        delivery_state: details?.state?.label,
        delivery_lga: details?.local_gov?.label,
        source_account_number: actionCtx?.currentAccount?.account_number,
        purpose: details?.purpose?.value,
        isleased: details?.owner_type === "Full Ownership" ? 0 : 1
        //   details?.terminal_type === "BankBox (Card Reader)"
        //     ? "bankbox"
        //     : "standard",
      };
      val = obj;
    }

    console.log(filterEmptyColumns(val));
    return;
    const data = await dispatch(requestPos(filterEmptyColumns(val)));
    if (data?.payload?.data?.status === "success") {
      handleFinish();
    }
  };

  const handleFinish = () => {
    setDetails({
      owner_type: "Full Ownership",
      terminal_label: "",
      tax: "",
      purpose: "",
      delinery_method: "Home Delivery",
      state: "",
      local_gov: "",
      address: "",
    });
    navigate("/dashboard-pos");
    setShowModal((prev) => {
      return { ...prev, pin: false };
    });
  };

  useEffect(() => {
    if (states?.length < 1) {
      dispatch(getStates());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [selectShow, setSelectShow] = useState("");

  return (
    <>
      <DashboardLayout>
        <TitletextBox
          title={`Request for a BankBox`}
          text={`Ready to be a bank to the less banked people, request for BankBox now.`}
          back
          onBack={() => {
            navigate("/dashboard-pos");
          }}
        />
        <ContainerWrapper
          back={step > 1}
          onBack={() => {
            if (step > 1) {
              setStep(step - 1);
            }
          }}
          // stepClickable={}
          disabled={handleDisableCheck()}
          activeStep={step}
          stepList={stepList}
          btnText={
            step === 1
              ? "Select and proceed"
              : step === 2
              ? `Next shipping details`
              : step === 3
              ? `Confirm details`
              : "Request Terminal"
          }
          onBtnClick={handleNextStep}
        >
          <div className="request-new-bankbox-wrapper-index-version-one-five">
            <div className="scrollable-div-box">
              {/* step 1 start -------------------- */}
              {step === 1 && (
                <>
                  {/* bankbox img display box start */}
                  <div className="bankbox-img-display-box grey-bg"></div>
                  {/* bankbox img display box end */}
                  {/* title grey box start */}
                  <div className="title-grey-wrapper-box">
                    <p className="title">Raven BankBox</p>

                    <div className="label-value-wrapper-box grey-bg">
                      {ravenBankBox?.map((chi, idx) => {
                        return (
                          <div
                            key={idx}
                            className="item-list-row border-theme-bottom"
                          >
                            <div className="icon-box">
                              <figure className="img-box white-black-bg">
                                {chi?.icon}
                              </figure>
                            </div>
                            {/* title text box start */}
                            <div className="title-text-box">
                              {chi?.title && (
                                <p className="title">{chi?.title}</p>
                              )}
                              {chi?.text && (
                                <p className="text grey-white-color">
                                  {chi?.text}
                                </p>
                              )}
                            </div>
                            {/* title text box end */}
                            {chi?.right_value && (
                              <p className="value-right">{chi?.right_value}</p>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  {/* title grey box end */}
                  {/* title grey box start */}
                  <div className="title-grey-wrapper-box">
                    <p className="title">Features</p>

                    <div className="label-value-wrapper-box grey-bg">
                      {featureList?.map((chi, idx) => {
                        return (
                          <div
                            key={idx}
                            className="item-list-row border-theme-bottom"
                          >
                            <div className="icon-box">
                              <figure className="img-box white-black-bg">
                                {chi?.icon}
                              </figure>
                            </div>
                            {/* title text box start */}
                            <div className="title-text-box">
                              {chi?.title && (
                                <p className="title">{chi?.title}</p>
                              )}
                              {chi?.text && (
                                <p className="text grey-white-color">
                                  {chi?.text}
                                </p>
                              )}
                            </div>
                            {/* title text box end */}
                            {chi?.right_value && (
                              <p className="value-right">{chi?.right_value}</p>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  {/* title grey box end */}
                </>
              )}
              {/* step 1 end -------------------- */}
              {/* step 2 start --------------------- */}
              {step === 2 && (
                <>
                  {/* select owner type box start */}
                  <div className="select-title-owner-type-box">
                    <p className="title-box grey-white-color">
                      Select ownership type
                    </p>
                    {/* select box wrap start */}
                    <div className="select-box-wrap">
                      {ownerTypeList?.map((chi, idx) => {
                        return (
                          <div
                            className={`select-item grey-bg select-option-class ${
                              chi?.active
                                ? "box-active select-option-class-active"
                                : ""
                            }`}
                            key={idx}
                            onClick={() => {
                              if (chi?.name !== details?.owner_type) {
                                setDetails((prev) => {
                                  return { ...prev, owner_type: chi?.name };
                                });
                              }
                            }}
                          >
                            {" "}
                            <div className="check-box">
                              <FaCheck
                                style={{ filter: "brightness(1000%)" }}
                                className="icon"
                              />
                            </div>
                            <p
                              className={`name ${
                                chi?.name !== details?.owner_type
                                  ? "grey-white-color"
                                  : ""
                              }`}
                            >
                              {chi?.name}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                    {/* select box wrap end */}
                  </div>
                  {/* select owner type box end */}
                  <RavenInputField
                    color="black-light"
                    value={details?.terminal_label}
                    onChange={handleChange}
                    name="terminal_label"
                    label="Terminal label * "
                    placeholder="e.g Cargo Stores"
                    type="text"
                  />
                  {/* info box start */}
                  <div className="info-box">
                    <p className="title">Select a quantity *</p>
                    {/* increase decrease box start */}
                    <div className="increase-decrease-box  border-theme">
                      <div
                        onClick={() => {
                          details.tax >= 1 &&
                            setDetails((prev) => {
                              return {
                                ...prev,
                                tax: Number(Number(details.tax) - 1),
                              };
                            });
                        }}
                        className="decrease-box grey-bg border-profile"
                      >
                        <FaMinus className="grey-white-color" />
                      </div>
                      <div className="input-box">
                        {/* <RavenInputField type={`number`} className={`input`} color={`black-light`} /> */}
                        <input
                          value={details?.tax}
                          onChange={(e) => {
                            e.target.value >= 0 && handleChange(e);
                          }}
                          name="tax"
                          type="number"
                          className="input"
                        />
                      </div>
                      <div
                        onClick={() => {
                          //   details.tax <= 100 &&
                          setDetails((prev) => {
                            return {
                              ...prev,
                              tax: Number(Number(details.tax) + 1),
                            };
                          });
                        }}
                        className="increase-box grey-bg border-profile"
                      >
                        <FaPlus className="grey-white-color" />
                      </div>
                    </div>
                    {/* increase decrease box end */}
                  </div>
                  {/* info box end */}
                  <div className="request-badge-box">
                    <BadgeComponent
                      className={`pending`}
                      text={`Terminal Price: N20,000.00`}
                    />
                  </div>
                  <form
                    style={{
                      padding: "0rem .1rem",
                      width: "100%",
                      zIndex: 10,
                      position: "relative",
                    }}
                    action=""
                    autoComplete="off"
                  >
                    <RavenInputField
                      color="black-light"
                      value={details?.purpose}
                      onChange={(e) => {
                        setDetails((prev) => {
                          return { ...prev, purpose: e };
                        });
                      }}
                      //   name="terminal_label"
                      label="Purpose of request * "
                      placeholder="Dropdown Option"
                      type="select"
                      menuPlacement={`top`}
                      selectOption={purposeOption}
                      selectStyles={reactSelectStyle}
                    />
                  </form>
                </>
              )}
              {/* step 2 end --------------------- */}
              {/* step 3 start --------------------- */}
              {step === 3 && (
                <>
                  {/* select owner type box start */}
                  <div className="select-title-owner-type-box">
                    <p className="title-box grey-white-color">
                      Select shipping type
                    </p>
                    {/* select box wrap start */}
                    <div className="select-box-wrap">
                      {deliveryTypeList?.map((chi, idx) => {
                        return (
                          <div
                            key={idx}
                            className={`select-item grey-bg select-option-class ${
                              chi?.active
                                ? "box-active select-option-class-active"
                                : ""
                            }`}
                            onClick={() => {
                              if (chi?.name !== details?.delinery_method) {
                                setDetails((prev) => {
                                  return {
                                    ...prev,
                                    delinery_method: chi?.name,
                                  };
                                });
                              }
                            }}
                          >
                            {" "}
                            <div className="check-box">
                              <FaCheck
                                style={{ filter: "brightness(1000%)" }}
                                className="icon"
                              />
                            </div>
                            <div className="img-icon-box white-black-bg">
                              <figure className="img-box">{chi?.icon}</figure>
                            </div>
                            <p
                              className={`name ${
                                chi?.name !== details?.delinery_method
                                  ? "grey-white-color"
                                  : ""
                              }`}
                            >
                              {chi?.name}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                    {/* select box wrap end */}
                  </div>
                  {/* select owner type box end */}
                  {/* home delivery start */}
                  <CollapseFragment
                    collapse={
                      details?.delinery_method &&
                      details?.delinery_method === "Pickup"
                    }
                    disableDefaultControl={true}
                    hideTitle={true}
                    className={`collapse-box-business`}
                  >
                    <form
                      style={{
                        width: "100%",
                        position: "relative",
                        zIndex: selectShow === "state" ? 11 : 2,
                      }}
                      action=""
                    >
                      <RavenInputField
                        color="black-light"
                        label="State of resident* "
                        type="select"
                        placeholder="Select a state."
                        selectOption={states}
                        selectStyles={reactSelectStyle}
                        value={details?.state}
                        onChange={(e) => {
                          dispatch(getLgaByStates(e.value));
                          setDetails((prev) => {
                            return { ...prev, state: e, lga: "" };
                          });
                        }}
                        onMenuOpen={() => {
                          setSelectShow("state");
                        }}
                      />
                    </form>

                    <form
                      style={{
                        width: "100%",
                        position: "relative",
                        zIndex: selectShow === "lga" ? 12 : 2,
                      }}
                      action=""
                    >
                      <RavenInputField
                        color="black-light"
                        label="Local government* "
                        type="select"
                        placeholder="Select a local government"
                        selectOption={Lgas}
                        selectStyles={reactSelectStyle}
                        value={details?.local_gov}
                        onChange={(e) => {
                          setDetails((prev) => {
                            return { ...prev, local_gov: e };
                          });
                        }}
                        onMenuOpen={() => {
                          setSelectShow("lga");
                        }}
                        // selectMenuOpen={true}
                        menuPlacement={`top`}
                        disabled={!details?.state}
                      />
                    </form>

                    <form style={{ width: "100%" }} action="">
                      <RavenInputField
                        color="black-light"
                        label="House address* "
                        type="text"
                        placeholder="Name of street "
                        value={details?.address}
                        name="address"
                        onChange={handleChange}
                      />
                    </form>
                  </CollapseFragment>
                  {/* home delivery end */}
                  {/* pick up start */}
                  <CollapseFragment
                    collapse={details?.delinery_method === "Home Delivery"}
                    disableDefaultControl={true}
                    hideTitle={true}
                    className={`collapse-box-business`}
                  >
                    <div className="pick-up-delivery-details-box">
                      <p className="label">Pick up address</p>
                      <div className="raven-loc-box grey-bg">
                        <div className="icon-box">
                          <figure className="img-box">{locationIcon}</figure>
                        </div>
                        <p className="address grey-white-color">
                          The Quad, Lagos NG, Plot 20 Layi Yusuf Cresent, Lagos
                          Island, Lekki 500001
                        </p>
                      </div>
                    </div>
                  </CollapseFragment>
                  {/* pick up end */}
                </>
              )}
              {/* step 3 end --------------------- */}
              {/* step 4 start --------------------- */}
              {step === 4 && (
                <>
                  <NewPreviewDetailBox
                    adress={details?.address}
                    reason={details?.reason}
                    quantity={details?.tax}
                    state={details?.state?.label}
                    lga={details?.local_gov?.label}
                    landmark={details?.landmark}
                    type={details?.owner_type}
                  />
                </>
              )}
              {/* step 4 end --------------------- */}
            </div>
          </div>
        </ContainerWrapper>
      </DashboardLayout>

      {/* pin modal start */}

      <TransactionPinModal
        pin={details?.user_pin}
        setPin={(e) => {
          setDetails((prev) => {
            return { ...prev, user_pin: e };
          });
        }}
        onPay={(pin, token) => {
          handleSubmitDetails(pin, token);
        }}
        value={details?.transfer_pin}
        visible={showModal.pin}
        onCancel={() => {
          setDetails((prev) => {
            return { ...prev, transfer_pin: "" };
          });
          setShowModal((prev) => {
            return { ...prev, pin: false };
          });
        }}
        loading={loading}
        btnLabel={`Confirm Request`}
      />
      {/* pin modal end */}
    </>
  );
};

export default NewRequestTerminal;
