import React from "react";
import VerificationLayoutOneFive from "./VerificationLayoutOneFive";
import BadgeComponent from "../../../components/fragments/badge/BadgeComponent";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RavenToolTip } from "@ravenpay/raven-bank-ui";

const VerificationIndexOneFive = () => {
  const navigate = useNavigate();
  const { business } = useSelector((state) => state.user);
  // const [showModal, setShowModal] = useState({
  //     one: false,
  //     two: true,
  //     three: false,
  // })
  const tierOneIcon = (
    <svg
      className="img"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.9055 2.27762C8.5319 1.96442 9.2692 1.96442 9.8956 2.27762L14.2405 4.4501V10.4042C14.2405 12.4601 12.3401 13.8479 8.90055 16.0201C5.46099 13.8479 3.56055 12.0151 3.56055 10.4042V4.4501L7.9055 2.27762ZM11.6506 7.48692C11.9402 7.19728 11.9402 6.72769 11.6506 6.43805C11.3609 6.14841 10.8913 6.14841 10.6017 6.43805L8.45613 8.58361L7.64556 7.77305C7.35593 7.48341 6.88633 7.48341 6.59669 7.77305C6.30705 8.06269 6.30705 8.53228 6.59669 8.82192L7.93169 10.1569C8.22133 10.4466 8.69093 10.4466 8.98056 10.1569L11.6506 7.48692Z"
        fill="white"
      />
    </svg>
  );

  const tierTwoIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
      className="img"
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M3.77 7.318C3.77 3.884 6.56 1.1 10 1.1c3.44 0 6.23 2.784 6.23 6.218 0 2.325-1.221 4.3-3.115 5.352v1.335c0 1.145-.93 2.073-2.077 2.073H8.962a2.075 2.075 0 01-2.077-2.073V12.67C5.002 11.6 3.77 9.635 3.77 7.318zm3.56.902a.89.89 0 01.89-.89h3.56a.89.89 0 110 1.78H8.22a.89.89 0 01-.89-.89z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#fff"
        d="M8.22 17.12a.89.89 0 100 1.78h3.56a.89.89 0 100-1.78H8.22z"
      ></path>
    </svg>
  );

  const tierThreeIcon = (
    <svg
      className="img"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.75333 3.13112C6.58786 2.32997 7.71974 1.87988 8.89995 1.87988C10.0802 1.87988 11.212 2.32997 12.0466 3.13112C12.8811 3.93228 13.35 5.01888 13.35 6.15188V11.8479C13.35 12.9809 12.8811 14.0675 12.0466 14.8686C11.212 15.6698 10.0802 16.1199 8.89995 16.1199C7.71974 16.1199 6.58786 15.6698 5.75333 14.8686C4.91879 14.0675 4.44995 12.9809 4.44995 11.8479V6.15188C4.44995 5.01888 4.91879 3.93228 5.75333 3.13112ZM9.64162 5.88481C9.64162 5.4752 9.30956 5.14314 8.89995 5.14314C8.49034 5.14314 8.15828 5.4752 8.15828 5.88481V7.66481C8.15828 8.07442 8.49034 8.40648 8.89995 8.40648C9.30956 8.40648 9.64162 8.07442 9.64162 7.66481V5.88481Z"
        fill="white"
      />
    </svg>
  );

  const addressIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 20 20"
      className="img"
      style={{ marginLeft: ".6rem" }}
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M2.18 6.12a.89.89 0 00-.4.736v7.81c0 .748.638 1.354 1.424 1.354h11.392c.787 0 1.424-.606 1.424-1.354v-7.81a.89.89 0 00-.399-.736l-6.17-4.173a.99.99 0 00-1.1 0L2.178 6.12zm3.16 6.043a.742.742 0 000 1.484h7.12a.742.742 0 000-1.484H5.34z"
        clipRule="evenodd"
      ></path>
    </svg>
  );

  const tierList = [
    {
      title: "Tier 1",
      text: "Requires your BVN, NIN ",
      status:
        // String(business?.address) !== "3" ||
        String(business?.nin) !== "3" || String(business?.bvn) !== "3"
          ? "pending"
          : "verified",
      icon: tierOneIcon,
      color: "rgba(117, 90, 226, 1)",
      onClick: () => {
        navigate("/dashboard-verification-bvn-new");
      },
    },
    {
      title: "Tier 2",
      text: "Requires Address verification",
      status: String(business?.address) !== "3" ? "pending" : "verified",
      icon: addressIcon,
      color: "rgba(26, 206, 55, 1)",
      onClick: () => {
        navigate("/dashboard-verification-address-new");
      },
      disable_text: "Complete Tier 1",
      disable:
        String(business?.nin) !== "3" || String(business?.bvn) !== "3"
          ? true
          : false,
    },
    {
      title: "Tier 3",
      text: "Requires your CAC, Directors info, & Indemnity",
      status:
        String(business?.cac) !== "3" ||
        String(business?.directors) !== "3" ||
        String(business?.indemnity) !== "3"
          ? "pending"
          : "verified",
      icon: tierTwoIcon,
      color: "rgba(234, 135, 45, 1)",
      onClick: () => {
        navigate("/dashboard-verification-cac-new");
      },
      disable_text: "Complete Tier 2",
      disable:
        String(business?.address) !== "3" ||
        String(business?.nin) !== "3" ||
        String(business?.bvn) !== "3"
          ? true
          : false,
    },
    {
      title: "Tier 4",
      text: "Requires a Corporate Reference, & Shareholder details",
      status: "pending",
      icon: tierThreeIcon,
      color: "rgba(255, 15, 0, 1)",
      onClick: () => {
        navigate("/dashboard-verification-invite-referee-new");
      },
      disable_text: "Complete Tier 3",
      disable:
      (  String(business?.cac) !== "3" ||
        String(business?.directors) !== "3" ||
        String(business?.indemnity) !== "3") && String(business?.cac) !== "1"
          ? true
          : false,
      hide: String(business?.cac) === "7",
    },
  ];

  const arrowIcon = (
    <svg
      className="img"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="dull-bright-filter"
        d="M6 12L10 8L6 4"
        stroke="#292929"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const verifyIcon = (
    <svg
      className="img"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_19614_2699)">
        <g filter="url(#filter0_di_19614_2699)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.66611 4.99891C5.67437 4.95809 8.41528 3.81033 10.5 1.94446C12.5847 3.81033 15.3256 4.95809 18.3339 4.99891C18.4431 5.64968 18.5 6.31821 18.5 7.00003C18.5 12.2249 15.1608 16.6698 10.5 18.3172C5.83923 16.6698 2.5 12.2249 2.5 7.00003C2.5 6.31821 2.55686 5.64968 2.66611 4.99891ZM14.2071 8.70711C14.5976 8.31658 14.5976 7.68342 14.2071 7.29289C13.8166 6.90237 13.1834 6.90237 12.7929 7.29289L9.5 10.5858L8.20711 9.29289C7.81658 8.90237 7.18342 8.90237 6.79289 9.29289C6.40237 9.68342 6.40237 10.3166 6.79289 10.7071L8.79289 12.7071C9.18342 13.0976 9.81658 13.0976 10.2071 12.7071L14.2071 8.70711Z"
            fill="#1ACE37"
          />
          <path
            d="M2.88027 5.24424C5.78911 5.15552 8.44316 4.0525 10.5 2.27728C12.5568 4.0525 15.2109 5.15552 18.1197 5.24424C18.2055 5.8169 18.25 6.40319 18.25 7.00003C18.25 12.0845 15.0204 16.4152 10.5 18.0517C5.97958 16.4152 2.75 12.0845 2.75 7.00003C2.75 6.40319 2.79448 5.8169 2.88027 5.24424ZM14.3839 8.88388C14.872 8.39573 14.872 7.60427 14.3839 7.11612C13.8957 6.62796 13.1043 6.62796 12.6161 7.11612L9.5 10.2322L8.38388 9.11612C7.89573 8.62796 7.10427 8.62796 6.61612 9.11612C6.12796 9.60427 6.12796 10.3957 6.61612 10.8839L8.61612 12.8839C9.10427 13.372 9.89573 13.372 10.3839 12.8839L14.3839 8.88388Z"
            stroke="#B3EFBC"
            strokeWidth="0.5"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_di_19614_2699"
          x="0.5"
          y="0.944458"
          width="20"
          height="20.6727"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.700654 0 0 0 0 0.935948 0 0 0 0 0.738562 0 0 0 0.3 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_19614_2699"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_19614_2699"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="5" />
          <feGaussianBlur stdDeviation="1.65" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.4 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect2_innerShadow_19614_2699"
          />
        </filter>
        <clipPath id="clip0_19614_2699">
          <rect
            width="20"
            height="20"
            className="white-black-color"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );

  return (
    <>
      <VerificationLayoutOneFive>
        <div
          onClick={() => {
            // console.log(business);
          }}
          className="middle-veri-content-box"
        >
          <p className="title">Begin your verification process</p>
          {/* content select wrap start */}
          <div className="tier-select-wrap-box card-bg shadow-shadow-box-shadow border-theme">
            {tierList?.map((chi, idx) => {
              const {
                color,
                icon,
                status,
                text,
                title,
                onClick,
                disable,
                hide,
                disable_text,
              } = chi;
              if (hide) {
                return <React.Fragment key={idx}></React.Fragment>;
              }
              return (
                <div
                  onClick={() => {
                    !disable && onClick && onClick();
                  }}
                  key={idx}
                  className={`item-tier-row border-theme-bottom ${
                    disable && "tooltip-hover-wrap"
                  }`}
                  style={{ cursor: disable ? "not-allowed" : "pointer" }}
                >
                  {disable && (
                    <RavenToolTip
                      position={`right`}
                      color="black-light"
                      text={disable_text}
                    />
                  )}
                  <div
                    className={`img-wrap-box ${
                      idx === 1 && "img-wrap-box-shift"
                    }`}
                  >
                    <figure
                      style={{ backgroundColor: color }}
                      className="img-box"
                    >
                      {icon}
                    </figure>
                  </div>
                  <div className="title-text-box">
                    <p className="title">{title}</p>
                    <p className="text grey-white-color">{text}</p>
                  </div>
                  <BadgeComponent
                    text={status}
                    className={status === "verified" ? "success" : status}
                  />
                  <div className="right-arrow">
                    <figure className="img-box">{arrowIcon}</figure>
                  </div>
                </div>
              );
            })}
          </div>
          {/* content select wrap end */}
          {/* bottom text start */}
          <div className="bottom-text-box">
            <div className="img-veri-box">
              <figure className="img-box">{verifyIcon}</figure>
            </div>
            <p className="text grey-white-color">
              Verification protects customers and their money from fraud.
            </p>
          </div>
          {/* bottom text end */}
        </div>
      </VerificationLayoutOneFive>
    </>
  );
};

export default VerificationIndexOneFive;
