import React, { useContext, useEffect, useState } from "react";
import DashboardLayout from "../../../layout/dashboard/DashboardLayout";
import TitletextBox from "../../../components/reusables/TitletextBox";
import ContainerWrapper from "../../../components/reusables/ContainerWrapper";
import { useNavigate } from "react-router-dom";
import NoContent from "../../../components/reusables/NoContent";
import DeleteInvoiceModal from "./DeleteInvoiceModal";
import "../../../styles/dashboard/invoice/existing_invoice.css";
import "react-circular-progressbar/dist/styles.css";

import {
  RavenInputField,
  RavenPagination,
  RavenTable,
  RavenTableRow,
  RavenToolTip,
} from "@ravenpay/raven-bank-ui";
import { useDispatch, useSelector } from "react-redux";
import noContentImg from "../../../assets/no-content-img.png";
import { getInvoices, getInvoiceSettings } from "../../../redux/invoice";
import {
  capitalizeFirstLetter,
  formatDate,
  formatMetaData,
  formatNumWithCommaNairaSymbol,
  getPageNum,
  getTotalPage,
  // lowerCaseWrap,
  trimLongString,
} from "../../../utils/helper/Helper";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import useDebounce from "../../../utils/helper/UseDebounce";
import NoContentBox from "../../../components/reusables/NoContentBox";
import MobileTableCard from "../../../components/reusables/MobileTableCard";
import ModalOverviewModalInvoice from "./MobileverviewModal";
import useWindowSize from "../../../utils/helper/UseWindowSize";
import NocontentPage from "../../../components/reusables/NocontentPage";
// import PerPageComponent from "../../../components/reusables/PerPageComponent";
import ActionContext from "../../../context/ActionContext";
import InvoiceSettings from "./InvoiceSettings";
import InvoiceBusinessModal from "./InvoiceBusinessModal";
import InvoiceSettingsAddress from "./InvoiceSettingAddress";

function Invoice() {
  let content = true;
  const actionCtx = useContext(ActionContext);

  const [showModal, setShowModal] = useState({
    delete: false,
    mobile_view: false,
    setting: false,
    setting_address: false,
    business_verify: false,
  });
  const [singleChild, setSingleChild] = useState({});
  const dispatch = useDispatch();
  const { user_resource_and_permissions } = useSelector((state) => state.user);
  const { loading, invoices, invoiceSetting } = useSelector(
    (state) => state.invoice
  );

  const [urlBack, setUrlBack] = useState("");
  useEffect(() => {
    const url = window?.location?.href?.split("?")[1];
    if (url) {
      setUrlBack(url);
    }
  }, []);

  const headerList = [
    "INVOICE ID",
    "INVOICE TITLE",
    "CUSTOMER",
    " DUE DATE",
    "SUBTOTAL AMOUNT",
    "TOTAL AMOUNT",
    "STATUS",
  ];

  const formatTypeWrap = (param) => {
    // 0 = pending, 1 = unpaid, 2 = paid, 3 = due
    return (
      <div
        className={`status-type-wrap ${
          Number(param) === 0 && "status-type-wrap-pending"
        } ${Number(param) === 2 && "status-type-wrap-success "} ${
          Number(param) === 3 && "status-type-wrap-fail"
        } ${Number(param) === 1 && "status-type-wrap-pending"} ${
          Number(param) === 0 && "status-type-wrap-pending"
        }`}
      >
        <span>
          {" "}
          {Number(param) === 1
            ? "Unpaid"
            : Number(param) === 0
            ? "Pending"
            : Number(param) === 3
            ? "Due"
            : Number(param) === 2
            ? "Paid"
            : Number(param) === 4
            ? "Draft"
            : param}
        </span>
      </div>
    );
  };

  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);

  const invoiceActions = (param) => {
    return (
      <div className="invoice-table-action-wrap">
        {loading ? (
          <Skeleton width={32} height={32} circle />
        ) : (
          <>
            {" "}
            {user_resource_and_permissions?.allowed_permissions?.includes(
              "can_delete_invoice"
            ) && (
              <figure
                onClick={() => {
                  setSingleChild(param);
                  setShowModal((prev) => {
                    return { ...prev, delete: true };
                  });
                }}
                className="img-box delete-box tooltip-hover-wrap darken-action-bg"
              >
                <RavenToolTip
                  color={`black-light`}
                  position={`left`}
                  text={`Delete invoice ${param?.title || ""}`}
                />
                <svg
                  width="15"
                  height="18"
                  viewBox="0 0 15 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="img"
                >
                  <path
                    d="M10.5 4.5V3.9C10.5 3.05992 10.5 2.63988 10.3365 2.31901C10.1927 2.03677 9.96323 1.8073 9.68099 1.66349C9.36012 1.5 8.94008 1.5 8.1 1.5H6.9C6.05992 1.5 5.63988 1.5 5.31901 1.66349C5.03677 1.8073 4.8073 2.03677 4.66349 2.31901C4.5 2.63988 4.5 3.05992 4.5 3.9V4.5M6 8.625V12.375M9 8.625V12.375M0.75 4.5H14.25M12.75 4.5V12.9C12.75 14.1601 12.75 14.7902 12.5048 15.2715C12.289 15.6948 11.9448 16.039 11.5215 16.2548C11.0402 16.5 10.4101 16.5 9.15 16.5H5.85C4.58988 16.5 3.95982 16.5 3.47852 16.2548C3.05516 16.039 2.71095 15.6948 2.49524 15.2715C2.25 14.7902 2.25 14.1601 2.25 12.9V4.5"
                    stroke="#FF0F00"
                    strokeWidth="1.4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </figure>
            )}
          </>
        )}
      </div>
    );
  };
  const size = useWindowSize();
  const [refreshTable, setRefreshTable] = useState(false);
  useEffect(() => {
    getAllInvoice();
    dispatch(getInvoiceSettings());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTable]);

  // useEffect(() => {
  //   dispatch(getInvoiceSettings());
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const getAllInvoice = async (page, searchVal) => {
    const obj = {
      per_page: actionCtx?.perPageVal?.value,
      current_page: page || 1,
      status: statusVal?.value || "all",
      search: searchVal || "",
    };
    const data = await dispatch(getInvoices(obj));
    if (data.payload.data.status === "success") {
      if (data.payload.data.data.data.length > 0) {
        setTableData(true);
      }
    }
  };

  const handleFinish = () => {
    setRefreshTable(!refreshTable);
    setSingleChild({});
    setShowModal((prev) => {
      return { ...prev, delete: false, setting: false, setting_address: false };
    });
  };
  const [statusVal, setStatusVal] = useState({ label: "All", value: "all" });
  const statusOptions = [
    { label: "All", value: "all" },
    { label: "Draft", value: "4" },
    // { label: "Pending", value: "0" },
    { label: "Unpaid", value: "1" },
    { label: "Paid", value: "2" },
    { label: "Due", value: "3" },
  ];

  const getAllInvoiceBySort = async (status, page) => {
    const obj = {
      per_page: actionCtx?.perPageVal?.value,
      current_page: page || 1,
      status: String(status) || "all",
      search: search || "",
    };
    // console.log(obj);
    dispatch(getInvoices(obj));
  };

  // const [userInfo, setUserInfo] = useState(false);
  const [search, setSearch] = useState("");
  const debouncedSearchTerm = useDebounce(search, 1000);
  useEffect(() => {
    let isMount = true;

    if (isMount && debouncedSearchTerm?.length > 1) {
      const obj = {
        per_page: actionCtx?.perPageVal?.value,
        current_page: 1,
        status: statusVal?.value || "all",
        search: search || "",
      };
      dispatch(getInvoices(obj));
    }

    if (isMount && debouncedSearchTerm?.length < 1) {
      const obj = {
        per_page: actionCtx?.perPageVal?.value,
        current_page: 1,
        status: statusVal?.value || "all",
        search: "",
      };
      dispatch(getInvoices(obj));
    }

    return () => {
      isMount = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  const noContentList = [
    "Track of financial transactions",
    "Manage payments and track outstanding balances",
    "Comply with legal requirements for record keeping",
  ];

  const [tableData, setTableData] = useState(false);

  return (
    <>
      <DashboardLayout loadingContent={invoices?.data?.length > 0 && !tableData}>
        {invoices?.data?.length > 0 || tableData ? (
          <>
            <TitletextBox
              title={`Invoices`}
              text={`All created invoices in one section`}
              addBtn={user_resource_and_permissions?.allowed_permissions?.includes(
                "can_create_invoice"
              )}
              toolTipMsg={`Create a new invoice`}
              onAdd={() => {
                // console.log(invoiceSetting);
                if (invoiceSetting?.address) {
                  navigate("/dashboard-invoice-new");
                } else {
                  setShowModal((prev) => {
                    return { ...prev, business_verify: true };
                  });
                }
                // setShowModal((prev) => {
                //   return { ...prev, business_verify: true };
                // });
              }}
              back={urlBack}
              onBack={() => navigate(urlBack ? `/${urlBack}` : -1)}
              invoiceSettings
              onInvoiceSettings={() => {
                setShowModal((prev) => {
                  return { ...prev, setting: true };
                });
              }}
            ></TitletextBox>
            {/* top invoice container start */}
            <div
              // onClick={() => {
              //   console.log(invoices);
              // }}
              className="top-invoice-container-box"
            >
              {/* box start */}
              <div className="box card-bg">
                <div className="title grey-white-color">
                  Invoice created{" "}
                  <div className="title-total black-fade-white-bg">
                    <span>{invoices?.total_invoice || "0"}</span>
                  </div>
                </div>
                <div className="value-box grey-bg">
                  {loading ? (
                    <Skeleton width={`20%`} height={20} />
                  ) : (
                    <p className="value">
                      {formatNumWithCommaNairaSymbol(
                        invoices?.total_invoice_amount || 0
                      ) || "0"}
                    </p>
                  )}
                </div>
              </div>
              {/* box end */}
              {/* box start */}
              <div className="box card-bg">
                <div className="title grey-white-color">
                  Total Paid Invoice{" "}
                  <div className="title-total black-fade-white-bg">
                    <span>{invoices?.total_paid_invoice || "0"}</span>
                  </div>
                </div>
                {/* <p className="title grey-white-color">Total Paid Invoice</p> */}
                <div className="value-box grey-bg">
                  {loading ? (
                    <Skeleton width={`20%`} height={20} />
                  ) : (
                    <p style={{ color: "#1ACE37" }} className="value">
                      {formatNumWithCommaNairaSymbol(
                        invoices?.total_paid_amount || 0
                      ) || "0"}
                    </p>
                  )}
                </div>
              </div>
              {/* box end */}

              {/* box start */}
              <div className="box card-bg">
                <div className="title grey-white-color">
                  Total Overdue{" "}
                  <div className="title-total black-fade-white-bg">
                    <span>{invoices?.total_due_invoice || "0"}</span>
                  </div>
                </div>
                {/* <p className="title grey-white-color">Total Overdue</p> invoice-single-id*/}
                <div className="value-box grey-bg">
                  {loading ? (
                    <Skeleton width={`20%`} height={20} />
                  ) : (
                    <p style={{ color: "#FF0F00" }} className="value">
                      {formatNumWithCommaNairaSymbol(
                        invoices?.total_due_amount || 0
                      ) || "0"}
                    </p>
                  )}
                </div>
              </div>
              {/* box end */}
              {/* box start */}
              <div className="box card-bg">
                <div className="title grey-white-color">
                  Total Pending invoice{" "}
                  <div className="title-total black-fade-white-bg">
                    <span>{invoices?.total_unpaid_invoice || "0"}</span>
                  </div>
                </div>
                {/* <p className="title grey-white-color">Total Pending invoice</p> */}
                <div className="value-box grey-bg">
                  {loading ? (
                    <Skeleton width={`20%`} height={20} />
                  ) : (
                    <p style={{ color: "#EA872D" }} className="value">
                      {formatNumWithCommaNairaSymbol(
                        invoices?.total_unpaid_amount || 0
                      ) || "0"}
                    </p>
                  )}
                </div>
              </div>
              {/* box end */}
              {/* box start */}
            {String(invoices?.total_draft_count) !== "0" &&  <div className="box card-bg">
                <div className="title grey-white-color">
                  Total Draft invoice{" "}
                  <div className="title-total black-fade-white-bg">
                    <span>{invoices?.total_draft_count || "0"}</span>
                  </div>
                </div>
                {/* <p className="title grey-white-color">Total Pending invoice</p> */}
                <div className="value-box grey-bg">
                  {loading ? (
                    <Skeleton width={`20%`} height={20} />
                  ) : (
                    <p style={{ color: "#476885" }} className="value">
                      {formatNumWithCommaNairaSymbol(
                        invoices?.total_draft_amount || 0
                      ) || "0"}
                    </p>
                  )}
                </div>
              </div>}
              {/* box end */}
            </div>
            {/* top invoice container end */}
            {/* wrap start */}
            {!content && (
              <ContainerWrapper width={`100%`} dontShowHeader>
                <NoContent
                  onClick={() => navigate("/dashboard-invoice-new")}
                  title="No Invoice created yet."
                />
              </ContainerWrapper>
            )}
            {content && (
              <ContainerWrapper
                pagination={
                  invoices?.next_page_url_params ||
                  invoices?.prev_page_url_params ? (
                    <RavenPagination
                      color={`black-light`}
                      blackHover
                      currentPage={Number(invoices?.current_page) || 1}
                      nextPage={invoices?.next_page_url_params}
                      prevPage={invoices?.prev_page_url_params}
                      onNext={(e) => {
                        getAllInvoice(getPageNum(e));
                      }}
                      onPrev={(e) => {
                        getAllInvoice(getPageNum(e));
                      }}
                      totalPage={
                        getTotalPage(
                          Number(invoices?.per_page),
                          Number(invoices?.total)
                        ) || 1
                      }
                      onNumView={(e) => {
                        getAllInvoice(e);
                      }}
                    />
                  ) : (
                    ""
                  )
                }
                width={`100%`}
                dontShowHeader
              >
                <div
                  // onClick={() => {
                  //   console.log(invoices);
                  // }}
                  className="invoice-main-wrap"
                >
                  {/* title text box start */}
                  {/*  */}
                  {/* title text box end */}
                  {/* filter and sort wrap start */}
                  <div className="filter-sort-wrap-box border-theme-bottom">
                    {/* per page start */}
                    {/* <PerPageComponent /> */}
                    {/* per page end */}
                    {/* status box start */}
                    <div className="status-box-wrap">
                      {statusOptions?.map((chi, idx) => {
                        const { value, label } = chi;
                        return (
                          <div
                            key={idx}
                            className={`child  ${
                              statusVal?.value === value && "child-active"
                            }`}
                            onClick={() => {
                              // console.log(value);
                              getAllInvoiceBySort(value);
                              setStatusVal(chi);
                            }}
                          >
                            <span className="grey-white-color">{label}</span>
                          </div>
                        );
                      })}
                    </div>
                    {/* status box end */}
                    {/* search satatrt */}
                    <form
                      autoComplete="off"
                      onSubmit={(e) => {
                        e.preventDefault();
                      }}
                      action=""
                    >
                      {" "}
                      <RavenInputField
                        className={`invoice-search`}
                        type={`search`}
                        color={`black-light`}
                        placeholder={`Search`}
                        value={search}
                        onChange={(e) => {
                          setSearch(e.target?.value);
                        }}
                      />
                    </form>

                    {/* search end */}
                  </div>
                  {/* filter and sort wrap end */}
                  {/* table start */}
                  {invoices?.data?.length > 0 ? (
                    <div className="table-wrap table-wrap-invoce-fitler">
                      <RavenTable
                        className={`table-fixed-type ${
                          formatMetaData(user?.theme_display_settings)
                            ?.table_font === "bold" && "table-business"
                        }`}
                        headerList={headerList}
                        action
                      >
                        {invoices?.data?.map((chi, idx) => {
                          const {
                            displayId,
                            title,
                            customer_name,
                            due_date,
                            gross,
                            status,
                            net,
                          } = chi;
                          return (
                            <RavenTableRow
                              loading={loading}
                              onRowClick={() => {
                                if (
                                  user_resource_and_permissions?.allowed_permissions?.includes(
                                    "can_view_invoice"
                                  )
                                ) {
                                  if (Number(chi?.status) === 4) {
                                    localStorage?.setItem(
                                      "invoice-draft-single-id",
                                      chi?.id
                                    );
                                    navigate("/dashboard-invoice-new");
                                    // console.log(chi?.id);
                                  } else {
                                    localStorage?.setItem(
                                      "invoice-single-id",
                                      chi?.id
                                    );
                                    navigate("/dashboard-invoice-preview");
                                  }
                                }
                              }}
                              action
                              key={idx}
                              one={displayId || "---"}
                              two={capitalizeFirstLetter(title) || "---"}
                              three={customer_name}
                              four={formatDate(due_date)?.split("— ")[0]}
                              six={formatNumWithCommaNairaSymbol(gross)}
                              five={formatNumWithCommaNairaSymbol(net)}
                              seven={formatTypeWrap(status)}
                              ManualAddActions={() => {
                                return status !== 2 ? (
                                  invoiceActions(chi)
                                ) : (
                                  <>&nbsp;</>
                                );
                              }}
                            />
                          );
                        })}
                      </RavenTable>

                      <div className="mobile-table-box">
                        {invoices?.data?.map((chi, idx) => {
                          const { title, customer_name, due_date, net } = chi;
                          return (
                            <MobileTableCard
                              key={idx}
                              amount={formatNumWithCommaNairaSymbol(net)}
                              text={`${
                                formatDate(due_date).split("—")[0]
                              } • ${trimLongString(customer_name, 15)}`}
                              avatar={title}
                              onRowClick={() => {
                                if (
                                  user_resource_and_permissions?.allowed_permissions?.includes(
                                    "can_view_invoice"
                                  )
                                ) {
                                  setSingleChild(chi);
                                  setShowModal((prev) => {
                                    return { ...prev, mobile_view: true };
                                  });
                                }
                              }}
                              loading={loading}
                              title={title}
                              showImg
                            />
                          );
                        })}
                      </div>
                    </div>
                  ) : (
                    <NoContentBox
                      title={`No invoice found for ${statusVal?.label} invoice`}
                      text={`Click on the filter status to view invoice of your choice`}
                    />
                  )}
                  {/* table end */}
                </div>
              </ContainerWrapper>
            )}
          </>
        ) : (
          <NocontentPage
            loading={loading}
            onBtnClick={() => {
              if (invoiceSetting?.address) {
                navigate("/dashboard-invoice-new");
              } else {
                setShowModal((prev) => {
                  return { ...prev, business_verify: true };
                });
              }
            }}
            title={`With Invoice you got the 
superpower ⚡️ to power payment 
for your business`}
            img={noContentImg}
            list={noContentList}
            btntext={
              user_resource_and_permissions?.allowed_permissions?.includes(
                "can_create_invoice"
              ) && `Create Invoice`
            }
          />
        )}
        {/* wrap end */}
      </DashboardLayout>
      {/* modal start */}
      <DeleteInvoiceModal
        detail={singleChild}
        visible={showModal?.delete}
        onCancel={() => {
          setShowModal((prev) => {
            return { ...prev, delete: false };
          });
        }}
        onFinish={handleFinish}
      />
      {/* modal end */}
      {/* modal start */}
      <ModalOverviewModalInvoice
        detail={singleChild}
        visible={showModal?.mobile_view && size.width < 900}
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, mobile_view: false };
          });
        }}
        onDelete={() => {
          setShowModal((prev) => {
            return { ...prev, mobile_view: false, delete: true };
          });
        }}
      />
      {/* modal end */}
      {/* modal start */}
      <InvoiceSettings
        onFinish={handleFinish}
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, setting: false };
          });
        }}
        visible={showModal?.setting}
      />
      {/* invoice end */}
      {/* modal start */}
      <InvoiceSettingsAddress
        onFinish={() => {
          handleFinish();
          navigate("/dashboard-invoice-new");
        }}
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, setting_address: false };
          });
        }}
        visible={showModal?.setting_address}
      />
      {/* invoice end */}
      {/* modal start */}
      <InvoiceBusinessModal
        // onFinish={handleFinish}
        onAddAddress={() => {
          setShowModal((prev) => {
            return { ...prev, setting_address: true, business_verify: false };
          });
        }}
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, business_verify: false };
          });
        }}
        visible={showModal?.business_verify}
      />
      {/* invoice end */}
    </>
  );
}

export default Invoice;
