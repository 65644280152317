import React from "react";
// import NewSelectScheduleBox from "../../../components/reusables/NewSelectScheduleBox";
// import SelectScheduleBox from "../../../../components/reusables/SelectScheduleBox";Group 238182raven-default-bank-img.svg
import "../../../styles/fragments/PreviewDetailsCommonBox.css";
// import bankIconDefault from "../../../assets/bank-general-img.svg";
import ravenLogo from "../../../assets/raven-small-logo-green.svg";
// import bankIconDefault from "../../../assets/bank-general-img.svg";
import { RavenToggleSlide } from "@ravenpay/raven-bank-ui";
// import bankIcon from "../../../assets/bank-general-img.svg";
import moment from "moment";
import {
  capitalizeAllWords,
  formatDate,
  lowerCaseWrap,
  trimLongString,
} from "../../../utils/helper/Helper";
// import nairaIcon from "../../../assets/nairaIcon.svg";
// import usdIcon from "../../../assets/usdIcon.svg";
// import kshIcon from "../../../assets/kshIcon.svg";
// import poundsIcon from "../../../assets/poundsIcon.svg";
// import cedicsIcon from "../../../assets/cedicsIcon.svg";
// import randsIcon from "../../../assets/randsIcon.svg";
// import { FaTimes } from "react-icons/fa";
// import { useDispatch } from "react-redux";
// import { getBeneficiaries } from "../../../../redux/settings";
// import { useSelector } from "react-redux";

// const restructureName = (name) => {
//     let val;
//     const nameSplit = name?.split(" ");
//     if (nameSplit?.length > 2) {
//       const newVal = `${nameSplit[0]} ${nameSplit[1]}  ...`;
//       val = newVal;
//       // eslint-disable-next-line no-unused-expressions
//     } else {
//       val = name;
//     }
//     return val;
//   };

const NewPreviewDetailBox = ({
  collapse,
  amount,
  name,
  bank,
  account_name,
  narration,
  onChange,
  value,
  id,
  checked,
  account_num,
  totalFee,
  onChangeSchedule,
  valueSchedule,
  enableTime,
  onReset,
  details,
  bulkAmount,
  setDetails,
  handleRemoveFromList,
  account_title,
  account_purpose,
  currency,
  primary_currency,
  handleChange,
  list,
}) => {
  //   const [viewAll, setViewAll] = useState(false);

  // const checkCurrency = (param) => {
  //   return param === "Naira"
  //     ? nairaIcon
  //     : param === "Pounds"
  //     ? poundsIcon
  //     : param === "USD"
  //     ? usdIcon
  //     : param === "Rands"
  //     ? randsIcon
  //     : param === "Cedics"
  //     ? cedicsIcon
  //     : kshIcon;
  // };

  return (
    <div className="new-preview-detail-box-wrap card-bg border-theme">
      {/* img top box start */}
      <div className="img-top-box-wrap border-theme-bottom">
        <div className="img-wrap">
          <figure className="img-box">
            <img src={ravenLogo} alt="" className="img" />
          </figure>
          {/* <div className="arrow-wrap grey-bg">
            <div className="arrow-box">
              <FaArrowRight className="icon" />
            </div>
          </div> */}
        </div>
        {/* amount date box styart */}
        <div className="amount-date-box">
          <p className="amount">
            {trimLongString(capitalizeAllWords(account_title), 25)}
          </p>
          <p className="date grey-white-color-white">
            {valueSchedule
              ? formatDate(valueSchedule)
              : formatDate(moment().format("YYYY-MM-DD HH:mm:ss"))}
          </p>
        </div>
        {/* amount date box end */}
      </div>
      {/* img top box end */}
      {/* middle content wrap start */}
      <div className="middle-content-wrap border-theme-bottom">
        <>
          {/* box start */}
          <div className="box">
            <p className="label grey-white-color-white">Account Title:</p>
            <p className="value">{lowerCaseWrap(account_title) || ""}</p>
          </div>
          {/* box end */}
          {/* box start */}
          <div className="box">
            <p className="label grey-white-color-white">Account Purpose:</p>
            <p className="value">{trimLongString(account_purpose, 16) || ""}</p>
          </div>
          {/* box end */}
          {/* box start */}
          <div className="box">
            <p className="label grey-white-color-white">Primary Currency:</p>
            <p className="value">{primary_currency || ""}</p>
          </div>
          {/* box end */}
        </>
      </div>
      {/* middle content wrap end */}
      {/* bottom- content wrap start */}
      <div className="bottom-content-wrap">
        {/* bottom box start */}
        <div className="bottom-box">
          <p className="text grey-white-color">{`Set as Primary Account`}</p>
          <RavenToggleSlide
            onChange={onChange}
            id={id}
            value={value}
            color={`purple-light`}
            checked={checked}
            //   className={`toggle-box-wrap`}
          />
        </div>
        {/* bottom box end */}

        {/* template start ------------------ */}
      </div>
      {/* bottom- content wrap end */}
    </div>
  );
};

export default NewPreviewDetailBox;
