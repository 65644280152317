import {
  RavenCheckBox,
  RavenInputField,
  RavenNumberFormat,
} from "@ravenpay/raven-bank-ui";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ContainerWrapper from "../../../../components/reusables/ContainerWrapper";
import TitletextBox from "../../../../components/reusables/TitletextBox";
import ActionContext from "../../../../context/ActionContext";
import DashboardLayout from "../../../../layout/dashboard/DashboardLayout";
import {
  getBetTypes,
  makeBettingPayment,
  validateBetIdNumber,
} from "../../../../redux/billPayment";
import "../../../../styles/dashboard/bill-payment/Airtime.css";
import {
  convertObjectToFormData,
  filterEmptyColumns,
  formatNumWithCommaNairaSymbol,
  formatNumWithoutCommaNaira,
  reactSelectStyle,
} from "../../../../utils/helper/Helper";
import { ThreeDots } from "react-loader-spinner";
import useDebounce from "../../../../utils/helper/UseDebounce";
// import PreviewBettingDetailsBox from "./PreviewbettingBox";
import TransactionPinModal from "../../../../components/reusables/TransactionPinModal";
import SuccessModal from "../../../../components/reusables/SuccessModal";
import { getAllAccounts, getUser } from "../../../../redux/user";
import NewPreviewDetailBox from "./NewPreviewBox";
import useWindowSize from "../../../../utils/helper/UseWindowSize";
import DescriptionPreviewBox from "../../../../components/initiator-des-box/DescriptionPreviewBox";
// import PreviewAirtimeDetailsBox from "../airtime/PreviewAirtime";

const DashboardBettingIndex = () => {
  const [fromDate, setFromDate] = useState("");
  const [showModal, setShowModal] = useState({
    pin: false,
    success: false,
  });
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const { loading, betTypes, loadingVerify } = useSelector(
    (state) => state.billPayment
  );
  const actionCtx = useContext(ActionContext);
  const navigate = useNavigate();
  const stepList = ["Betting detail", "Confirm Transactions"];
  const [step, setStep] = useState(1);

  const [details, setDetails] = useState({
    customer_id: "",
    type: "",
    amount: "",
    source_account: "",
    user_pin: "",
    name: "",
    retrieve_info: "",
    beneficiary: "",
    description: "",
    des_imges: [],
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    const obj = { ...details, [name]: value };
    setDetails(obj);
  };

  useEffect(() => {
    dispatch(getBetTypes());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validateBetidFunc = async (obj) => {
    if (details?.customer_id && details?.type) {
      const defaultObj = {
        customer_id: details?.customer_id,
        type: details?.type?.value,
      };
      // console.log(defaultObj);
      const data = await dispatch(validateBetIdNumber(defaultObj));
      if (data?.payload?.status === "success") {
        const val = data?.payload?.data;
        setDetails((prev) => {
          return { ...prev, retrieve_info: val, name: val?.name };
        });
        //   console.log(val);
      }
    }
  };

  const debouncedSearchTerm = useDebounce(details?.customer_id, 1500);
  useEffect(() => {
    let isMount = true;

    if (isMount && details?.customer_id.length > 3 && details?.type) {
      const obj = {
        customer_id: details?.customer_id,
        type: details?.type?.value,
      };
      validateBetidFunc(obj);
    }

    if (isMount && debouncedSearchTerm?.length < 1) {
    }

    return () => {
      isMount = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  const handleSubmit = async (pin, token) => {
    const obj = {
      customer_id: details?.customer_id,
      type: details?.type?.value,
      amount: formatNumWithoutCommaNaira(details?.amount),
      user_pin: pin,
      two_factor_token: token,
      name: details?.name,
      selected_account_number:
        details?.source_account?.account_number ||
        actionCtx?.currentAccount?.account_number,
      account_number:
        details?.source_account?.account_number ||
        actionCtx?.currentAccount?.account_number,
      schedule: fromDate ? "later" : "now",
      schedule_date: fromDate ? fromDate : "",
      description: details?.description,
      document: details?.des_imges,
    };
    // console.log(obj);
    // return
    const data = await dispatch(
      makeBettingPayment(convertObjectToFormData(filterEmptyColumns(obj)))
    );
    // console.log(data);
    if (data?.payload?.data?.status === "success") {
      handleFinish();
    }
  };

  const handleFinish = () => {
    // setSelectedProvider("");
    dispatch(getUser());
    dispatch(getAllAccounts());
    setDetails({
      customer_id: "",
      type: "",
      amount: "",
      user_pin: "",
      name: "",
      retrieve_info: "",
      beneficiary: "",
      source_account: "",
      description: "",
      des_imges: [],
    });
    setStep(1);
    setShowModal((prev) => {
      return { ...prev, success: true, pin: false };
    });
  };
  const size = useWindowSize();

  useEffect(() => {
    if (
      actionCtx?.currentAccount?.authorization_level !==
        ("initiator_approver" || "initiator") &&
      actionCtx?.currentAccount?.authorization_level !==
        ("initiator" || "initiator_approver")
    ) {
      navigate("/dashboard-bill-payment");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const formatObjValue = (param) => {
  //   return { ...param, label: param?.value };
  // };

  // const showLabelFormat = (imgUrl, name) => {
  //   return (
  //     <div
  //       style={{
  //         cursor: "pointer",
  //           paddingBottom: "1rem",
  //         alignItems: "center",
  //         display: "flex",
  //         gap: ".5rem",

  //       }}
  //       className={`name-account-box`}
  //     >
  //       <figure style={{ width: "3rem", height: "3rem", borderRadius: "50%" }}>
  //         <img
  //           style={{ width: "100%", height: "100%", objectFit: "contain" }}
  //           src={imgUrl}
  //           alt=""
  //         />
  //       </figure>
  //       <p>{name}</p>
  //     </div>
  //   );
  // };

  // const formatOptionOne = (list) => {
  //   if (list?.length > 0) {
  //     const newList = list?.map((chi, idx) => {
  //       return {
  //         ...chi,
  //         label: showLabelFormat(chi?.logo, chi?.label),
  //         value: chi?.value,
  //       };
  //     });
  //     return newList;
  //   }
  // };

  return (
    <>
      <DashboardLayout>
        <TitletextBox
          title={`Betting`}
          text={`Fund your betting wallet straight from here.`}
          back={true}
          onBack={() => {
            navigate("/dashboard-bill-payment");
          }}
        />
        <ContainerWrapper
          width={size.width < 900 && `100%`}
          loading={loading}
          stepList={stepList}
          activeStep={step}
          back={step > 1}
          onBack={() => {
            step > 1 && setStep(step - 1);
          }}
          onBtnClick={() => {
            if (step === 1) {
              setStep(2);
            }
            if (step === 2) {
              if (user?.pin_status === 0) {
                actionCtx.setCheckShow("nine");
              } else {
                setShowModal((prev) => {
                  return { ...prev, pin: true };
                });
              }
              // setShowModal((prev) => {
              //   return { ...prev, pin: true };
              // });
            }
          }}
          btnText={`Complete Transaction`}
          stepClickable={
            details?.amount &&
            details?.type &&
            details?.name &&
            details?.customer_id
          }
          onStepClick={(e) => {
            details?.amount &&
              details?.type &&
              details?.name &&
              details?.customer_id &&
              setStep(e);
          }}
          disabled={
            step === 2 &&
            actionCtx?.currentAccount?.authorization_level === "initiator" &&
            !details?.description
              ? true
              : step === 1
              ? !details?.amount ||
                !details?.type ||
                !details?.name ||
                !details?.customer_id
              : false
          }
        >
          <div className="bill-payment-airtime-wrap bill-payment-betting-wrap animate-move-up-class">
            {step === 1 && (
              <>
                {/* form input start */}
                <form autoComplete="off" style={{ width: "100%" }} action="">
                  {" "}
                  <RavenInputField
                    type={`select`}
                    color={`black-light`}
                    label={`Betting provider* `}
                    placeholder={`e.g sporty bet`}
                    style={{ position: "relative", zIndex: "20" }}
                    selectStyles={reactSelectStyle}
                    selectOption={betTypes}
                    value={details?.type}
                    onChange={(e) => {
                      e.value !== details?.type?.value &&
                        setDetails((prev) => {
                          return { ...prev, type: e, customer_id: "" };
                        });
                    }}
                  />
                </form>

                {/* form input end */}
                {/* form input start */}
                <form autoComplete="off" style={{ width: "100%" }} action="">
                  {" "}
                  <RavenInputField
                    type={`text`}
                    color={`black-light`}
                    label={`User ID `}
                    placeholder={`Enter betting number .`}
                    disabled={!details?.type}
                    onChange={handleChange}
                    value={details?.customer_id}
                    name={`customer_id`}
                    onActionClick={validateBetidFunc}
                    labelSpanText={
                      loadingVerify ? (
                        <div
                          style={{
                            padding: "0rem",
                            display: "flex",
                            justifyContent: "flex-end",
                            transform: "translateX(1.5rem)",
                          }}
                          className="load-wrap"
                        >
                          <ThreeDots
                            height="10"
                            width="80"
                            radius="9"
                            // color="#020202"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                          />
                        </div>
                      ) : (
                        "Verify"
                      )
                    }
                    labelColor={`purple-light`}
                  />
                </form>

                {/* form input end */}
                {/* verify name box start */}
                <div
                  className={`verify-name-box ${
                    details?.name && "verify-name-box-show"
                  }`}
                >
                  <RavenCheckBox
                    checked={true}
                    color={`black-light`}
                    className={`check`}
                  />
                  <p className="text">{details?.name || "Verified"}</p>
                </div>
                {/* verify name box end */}
                {/* form input start */}
                <form autoComplete="off" style={{ width: "100%" }} action="">
                  {" "}
                  <RavenInputField
                    type={`number`}
                    color={`black-light`}
                    label={`Amount*  `}
                    placeholder={`E.g 5,000.00`}
                    onActionClick={() => {
                      actionCtx?.setShowAmount(!actionCtx?.showAmount);
                    }}
                    onChange={handleChange}
                    value={details?.amount}
                    name={`amount`}
                    thousandFormat
                    labelSpanText={`Bal: ${
                      actionCtx?.showAmount
                        ? details?.source_account
                          ? RavenNumberFormat(details?.source_account?.balance)
                          : formatNumWithCommaNairaSymbol(
                              actionCtx?.currentAccount?.balance
                            )
                        : "••••••"
                    }`}
                    labelColor={`black-light`}
                    labelClassName={`label-span-amount label-span-theme`}
                  />
                </form>

                {/* form input end */}
              </>
            )}
            {step === 2 && (
              <>
                {/* select schedule start */}
                {/* <SelectScheduleBox
                  id={`onupd-iu`}
                  value={fromDate}
                  onChange={(e) => setFromDate(e)}
                  onReset={() => setFromDate("")}
                /> */}
                {/* select schedule end */}
                {/* <PreviewBettingDetailsBox
                  value={details?.beneficiary}
                  onChange={(e) => {
                    setDetails((prev) => {
                      return { ...prev, beneficiary: e.target.checked };
                    });
                  }}
                  provider={details?.type?.value}
                  name={details?.name}
                  //   narration={details?.narration}
                  number={details?.customer_id}
                  amount={details?.amount}
                  checked={details?.beneficiary}
                /> */}
                <NewPreviewDetailBox
                  onReset={() => setFromDate("")}
                  valueSchedule={fromDate}
                  onChangeSchedule={(e) => {
                    setFromDate(e);
                  }}
                  value={details?.beneficiary}
                  onChange={(e) => {
                    setDetails((prev) => {
                      return { ...prev, beneficiary: e.target.checked };
                    });
                  }}
                  narration={details?.narration}
                  //   previewOpen={previewOpen}
                  //   setPreviewOpen={setPreviewOpen}
                  logo={details?.type?.logo}
                  provider={details?.type?.value}
                  name={details?.name}
                  //   narration={details?.narration}
                  number={details?.customer_id}
                  amount={details?.amount}
                  checked={details?.beneficiary}
                  onSourceSelect={(param) => {
                    setDetails((prev) => {
                      return { ...prev, source_account: param };
                    });
                  }}
                  sourceAccount={
                    details?.source_account || actionCtx?.currentAccount
                  }
                />
                {/* initiar attachment start */}
                {actionCtx?.currentAccount?.authorization_level ===
                  "initiator" && (
                  <>
                    <div
                      style={{
                        maxWidth: "40rem",
                        display: "flex",
                        flexDirection: "column",
                        gap: "2rem",
                      }}
                      className="initiator-input-box-wrapper"
                    >
                      <RavenInputField
                        placeholder="Enter description"
                        color="black-light"
                        label="Add description *"
                        name="description"
                        onChange={(e) => {
                          setDetails((prev) => {
                            return { ...prev, description: e.target.value };
                          });
                        }}
                        value={details?.description}
                      />
                      {/* add and preview images box start */}
                      <DescriptionPreviewBox
                        onAdd={(param) => {
                          const newList = [param, ...details?.des_imges];
                          setDetails((prev) => {
                            return { ...prev, des_imges: newList };
                          });
                        }}
                        imgList={details?.des_imges}
                        onRemove={(param) => {
                          setDetails((prev) => {
                            return { ...prev, des_imges: param };
                          });
                        }}
                        onView={(param) => {
                          actionCtx.setImgPreviewVal(
                            URL.createObjectURL(param)
                          );
                        }}
                      />
                      {/* add and preview images box end */}
                    </div>
                  </>
                )}
                {/* intitiator attachment end */}
              </>
            )}
          </div>
        </ContainerWrapper>
      </DashboardLayout>
      {/* modal start */}
      <TransactionPinModal
        pin={details?.user_pin}
        setPin={(e) => {
          setDetails((prev) => {
            return { ...prev, user_pin: e };
          });
        }}
        onPay={(pin, token) => {
          // console.log(pin, token);
          handleSubmit(pin, token);
        }}
        value={details?.transfer_pin}
        visible={showModal.pin}
        onCancel={() => {
          setDetails((prev) => {
            return { ...prev, transfer_pin: "" };
          });
          setShowModal((prev) => {
            return { ...prev, pin: false };
          });
        }}
        loading={loading}
      />
      {/* pin modal end */}
      {/* sign up modal start */}
      <SuccessModal
        visible={showModal.success}
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, success: false };
          });
        }}
        title="Betting Topup Successful."
        text={` You should receive a confirmation message with the details of your payment shortly.  We appreciate your business and look forward to serving you in the future`}
        btnText={`Close`}
        onBtnClick={() => {
          setShowModal((prev) => {
            return { ...prev, success: false };
          });
        }}
      />
    </>
  );
};

export default DashboardBettingIndex;
