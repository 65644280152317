import React, { useState } from "react";
import "./CollapseFragment.css";



const CollapseFragment = ({
  children,
  className,
  collapse,
  collapseHeight,
  title,
  onCollapse,
  disableDefaultControl,
  iconColor,
  iconSize,
  titleSize,
  titleColor,
  collapseIcon,
  hideCollapseIcon,
  hideTitle,
}) => {
  const [defaultCollapse, setDefaultCollapse] = useState(true);

  const dropIcon = (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="img"
    >
      <path d="M8 10.5L12 14.5L16 10.5" stroke={iconColor || "#596080"} />
    </svg>
  );

  return (
    <div
      className={`reusable-collapse-fragment-box-index-wrapper ${
        !disableDefaultControl && !defaultCollapse
          ? `reusable-collapse-fragment-box-index-wrapper-open`
          : ``
      } ${
        disableDefaultControl && !collapse
          ? `reusable-collapse-fragment-box-index-wrapper-open`
          : ``
      } ${className ? className : ""}`}
      style={
        {
          "--title-size": titleSize || "1.45rem",
          "--collapse-height": collapseHeight || "30rem",
          "--title-color": titleColor || "black",
          "--icon-size": iconSize || "3rem",
        } 
      }
    >
      {/* title drop icon start  */}
      {!hideTitle && (
        <div
          onClick={() => {
            if (!disableDefaultControl) {
              setDefaultCollapse(!defaultCollapse);
            } else {
              onCollapse && onCollapse();
            }
          }}
          className="title-drop-icon-box"
        >
          <p className="title-collapse">{title || "Title"}</p>
          {!hideCollapseIcon && (
            <div className="icon-box">
              <figure className="img-box">
                {collapseIcon ? collapseIcon : dropIcon}
              </figure>
            </div>
          )}
        </div>
      )}
      {/* title drop icon end  */}
      {/* collapse box start */}
      <div className="collapse-outer-box">
        <div className="collapse-inner-box">{children}</div>
      </div>
      {/* collapse box end */}
    </div>
  );
};

export default CollapseFragment;
